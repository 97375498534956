<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader :HeaderTitle="HeaderTitle" :confirmBack="confirmBack">
        </FormHeader>
        <center>
          <md-card style="width:50%;min-width: 500px">
            <md-card-content>
              <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :formMessage="formMessage"
                :showFormMessage="showFormMessage"
              >
              </FormComponent>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { FormComponent, FormHeader, Spinner } from "@/components";
export default {
  methods: {
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    formSubmit: async function(object) {
      this.isLoading = true;
      const TemporaryPassword = this.createPassword();
      object["TemporaryPassword"] = TemporaryPassword;
      console.log(object);
      try {
        const ret = await this.$store.dispatch("createCustomer", {
          router: this.$router,
          customer: object
        });
        this.isLoading = false;
        this.$router.push({
          name: "Customer List",
          params: { message: "New customer added" }
        });
      } catch (err) {
        this.isLoading = false;
        console.log("createCustomer error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    createPassword() {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 16; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  },
  components: {
    FormComponent,
    FormHeader,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Customer",
      FormTitle: "Customer Profile",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      FormItem: {
        row1: [
          {
            type: "text",
            key: "GivenName",
            name: "First Name",
            required: true,
            value: ""
          },
          {
            type: "text",
            key: "FamilyName",
            name: "Last Name",
            required: true,
            value: ""
          }
        ],
        row2: [
          {
            type: "text",
            key: "Name",
            name: "Username",
            required: true,
            value: ""
          }
        ],
        row3: [
          {
            type: "phone",
            key: "Phone",
            name: "Phone",
            required: true,
            value: "",
            countyCode: ""
          },
          {
            type: "text",
            key: "Email",
            name: "Email",
            required: true,
            value: ""
          }
        ],
        row4: [
          {
            type: "date",
            key: "Birthday",
            name: "Birthday",
            required: true,
            value: ""
          },
          {
            type: "select",
            key: "Gender",
            name: "Gender",
            required: true,
            value: "",
            option: [
              {
                key: "M",
                name: "M"
              },
              {
                key: "F",
                name: "F"
              },
              {
                key: "Others",
                name: "Others"
              }
            ]
          }
        ]
      }
    };
  }
};
</script>
