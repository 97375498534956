<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <TableHeader
          :HeaderTitle="HeaderTitle"
          :AddNewButton="AddNewButton"
          v-on:createNewItem="createNewItem"
        ></TableHeader>
        <md-card>
          <md-card-content>
            <TableComponent
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectSortOption="selectSortOption"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              v-on:SortPopupControl="SortPopupControl"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :sortOption="sortOption"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
              :showSortPopup="showSortPopup"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showPopup">
        <Popup
          v-on:popupcancel="popupcancel"
          v-on:popupaction="popupaction"
          :PopupTitle="PopupTitle"
          :PopupContent="PopupContent"
          :actionButton="actionButton"
        ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { TableComponent, TableHeader, Popup, Spinner } from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      message: "",
      showPopup: false,
      showSortPopup: false,
      PopupTitle: "Disable account",
      PopupContent: "This action will forbid customers to access their account",
      actionButton: "Disable anyway",
      index: 0,
      Sort: [],
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "Customer Group",
      TableItemList: [],
      AddNewButton: "Add Group",
      columns: ["Customer Group Name", "Created Date", "Users in Group"],
      //sortOption:[],
      sortOption: [
        {
          Key: "CreatedDatetime",
          Name: "Recent Added",
          Value: false,
          Order: "desc"
        },
        {
          Key: "UpdateDateTime",
          Name: "Last Update",
          Value: false,
          Order: "desc"
        },
        {
          Key: "UsersInGroup",
          Name: "Users Number",
          Value: true,
          Order: "desc"
        }
      ],
      selectedItemAction: [],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true
    };
  },
  async created() {
    this.isLoading = true;
    this.message = this.$route.params.message;
    if (this.$route.params.page) {
      if (Number(this.$route.params.page) > 0) {
        this.pageNumber = Number(this.$route.params.page);
      }
      this.index =
        this.index + (Number(this.$route.params.page) - 1) * this.displaySize;
    }
    this.checkShowMessage();
    this.setDefaultSort();
    await this.searchItem({ keyword: "" });
    this.isLoading = false;
  },
  methods: {
    setDefaultSort: function() {
      var index = this.sortOption.findIndex(option => option.Value === true);
      var object = {};
      object[this.sortOption[index].Key] = {
        order: this.sortOption[index].Order
      };
      this.Sort = [object];
    },
    SortPopupControl: function() {
      this.showSortPopup = !this.showSortPopup;
    },
    goPage: function(action) {
      if (action == "next") {
        this.index = this.index + this.displaySize;
        this.pageNumber = this.pageNumber + 1;
      } else if (action == "prev" && this.index > 0) {
        this.index = this.index - this.displaySize;
        this.pageNumber = this.pageNumber - 1;
      } else {
        this.index = 0;
        this.pageNumber = 1;
      }
      this.$router.replace({
        name: "CustomerGroup List PageNumber",
        params: { page: this.pageNumber }
      });
      this.searchItem({ keyword: "" });
    },
    checkShowMessage: async function() {
      if (this.message) {
        try {
          await this.delayFunction();
          this.showMessage = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    delayFunction: async function() {
      setTimeout(function() {
        this.showMessage = false;
        this.message = "";
        return true;
      }, 1000);
    },
    selectedItemActionFunction: function(action) {
      console.log("selectedItemActionFunction", action);
    },
    onclickItem: function(ID) {
      console.log("onclickItem", ID);
      this.$router.push({
        name: "CustomerGroup Detail",
        params: { customerGroupID: ID }
      });
    },
    createNewItem: function() {
      this.$router.push({ name: "Create Customer Group" });
    },
    popupcancel: function() {
      console.log("popupcancel");
      this.showPopup = false;
    },
    popupaction: function() {
      console.log("popupaction");
      this.showPopup = false;
    },

    selectSortOption: function(option) {
      console.log("charge sorting with " + option);
      var sortKey = "";
      var sortOrder = "";
      for (var i = 0; i < this.sortOption.length; i++) {
        if (this.sortOption[i].Key == option) {
          if (this.sortOption[i].Value == true) {
            if (this.sortOption[i].Order == "asc") {
              this.sortOption[i].Order = "desc";
            } else {
              this.sortOption[i].Order = "asc";
            }
          } else {
            this.sortOption[i].Value = true;
          }
          sortKey = this.sortOption[i].Key;
          sortOrder = this.sortOption[i].Order;
        } else {
          this.sortOption[i].Value = false;
        }
      }
      var object = {};
      object[sortKey] = { order: sortOrder };
      this.Sort = [object];
      this.showSortPopup = false;
      this.searchItem({ keyword: "" });
    },
    selectAllItem: function(action) {
      this.selectAll = false;
      if (action) {
        this.selectAll = true;
        for (var i = 0; i < this.TableItemList.length; i++) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index < 0) {
            this.selectedItem.push(this.TableItemList[i].ID);
          }
        }
      } else {
        for (var i = 0; i < this.TableItemList.length; i++) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          this.selectedItem.splice(index, 1);
        }
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectItem: function(ID, action) {
      if (action) {
        this.selectedItem.push(ID);
      } else {
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID);
        this.selectedItem.splice(index, 1);
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    searchItem: async function({ keyword }) {
      this.isLoading = true;
      var object = {
        Size: this.displaySize,
        From: this.index,
        Sort: this.Sort
      };
      if (keyword) {
        object.Keyword = keyword;
        this.selectAll = false;
        (this.selectedItem = []), (this.selectedItemCounter = 0);
        this.index = 0;
        this.pageNumber = 1;
      }
      try {
        const ret = await this.$store.dispatch("listUserGroup", {
          router: this.$router,
          data: object
        });
        this.setDisplayList(ret.data.data.hits.hits);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("listUserGroup error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    // listItem: async function(){
    //   this.isLoading = true;
    //   var object = {
    //     "Size": this.displaySize,
    //     "From": this.index,
    //     "Sort": this.Sort
    //   }
    //   console.log(object);
    //   try{
    //     const ret= await this.$store.dispatch('listUserGroup',{router:this.$router,data:object})
    //     this.setDisplayList(ret.data.data.hits.hits)
    //     this.isLoading = false;
    //   }catch(err){
    //     this.isLoading = false;
    //     console.log("listUserGroup error", err.response.data);
    //     this.formMessage = err.response.data.message
    //     this.showFormMessage = true
    //   }
    //   },
    setDisplayList(list) {
      var newlist = [];
      for (var i = 0; i < list.length; i++) {
        //var date = list[i]["_source"].CreatedDatetime.split('T')[0]
        var date = new Date(list[i]["_source"].CreatedDatetime);
        var object = {
          ID: list[i]["_source"].UserGroupID,
          "Customer Group Name": list[i]["_source"].UserGroupID,
          "Created Date": date,
          "Users in Group": list[i]["_source"].UsersInGroup
        };
        newlist.push(object);
      }
      this.TableItemList = newlist;
      this.TableSelectUIControl(this.TableItemList);
    },
    TableSelectUIControl(ItemList) {
      this.showNextButton = true;
      if (ItemList.length < this.displaySize) {
        this.showNextButton = false;
      }

      var counter = 0;
      for (var i = 0; i < ItemList.length; i++) {
        var index = this.selectedItem.indexOf(ItemList[i].ID);
        if (index > -1) {
          counter++;
        }
      }
      if (counter == ItemList.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  }
};
</script>
