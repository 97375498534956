import { get } from "jquery";
import oneSipApiCall from "../apiCall.js";

const table = {

  createProductType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-product-type",
      data
    );
  },
  getProductType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-product-type",
      data
    );
  },
  updateProductType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-product-type",
      data
    );
  },
  listProductType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-list-product-type",
      data
    );
  },
  getLanguage({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-language", data);
  },
  updateLanguage({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-update-language", data);
  },
  deleteLanguage({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-delete-language", data);
  },
  getCountry({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-country", data);
  },
  updateCountry({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-update-country", data);
  },
  searchCountry({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-search-country", data);
  },
  searchParingTag({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-paring-tag",
      data
    );
  },
  createCountry({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-country", data);
  },
  createParingTag({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-paring-tag",
      data
    );
  },
  updateParingTag({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-paring-tag",
      data
    );
  },
  deleteParingTag({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-paring-tag",
      data
    );
  },
  getParingTag({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-paring-tag", data);
  },
  createAroma({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-aroma", data);
  },
  getAroma({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-aroma", data);
  },
  getAromaKeyword({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-aroma-keyword", data);
  },
  updateAroma({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-update-aroma", data);
  },
  deleteAroma({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-delete-aroma", data);
  },
  deleteAromaKeyword({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-delete-aroma-keyword", data);
  },
  searchAroma({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-search-aroma", data);
  },
  createTasteNote({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-tastenote",
      data
    );
  },
  getTasteNote({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-tastenote", data);
  },
  getTasteNoteKeyword({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-tastenote-keyword",
      data
    );
  },
  deleteTasteNoteKeyword({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-tastenote-keyword",
      data
    );
  },
  updateTasteNote({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-tastenote",
      data
    );
  },
  deleteTasteNote({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-tastenote",
      data
    );
  },
  setTasteNoteRelation({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/setTasteNoteRelation",
      data
    );
  },
  searchTasteNotes({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-tastenote",
      data
    );
  },
  listTemporaryTasteNote({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/listTemporaryTasteNote",
      data
    );
  },
  deleteOrHideTemporaryTastenote({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-hide-delete-temporary-tastenote",
      data
    );
  },
  getAllTasteNoteRelation({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/getAllTasteNoteRelation",
      data
    );
  }
};

export default table;
