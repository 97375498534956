<template lang="html">
  <div class="container">
    <ShareNetwork
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :style="{ backgroundColor: network.color }"
      :url="sharing.url"
      :title="sharing.title"
      :description="sharing.description"
      :quote="sharing.quote"
      :hashtags="sharing.hashtags"
      :twitterUser="sharing.twitterUser"
    >
      <div class="label">
        <div class="icon">
          <font-awesome-icon :icon="network.icon" />
        </div>
        <div class="name">{{ network.name }}</div>
      </div>
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: "ShareButtonsCon",
  data() {
    return {
      sharing: {
        url: "https://pwa.1sip.com.hk/",
        title: "Testing Title",
        description: "Testing Description",
        quote: "Testing Quote"
      },
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "far fah fa-lg fa-envelope",
          color: "#333333"
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fab fah fa-lg fa-facebook-f",
          color: "#1877f2"
        },
        {
          network: "line",
          name: "Line",
          icon: "fab fah fa-lg fa-line",
          color: "#00c300"
        },
        {
          network: "messenger",
          name: "Messenger",
          icon: "fab fah fa-lg fa-facebook-messenger",
          color: "#0084ff"
        },
        // { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fab fah fa-lg fa-telegram-plane",
          color: "#0088cc"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fah fa-lg fa-twitter",
          color: "#1da1f2"
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fah fa-lg fa-whatsapp",
          color: "#25d366"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  a {
    margin: 5px;
    border-radius: 5px;
    color: #ffffff !important;

    &:hover {
      text-decoration: none;
    }
  }
}

.label {
  display: flex;
  align-items: center;

  > .icon {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
    font-size: 1.35em;
  }

  > .name {
    padding: 0 10px;
  }
}
</style>
