<template>
  <div>
    <div v-if="QuestionList.length == 0" style="text-align:center">
      <md-card style="margin:10px 0">
        <md-card-content>
          <h3 class="formTitle">Create your first question</h3>
          <p style="font-weight: 500;">
            Creating question, you'll be able to create your survey template.
          </p>
          <md-button
            style=" background-color: #575757 !important;width: 30% !important;"
            @click="addQuestion()"
            >Start</md-button
          >
        </md-card-content>
      </md-card>
    </div>
    <div v-if="QuestionList.length > 0">
      <SurveyQuestionForm
        style="width: 90%;"
        v-for="(question, index) in QuestionList"
        v-on:removeQuestion="removeQuestion"
        v-on:diplicateQuestion="diplicateQuestion"
        v-on:moveUp="moveUp"
        v-on:moveDown="moveDown"
        :key="index"
        :index="index"
        :questionObj="question"
      />
      <md-card>
        <md-card-content>
          <div style="text-align: center;" @click="addQuestion()">
            <md-icon>add_circle_outline</md-icon>
            <u class="addQuestion">Add new question</u>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SurveyQuestionForm from "./SurveyQuestionForm.vue";

export default {
  components: {
    SurveyQuestionForm
  },
  methods: {
    moveUp: function(index) {
      console.log("moveUp", index);
      if (index != 0) {
        var temp = this.QuestionList[index - 1];
        this.$set(this.QuestionList, index - 1, this.QuestionList[index]);
        this.$set(this.QuestionList, index, temp);
      }
    },
    moveDown: function(index) {
      console.log("moveDown", index);
      if (index != this.QuestionList.length - 1) {
        var temp = this.QuestionList[index + 1];
        this.$set(this.QuestionList, index + 1, this.QuestionList[index]);
        this.$set(this.QuestionList, index, temp);
      }
    },
    diplicateQuestion: function(index) {
      console.log("diplicateQuestion", index);
      this.QuestionList.splice(
        index + 1,
        0,
        JSON.parse(JSON.stringify(this.QuestionList[index]))
      );
    },
    removeQuestion: function(index) {
      console.log("removeQuestion", index);
      this.QuestionList.splice(index, 1);
    },
    addQuestion: function() {
      var object = {
        Question: null,
        Type: null,
        Optional: true,
        Hits: "",
        OtherOption: false
      };
      this.QuestionList.push(object);
    }
  },

  name: "survey-template-question-component",
  props: {
    QuestionList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>

<style>
.addQuestion {
  cursor: pointer;
  font-size: 18px;
}
.formTitle {
  padding-left: 15px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
}
</style>
