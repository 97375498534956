<template>
  <md-card class="md-card-nav-tabs" md-with-hover>
    <md-card-media>
      <img
        :src="process.env.VUE_APP_S3_URL + 'product/' + item.product.ProductID"
        alt="item.product.Name"
      />
    </md-card-media>
    <md-card-header>
      <div>{{ item.product.Name }}</div>
      <div class="md-subhead"></div>
    </md-card-header>
    <md-card-content>
      <slot name="content">Quantity: {{ item.quantity }}</slot>
      <br v-if="item.discount != 0" />
      <slot v-if="item.discount != 0" name="content"
        >Discount: {{ item.discount }} % off</slot
      >
      <br v-if="item.Frequency" />
      <slot v-if="item.Frequency" name="content"
        >Frequency: {{ item.Frequency }}</slot
      >
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "selected-product-card",
  props: ["item"],
  data: () => ({
    loading: false,
    image: null,
    defaultImage: require("@/assets/img/we_sip_logo.png")
  }),
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.md-card .md-title {
  font-size: 18px;
  line-height: 26px;
}
.md-card .md-card-header {
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
  box-shadow: none;
  text-align: center;
}
</style>
