<template>
  <div class="overlay">
    <Spinner v-if="isLoading" />
    <Transition name="modal" v-if="showPopup && !isLoading">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div
                id="modal-header"
                :class="`modal-header ${isEndedStatus ? 'disabled' : ''}`"
              >
                <slot name="header">
                  <div class="event-div">
                    <div>
                      <div class="event-title">
                        {{ this.eventRecord.data.EventDetail.Title }}
                      </div>
                      <div class="event-info">
                        <div>
                          {{
                            this.eventRecord.data.EventDetail.EventDate +
                              " " +
                              this.eventRecord.data.EventDetail.EventStartTime +
                              " - " +
                              this.eventRecord.data.EventDetail.EventEndTime
                          }}
                        </div>
                        <div>
                          {{ this.eventRecord.data.EventDetail.Location.Name }}
                        </div>
                        <div>
                          {{ this.eventRecord.data.EventDetail.Host.Name.en }}
                        </div>
                      </div>
                    </div>
                    <div v-if="isEndedStatus" class="status-div">
                      {{ status }}
                    </div>
                  </div>
                </slot>
              </div>
              <div class="modal-body" v-if="!isLoading">
                <div class="parti-info">
                  <div class="left">
                    <div class="parti-title">Participant Information</div>
                    <div>
                      Participant name: {{ this.user.data.userInfo.Name }}
                    </div>
                    <div>
                      Guest Invited:
                      {{ this.eventRecord.data.NumberOfSeat - 1 }}
                    </div>
                  </div>
                  <div class="right">
                    <div>Arrival Status</div>
                    <div class="seatNum">
                      {{
                        this.eventRecord.data.ParticipatedNumber +
                          "/" +
                          this.eventRecord.data.NumberOfSeat
                      }}
                    </div>
                  </div>
                </div>
                <div class="guest-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(guest, i) in this.eventRecord.data.Guest"
                        :key="i"
                      >
                        <td>{{ guest.Name }}</td>
                        <td>{{ guest.Contact }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="this.canAttend" class="attendance">
                  <div class="left">
                    <div class="attendance-title">Take Attendance</div>
                    <div class="num-input-div">
                      <NumberInputBox
                        :value="takeSeat"
                        :max="maxSeat"
                        @update="updateSeat"
                      />
                    </div>
                    <div>
                      <button class="attend-btn" @click="takeAttendance">
                        Mark Attendance
                      </button>
                    </div>
                  </div>
                  <div class="right">
                    <a class="cancel-btn" @click="cancel">Cancel</a>
                  </div>
                </div>
                <div v-if="!this.canAttend" class="done">
                  <button class="done-btn" @click="cancel">Done</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <md-snackbar :md-active.sync="showSnackMessage">{{ snackMsg }}</md-snackbar>
  </div>
</template>
<script>
//import NumberInput from "./FormComponents/NumberInput.vue";
import { Spinner } from "@/components";
import NumberInputBox from "@/components/v2/NumberInputBox.vue";
export default {
  components: {
    Spinner,
    NumberInputBox
    //NumberInput,
  },
  created() {
    //this.getLanguage();
  },
  watch: {
    code: function(newVal, oldVal) {
      console.log(newVal);
      this.getUserEventRecord(newVal);
    }
  },
  computed: {
    isEndedStatus: function() {
      return this.status == "Ended";
    }
  },
  methods: {
    getUserEventRecord: async function(code) {
      this.isLoading = true;
      this.eventRecord = (
        await this.$store.dispatch("scanQRCode", {
          router: this.$router,
          data: { code: code }
        })
      ).data;
      this.user = await this.$store.dispatch("getCustomerInfo", {
        router: this.$router,
        customer: { userID: this.eventRecord.data.UserID }
      });
      console.log(this.eventRecord);
      console.log(this.user);
      this.maxSeat =
        this.eventRecord.data.NumberOfSeat -
        this.eventRecord.data.ParticipatedNumber;
      this.takeSeat =
        this.eventRecord.data.NumberOfSeat -
        this.eventRecord.data.ParticipatedNumber;
      this.canAttend = this.eventRecord.canAttend;
      this.status = this.eventRecord.data.Status;
      //this.updateStatus()
      this.isLoading = false;
      //console.log(this.status)
      //console.log(this.isEndedStatus);
    },
    updateSeat: function(newVal) {
      this.takeSeat = newVal;
    },
    takeAttendance: async function() {
      var data = (
        await this.$store.dispatch("takeAttendance", {
          router: this.$router,
          data: { code: this.code, addNumber: this.takeSeat }
        })
      ).data;
      console.log(data);
      this.eventRecord.data.ParticipatedNumber =
        data.data.Attributes.ParticipatedNumber;

      if (
        this.eventRecord.data.ParticipatedNumber >=
        this.eventRecord.data.NumberOfSeat
      ) {
        this.canAttend = false;
      }
      this.takeSeat =
        this.eventRecord.data.NumberOfSeat -
        this.eventRecord.data.ParticipatedNumber;
      this.showPopup = false;
      this.updateAttendance = true;
      this.maxSeat =
        this.eventRecord.data.NumberOfSeat -
        this.eventRecord.data.ParticipatedNumber;
      //this.$emait('update:updateAttendance',true)
      this.$emit("update:showPopup", false);
    },
    cancel: function() {
      this.showPopup = false;
      this.$emit("update:showPopup", false);
    },
    updateStatus() {
      if (this.isEndedStatus) {
        document.querySelector("#modal-header").classList.add("disabled");
      } else {
        document.querySelector("#modal-header").classList.remove("disabled");
      }
    }
  },
  name: "user-event-popup",
  props: {
    showPopup: {
      required: true,
      type: Boolean,
      default() {
        return false;
      }
    },
    updateAttendance: {
      default() {
        return false;
      }
    },
    code: {
      required: true,
      type: String,
      default() {
        return "";
      }
    }
  },
  data() {
    return {
      isLoading: false,
      eventRecord: null,
      user: null,
      takeSeat: 0,
      maxSeat: 0,
      canAttend: false,
      snackMsg: "",
      showSnackMessage: false,
      status: ""
    };
  }
};
</script>
<style>
.popup {
  padding: 30px 0px;
  margin: 0px 20px;
}
.popupcontent {
  width: 80%;
}
.modal-wrapper {
  z-index: -1;
}
.modal-container {
  z-index: 50;
  min-width: 350px;
  max-width: 400px;
}
.modal-header {
  font-size: 24px;
  font-weight: 500;
}
.modal-body {
  font-size: 16px;
  font-weight: 500;
}
.cancelButton {
  width: 100%;
  background-color: #575757;
  color: white;
  border-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.actionButton {
  width: 100%;
  background-color: white;
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
/* .add-variance-container{
        max-width: 50%;
        max-height: 800px;
        margin: 0px auto;
        position: relative;
        background-color: #fff;
        transition: all .3s ease;
        box-shadow: 0 27px 24px 0 rgba(0,0,0,.2), 0 40px 77px 0 rgba(0,0,0,.22);
        border-radius: 6px;
        border: none;
        overflow-y: scroll;
    } */
</style>
<style lang="scss" scoped>
.modal-header {
  background-color: #3a3a3a;
  color: white;
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  &.disabled {
    color: #858585;
  }
}
.modal-container {
  max-width: 800px;
}
.event-div {
  display: flex;
  align-items: center;
  width: 100%;
}
.event-title {
  font-weight: bolder;
  font-size: 28px;
  margin-bottom: 20px;
}
.event-info {
  font-weight: normal;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  div {
    margin-right: 16px;
  }
}
.status-div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: white;
}
.parti-info {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-bottom: 16px;
  .left {
    flex: 1;
    color: #525252;
    .parti-title {
      font-size: 28px;
      font-weight: bolder;
      color: black;
      margin-bottom: 28px;
    }
    div:not(.parti-info):not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .right {
    flex: 1;
    align-self: end;
    display: flex;
    flex-direction: column;
    align-items: self-end;
    .seatNum {
      margin-top: 16px;
      font-size: 36px;
    }
  }
}
.guest-table {
  table {
    thead {
      border-bottom: 1px solid #9e9e9e;
    }
    tbody {
      color: #525252;
    }
  }
}
.attendance {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
  .attendance-title {
    font-size: 28px;
    margin-right: 28px;
  }
  .num-input-div {
    margin-right: 20px;
  }
  .attend-btn {
    background-color: #565656;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    width: 180px;
    height: 40px;
    font-weight: lighter;
  }
}
.done {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .done-btn {
    background-color: #565656;
    outline: none;
    border: none;
    border-radius: 5px;
    color: white;
    width: 180px;
    height: 40px;
    font-weight: lighter;
  }
}
</style>
