<template>
  <div>
    <!-- <draggable
            class="dragArea"
            tag="ul"
            :list="list"
            :group="{ name: 'g1' }"
        > -->
    <draggable :list="FileList">
      <table class="fileSelecterTable">
        <tr v-for="(value, key) in FileList" :key="key">
          <td>
            <img
              v-if="value.url != ''"
              :src="value.url"
              style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
            />
            <md-field v-else style="margin-top:0px;padding-top:5px">
              <md-file
                :accept="value.accept"
                @md-change="uploadFile($event, key)"
              />
            </md-field>
          </td>
          <td v-if="value.url != ''" style="width: 30%;text-align: center;">
            <div class="imagePreview"><u>Remove</u></div>
          </td>
        </tr>
      </table>
    </draggable>
    <div style="text-align: center;" @click="addData(key, Item.key)">
      <md-icon>add_circle_outline</md-icon>
      <u class="addLang">{{ addDataText }}</u>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: {
    FileList: {
      type: Array,
      default: () => []
    },
    addDataText: {
      type: String,
      default: "Add"
    }
  },
  data() {
    return {};
  },
  methods: {
    uploadFile: async function(fileList, key) {
      if (fileList.constructor === FileList && fileList[0] !== undefined) {
        var file = fileList[0];
        this.FileList[key].file = file;
        this.FileList[key].base64 = await this.getBase64(file);
        this.FileList[key].url = URL.createObjectURL(file);
      }
      this.$emit("addData", key);
    },
    getBase64(file) {
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = ev => {
          resolve(ev.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  }
};
</script>
<style>
.fileSelecterTable {
  width: 100%;
}
.fileSelecterTable td {
  border: none;
}
</style>
