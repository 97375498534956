<template>
  <div>
    <label>{{ item.name }}</label
    ><label style="color:red" v-if="item.required">*</label>
    <div class="row" style="margin-top:0px;padding-top:5px">
      <md-field style="width: 20%;">
        <md-input
          type="number"
          class="formInput"
          min="0"
          v-model="item.hours"
          @change="getTimeString"
        />
      </md-field>
      時
      <md-field style="width: 20%;">
        <md-input
          type="number"
          class="formInput"
          min="0"
          max="59"
          v-model="item.minutes"
          @change="getTimeString"
        />
      </md-field>
      分
      <md-field style="width: 20%;">
        <md-input
          type="number"
          class="formInput"
          min="0"
          max="59"
          v-model="item.seconds"
          @change="getTimeString"
        />
      </md-field>
      秒
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    checkEdited: function(event) {
      console.log(event);
      this.$emit("checkEdited", true);
    },
    getTimeString() {
      var timeString = "";
      if (this.item.hours > 0) {
        timeString += this.item.hours + "h";
      }
      if (this.item.minutes > 0) {
        timeString += this.item.minutes + "m";
      }
      if (this.item.seconds > 0) {
        timeString += this.item.seconds + "s";
      }
      console.log("timeString", timeString);
      this.item.value = timeString;
    }
  },
  name: "time-input-component",
  props: {
    required: {
      default: false
    },
    item: {
      default: null
    }
  }
};
</script>
<style></style>
