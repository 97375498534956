<template>
    <div v-if="stepList.length == 0" style="text-align:center">
        <md-card style="margin:10px 0;width: 550px;">
            <md-card-content>
                <!-- <h3 class="formTitle">Create your first Step</h3> -->
                <md-button style=" background-color: #575757 !important;width: 30% !important;"
                    @click="addStep(0)">新增步驟</md-button>
            </md-card-content>
        </md-card>
    </div>
    <div v-else>
        <draggable class="dragArea" :list="stepList" :group="{ name: 'stepList' }" style="width: 90%;">
            <div v-for="(step, index) in stepList " :key="index" style="padding: 30px 20px;">
                <md-card style="margin: unset;">
                    <div style="position:absolute;right:-80px">
                        <table>
                            <tr>
                                <td>
                                    <md-button class="md-fab md-mini md-primary md-arrow" @click="delStep(index)"
                                        style="background-color: white !important;">
                                        <md-icon style="color: #cc2727 !important;">delete</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <md-button class="md-fab md-mini md-primary md-arrow" @click="moveUp(index)">
                                        <md-icon>arrow_upward</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <md-button class="md-fab md-mini md-primary md-arrow" @click="moveDown(index)">
                                        <md-icon>arrow_downward</md-icon>
                                    </md-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <md-card-content style="min-height: 150px;">
                        <table>
                            <tr>
                                <td style="width: 20%; min-width: 120px">
                                    <div v-if="step.image.url" class="iconButton">
                                        <label @click="removeIcon(index)"><md-icon class="delete-button"
                                                style="color: #cc2727 !important;">delete</md-icon></label>
                                        <img :src="step.image.url" @click="iconPopupControl(index, true)" />
                                    </div>
                                    <div v-else class="noicondiv" @click="iconPopupControl(index, true)">
                                        <md-icon style="font-size: 50px !important">add</md-icon>
                                    </div>
                                </td>
                                <td>
                                    <label>Step {{ index + 1 }}</label>
                                    <md-field style="margin-top:0px;padding-top:5px">
                                        <md-input type="text" class="formInput" v-model="step.step" />
                                    </md-field>
                                </td>
                            </tr>
                        </table>
                    </md-card-content>
                </md-card>
                <md-card style="margin: unset;">
                    <md-card-content>
                        <div style="text-align: center;" @click="addStep(index + 1)">
                            <md-icon>add_circle_outline</md-icon>
                            <u class="addQuestion">Add step</u>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </draggable>
        <Transition name="modal" v-if="showConfirmDeleteMedia">
            <Popup v-on:popupcancel="showConfirmDeleteMedia = false" v-on:popupaction="deleteStepImage"
                PopupTitle="刪除圖片" :PopupContent="PopupContent" actionButton="刪除"></Popup>
        </Transition>
        <iconPopup v-on:iconPopupControl="iconPopupControl" v-on:ImageToStep="ImageToStep" />
        <Spinner v-if="isLoading" />
    </div>

</template>

<script>
import {
    Spinner,
    Popup
} from "@/components";
import iconPopup from "./iconPopup.vue";
import draggable from "vuedraggable";
export default {
    components: {
        draggable,
        iconPopup,
        Spinner,
        Popup
    },
    created() {
        this.TasteCardID = this.$route.params.TasteCardID;
    },
    methods: {
        deleteStepImage: async function () {
            this.isLoading = true;
            try {
                const ret = await this.$store.dispatch("deleteStepImage", {
                    router: this.$router,
                    data: { CardID: this.TasteCardID, path: this.stepList[this.actionIndex].image.path }
                });
                this.isLoading = false;
                this.showConfirmDeleteMedia = false;
                this.stepList[this.actionIndex].image = {
                    id: "",
                    url: "",
                    type: "",
                    path: ""
                }
            } catch (error) {
                this.isLoading = false;
                console.log("deleteStepImage error", error);
            }
        },
        addStep: function (index) {
            console.log(this.stepList.length);
            this.paddingValue = 170 + this.stepList.length * 570;
            //this.$emit("addStep", index);
            this.stepList.splice(index, 0, {
                step: "",
                image: ""
            });
        },
        delStep: function (index) {
            this.paddingValue = 170 + this.stepList.length * 570;
            this.stepList.splice(index, 1);
        },
        moveUp: function (index) {
            if (index != 0) {
                var temp = this.stepList[index - 1];
                this.$set(this.stepList, index - 1, this.stepList[index]);
                this.$set(this.stepList, index, temp);
            }
        },
        moveDown: function (index) {
            console.log("moveDown", index);
            if (index != this.stepList.length - 1) {
                var temp = this.stepList[index + 1];
                this.$set(this.stepList, index + 1, this.stepList[index]);
                this.$set(this.stepList, index, temp);
            }
        },
        iconPopupControl: function (index, action) {
            console.log("addImage", index, action);
            this.stepIndex = index;
            let filterClass = document.querySelector(".filter-mask");
            if (action) {
                document.body.style.overflowY = "hidden";
                filterClass.classList.remove("filter-out");
                filterClass.classList.add("filter-in");
            } else {
                document.body.style.overflowY = "auto";
                filterClass.classList.remove("filter-in");
                filterClass.classList.add("filter-out");
            }
        },
        ImageToStep: function (image) {

            this.stepList[this.stepIndex].image = image
            this.iconPopupControl(0, false);
        },
        removeIcon: async function (index) {
            console.log("removeIcon", index);
            var image = this.stepList[index].image;
            console.log("removeIcon", image);
            if (image.type == "icon") {
                this.stepList[index].image = {
                    id: "",
                    url: "",
                    type: ""
                }
            } else if (image.type == "tempImage") {
                this.isLoading = true;
                try {
                    await this.$store.dispatch("deleteMedia", {
                        router: this.$router,
                        data: { MediaID: image.id }
                    });
                    this.isLoading = false;
                    this.stepList[index].image = {
                        id: "",
                        url: "",
                        type: ""
                    }
                } catch (err) {
                    this.isLoading = false;
                    console.log("deleteMedia error", err);
                }
            } else if (image.type == "image") {
                this.actionIndex = index;
                this.showConfirmDeleteMedia = true;
                this.PopupContent = "確定要刪除圖片嗎? 刪除後無法復原";
                // this.stepList[index].image = {
                //     id: "",
                //     url: "",
                //     type: ""
                // }
            }
        }
    },
    name: "step-component",
    props: {
        
        stepList: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            TasteCardID: "",
            icon: "icon",
            content: "content",
            paddingValue: 170,
            showiconPopup: false,
            stepIndex: 0,
            isLoading: false,
            showConfirmDeleteMedia: false,
            PopupContent: "",
            actionIndex: 0
        };
    },
    computed: {}
};
</script>

<style>
.addQuestion {
    cursor: pointer;
    font-size: 18px;
}

.formTitle {
    padding-left: 15px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 20px;
}

.iconButton {
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;
}

.noicondiv {
    text-align: center;
    cursor: pointer;
    height: 100px;
    width: 100px;
    border: solid black 2px;
    padding: 35px 0;
}

.delete-button {

    border-radius: 50% !important;
    color: red !important;
    border: none !important;
    cursor: pointer !important;
    position: absolute !important;
    top: -15px !important;
    right: -20px !important;
}
</style>