<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <TableHeader
          :HeaderTitle="HeaderTitle"
          :AddNewButton="AddNewButton"
          v-on:createNewItem="createNewItem"
        ></TableHeader>
        <md-card>
          <div class="selectedList">
            <label @click="setSelectList('Product')" :style="ProductColor"
              >產品</label
            >
            <label @click="setSelectList('Event')" :style="EventColor"
              >活動</label
            >
          </div>
          <ProductOrderList
            v-if="selectedList == 'Product'"
            v-on:setLoading="setLoading"
          ></ProductOrderList>
          <EventOrderList
            v-if="selectedList == 'Event'"
            v-on:setLoading="setLoading"
          >
          </EventOrderList>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import {
  TableHeader,
  ProductOrderList,
  EventOrderList,
  Spinner
} from "@/components";

export default {
  components: {
    TableHeader,
    ProductOrderList,
    EventOrderList,
    Spinner
  },

  data() {
    return {
      isLoading: false,
      message: "",
      showMessage: false,
      selectedList: "Product",
      ProductColor: {
        color: "black",
        "border-bottom": "1px solid black"
      },
      EventColor: {
        color: "gray"
      },
      HeaderTitle: "Order List",
      AddNewButton: "Add New Order",
      selectType: "Product"
    };
  },
  created() {},
  methods: {
    createNewItem: function() {
      console.log(this.selectType);
      if (this.selectType == "Product") {
        this.$router.push({ name: "Create Product Order" });
      }
    },
    setLoading: function(isLoading) {
      this.isLoading = isLoading;
    },
    setSelectList: function(type) {
      this.selectType = type;
      this.selectedList = type;
      if (type == "Product") {
        this.ProductColor = {
          color: "black",
          "border-bottom": "1px solid black"
        };
        this.EventColor = {
          color: "gray"
        };
      } else {
        this.ProductColor = {
          color: "gray"
        };
        this.EventColor = {
          color: "black",
          "border-bottom": "1px solid black"
        };
      }
    }
  }
};
</script>
<style>
.selectedList {
  padding-top: 20px;
}
.selectedList label {
  font-size: 23px;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
