import oneSipApiCall from "../apiCall.js";

const survey = {
  searchSurveyTemplate({ commit, state }, { router, surveyTemplate }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-survey-template",
      surveyTemplate
    );
  },
  getSurveyTemplate({ commit, state }, { router, surveyTemplate }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-survey-template",
      surveyTemplate
    );
  },
  listSurveyTemplates({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-list-survey-template",
      data
    );
  },
  createSurvetTemplate({ commit, state }, { router, surveyTemplate }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-survey-template",
      surveyTemplate
    );
  },
  updateSurvetTemplate({ commit, state }, { router, surveyTemplate }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-survey-template",
      surveyTemplate
    );
  },
  deleteSurvetTemplate({ commit, state }, { router, gatheringType }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-survey-template",
      gatheringType
    );
  },
  listEventSurvey({ commit, state }, { router, data }) {
    console.log(data);
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-event-survey",
      data
    );
  },
  getSurvey({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-survey", data);
  },
  getSurveyResult({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-survey-result",
      data
    );
  },
  createEventSurvey({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-event-survey-list",
      data
    );
  },
  updateSurvey({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-update-survey", data);
  },
  updateSurveySort({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-survey-sort",
      data
    );
  },
  deleteEventSurvey({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-event-survey",
      data
    );
  },
  getSurveyTasteNoteAroma({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/get-survey-taste-note-aroma",
      data
    );
  }
};
export default survey;
