<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="add-variance-container">
        <div class="popupclose" @click="popupcancel">
          <md-icon>close</md-icon>
        </div>
        <div class="popup">
          <div class="modal-header">
            <slot name="header">{{ PopupTitle }}</slot>
          </div>
          <div class="modal-body">
            <LanguageObjectInput :Item="VarianceNameInput" />
            <div class="row">
              <div class="inputcolumn">
                <NumberInput :Item="PriceInput" />
              </div>
              <div class="inputcolumn">
                <NumberInput :Item="WeightInput" />
              </div>
            </div>
            <div>
              <label>選項圖片</label>
              <div class="varianceImage">
                <draggable :list="VarianceImageInput" :group="{ name: 'g1' }">
                  <div v-for="Item in VarianceImageInput" :key="Item.ID">
                    <FileInput
                      v-on:RemoveItem="RemoveItem"
                      v-on:SetItemObject="SetItemObject"
                      :Item="Item"
                      :acceptType="acceptType"
                    />
                  </div>
                </draggable>
              </div>
              <div
                style="text-align: center;padding-top: 20px;"
                @click="addVarianceImageInput()"
              >
                <md-icon>add_circle_outline</md-icon>
                <u class="addLang">增加圖片</u>
              </div>
            </div>
            <div style="padding-top:50px">
              <InventorySelecter
                maxheight="max-height: 200px;"
                :InventoryList="InventoryList"
              />
            </div>
          </div>
          <center>
            <div
              class="row"
              style="text-align: center;padding-top: 50px; width: 50%;"
            >
              <div class="column" style="width:47.5%">
                <button class="cancelButton" @click="popupcancel">
                  取消
                </button>
              </div>
              <div class="column" style="width:5%"></div>
              <div class="column" style="width:47.5%">
                <button class="actionButton" @click="popupaction">
                  {{ actionButton }}
                </button>
              </div>
            </div>
          </center>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDeleteImage">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="removeImagePopupAction"
        PopupTitle="Confim Delete Image"
        :PopupContent="removeImagePopupContent"
        actionButton="Delete"
      ></Popup>
    </Transition>
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import LanguageObjectInput from "./FormComponents/LanguageObjectInput.vue";
import NumberInput from "./FormComponents/NumberInput.vue";
import FileInput from "./FormComponents/FileInput.vue";
import InventorySelecter from "./InventorySelecter.vue";
import Popup from "./Popup.vue";
export default {
  components: {
    draggable,
    LanguageObjectInput,
    NumberInput,
    FileInput,
    InventorySelecter,
    Popup
  },
  created() {
    this.getLanguage();
  },
  methods: {
    removeImagePopupAction: async function() {
      if (this.VarianceImageInput.length <= 1) {
        this.formMessage = "You must have at least one image";
        this.showFormMessage = true;
        return;
      }
    },
    SetItemObject: function(ID, object) {
      var index = this.VarianceImageInput.findIndex(x => x.ID == ID);
      this.VarianceImageInput[index].url = object.url;
      this.VarianceImageInput[index].base64 = object.base64;
      this.$forceUpdate();
    },
    popupcancel: function() {
      this.showConfirmDeleteImage = false;
      this.$emit("popupcancel");
    },
    popupaction: async function() {
      var object = {
        Name: this.VarianceNameInput.value,
        Price: parseInt(this.PriceInput.value),
        Weight: parseInt(this.WeightInput.value),
        Image: this.VarianceImageInput
      };
      if (this.InventoryList.length > 0) {
        object["Inventory"] = this.InventoryList;
      }
      console.log("popupaction", object);
      this.$emit("popupaction", object);
    },
    addVarianceImageInput: function() {
      var ID = new Date().getTime();
      this.VarianceImageInput.push({ ID: ID, base64: "", url: "" });
      console.log("VarianceImageInput", this.VarianceImageInput);
    },
    RemoveItem: function(ID) {
      var index = this.VarianceImageInput.findIndex(x => x.ID == ID);
      if (this.VarianceImageInput[index].currentImage) {
        console.log("deleteImageID", ID);
        this.deleteImageID = ID;
        this.removeImagePopupContent =
          "Are you sure you want to delete " + this.deleteImageID + " ?";
        this.showConfirmDeleteImage = true;
      } else {
        this.VarianceImageInput.splice(index, 1);
      }
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        console.log("getLanguage", ret.data);
        this.languageList = ret.data.Language;
        for (var i = 0; i < this.languageList.length; i++) {
          if (
            this.VarianceNameInput.value[this.languageList[i].Language] ==
            undefined
          ) {
            this.VarianceNameInput.value[this.languageList[i].Language] = "";
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  },
  name: "newdata-popup-component",
  props: {
    PopupTitle: {
      required: true,
      type: String,
      default() {
        return "";
      }
    },
    actionButton: {
      required: true,
      type: String,
      default() {
        return "";
      }
    },
    VarianceNameInput: {
      type: Object,
      default() {
        return {
          type: "object",
          key: "Name",
          name: "選項名稱",
          required: true,
          style: "width: 100%;",
          model: "",
          value: {}
        };
      }
    },
    PriceInput: {
      type: Object,
      default() {
        return {
          key: "Price",
          name: "格價 (HKD)",
          required: true,
          value: ""
        };
      }
    },
    WeightInput: {
      type: Object,
      default() {
        return {
          key: "Weight",
          name: "重量 (g)",
          required: true,
          value: ""
        };
      }
    },
    VarianceImageInput: {
      type: Array,
      default() {
        return [];
      }
    },
    InventoryList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      newData: "",
      cancelButton: "Cancel",
      DoneButton: "Add",
      // VarianceNameInput: {
      //     "type": "object",
      //     "key": "Name",
      //     "name": "Variance Name",
      //     "required": true,
      //     "style": "width: 100%;",
      //     "model": "",
      //     "value": {}
      // },
      // PriceInput:{
      //     "key": "Price",
      //     "name": "Price",
      //     "required": true,
      //     "value": "",
      // },
      // WeightInput: {
      //     "key": "Weight",
      //     "name": "Weight (g)",
      //     "required": true,
      //     "value": "",
      // },
      showConfirmDeleteImage: false,
      deleteImageID: "",
      acceptType: "image/*",
      removeImagePopupContent: "",
      showFormMessage: false,
      formMessage: ""
    };
  },
  computed: {}
};
</script>
<style>
.popup {
  padding: 30px 0px;
  margin: 0px 20px;
}
.popupcontent {
  width: 80%;
}
.modal-wrapper {
  z-index: -1;
}
.modal-container {
  z-index: 50;
  min-width: 350px;
  max-width: 400px;
}
.modal-header {
  font-size: 24px;
  font-weight: 500;
}
.modal-body {
  font-size: 16px;
  font-weight: 500;
}
.cancelButton {
  width: 100%;
  background-color: #575757;
  color: white;
  border-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.actionButton {
  width: 100%;
  background-color: white;
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}

.varianceImage {
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.add-variance-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 50%;
  height: 80%;
  margin: 0px auto;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
</style>
