<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createTasteCard"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <div class="row">
          <div style="width:45%;">
            <md-card>
              <md-card-content>
                <h3 class="formTitle">基本資料</h3>
                <div class="row">
                  <div style="width: 50%;padding: 0 10px;">
                    <AutoComplete
                      :item="ProductInput"
                      v-on:checkEdited="checkEdited"
                      v-on:checkAutocomplete="searchProduct"
                      v-on:setAutocompleteValue="setProduct"
                    />
                  </div>
                  <div style="width: 50%;padding: 0 10px;">
                    <AutoComplete
                      :item="AuthorInput"
                      v-on:checkEdited="checkEdited"
                      v-on:checkAutocomplete="searchUser"
                      v-on:setAutocompleteValue="setAuthor"
                    />
                  </div>
                </div>
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteCardInfo.TitleInput"
                />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteCardInfo.Description"
                />
                <TasteNotesInput
                  v-on:checkEdited="checkEdited"
                  :languageList="languageList"
                  :Item="TasteCardInfo.TasteNotesInput"
                />
                <AromaInput
                  v-on:checkEdited="checkEdited"
                  :languageList="languageList"
                  :Item="TasteCardInfo.AromaInput"
                />
                <FiveSensesInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteCardInfo.FiveSensesInput"
                />
                <div class="row">
                  <div
                    class="column inputcolumn"
                    v-for="(field, key) in ProductParameterInputList"
                    :key="key"
                  >
                    <TextInput
                      v-if="field.type == 'Text'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    />
                    <SelectInput
                      v-if="field.type == 'Option'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    />
                    <TimeInput
                      v-if="field.type == 'Time'"
                      v-on:checkEdited="checkEdited"
                      :item="field"
                      :name="field.name"
                      :value="field.value"
                    />
                  </div>
                </div>
                <div class="row">
                  <SelectInput
                    style="width: 50%;"
                    v-on:checkEdited="checkEdited"
                    :Item="TasteCardInfo.StatusInput"
                  />
                  <div style="width: 50%;padding-left: 50px;">
                    <label>顯示語言</label>
                    <div class="row">
                      <CheckBoxInput
                        v-for="(field, key) in TasteCardInfo.DisplayLanguageInput.option"
                        :key="key"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      />
                    </div>
                  </div>
                </div>
                <div>
                    <label>Taste Card 產品圖片</label>
                    <label style="color:red">*</label>
                    <FileInput
                      v-on:RemoveItem="RemoveTasteCardImage"
                      v-on:setMedia="setMedia"
                      :Item="TasteCardImage"
                      :acceptType="acceptType"
                    />
                  </div>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:55%;padding-left: 30px;">
            <md-card>
              <md-card-content>
                <div class="row">
                  <div style="width: 50%; ">
                    <h3 class="formTitle">沖煮步驟</h3> 
                  </div>
                  <div style="width: 50%; text-align: right;">
                    <label class="clearAll" @click="clearAllStep()"><u>clear all</u></label>
                  </div>
                  
                </div>
                
                <stepPage
                  :stepList="BrewingStepList"
                />
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>

    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  Spinner,
  TextInput,
  AutoComplete,
  TasteNotesInput,
  AromaInput,
  SelectInput,
  CheckBoxInput,
  FiveSensesInput,
  FileInput,
  TimeInput
} from "@/components";
import stepPage from "./components/stepPage.vue";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput,
    AutoComplete,
    TasteNotesInput,
    AromaInput,
    SelectInput,
    CheckBoxInput,
    FiveSensesInput,
    stepPage,
    FileInput,
    TimeInput
  },
  async created() {
    await this.getLanguage();
    this.ProductID = this.$route.params.ProductID;
    if (this.ProductID) {
      await this.getProduct();
    }
  },
  methods: {
    /*
       form function
    */
    setMedia: async function(Item) {
      console.log("setMedia", Item);
      this.TasteCardImage.type = "tempImage"
    },
    RemoveTasteCardImage: async function(ID, deleteAction) {
      console.log("RemoveItem", ID);
      console.log("deleteAction", deleteAction);
      if (deleteAction) {
        console.log("deleteAction", this.TasteCardImage);
        this.TasteCardImage.id = "";
        this.TasteCardImage.url = "";
        this.TasteCardImage.path = "";
        this.TasteCardImage.type = "default";
      }
    },
    clearAllStep: function() {
      this.BrewingStepList = [];
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    setProduct: async function(product) {
      console.log("setProduct", product);
      this.ProductID = product.Key;
      await this.getProduct();
    },
    setAuthor: async function(author) {
      console.log("setAuthor", author);
      this.UserID = author.Key;
    },
    setProductAutocompleteList: function(list) {
      this.ProductInput.autocompleteList = [];
      for (var i in list) {
        this.ProductInput.autocompleteList.push({
          Key: list[i]["_source"].ProductID,
          Name: list[i]["_source"].Name[this.language]
        });
      }
    },
    setUserAutocompleteList: function(list) {
      this.AuthorInput.autocompleteList = [];
      for (var i in list) {
        var name = list[i]["_source"].Name;
        if (list[i]["_source"].SupplierName) {
          name = list[i]["_source"].SupplierName;
        }
        this.AuthorInput.autocompleteList.push({
          Key: list[i]["_source"].UserID,
          Name: name
        });
      }
    },
    setFormValue: async function(data) {
      //TasteNotesInput
      console.log("TasteNote", data.TasteNote);
      if (data.TasteNote.length > 0) {
        this.TasteCardInfo.TasteNotesInput.value = [];
        this.TasteCardInfo.TasteNotesInput.model = [];
        for (var i in data.TasteNote) {
          var tastenote = data.TasteNote[i];
          this.TasteCardInfo.TasteNotesInput.value.push(tastenote.TasteNoteID);
          var TasteNotesString = "";
          for (const [key, value] of Object.entries(tastenote.Name)) {
            TasteNotesString += key + " : " + value + " / ";
          }
          TasteNotesString = TasteNotesString.slice(0, -2);
          this.TasteCardInfo.TasteNotesInput.model.push(TasteNotesString);
        }
      }
      //AromaInput
      console.log("Aroma", data.Aroma);
      if (data.Aroma && data.Aroma.length > 0) {
        this.TasteCardInfo.AromaInput.value = [];
        this.TasteCardInfo.AromaInput.model = [];
        for (var i in data.Aroma) {
          var aroma = data.Aroma[i];
          this.TasteCardInfo.AromaInput.value.push(aroma.AromaID);
          var AromaString = "";
          for (const [key, value] of Object.entries(aroma.Name)) {
            AromaString += key + " : " + value + " / ";
          }
          AromaString = AromaString.slice(0, -2);
          this.TasteCardInfo.AromaInput.model.push(AromaString);
        }
      }
      this.ProductParameterInputList = [];
      if (data.ProductType["Parameter"]) {
        var Parameter = data.ProductType["Parameter"];
        for (var p in Parameter) {
          var parameter = Parameter[p];
          console.log(parameter);
          var object = {
            type: parameter.Type,
            required: true,
            key: parameter.Key,
            name: parameter.Name.zh,
            value: ""
          };
          if (parameter.Type == "Option") {
            var option = [];
            for (var o in parameter.Option) {
              var optionSelect = parameter.Option[o];
              option.push({
                key: optionSelect.Key,
                name: optionSelect.Name.zh
              });
              object.option = option;
            }
          } else if (parameter.Type == "Time") {
            object.hours = 0;
            object.minutes = 0;
            object.seconds = 0;
          }
          if (parameter.Unit) {
            object.Unit = parameter.Unit;
          }
          this.ProductParameterInputList.push(object);
        }
      }
      this.$forceUpdate();
    },
    /*
        api
    */
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        this.TasteCardInfo.DisplayLanguageInput.option = [];
        for (var i in this.languageList) {
          var language = this.languageList[i];
          this.TasteCardInfo.DisplayLanguageInput.option.push({
            key: language.Language,
            name: language.Language,
            value: true
          });
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    searchProduct: async function(keyword) {
      if (!keyword) return;
      var object = {
        Keyword: keyword
      };
      try {
        const ret = await this.$store.dispatch("searchProduct", {
          router: this.$router,
          keyword: object
        });
        console.log("searchProduct", ret.data.data.hits.hits);
        this.setProductAutocompleteList(ret.data.data.hits.hits);
      } catch (err) {
        this.isLoading = false;
        console.log("searchProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    searchUser: async function(keyword) {
      if (!keyword) return;
      var object = {
        Keyword: keyword,
        Filter: {
          UserRole: ["Offical", "Supplier"]
        },
        Source: ["UserID", "Name", "SupplierName"]
      };
      try {
        const ret = await this.$store.dispatch("SearchUser", {
          router: this.$router,
          keyword: object
        });
        console.log("searchUser", ret.data.data.hits.hits);
        this.setUserAutocompleteList(ret.data.data.hits.hits);
      } catch (err) {
        this.isLoading = false;
        console.log("searchUser error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    async getProduct() {
      this.isLoading = true;
      var Object = {
        ProductID: this.ProductID
      };
      try {
        const ret = await this.$store.dispatch("getProduct", {
          router: this.$router,
          product: Object
        });
        console.log("getProduct", ret.data);
        if (!this.ProductInput.value) {
          this.ProductInput.value = ret.data.ProductDetail.Name[this.language];
        }
        this.setFormValue(ret.data.ProductDetail);
        this.TasteCardImage.path = "taste-card-resource/product/" +
          this.$route.params.ProductID;
        this.TasteCardImage.url =
          this.s3path +
          "taste-card-resource/product/" +
          this.$route.params.ProductID;
        this.TasteCardImage.type = "default";
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("getProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    createTasteCard: async function(ProductID) {
      var TasteCardObject = {
        ProductID: this.ProductID,
        UserID: this.UserID,
        Title: this.TasteCardInfo.TitleInput.value,
        Description: this.TasteCardInfo.Description.value,
        TasteNote: this.TasteCardInfo.TasteNotesInput.value,
        Aroma: this.TasteCardInfo.AromaInput.value,
        Status: this.TasteCardInfo.StatusInput.value,
        Language: this.TasteCardInfo.DisplayLanguageInput.value,
        ProductImage: this.TasteCardImage,
        FiveSenses: {},
        Parameter: {},
        Step: this.BrewingStepList
      };


      for (var i in this.TasteCardInfo.FiveSensesInput.value) {
        TasteCardObject.FiveSenses[
          this.TasteCardInfo.FiveSensesInput.value[i].key
        ] = parseInt(this.TasteCardInfo.FiveSensesInput.value[i].value);
      }
      for (var i in this.ProductParameterInputList) {
        if (this.ProductParameterInputList[i].Unit) {
          //check value contain unit or not
          if (
            this.ProductParameterInputList[i].value.includes(
              this.ProductParameterInputList[i].Unit
            )
          ) {
            TasteCardObject.Parameter[
              this.ProductParameterInputList[i].key
            ] = this.ProductParameterInputList[i].value;
          } else {
            TasteCardObject.Parameter[this.ProductParameterInputList[i].key] =
              this.ProductParameterInputList[i].value +
              this.ProductParameterInputList[i].Unit;
          }
        } else {
          console.log(this.ProductParameterInputList[i], "no unit");
          TasteCardObject.Parameter[
            this.ProductParameterInputList[i].key
          ] = this.ProductParameterInputList[i].value;
        }
      }
      for (const [key, value] of Object.entries(TasteCardObject.FiveSenses)) {
        if (value > 4 || value < 0) {
          this.formMessage = "Five Senses value must between 0-4";
          this.showFormMessage = true;
          return;
        }
      }
      for (const [key, value] of Object.entries(TasteCardObject.Parameter)) {
        if (!value) {
          console.log(key + " no value");
          return;
        }
      }
      console.log("createTasteCard", TasteCardObject);
      //return;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createTasteCard", {
          router: this.$router,
          tasteCard: TasteCardObject
        });
        this.isLoading = false;
        console.log("createTasteCard", ret.data);
        this.formMessage = "新增成功";
        this.showFormMessage = true;
        await this.createInspiration(ret.data.TasteCardID, this.ProductID);
      } catch (error) {
        this.isLoading = false;
        console.log("createTasteCard error", error.response.data);
        return false;
      }
    },
    createInspiration: async function(TasteCardID, ProductID) {
      console.log("createInspiration", TasteCardID, ProductID);
      var Object = {
        Author: [this.UserID],
        MediaList: [
          {
            type: "TasteCard",
            CardID: TasteCardID
          }
        ],
        ProductList: [ProductID],
        Status: "Published"
      };
      console.log("createInspiration", Object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createInspiration", {
          router: this.$router,
          data: Object
        });
        this.isLoading = false;
        console.log("createInspiration", ret.data);
        this.$router.go(-1);
        return true;
      } catch (err) {
        console.log("createInspiration error", err.response.data);
        return false;
      }
    }
  },
  data() {
    return {
      s3path: process.env.VUE_APP_S3_URL,
      acceptType: "image/*",
      language: "zh",
      isLoading: false,
      ProductID: null,
      Product: null,
      showFormMessage: false,
      formMessage: "",
      ProductInput: {
        name: "產品名稱",
        value: "",
        required: true,
        autocompleteList: []
      },
      AuthorInput: {
        name: "作者",
        value: "",
        required: true,
        autocompleteList: []
      },
      UserID: null,
      languageList: [],
      HeaderTitle: "新增 Tastecard",
      confirmBack: false,
      formActionButton: "新增",
      TasteCardImage: {
        id: "",
        url: "",
        path: "",
        type: "default",
      },
      TasteCardInfo: {
        TitleInput: {
          type: "text",
          key: "Title",
          name: "標題",
          required: true,
          value: ""
        },
        Description: {
          type: "text",
          key: "Description",
          name: "描述",
          required: false,
          value: ""
        },
        TasteNotesInput: {
          key: "TasteNote",
          name: "風味",
          required: true,
          value: [],
          model: []
        },
        AromaInput: {
          key: "Aroma",
          name: "香氣",
          required: true,
          value: [],
          model: []
        },
        FeelingInput: {
          key: "Feeling",
          name: "意境",
          required: false,
          value: ""
        },
        BodyInput: {
          key: "Body",
          name: "酒體",
          required: false,
          value: "",
          option: [
            {
              key: "Thick",
              name: "厚"
            },
            {
              key: "Thin",
              name: "薄"
            }
          ]
        },
        AfterTasteInput: {
          key: "AfterTaste",
          name: "餘韻",
          required: false,
          value: "",
          option: [
            {
              key: "Long",
              name: "長"
            },
            {
              key: "Short",
              name: "短"
            }
          ]
        },
        StatusInput: {
          key: "Status",
          name: "Status",
          required: true,
          value: "Hide",
          option: [
            {
              key: "Hide",
              name: "隱藏"
            },
            {
              key: "Private",
              name: "私人"
            },
            {
              key: "UnListed",
              name: "不公開"
            },
            {
              key: "Published",
              name: "公開"
            }
          ]
        },
        DisplayLanguageInput: {
          key: "DisplayLanguage",
          name: "顯示語言",
          required: true,
          value: null,
          option: []
        },
        FiveSensesInput: {
          key: "FiveSenses",
          name: "五感 (between 0-4)",
          required: true,
          value: [
            {
              key: "Sweet",
              name: "甜",
              value: 0
            },
            {
              key: "Sour",
              name: "酸",
              value: 0
            },
            {
              key: "Bitter",
              name: "苦",
              value: 0
            },
            {
              key: "Salty",
              name: "鹹",
              value: 0
            },
            {
              key: "Umami",
              name: "鮮",
              value: 0
            }
          ]
        }
      },
      BrewingStepList: [
        {
          step: "This is a step 1 of brewing section",
          image: {
            id: "",
            url: "",
            type: ""
          }
        },
        {
          step: "This is a step 2 of brewing section",
          image: {
            id: "",
            url: "",
            type: ""
          }
        },
        {
          step: "This is a step 3 of brewing section",
          image: {
            id: "",
            url: "",
            type: ""
          }
        }
      ],
    };
  }
};
</script>
<style>
.clearAll {
  padding-right: 20px;
  cursor: pointer;
  line-height: 50px;
  font-weight: 400;
  font-size: 18px;
}
</style>