import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const newsletterSubscription = {
  getNewsletterSubscription({ commit, state }, { router }) {
    return new Promise((resolve, reject) => {
      commit("action_request");
      axios({ url: url + "/getNewsletterSubscription", method: "GET" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  updateNewsletterSubscription({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("updateNewsletterSubscription", form);
      commit("action_request");
      resolve(state.idToken);
      axios({
        url: url + "/updateNewsletterSubscription",
        data: form,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  }
};

export default newsletterSubscription;
