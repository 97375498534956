<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="updateProductType"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <div class="row">
          <div class="col" style="width: 45%;text-align: left;">
            <md-card style="height: 350px;">
              <md-card-content>
                <h3>名稱</h3>
                <TextInput
                  v-for="item in NameInputList"
                  :key="item.key"
                  :Item="item"
                />
                <CheckBoxInput :Item="noTasteCardInput" />
              </md-card-content>
            </md-card>
            <md-card>
              <md-card-content>
                <h3>參數</h3>
                <div v-for="item in Parameter" :key="item.Key">
                  <ParameterItem
                    v-on:delParameter="delParameter"
                    v-on:diplicateParameter="diplicateParameter"
                    :parameterItem="item"
                    :languageList="languageList"
                  />
                </div>
                <md-card-content>
                  <div style="text-align: center;" @click="addParameter()">
                    <md-icon>add_circle_outline</md-icon>
                    <u class="addQuestion">新增參數</u>
                  </div>
                </md-card-content>
              </md-card-content>
            </md-card>
          </div>

          <md-card style="width: 50%;text-align: left;margin-left: 25px;">
            <md-card-content>
              <h3>項目</h3>
              <div v-for="item in Column" :key="item.Key">
                <ColumnItem
                  v-on:delColumn="delColumn"
                  v-on:diplicateColumn="diplicateColumn"
                  :columnItem="item"
                  :languageList="languageList"
                />
              </div>
              <md-card-content>
                <div style="text-align: center;" @click="addColumn()">
                  <md-icon>add_circle_outline</md-icon>
                  <u class="addQuestion">新增項目</u>
                </div>
              </md-card-content>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, TextInput, CheckBoxInput } from "@/components";
import ColumnItem from "./component/ColumnItem.vue";
import ParameterItem from "./component/ParameterItem.vue";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput,
    CheckBoxInput,
    ColumnItem,
    ParameterItem
  },
  async created() {
    await this.getLanguage();
    console.log("ProductTypeID", this.$route.params);
    this.ProductTypeID = this.$route.params.ProductTypeID;
    if (!this.ProductTypeID) {
      this.$router.push({ name: "Product Type List" });
    }
    await this.getProductType();
  },
  methods: {
    delParameter(key) {
      console.log("delParameter", key);
      var index = this.Parameter.findIndex(x => x.Key == key);
      if (index > -1) {
        this.Parameter.splice(index, 1);
      }
    },
    diplicateParameter(key) {
      console.log("diplicateParameter", key);
      var parameterItem = this.Parameter.find(x => x.Key == key);
      const copiedParameterItem = Object.assign({}, parameterItem);
      console.log("parameterItem", parameterItem);
      copiedParameterItem.Key = key + "-copy (" + this.Parameter.length + ")";
      this.Parameter.push(copiedParameterItem);
    },
    delColumn(key) {
      console.log("delColumn", key);
      var index = this.Column.findIndex(x => x.Key == key);
      if (index > -1) {
        this.Column.splice(index, 1);
      }
    },
    diplicateColumn(key) {
      console.log("diplicateColumn", key);
      var columnItem = this.Column.find(x => x.Key == key);
      const copiedColumnItem = Object.assign({}, columnItem);
      console.log("columnItem", columnItem);
      copiedColumnItem.Key = key + "-copy (" + this.Column.length + ")";
      this.Column.push(copiedColumnItem);
    },
    addParameter() {
      var key = this.Parameter.length;
      var name = {};
      for (var i in this.languageList) {
        name[this.languageList[i].Language] = "";
      }
      this.Parameter.push({
        Key: key,
        Name: name,
        Type: "Text",
        Unit: ""
      });
    },
    addColumn() {
      var key = this.Column.length;
      var displayName = {};
      for (var i in this.languageList) {
        displayName[this.languageList[i].Language] = "";
      }
      this.Column.push({
        DataType: "object",
        Key: key,
        Name: "",
        displayName: displayName,
        required: false,
        shortDescriptionKey: false
      });
    },
    async updateProductType() {
      var object = {
        ProductTypeID: this.ProductTypeID,
        noTasteCard: this.noTasteCardInput.value
      };
      var name = {};
      for (var i in this.NameInputList) {
        name[this.NameInputList[i].name] = this.NameInputList[i].value;
      }
      object.Name = name;
      var shortDescriptionKeys = [];
      var Column = [];
      for (var i in this.Column) {
        Column.push({
          DataType: this.Column[i].DataType,
          Key: this.Column[i].Key,
          Name: this.Column[i].Name,
          displayName: this.Column[i].displayName,
          required: this.Column[i].required
        });
        if (this.Column[i].shortDescriptionKey) {
          console.log("shortDescriptionKey", this.Column[i].Key);
          shortDescriptionKeys.push(this.Column[i].Key);
        }
      }
      object.shortDescriptionKeys = shortDescriptionKeys;
      object.Column = Column;

      var parameter = [];
      for (var i in this.Parameter) {
        var parameterObject = {
          Key: this.Parameter[i].Key,
          Name: this.Parameter[i].Name,
          Type: this.Parameter[i].Type
        };
        if (this.Parameter[i].Unit) {
          parameterObject.Unit = this.Parameter[i].Unit;
        }
        if (this.Parameter[i].Type == "Option") {
          parameterObject.Option = this.Parameter[i].Option;
        }
        parameter.push(parameterObject);
      }
      object.Parameter = parameter;
      console.log("updateProductType", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateProductType", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        this.formMessage = "更新成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },

    /*
        api
    */
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setField() {
      for (var i in this.languageList) {
        for (const [key, value] of Object.entries(this.languageList[i])) {
          this.NameInputList.push({
            key: value,
            name: value,
            value: this.Name[value],
            required: true
          });
        }
      }
    },
    async getProductType() {
      var object = {
        ProductTypeID: this.ProductTypeID
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("getProductType", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        console.log("getProductType", ret);
        this.ProductTypeID = ret.data.productType.ProductTypeID;
        this.Name = ret.data.productType.Name;
        this.Column = ret.data.productType.Column;
        if (ret.data.productType.noTasteCard) {
          this.noTasteCardInput.value = ret.data.productType.noTasteCard;
        }
        if (ret.data.productType.Parameter) {
          this.Parameter = ret.data.productType.Parameter;
        }
        this.shortDescriptionKeys = ret.data.productType.shortDescriptionKeys;
        for (var i in this.shortDescriptionKeys) {
          var index = this.Column.findIndex(
            x => x.Key == this.shortDescriptionKeys[i]
          );
          if (index > -1) {
            this.Column[index].shortDescriptionKey = true;
          }
        }
        this.setField();
      } catch (err) {
        this.isLoading = false;
        console.log("getProductType error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "編輯產品類型",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      formMessage: "",
      ProductTypeID: "",
      Name: {},
      languageList: [],
      NameInputList: [],
      Column: [],
      noTasteCardInput: {
        name: "無TasteCard",
        value: false
      },
      shortDescriptionKeys: [],
      Parameter: []
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
