import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const faq = {
  getFAQ({ commit, state }, { router }) {
    return new Promise((resolve, reject) => {
      commit("action_request");
      axios({ url: url + "/getFAQ", method: "GET" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  createFAQ({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("createFAQ", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/createFAQ", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  },
  updateFAQ({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("updateFAQ", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/updateFAQ", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  },
  deleteFAQ({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("deleteFAQ", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/deleteFAQ", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  },
  setFAQ({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("setFAQ", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/setFAQ", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  }
};

export default faq;
