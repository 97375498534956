

const router = {
    redirectToPageBeforeVerify( context ){
        try {
            const targetPageItem = context.state.prevPages[ context.state.prevPages.length - 2 ]; // skip the verify page

            if( !targetPageItem ) return;
    
            Router.push({
              name: targetPageItem.name,
              params: targetPageItem.params,
            });
        }
        catch( err ){
          console.log( "Error: no prev pages");
        }

    }
};

export default router;
