import oneSipApiCall from "../apiCall.js";

const event = {
  searchEvent({ commit, state }, { router, keyword }) {
    return oneSipApiCall(commit, state, router, "/admin-search-event", keyword);
  },
  getEvent({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-event", data);
  },
  getEventVariance({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-event-variance",
      data
    );
  },
  getParticipantList({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-variance-participant",
      data
    );
  },
  listEventVariance({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-list-event-variance",
      data
    );
  },
  createEvent({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-event", data);
  },
  createEventVariance({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-event-variance",
      data
    );
  },
  createEventVarianceList({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-event-variance-list",
      data
    );
  },
  updateEvent({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-update-event", data);
  },
  updateEventStatus({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-event-status",
      data
    );
  },
  updateEventVariance({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-event-variance",
      data
    );
  },
  deleteEventImage({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-event-image",
      data
    );
  },
  updateEventImage({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-event-image",
      data
    );
  },
  deleteGathering({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-gathering",
      data
    );
  },
  deleteEventImage({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-event-image",
      data
    );
  },
  createEventType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-event-type",
      data
    );
  },
  getEventType({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-event-type", data);
  },
  updateEventType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-event-type",
      data
    );
  },
  deleteEventType({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-event-type",
      data
    );
  },
  createEventLevel({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-event-level",
      data
    );
  },
  getEventLevel({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-get-event-level", data);
  },
  updateEventLevel({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-event-level",
      data
    );
  },
  deleteEventLevel({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-event-level",
      data
    );
  },
  getHost({ commit, state }, { router }) {
    return oneSipApiCall(commit, state, router, "/admin-get-host");
  },
  addEventProduct({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-add-event-product",
      data
    );
  },
  deleteEventProduct({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-event-product",
      data
    );
  },
  setVarianceSchedule({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-set-variance-schedule",
      data
    );
  },
  removeVarianceSchedule({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-variance-schedule",
      data
    );
  }
};
export default event;
