import axios from "axios";

import url from "./config.js";
import errorHandler from "./errorHandler.js";

const oneSipApiCall = function(
  commit,
  state,
  router,
  path,
  params = null,
  form = false
) {
  var config = { url: url + path, data: params, method: "POST" };
  console.log("oneSipApiCall", config);
  if (form) {
    config["headers"] = { "Content-Type": "multipart/form-data" };
  }

  return new Promise((resolve, reject) => {
    commit("action_request");
    axios(config)
      .then(resp => {
        commit("action_success");
        resolve(resp);
      })
      .catch(async err => {
        commit("action_error", { err });
        console.log("response", err.response);
        console.log("request", err.request);

        if (err.response) {
          console.log("status", err.response.status);
          console.log("data", err.response.data);
          if (err.response.status == 401) {
            console.log("go to errorHandler");
            try {
              var ret = await errorHandler.handle(
                self,
                err,
                router,
                path,
                params
              );
              console.log(ret);
              if (ret.status == 200) {
                resolve(ret);
              } else {
                console.log(err);
                self.action_error(err);
                localStorage.removeItem("idToken");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                delete axios.defaults.headers.common["Authorization"];
                router.replace({ path: "/login" });
                reject(err);
              }
            } catch (err) {
              console.log("errorHandler error", err);
              localStorage.setItem("history", {
                path: path,
                params: params
              });
              localStorage.removeItem("idToken");
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              delete axios.defaults.headers.common["Authorization"];
              router.replace({ path: "/login" });
              commit("action_error", { err });
              reject(err);
            }
          } else {
            
            console.log("err not 401", err);
            commit("action_error", { err });
            reject(err);
          }
        } else {
          console.log("no response", err);
          commit("action_error", { err });
          reject(err);
        }
      });
  });
};

export default oneSipApiCall;
