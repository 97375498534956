<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="updateTasteNote"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <div class="row">
          <div class="col" style="width: 45%;text-align: left;">
            <md-card>
              <md-card-content>
                <h3>官方名稱</h3>
                <TextInput
                  v-for="item in NameInputList"
                  :key="item.key"
                  :Item="item"
                />
              </md-card-content>
            </md-card>
            <br />
            <div style="width: 50%;text-align: left;">
              <button
                class="enableButton"
                @click="showConfirmDeleteTasteNote = true"
              >
                刪除風味
              </button>
            </div>
          </div>
          <md-card style="width: 50%;text-align: left;margin-left: 25px;">
            <md-card-content>
              <h3>關鍵字</h3>
              <table>
                <tr v-for="item in KeywordInputList" :key="item.key">
                  <td>
                    <TextInput :Item="item" />
                  </td>
                  <td>
                    <md-button
                      class="md-icon-button  md-accent"
                      @click="removeKeyword(item.key)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </td>
                </tr>
              </table>
              <md-card-content>
                <div style="text-align: center;" @click="addKeyword()">
                  <md-icon>add_circle_outline</md-icon>
                  <u class="addQuestion">關鍵字</u>
                </div>
              </md-card-content>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDeleteTasteNote">
      <Popup
        v-on:popupcancel="showConfirmDeleteTasteNote = false"
        v-on:popupaction="deleteTasteNote"
        PopupTitle="確認刪除"
        PopupContent="是否確定要刪除這個風味? 刪除的資料將無法復原!"
        actionButton="刪除"
      />
    </Transition>
    <Transition name="modal" v-if="showConfirmDeleteKeyword">
      <Popup
        v-on:popupcancel="showConfirmDeleteKeyword = false"
        v-on:popupaction="deleteKeyword"
        PopupTitle="確認刪除"
        PopupContent="是否確定要刪除這個關鍵字? 刪除的資料將無法復原!"
        actionButton="刪除"
      />
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, TextInput, Popup } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput,
    Popup
  },
  async created() {
    console.log("TasteNoteID", this.$route.params);
    this.TasteNoteID = this.$route.params.TasteNoteID;
    if (!this.TasteNoteID) {
      this.$router.push({ name: "TasteNoteList" });
    }
    if (!this.$route.params.Name) {
      await this.getTasteNote();
    } else {
      this.Name = this.$route.params.Name;
    }
    await this.getLanguage();
    await this.getTasteNoteKeyword();
  },
  methods: {
    async deleteTasteNote() {
      this.isLoading = true;
      var object = {
        TasteNoteID: this.TasteNoteID
      };
      try {
        const ret = await this.$store.dispatch("deleteTasteNote", {
          router: this.$router,
          data: object
        });
        console.log("deleteTasteNote", ret);
        this.isLoading = false;
        this.showConfirmDeleteTasteNote = false;
        this.formMessage = "刪除成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.replace({ name: "TasteNoteList" });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    async updateTasteNote() {
      this.isLoading = true;
      var object = {
        TasteNoteID: this.TasteNoteID,
        Name: {},
        Keyword: []
      };
      for (var i in this.NameInputList) {
        object.Name[this.NameInputList[i].key] = this.NameInputList[i].value;
      }
      for (var i in this.KeywordInputList) {
        object.Keyword.push(this.KeywordInputList[i].value);
      }
      try {
        const ret = await this.$store.dispatch("updateTasteNote", {
          router: this.$router,
          data: object
        });
        console.log("updateTasteNote", ret);
        this.isLoading = false;
        this.formMessage = "更新成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.replace({ name: "TasteNoteList" });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    /*
        api
    */
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        for (var i in this.languageList) {
          for (const [key, value] of Object.entries(this.languageList[i])) {
            this.NameInputList.push({
              key: value,
              name: value,
              value: this.Name[value],
              required: true
            });
          }
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    async getTasteNoteKeyword() {
      var object = {
        TasteNote: this.TasteNoteID
      };
      try {
        const ret = await this.$store.dispatch("getTasteNoteKeyword", {
          router: this.$router,
          data: object
        });
        for (var i in ret.data.Keyword) {
          this.KeywordInputList.push({
            key: ret.data.Keyword[i],
            value: ret.data.Keyword[i],
            required: true
          });
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    addKeyword() {
      this.KeywordInputList.push({
        key: this.KeywordInputList.length,
        value: "",
        required: true
      });
    },
    removeKeyword(ID) {
      var keywordInput = this.KeywordInputList.find(item => item.key == ID);
      if (typeof keywordInput.key === "number") {
        this.KeywordInputList.splice(
          this.KeywordInputList.indexOf(keywordInput),
          1
        );
      } else {
        this.confirmKeyword = keywordInput;
        this.showConfirmDeleteKeyword = true;
      }
    },
    async deleteKeyword() {
      var object = {
        TasteNoteID: this.TasteNoteID,
        Keyword: this.confirmKeyword.value
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deleteTasteNoteKeyword", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        console.log("deleteTasteNoteKeyword", ret);
        this.showConfirmDeleteKeyword = false;
        this.formMessage = "刪除成功";
        this.showFormMessage = true;
        this.KeywordInputList.splice(
          this.KeywordInputList.indexOf(this.confirmKeyword),
          1
        );
        this.confirmKeyword = {};
      } catch (err) {
        this.isLoading = false;
        console.log("deleteTasteNoteKeyword error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    async getTasteNote() {
      var object = {
        TasteNote: [this.TasteNoteID]
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("getTasteNote", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        console.log("getTasteNote", ret);
        this.TasteNoteID = ret.data.tasteNoteList[0].TasteNoteID;
        this.Name = ret.data.tasteNoteList[0].Name;
      } catch (err) {
        this.isLoading = false;
        console.log("searchTasteNotes error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "編輯風味",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDeleteTasteNote: false,
      showConfirmDeleteKeyword: false,
      confirmKeyword: {},
      formMessage: "",
      TasteNoteID: "",
      Name: {},
      languageList: [],
      NameInputList: [],
      KeywordInputList: []
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
