import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";
const crm = {
  // getRefundRequestDetail({commit,state},data){
  //   return new Promise((resolve, reject) => {
  //     console.log(data);
  //     commit('action_request');
  //     //resolve(state.idToken);
  //     axios({url: url+'/getRefundRequestDetail', data:data, method: 'POST' })
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       if(err.response.stats==401){

  //       }
  //       commit('action_error',{err})
  //       reject(err)
  //     })

  //   })
  // },
  getRefundRequestList({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({ url: url + "/getRefundRequestList", data: data, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  adminCreateRefund({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({ url: url + "/adminCreateRefund", data: data, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },

  countCustomerOrder({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({ url: url + "/countCustomerOrder", data: data, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  getCustomerRefundRequest({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({
        url: url + "/getCustomerRefundRequest",
        data: data,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },

  getCustomerProductRecord({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({
        url: url + "/getCustomerProductRecord",
        data: data,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  updateOrderWithDeliveryID({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({
        url: url + "/updateOrderWithDeliveryID",
        data: data,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  }
};

export default crm;
