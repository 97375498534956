import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const bigPromotionBanner = {
  getBigPromotionBanner({ commit, state }, { router }) {
    return new Promise((resolve, reject) => {
      commit("action_request");
      axios({ url: url + "/getBigPromotionBanner", method: "GET" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  setBigPromotionBanner({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("setBigPromotionBanner", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/setBigPromotionBanner", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  }
};

export default bigPromotionBanner;
