<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          formActionButton="儲存"
        >
        </FormHeader>
        <center>
          <md-card style="width:50%;min-width: 500px">
            <md-card-content style="text-align: left;">
              <h3 class="formTitle">選項資料</h3>
              <LanguageObjectInput :Item="VarianceNameInput" />
              <div class="row">
                <div class="inputcolumn">
                  <NumberInput :Item="PriceInput" />
                </div>
                <div class="inputcolumn">
                  <NumberInput :Item="WeightInput" />
                </div>
              </div>
              <div>
                <label>選項圖片</label>
                <div class="varianceImage">
                  <draggable :list="VarianceImageInput" :group="{ name: 'g1' }">
                    <div v-for="Item in VarianceImageInput" :key="Item.ID">
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        :Item="Item"
                        :acceptType="acceptType"
                      />
                    </div>
                  </draggable>
                </div>
                <div
                  style="text-align: center;"
                  @click="addVarianceImageInput()"
                >
                  <md-icon>add_circle_outline</md-icon>
                  <u class="addLang">增加圖片</u>
                </div>
              </div>
              <div>
                <InventorySelecter
                  maxheight="max-height: 200px;"
                  :InventoryList="InventoryList"
                >
                </InventorySelecter>
              </div>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDeleteImage">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="removeImagePopupAction"
        PopupTitle="刪除圖片"
        :PopupContent="removeImagePopupContent"
        actionButton="刪除"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  FormHeader,
  LanguageObjectInput,
  NumberInput,
  FileInput,
  InventorySelecter,
  Popup,
  Spinner
} from "@/components";
export default {
  components: {
    FormHeader,
    draggable,
    LanguageObjectInput,
    NumberInput,
    FileInput,
    InventorySelecter,
    Popup,
    Spinner
  },
  async created() {
    this.isLoading = true;
    await this.getInventoryList();
    await this.getVariance(this.$route.params.VarianceID);

    this.isLoading = false;
  },
  methods: {
    //popup action
    popupcancel: function() {
      this.showConfirmDeleteImage = false;
    },
    removeImagePopupAction: async function() {
      var object = {
        VarianceID: this.$route.params.VarianceID,
        ImageID: this.deleteImageID
      };
      console.log("removeImagePopupAction", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deleteVarianceImage", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        this.showConfirmDeleteImage = false;
        this.formMessage = "Image Deleted";
        this.showFormMessage = true;
        var index = this.VarianceImageInput.findIndex(
          x => x.ID == this.deleteImageID
        );
        this.VarianceImageInput.splice(index, 1);
      } catch (err) {
        this.isLoading = false;
        console.log("deleteVarianceImage error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    //end of popup action
    //component action
    SetItemObject: function(ID, object) {
      var index = this.VarianceImageInput.findIndex(x => x.ID == ID);
      this.VarianceImageInput[index].url = object.url;
      this.VarianceImageInput[index].base64 = object.base64;
      this.$forceUpdate();
    },
    RemoveItem: async function(ID, deleteAction) {
      if (deleteAction) {
        this.deleteImageID = ID;
        this.removeImagePopupContent = "是否確定刪除此產品圖片?";
        this.showConfirmDeleteImage = true;
      } else {
        var index = this.VarianceImageInput.findIndex(x => x.ID == ID);
        this.VarianceImageInput.splice(index, 1);
      }
    },
    //end of component action
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    getInventoryList: async function() {
      try {
        const ret = await this.$store.dispatch("listInventory", {
          router: this.$router
        });
        console.log("listInventory", ret);
        this.AllInventoryList = ret.data.InventoryList;
        console.log("listInventory", ret.data.InventoryList);
      } catch (err) {
        console.log("listInventory error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getVariance: async function(VarianceID) {
      try {
        const ret = await this.$store.dispatch("getVariance", {
          router: this.$router,
          data: { VarianceID: VarianceID }
        });
        console.log("getVariance", ret);
        this.Variance = ret.data.ProductVariance;
        var VarianceName = "";
        for (const [key, value] of Object.entries(this.Variance.Name)) {
          VarianceName += key + " : " + value + " / ";
        }
        VarianceName = VarianceName.slice(0, -2);
        this.VarianceNameInput.model = VarianceName;
        this.VarianceNameInput.value = this.Variance.Name;
        this.PriceInput.value = this.Variance.Price;
        this.WeightInput.value = this.Variance.Weight;
        this.VarianceImageInput = [];
        if (this.Variance.Image.length > 0) {
          this.currentImageList = this.Variance.Image;
          for (var i = 0; i < this.Variance.Image.length; i++) {
            this.VarianceImageInput.push({
              ID: this.Variance.Image[i].ImageID,
              url: this.s3path + this.Variance.Image[i].path,
              current: true,
              base64: ""
            });
          }
        }
        this.InventoryList = [];
        var VarianceInventory = await this.getVarianceInventory();
        this.CurrentInventoryList = VarianceInventory;
        for (var i = 0; i < VarianceInventory.length; i++) {
          var Inventory = this.AllInventoryList.find(
            x => x.InventoryID == VarianceInventory[i].InventoryID
          );
          this.InventoryList.push({
            InventoryID: Inventory.InventoryID,
            InventoryName: Inventory.InventoryName,
            Quantity: VarianceInventory[i].Quantity
          });
        }
      } catch (err) {
        console.log("getVariance error", err);
        this.isLoading = false;
        this.formMessage = err;
        this.showFormMessage = true;
      }
    },
    getVarianceInventory: async function() {
      try {
        const ret = await this.$store.dispatch("getVarianceInventory", {
          router: this.$router,
          data: { VarianceID: this.$route.params.VarianceID }
        });
        console.log("getVarianceInventory", ret);
        return ret.data.VarianceInventory;
      } catch (err) {
        console.log("getVarianceInventory error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    addVarianceImageInput: function() {
      var ID = new Date().getTime();
      this.VarianceImageInput.push({ ID: ID, base64: "", url: "" });
    },
    setDatatoForm: function(data) {},
    formAction: async function() {
      var object = {
        VarianceID: this.$route.params.VarianceID,
        Name: this.VarianceNameInput.value,
        Price: parseInt(this.PriceInput.value),
        Weight: parseInt(this.WeightInput.value)
      };
      var Image = [];
      var newImage = [];
      for (var i in this.VarianceImageInput) {
        var imageObject = {
          ImageID: this.VarianceImageInput[i].ID
        };
        if (this.VarianceImageInput[i].isTempory) {
          imageObject.newImage = true;
        } else {
          imageObject.newImage = false;
        }
        Image.push(imageObject);
      }
      object["Image"] = Image;

      var deleteInventoryList = [];
      for (var i = 0; i < this.CurrentInventoryList.length; i++) {
        var index = this.InventoryList.findIndex(
          x => x.InventoryID == this.CurrentInventoryList[i].InventoryID
        );
        if (index == -1) {
          deleteInventoryList.push(this.CurrentInventoryList[i].InventoryID);
        }
      }
      object["Inventory"] = this.InventoryList;
      object["delInventory"] = deleteInventoryList;
      console.log("object", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateProductVariance", {
          router: this.$router,
          data: object
        });
        console.log("updateProductVariance", ret);
        this.formMessage = this.$route.params.VarianceID + " 選項已成功更新";
        this.showFormMessage = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.go(-1);
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("updateProductVariance error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },

  data() {
    return {
      isLoading: false,
      HeaderTitle: "修改選項",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      Variance: {},
      s3path: process.env.VUE_APP_S3_URL,
      VarianceNameInput: {
        type: "object",
        key: "Name",
        name: "選項名稱",
        required: true,
        style: "width: 100%;",
        model: "",
        value: {}
      },
      PriceInput: {
        key: "Price",
        name: "格價 (HKD)",
        required: true,
        value: ""
      },
      WeightInput: {
        key: "Weight",
        name: "重量 (g)",
        required: true,
        value: ""
      },
      currentImageList: [],
      VarianceImageInput: [],
      InventoryList: [],
      CurrentInventoryList: [],
      AllInventoryList: [],
      showConfirmDeleteImage: false,
      acceptType: "image/*",
      removeImagePopupContent: "",
      deleteImageID: ""
    };
  }
};
</script>
