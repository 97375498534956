<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader :HeaderTitle="HeaderTitle" :confirmBack="confirmBack">
        </FormHeader>
        <center>
          <md-card style="width:50%;min-width: 500px">
            <md-card-content>
              <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :formMessage="formMessage"
                :showFormMessage="showFormMessage"
              >
              </FormComponent>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { FormComponent, FormHeader } from "@/components";
export default {
  methods: {
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    formSubmit: async function(object) {
      console.log("formSubmit");
      console.log(object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createLocation", {
          router: this.$router,
          location: object
        });
        //const ret= await this.$store.dispatch('createCustomer', object)
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Location List",
            params: { message: "New Location added" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("createLocation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  components: {
    FormComponent,
    FormHeader
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Location",
      FormTitle: "New Location",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      FormItem: {
        row1: [
          {
            type: "text",
            key: "Name",
            name: "Name",
            required: true,
            style: "width:100%",
            value: null
          }
        ],
        row2: [
          {
            type: "text",
            key: "Address",
            name: "Address",
            required: true,
            style: "width:100%",
            value: null
          }
        ],
        row3: [
          {
            type: "select",
            key: "District",
            name: "District",
            required: true,
            value: null,
            option: [
              {
                key: "Hong Kong Island",
                name: "Hong Kong Island"
              },
              {
                key: "Kowloon",
                name: "Kowloon"
              },
              {
                key: "New Territories",
                name: "New Territories"
              }
            ]
          },
          {
            type: "number",
            key: "SaleableArea",
            name: "Saleable area(ft)",
            required: false,
            value: null
          }
        ],
        row4: [
          {
            type: "text",
            key: "Latitude",
            name: "Latitude",
            required: false,
            value: null
          },
          {
            type: "text",
            key: "Longitude",
            name: "Longitude",
            required: false,
            value: null
          }
        ]
      }
    };
  }
};
</script>
