<template>
  <draggable
    class="dragArea"
    tag="ul"
    :list="nextLayers"
    :group="{ name: 'g1' }"
  >
    <div v-for="el in nextLayers" :key="el.ID">
      <md-card class="nav-card">
        <md-card-expand>
          <md-card-actions md-alignment="space-between">
            <!-- <md-toolbar :style="{'background-color':el.TitleColour+'!important'}"> -->
            <md-toolbar :style="checkColor(el.TitleColour)">
              <div class="md-toolbar-section-start">
                <md-card-expand-trigger>
                  <md-button
                    class="md-icon-button"
                    :style="checkColor(el.TitleColour)"
                  >
                    <md-icon>keyboard_arrow_down</md-icon>
                  </md-button>
                </md-card-expand-trigger>
                <h3 class="md-title" style="flex: 1">
                  {{ el.ID }} : {{ el.Title.en
                  }}<span v-if="el.Title.zh" style="white-space: pre"
                    >&nbsp;({{ el.Title.zh }})</span
                  >
                </h3>
              </div>

              <md-menu md-size="big" md-direction="bottom-end">
                <md-button
                  class="md-icon-button"
                  md-menu-trigger
                  :style="checkColor(el.TitleColour)"
                >
                  <md-icon>more_vert</md-icon>
                  <md-menu-content>
                    <md-menu-item
                      @click="setAddDialogTarget(el.ID)"
                      v-if="allowAddBelow"
                    >
                      <span>Add Below</span>
                      <md-icon>add</md-icon>
                    </md-menu-item>
                    <md-menu-item @click="remove(el.ID)">
                      <span>Remove</span>
                      <md-icon>delete</md-icon>
                    </md-menu-item>
                  </md-menu-content>
                </md-button>
              </md-menu>
            </md-toolbar>
          </md-card-actions>
          <md-card-expand-content>
            <md-card-content>
              <form>
                <!-- <h5 for="BackgroundColor" v-if="el.ID">{{ el.ID }}</h5> -->

                <div v-if="el.Title">
                  <label>Title</label>
                  <table class="DragDropTable" style="width: 100%">
                    <div v-for="(value, key) in el.Title" :key="key">
                      <tr>
                        <td>
                          <label>{{ key }}</label>
                        </td>
                        <td style="width: 100%">
                          <md-field class="md-layout-item">
                            <md-input
                              name="Content"
                              id="Content"
                              v-model="el.Title[key]"
                            />
                          </md-field>
                        </td>
                        <td>
                          <div
                            class="itemRemove"
                            @click="showDelLangPopup(key, el.ID)"
                          >
                            <u>remove</u>
                          </div>
                        </td>
                      </tr>
                    </div>
                  </table>
                </div>

                <div v-if="el.Content">
                  <label for="BackgroundColor">Content</label>
                  <table class="DragDropTable" style="width: 100%">
                    <div v-for="(value, key) in el.Content" :key="key">
                      <tr>
                        <td>
                          <label>{{ key }}</label>
                        </td>
                        <td style="width: 100%">
                          <md-field class="md-layout-item">
                            <md-input
                              name="Content"
                              id="Content"
                              v-model="el.Content[key]"
                            />
                          </md-field>
                        </td>
                        <td>
                          <div
                            class="itemRemove"
                            @click="showDelLangPopup(key, el.ID)"
                          >
                            <u>remove</u>
                          </div>
                        </td>
                      </tr>
                    </div>
                  </table>
                </div>

                <div style="text-align: center;" @click="setNewLangId(el.ID)">
                  <md-icon>add_circle_outline</md-icon>
                  <u class="addLang">Add new Lang</u>
                </div>

                <div
                  style="padding-top: 20px"
                  v-if="
                    el.TitleColour ||
                      el.TitleColour == '' ||
                      el.Public ||
                      el.Public == ''
                  "
                >
                  <table class="DragDropTable" style="width: 100%">
                    <tr>
                      <td v-if="el.TitleColour">
                        <div style="width: 70%">
                          <md-field>
                            <label>TitleColour</label>
                            <md-input
                              style="margin-top: 20px;"
                              type="color"
                              name="TitleColour"
                              id="TitleColour"
                              v-model="el.TitleColour"
                              :disabled="sending"
                            />
                          </md-field>
                        </div>
                      </td>
                      <td v-if="el.Public">
                        <div style="width: 70%">
                          <label>Public</label>
                          <md-field>
                            <md-select v-model="el.Public" class="formInput">
                              <md-option value="true">True</md-option>
                              <md-option value="false">False</md-option>
                            </md-select>
                          </md-field>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="el.Url || el.Url == ''">
                  <table class="DragDropTable" style="width: 100%">
                    <tr>
                      <td>
                        <md-field>
                          <label for="Url">Url</label>
                          <md-input
                            name="Url"
                            id="Url"
                            v-model="el.Url"
                            :disabled="sending"
                          />
                        </md-field>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="el.BackgroundImage || el.BackgroundImage == ''">
                  <table class="DragDropTable" style="width: 100%">
                    <tr>
                      <td style="width: 70%">
                        <label>BackgroundImage</label><br />
                        <img
                          v-if="el.BackgroundImage != ''"
                          :src="el.BackgroundImage"
                          style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
                        />
                        <md-field>
                          <md-file
                            name="BackgroundImage"
                            id="BackgroundImage"
                            :disabled="sending"
                            accept="image/*"
                            @md-change="
                              confirmUploadFile(
                                $event,
                                el.ID,
                                el.BackgroundImage
                              )
                            "
                          />
                        </md-field>
                      </td>
                      <td style="width: 30%;text-align: center;">
                        <div
                          class="imagePreview"
                          @click="previewImage(el.BackgroundImage)"
                        >
                          <u>Preview</u>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
            </md-card-content>
          </md-card-expand-content>
        </md-card-expand>
      </md-card>
      <nested-draggable
        v-if="allowAddBelow"
        :nextLayers="el.NextLayer"
        :sending="sending"
        v-on:remove="remove"
        v-on:setNewLangId="setNewLangId"
        v-on:showDelLangPopup="showDelLangPopup"
        v-on:setAddDialogTarget="setAddDialogTarget"
      />
    </div>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  props: {
    allowAddBelow: {
      require: true,
      default() {
        return false;
      }
    },
    nextLayers: {
      required: true,
      type: Array,
      default() {
        return [];
      }
    },
    sending: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeImage: async function(id, imageUrl) {
      var object = {
        id: id,
        imageUrl: imageUrl
      };
      this.$emit("removeImage", object);
    },
    confirmUploadFile: async function(fileList, id, oldImage) {
      var MediaURLList = [];
      if (fileList.constructor === FileList && fileList[0] !== undefined) {
        for (var i = 0; i < fileList.length; i++) {
          var file = fileList[i];
          console.log("file", file);
          var object = {
            file: file,
            base64: await this.getBase64(file),
            url: URL.createObjectURL(file)
          };
          MediaURLList.push(object);
          //this.MediaBase64List[i]=file;
        }
      }
      var object = {
        id: id,
        MediaURLList: MediaURLList,
        imageUrl: oldImage
      };
      this.$emit("confirmUploadFile", object);
    },
    getBase64(file) {
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = ev => {
          resolve(ev.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    previewImage: function(url) {
      window.open(url, "_blank");
    },
    setAddDialogTarget: function(id) {
      console.log("setAddDialogTarget: " + id);
      this.$emit("setAddDialogTarget", id);
    },
    remove: function(id) {
      console.log("remove: " + id);
      this.$emit("remove", id);
    },
    setNewLangId: function(id) {
      console.log("set new lang target: " + id);
      this.$emit("setNewLangId", id);
    },
    showDelLangPopup: function(lang, id) {
      console.log("delete lang: " + lang + ", " + id);
      this.$emit("showDelLangPopup", lang, id);
    },
    checkColor(color) {
      if (color == undefined || color == null || color == "") {
        return {
          "background-color": "black !important"
        };
      } else {
        return {
          "background-color": color + " !important"
        };
      }
    }
  },
  data: () => ({}),
  computed: {},
  components: {
    draggable
  },
  name: "nested-draggable"
};
</script>
<style scoped>
.itemRemove {
  font-weight: 400;
  font-size: 18px;
  padding-top: 25px;
  color: #cc2727;
  cursor: pointer;
}
.imagePreview {
  font-weight: 400;
  font-size: 18px;
  padding-top: 25px;
  cursor: pointer;
}
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
.nav-card {
  background-color: transparent !important;
  box-shadow: none;
  margin: 0;
}
.nav-card .md-card-actions {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent !important;
}

.nav-card .md-toolbar {
  display: flex;
  margin: 0;
}
.nav-card .md-card-expand-content {
  background-color: #fff;
}
.nav-card .md-toolbar-section-start {
  display: flex;
}

.nav-card .md-toolbar-section-start .md-button {
  box-shadow: none;
}

.nav-card .md-menu .md-button {
  box-shadow: none;
}
.DragDropTable tr td {
  border: none;
}
.addLang {
  cursor: pointer;
  font-size: 18px;
}
.formInput {
  font-weight: 500;
  font-size: 18px !important;
}
</style>
