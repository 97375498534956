import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const surveyLanding = {
  getSurveyLanding({ commit, state }, { router }) {
    return new Promise((resolve, reject) => {
      commit("action_request");
      axios({ url: url + "/getSurveyLanding", method: "GET" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  updateSurveyLanding({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("updateSurveyLanding", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/updateSurveyLanding", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  }
  // updateCustomer({commit,state},customer){
  //   return new Promise((resolve, reject) => {
  //     console.log(customer);
  //     commit('action_request');
  //     //resolve(state.idToken);
  //     axios({url: url+'/admin-update-user-info', data:customer, method: 'POST' })
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       if(err.response.stats==401){

  //       }
  //       commit('action_error',{err})
  //       reject(err)
  //     })

  //   })
  // },
};

export default surveyLanding;
