<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        >
        </FormHeader>
        <center>
          <md-card style="width:50%;min-width: 500px;text-align: left;">
            <md-card-content>
              <div v-if="FormTitle" class="row">
                <div style="width:50%"><h3 class="formTitle">用戶資訊</h3></div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="inputcolumn">
                    <TextInput :Item="UserInput.GivenNameInput" />
                  </div>
                  <div class="inputcolumn">
                    <TextInput :Item="UserInput.FamilyNameInput" />
                  </div>
                </div>
                <div class="row">
                  <div class="fullRow">
                    <TextInput :Item="UserInput.NameInput" />
                  </div>
                </div>
                <div class="row">
                  <div class="fullRow">
                    <TextInput :Item="UserInput.EamilInput" />
                  </div>
                </div>
                <div class="row" v-if="userInfo.Supplier">
                  <div class="inputcolumn">
                    <TextInput :Item="UserInput.SupplierNameInput" />
                  </div>
                  <div class="inputcolumn">
                    <TextInput :Item="UserInput.SupplierDescriptionInput" />
                  </div>
                </div>
                <div class="row">
                  <div class="inputcolumn">
                    <table>
                      <tr>
                        <td style="width: 5%;">
                          <label style="font-size: 20px;">+</label>
                        </td>
                        <td style="width: 25%;">
                          <TextInput :Item="UserInput.CountryCodeInput" />
                        </td>
                        <td style="width: 5%;">
                          <label style="font-size: 25px;">-</label>
                        </td>
                        <td style="width: 65%;">
                          <TextInput :Item="UserInput.PhoneInput" />
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="inputcolumn">
                    <TextInput :Item="UserInput.BirthdayInput" />
                  </div>
                </div>
                <div class="row">
                  <div class="inputcolumn">
                    <SelectInput :Item="UserInput.GenderInput" />
                  </div>
                  <div class="inputcolumn">
                    <label>頭像</label>
                    <FileInput
                      v-on:RemoveItem="RemoveItem"
                      :Item="UserInput.AvatarInput"
                    />
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDeleteAvatar">
      <Popup
        v-on:popupcancel="showConfirmDeleteAvatar = false"
        v-on:popupaction="deleteAvatar"
        PopupTitle="刪除用戶頭像"
        PopupContent="是否確定刪除用戶頭像?"
        actionButton="刪除"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import {
  SelectInput,
  FileInput,
  TextInput,
  FormHeader,
  Spinner,
  Popup
} from "@/components";
export default {
  components: {
    SelectInput,
    FileInput,
    TextInput,
    FormHeader,
    Spinner,
    Popup
  },
  created() {
    console.log(this.$route.params);
    this.getUser(this.$route.params.customer);
  },
  methods: {
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    getUser: async function(UserID) {
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("getCustomerInfo", {
          router: this.$router,
          customer: { userID: UserID }
        });
        console.log(ret);
        this.userInfo = ret.data.userInfo;
        this.setDatatoForm(this.userInfo);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("getCustomerInfo error", err.response.data);
        this.message = err.response.data.message;
        this.showMessage = true;
      }
    },
    setDatatoForm: function(data) {
      console.log("setDatatoForm", data);
      for (const [userKey, userValue] of Object.entries(this.UserInput)) {
        if (userValue.type == "image") {
          console.log("image", data["Avatar"]);
          if (data["Avatar"] != null) {
            userValue["url"] = this.s3path + data["Avatar"];
          }
        } else {
          userValue["value"] = data[userValue.key];
        }
      }
    },
    RemoveItem: async function(ID, deleteAction) {
      if (deleteAction) {
        this.showConfirmDeleteAvatar = true;
      }
    },
    deleteAvatar: async function() {
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deteleUserAvatar", {
          router: this.$router,
          customer: { UserID: this.userInfo.UserID }
        });
        console.log("deteleUserAvatar", ret);
        this.UserInput.AvatarInput.url = "";
        this.isLoading = false;
        this.showConfirmDeleteAvatar = false;
      } catch (err) {
        this.isLoading = false;
        console.log("deteleUserAvatar error", err);
      }
    },
    formAction: async function() {
      console.log(this.UserInput);
      var object = {
        UserID: this.userInfo.UserID
      };
      for (const [userKey, userValue] of Object.entries(this.UserInput)) {
        if (userValue.key == "SupplierName" && !this.userInfo.Supplier) {
          continue;
        }
        if (userValue.required && userValue.value == "") {
          this.message = userValue.name + " is required";
          this.showMessage = true;
          return;
        }
        if (userValue.key == "PhoneCountryCode") {
          continue;
        } else if (userValue.key == "Phone") {
          if (this.userInfo[userValue.key] != userValue.value) {
            object[userValue.key] =
              this.UserInput.CountryCodeInput.value + userValue.value;
          }
        } else if (userValue.key == "Avatar") {
          console.log("MediaID", userValue.ID);
          if (userValue.ID !== "") {
            object[userValue.key] = userValue.ID;
          }
        } else {
          if (this.userInfo[userValue.key] !== userValue.value) {
            object[userValue.key] = userValue.value;
          }
        }
      }
      console.log("object", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateCustomer", {
          router: this.$router,
          customer: object
        });
        this.isLoading = false;
        this.message = "Update Success";
        this.showMessage = true;
      } catch (err) {
        this.isLoading = false;
        console.log("updateCustomer error", err.response.data);
        this.message = err.response.data.message;
        this.showMessage = true;
      }
    }
  },

  data() {
    return {
      //form default data
      s3path: process.env.VUE_APP_S3_URL,
      isLoading: false,
      HeaderTitle: "修改用戶資料",
      FormTitle: "用戶資料",
      formActionButton: "儲存",
      message: null,
      showMessage: false,
      UserInput: {
        GivenNameInput: {
          type: "text",
          key: "GivenName",
          name: "名字",
          required: true,
          value: ""
        },
        FamilyNameInput: {
          type: "text",
          key: "FamilyName",
          name: "姓氏",
          required: true,
          value: ""
        },
        NameInput: {
          type: "text",
          key: "Name",
          name: "Username",
          required: true,
          value: ""
        },
        SupplierNameInput: {
          type: "text",
          key: "SupplierName",
          name: "供應商名稱",
          required: true,
          value: ""
        },
        SupplierNameInput: {
          type: "text",
          key: "SupplierName",
          name: "供應商名稱",
          required: true,
          value: ""
        },
        SupplierDescriptionInput: {
          type: "text",
          key: "SupplierDescription",
          name: "供應商描述",
          required: false,
          value: ""
        },
        CountryCodeInput: {
          type: "number",
          key: "PhoneCountryCode",
          name: "區碼",
          required: false,
          value: ""
        },
        PhoneInput: {
          type: "number",
          key: "Phone",
          name: "電話",
          required: false,
          value: "",
          countyCode: ""
        },
        EamilInput: {
          type: "text",
          key: "Email",
          name: "電郵",
          required: true,
          value: ""
        },
        BirthdayInput: {
          type: "date",
          key: "Birthday",
          name: "出生日期",
          required: false,
          value: ""
        },
        GenderInput: {
          type: "select",
          type: "select",
          key: "Gender",
          name: "性別",
          required: false,
          value: "",
          option: [
            {
              key: "M",
              name: "M"
            },
            {
              key: "F",
              name: "F"
            },
            {
              key: "Others",
              name: "Others"
            }
          ]
        },
        AvatarInput: {
          type: "image",
          key: "Avatar",
          name: "頭像",
          base64: "",
          url: "",
          ID: "",
          isTempory: false
        }
      },
      userInfo: {},
      confirmBack: false,
      showConfirmDeleteAvatar: false
    };
  }
};
</script>
