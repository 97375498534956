<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="updateEventLevel"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <center>
          <md-card style="width: 50%;text-align: left;">
            <md-card-content>
              <LanguageObjectInput :Item="eventLevelInput" />
            </md-card-content>
          </md-card>
          <br />
          <div style="width: 50%;text-align: left;">
            <button class="enableButton" @click="showConfirmDelete = true">
              刪除等級
            </button>
          </div>
        </center>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDelete">
      <Popup
        v-on:popupcancel="showConfirmDelete = false"
        v-on:popupaction="deleteEventLevel"
        PopupTitle="確認刪除"
        PopupContent="是否確定要刪除這個等級? 刪除的資料將無法復原!"
        actionButton="刪除"
      />
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, LanguageObjectInput, Popup } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    LanguageObjectInput,
    Popup
  },
  async created() {
    console.log("EventLevelID", this.$route.params);
    this.EventLevelID = this.$route.params.EventLevelID;
    if (!this.EventLevelID) {
      this.$router.push({ name: "EventLevelList" });
    }
    if (!this.$route.params.Name) {
      await this.getEventLevel();
    } else {
      this.Name = this.$route.params.Name;
      this.HeaderTitle = this.Name[this.language];
    }
    await this.getLanguage();
  },
  methods: {
    updateEventLevel: async function() {
      var object = {
        EventLevelID: this.EventLevelID,
        Name: this.eventLevelInput.value
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateEventLevel", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        this.formMessage = "更新成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.replace({ name: "EventLevelList" });
        }, 2000);
      } catch (err) {
        console.log("updateEventLevel error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    deleteEventLevel: async function() {
      this.showConfirmDelete = false;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deleteEventLevel", {
          router: this.$router,
          data: { EventLevelID: this.EventLevelID }
        });
        console.log("deleteEventLevel", ret);
        this.isLoading = false;
        this.formMessage = "刪除成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.replace({ name: "EventLevelList" });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("deleteEventLevel error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getEventLevel: async function() {
      try {
        const ret = await this.$store.dispatch("getEventLevel", {
          router: this.$router,
          data: { EventLevelID: this.EventLevelID }
        });
        console.log("getEventLevel", ret);
        this.EventLevelID = ret.data.EventLevel.EventLevelID;
        this.Name = ret.data.EventLevel.Name;
        this.HeaderTitle = this.Name[this.language];
      } catch (err) {
        console.log("getEventLevel error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        var model = "";
        for (var i in this.languageList) {
          model +=
            this.languageList[i].Language +
            ":" +
            this.Name[this.languageList[i].Language] +
            " / ";
          this.eventLevelInput.value[this.languageList[i].Language] = this.Name[
            this.languageList[i].Language
          ];
        }
        this.eventLevelInput.model = model.slice(0, -2);
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      EventLevelID: "",
      language: "zh",
      formActionButton: "儲存",
      HeaderTitle: "",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      deleteContent: "",
      deleteLanguageID: "",
      formMessage: "",
      languageList: [],
      Name: {},
      eventLevelInput: {
        name: "等級名稱",
        key: "Name",
        required: true,
        model: "",
        value: {}
      }
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
