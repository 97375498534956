<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="popup">
          <div class="modal-header">
            <slot name="header">{{ PopupTitle }}</slot>
          </div>
          <div class="modal-body" style="text-align: center">
            <Form
              v-on:formSubmit="popupaction"
              v-on:cancelAction="popupcancel"
              :FormItem="PopupContent"
              :DoneButton="actionButton"
              :cancelButton="cancelButton"
            >
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "./Form.vue";
export default {
  components: {
    Form
  },
  methods: {
    wrapperclick: function() {
      console.log("wrapperclick");
    },
    containerclick: function() {
      console.log("containerclick");
    },
    popupcancel: function() {
      this.$emit("popupcancel");
    },
    popupaction: async function(object) {
      console.log("PopupContent: ", object);
      this.$emit("popupaction", object);
    }
  },
  name: "newdata-popup-component",
  props: {
    PopupTitle: {
      required: true,
      type: String,
      default() {
        return "";
      }
    },
    PopupContent: {
      required: true,
      type: Object,
      default() {
        return {};
      }
    },
    actionButton: {
      required: true,
      type: String,
      default() {
        return "";
      }
    }
  },
  data() {
    return {
      newData: "",
      cancelButton: "Cancel",
      DoneButton: "Add"
    };
  },
  computed: {}
};
</script>
<style>
.popup {
  padding: 30px 0px;
  margin: 0px 20px;
}
.popupcontent {
  width: 80%;
}
.modal-wrapper {
  z-index: -1;
}
.modal-container {
  z-index: 50;
  min-width: 350px;
  max-width: 400px;
}
.modal-header {
  font-size: 24px;
  font-weight: 500;
}
.modal-body {
  font-size: 16px;
  font-weight: 500;
}
.cancelButton {
  width: 100%;
  background-color: #575757;
  color: white;
  border-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.actionButton {
  width: 100%;
  background-color: white;
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
</style>
