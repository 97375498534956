import { render, staticRenderFns } from "./CreateParingTag.vue?vue&type=template&id=104c3f6a&"
import script from "./CreateParingTag.vue?vue&type=script&lang=js&"
export * from "./CreateParingTag.vue?vue&type=script&lang=js&"
import style0 from "./CreateParingTag.vue?vue&type=style&index=0&id=104c3f6a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports