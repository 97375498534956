<template lang="html">
  <md-content
    class="loading-overlay md-primary full-screen md-layout md-alignment-center"
    style="background-color: gray;"
  >
    <md-progress-spinner
      claas="spinner"
      md-mode="indeterminate"
      style="stroke:darkgray"
    />
  </md-content>
</template>

<script>
// import loaderStore from '@/assets/functions/loaderHandler.js';
import { mapState } from "vuex";

export default {
  name: "TheLoader"

  // computed: mapState({
  //   isLoading(state) {
  //     return state.loader.loading;
  //   },
  // }),
  // props: {
  //     isLoading: {
  //         type: Boolean,
  //         default: false
  //     }
  // }
};
</script>

<style lang="css" scoped>
.loading-overlay {
  position: fixed;
  z-index: 1000;
  opacity: 0.8;

  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.spinner circle {
  stroke: darkgray !important;
}
</style>
