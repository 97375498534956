import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const newInSection = {
  getNewInSection({ commit, state }, { router }) {
    return new Promise((resolve, reject) => {
      commit("action_request");
      axios({ url: url + "/getNewInSection", method: "GET" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  updateNewInSectionTitle({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("updateNewInSectionTitle", form);
      commit("action_request");
      resolve(state.idToken);
      axios({
        url: url + "/updateNewInSectionTitle",
        data: form,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  },
  createNewInSectionContent({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("createNewInSectionContent", form);
      commit("action_request");
      resolve(state.idToken);
      axios({
        url: url + "/createNewInSectionContent",
        data: form,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  },
  updateNewInSectionContent({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("updateNewInSectionContent", form);
      commit("action_request");
      resolve(state.idToken);
      axios({
        url: url + "/updateNewInSectionContent",
        data: form,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          console.log(err);
          if (err.response.stats == 401) {
            this.$router.push({ path: "/login" });
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  setNewInSection({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("setNewInSection", form);
      commit("action_request");
      resolve(state.idToken);
      axios({ url: url + "/setNewInSection", data: form, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  },
  deleteNewInSectionContent({ commit, state }, form) {
    return new Promise((resolve, reject) => {
      console.log("deleteNewInSectionContent", form);
      commit("action_request");
      resolve(state.idToken);
      axios({
        url: url + "/deleteNewInSectionContent",
        data: form,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(async err => {
          try {
            var ret = await errorHandler.handle(
              commit,
              err,
              router,
              path,
              params
            );
            console.log(ret);
            if (ret.status == 200) {
              resolve(ret);
            } else {
              console.log(err);
              commit("action_error", { err });
              reject(err);
            }
          } catch (err) {
            console.log(err);
            commit("action_error", { err });
            reject(err);
          }
        });
    });
  }
  //   return new Promise((resolve, reject) => {
  //     commit('action_request');
  //     //resolve(state.idToken);
  //     console.log({url: url+path,data:params, method: 'POST' })
  //     var config={url: url+path,data:params, method: 'POST' }
  //     if(form){
  //       config["headers"]={ 'Content-Type': 'multipart/form-data' }
  //     }
  //     axios(config)
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(async err => {
  //       try{
  //         var ret=await errorHandler.handle(commit,err,router,path,params)
  //         console.log(ret)
  //         if(ret.status==200){
  //           resolve(ret)
  //         }
  //         else{
  //           console.log(err);
  //           commit('action_error',{err})
  //           reject(err)
  //         }
  //       }
  //       catch(err){
  //         console.log(err);
  //         commit('action_error',{err})
  //         reject(err)
  //       }

  //     })

  //   })
};

export default newInSection;
