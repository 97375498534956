<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <md-card style="width:50%;min-width: 500px">
            <md-card-content>
              <FormComponent
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :haveDoneButton="false"
              >
              </FormComponent>
            </md-card-content>
          </md-card>
          <md-card
            style="width:45%;min-width: 500px; margin-left: 50px;z-index: 0;"
          >
            <md-card-content>
              <ImageEditor
                v-on:confirmUploadFile="confirmUploadFile"
                :Image="Image"
                :newImage="newImage"
                :ImageEditorTitle="ImageEditorTitle"
                :sending="sending"
              ></ImageEditor>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmUpdateImage">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="updateImagePopupAction"
        :PopupTitle="updateImagePopupTitle"
        :PopupContent="updateImagePopupContent"
        :actionButton="updateImageActionButton"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  FormComponent,
  ImageEditor,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    FormHeader,
    FormComponent,
    ImageEditor,
    Popup,
    Spinner
  },
  created() {
    this.getInformation("SurveyLandingSection");
  },
  mounted() {},
  methods: {
    /***  Page Function  ***/
    getInformation: async function(InformationID) {
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("getInformation", {
          router: this.$router,
          data: { id: InformationID }
        });
        console.log("getInformation", ret);
        this.setDatatoForm(ret.data.Information.content);
        this.Image = ret.data.Information.content.BackgroundImage;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("getInformation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    formAction: async function() {
      this.sending = true;
      var Content = await this.getFormData(this.FormItem);
      Content.BackgroundImage = this.Image;
      var object = {
        id: "SurveyLandingSection",
        content: Content
      };
      console.log("imageActionSave", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateInformation", {
          router: this.$router,
          data: object
        });
        console.log("updateInformation", ret);
        this.sending = false;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Content Management",
            params: { message: "Survey Landing Section has been updated" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.sending = false;
        console.log("updateInformation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setDatatoForm: function(data) {
      console.log("setDatatoForm", data);
      for (const [key, value] of Object.entries(this.FormItem)) {
        for (var i = 0; i < value.length; i++) {
          value[i]["value"] = data[value[i].key];
        }
      }
    },
    uploadFile: async function(fileData) {
      console.log("uploadFile", fileData);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("uploadHomeBannerImage", {
          router: this.$router
        });
        console.log("uploadHomeBannerImage", ret);
        const retMedia = await this.$store.dispatch("updateMediaToS3", {
          file: fileData.MediaURLList[0].base64,
          urlObject: ret.data.urlObject
        });
        this.isLoading = false;
        console.log("updateMediaToS3", retMedia);
        var newImageUrl = ret.data.urlObject.url.split("?")[0];
        this.Image[this.updateImageObject.id] = newImageUrl;
        this.formMessage = fileData.id + " New Image Upload Success";
        this.showFormMessage = true;
        this.imageActionSave();
      } catch (err) {
        this.isLoading = false;
        console.log("uploadHomeBannerImage error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getFormData: async function(form) {
      var FormData = {};
      for (const [key, value] of Object.entries(form)) {
        for (var i = 0; i < value.length; i++) {
          if (value[i].required) {
            if (value[i].value) {
              if (value[i].type == "number") {
                FormData[value[i].key] = parseInt(value[i].value);
              } else {
                FormData[value[i].key] = value[i].value;
              }
            } else {
              this.formMessage = value[i].name + " is required";
              this.showFormMessage = true;
              return;
            }
          } else {
            if (value[i].type == "number") {
              FormData[value[i].key] = parseInt(value[i].value);
            } else {
              FormData[value[i].key] = value[i].value;
            }
          }
        }
      }
      return FormData;
    },
    imageActionSave: async function() {
      this.sending = true;
      var Content = await this.getFormData(this.FormItem);
      Content.BackgroundImage = this.Image;
      var object = {
        id: "SurveyLandingSection",
        content: Content
      };
      console.log("imageActionSave", object);
      try {
        const ret = await this.$store.dispatch("updateInformation", {
          router: this.$router,
          data: object
        });
        console.log("updateInformation", ret);
        this.sending = false;
        setTimeout(() => {
          this.formMessage = "Survey Landing Section has been updated";
          this.showFormMessage = true;
        }, 2000);
      } catch (err) {
        this.sending = false;
        console.log("updateInformation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },

    /*** End of Page Function  ***/

    /***  open Popup  ***/
    confirmUploadFile: function(object) {
      this.updateImageObject = object;
      this.updateImagePopupContent =
        "Are you sure you want to update " + object.id + " ?";
      this.showConfirmUpdateImage = true;
    },
    /***  end of open Popup  ***/

    /***  Popup Action  ***/
    updateImagePopupAction: async function() {
      this.showConfirmUpdateImage = false;
      var object = {
        Url: this.updateImageObject.imageUrl
      };
      try {
        const ret = await this.$store.dispatch("deleteHomeBannerImage", {
          router: this.$router,
          data: object
        });
        console.log("removeImagePopupAction", ret);
        this.uploadFile(this.updateImageObject);
      } catch (err) {
        console.log("deleteHomeBannerImage error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    popupcancel: function() {
      this.newImage = null;
      this.updateImageObject = {};
      this.showConfirmUpdateImage = false;
    }
    /***  end of Popup Action  ***/
  },
  data() {
    return {
      isLoading: false,
      sending: false,
      HeaderTitle: "Survey Landing Section",
      formActionButton: "Save",
      formMessage: null,
      FormTitle: "Text Information",
      ImageEditorTitle: "Background Image",
      showFormMessage: false,
      confirmBack: false,
      checkEdited: false,
      Image: {},
      FormItem: {
        row1: [
          {
            type: "object",
            key: "Title",
            name: "Title",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ],
        row2: [
          {
            type: "object",
            key: "Content",
            name: "Content",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ],
        row3: [
          {
            type: "object",
            key: "ButtonText",
            name: "ButtonText",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ],
        row4: [
          {
            type: "text",
            key: "ButtonUrl",
            name: "ButtonUrl",
            required: true,
            style: "width:100%",
            value: null
          }
        ]
      },
      newImage: "",
      showConfirmUpdateImage: false,
      updateImagePopupTitle: "Confirm Update Image",
      updateImagePopupContent: "",
      updateImageActionButton: "Confirm",
      updateImageObject: {}
    };
  }
};
</script>
