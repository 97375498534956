import axios from "axios";
import oneSipApiCall from "../apiCall.js";
const Buffer = require("buffer").Buffer;

const upload = {
  uploadMedia({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-upload-temporary-media",
      data
    );
  },
  deleteMedia({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-delete-temporary-media",
      data
    );
  },
  async updateMediaToS3({ commit, state }, { file, urlObject }) {
    console.log("updateMediaToS3", urlObject);

    delete axios.defaults.headers.common["Authorization"];
    axios.defaults.data = "";
    var buf = Buffer.from(
      file.replace(/^data:+\w+\/\w+;base64,/, ""),
      "base64"
    );
    const signedUrl = urlObject.url;
    console.log(axios.defaults);
    try {
      const response = await axios.put(signedUrl, buf, {
        headers: {
          "Content-Type": urlObject.ContentType,
          "x-amz-content-sha256": "UNSIGNED-PAYLOAD",
          "Content-Encoding": "base64"
        }
      });
      axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "idToken"
      );
      console.log("response", response);
      return response;
    } catch (error) {
      console.error(`Error uploading image to S3: ${error}`);
      axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "idToken"
      );
      return error;
    }
  }
};
export default upload;
