import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const userGroup = {
  listUserGroup({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/AdminGetUserGroup", data);
  },
  listAllUserGroup({ commit, state }, { router }) {
    return oneSipApiCall(commit, state, router, "/AdminListUserGroup");
  },
  createUserGroup({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/AdminCreateUserGroup", data);
  },
  listUserInUserGroup({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/AdminGetUserInUserGroup",
      data
    );
  },
  addUserToUserGroup({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/AdminAddUserToUserGroup",
      data
    );
  },
  removeUserFromUserGroup({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/AdminRemoveUserFromUserGroup",
      data
    );
  },
  deleteUserGroup({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/AdminDeleteUserGroup", data);
  }
};

export default userGroup;
