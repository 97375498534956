<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="popup">
          <div class="modal-header">
            <slot name="header">{{ PopupTitle }}</slot>
          </div>
          <div class="modal-body" style="text-align: center">
            <center>
              <div class="popupcontent">{{ PopupContent }}</div>
            </center>
            <div class="row" style="text-align: center;padding-top: 20px;">
              <div class="column" style="width:47.5%">
                <button class="cancelButton" @click="popupcancel">取消</button>
              </div>
              <div class="column" style="width:5%"></div>
              <div class="column" style="width:47.5%">
                <button class="actionButton" @click="popupaction">
                  {{ actionButton }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    wrapperclick: function() {
      console.log("wrapperclick");
    },
    containerclick: function() {
      console.log("containerclick");
    },
    popupcancel: function() {
      this.$emit("popupcancel");
    },
    popupaction: function() {
      this.$emit("popupaction");
    }
  },
  name: "popup-component",
  props: {
    PopupTitle: {
      required: true,
      type: String,
      default() {
        return "";
      }
    },
    PopupContent: {
      required: true,
      type: String,
      default() {
        return "";
      }
    },
    actionButton: {
      required: true,
      type: String,
      default() {
        return "";
      }
    }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
<style>
.popup {
  padding: 30px 0px;
  margin: 0px 20px;
}
.popupcontent {
  width: 80%;
}
.modal-wrapper {
  z-index: -1;
}
.modal-container {
  z-index: 99;
  min-width: 350px;
  max-width: 400px;
}
.modal-header {
  font-size: 24px;
  font-weight: 500;
}
.modal-body {
  font-size: 16px;
  font-weight: 500;
}
.cancelButton {
  width: 100%;
  background-color: #575757;
  color: white;
  border-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.actionButton {
  width: 100%;
  background-color: white;
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
</style>
