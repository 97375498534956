<template>
  <div>
    <label class="infoTitle">{{ Title }}</label>
    <table class="informationTable">
      <tr v-for="(value, key) in ProductList" :key="key">
        <td>
          <img class="productImg" :src="value.Image" />
        </td>
        <td>
          <div class="column productText">
            <div>{{ value["ProductName"] }}</div>
            <div>Quantity: {{ value["Quantity"] }}</div>
          </div>
        </td>
        <td>
          <div class="productText">${{ value["UnitPrice"] }}</div>
        </td>
        <!-- <td style="text-align: right;">{{ value }}</td> -->
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    Title: {
      type: String,
      default: "Information Title"
    },
    ProductList: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style>
.informationTable {
  width: 100%;
}
.informationTable td {
  border: none;
}
.infoTitle {
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
}
</style>
