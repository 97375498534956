const label = "Checkbox";
const name = "Checkbox";

export default {
  icon: "text_fields",
  label,
  name,
  haveAnswer: true,
  haveLikeAction: false,
  haveHaveModelAnswer: true,
  haveHits: true
};
