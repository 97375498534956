<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:40%" v-if="!editOn">
            <md-card style="margin:10px 0">
              <md-card-content>
                <FormComponent
                  v-on:formSubmit="formSubmit"
                  v-on:checkEdited="checkEdited"
                  :FormTitle="FormTitle"
                  :FormItem="FormItem"
                >
                </FormComponent>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:40%;padding-top:10px" v-if="editOn">
            <CodeEditor
              :code="HtmlPart"
              v-on:updateCode="updateHtmlPart"
            ></CodeEditor>
          </div>
          <div style="width:60%;padding-left: 30px;z-index: 0;">
            <HtmlPreview
              :HtmlPart="HtmlPart"
              @update="updateHtmlPart"
            ></HtmlPreview>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormComponent,
  FormHeader,
  CodeEditor,
  HtmlPreview,
  Spinner
} from "@/components";

export default {
  components: {
    FormComponent,
    FormHeader,
    HtmlPreview,
    CodeEditor,
    Spinner
  },
  created() {},
  methods: {
    formAction: function() {
      this.editOn = !this.editOn;
    },
    formSubmit: async function(data) {
      data.HtmlPart = this.HtmlPart;
      console.log("formSubmit", data);
      this.isLoading = true;
      try {
        await this.$store.dispatch("createEmailTemplate", {
          router: this.$router,
          data: data
        });
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Email Template",
            params: { message: "New Email Template created" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("createEmailTemplate error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },

    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    updateHtmlPart(value) {
      this.confirmBack = true;
      this.HtmlPart = value;
    }
  },
  watch: {
    HtmlPart(newValue) {
      console.log("HtmlPart changed: " + newValue);
    }
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Email Template",
      FormTitle: "Template Info",
      formActionButton: "Edit Mode",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      QuestionList: [],
      HtmlPart: "",
      editOn: false,
      FormItem: {
        row1: [
          {
            type: "text",
            key: "TemplateName",
            name: "Template Name",
            required: true,
            style: "width:100%",
            value: null
          }
        ],
        row2: [
          {
            type: "text",
            key: "SubjectPart",
            name: "Subject",
            required: true,
            style: "width:100%",
            value: null
          }
        ],
        row3: [
          {
            type: "text",
            key: "TextPart",
            name: "Text",
            required: false,
            style: "width:100%",
            value: null
          }
        ],
        row4: [
          {
            type: "object",
            key: "templateData",
            name: "Data",
            hits: "TemplateData (using {{ data }} in html for data)",
            style: "width:100%",
            value: {}
          }
        ]
      }
    };
  }
};
</script>
