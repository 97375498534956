<template>
  <div class="content" style="overflow-x: scroll;">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row" style="flex-wrap: inherit;">
          <div style="min-width:40%">
            <md-card style="margin:10px 0">
              <md-card-content>
                <TextInput
                  :Item="SurveyInfoInput.TemplateNameInput"
                  v-on:checkEdited="checkEdited"
                />
                <TextInput
                  :Item="SurveyInfoInput.DescriptionInput"
                  v-on:checkEdited="checkEdited"
                />
                <SelectInput
                  :Item="SurveyInfoInput.TemplateTypeInput"
                  v-on:checkEdited="checkEdited"
                />
                <CheckBoxInput
                  :Item="SurveyInfoInput.HideProductNameInput"
                  v-on:checkEdited="checkEdited"
                />
                <CheckBoxInput
                  :Item="SurveyInfoInput.CanReSubmitInput"
                  v-on:checkEdited="checkEdited"
                />
              </md-card-content>
            </md-card>
          </div>
          <div style="min-width:45%;padding-left: 20px;">
            <SurveyTemplatePage
              v-on:addSurveyPage="addSurveyPage"
              v-on:delSurveyPage="delSurveyPage"
              v-on:diplicateSurveyPage="diplicateSurveyPage"
              :QuestionList="QuestionList"
            />
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  TextInput,
  SelectInput,
  FormHeader,
  CheckBoxInput,
  //SurveyTemplateQuestion,
  SurveyTemplatePage,
  Spinner
} from "@/components";

export default {
  components: {
    TextInput,
    SelectInput,
    FormHeader,
    CheckBoxInput,
    //SurveyTemplateQuestion,
    SurveyTemplatePage,
    Spinner
  },
  created() {},
  methods: {
    diplicateSurveyPage: function(index) {
      console.log("diplicateSurveyPage", index);
      this.QuestionList.splice(
        index + 1,
        0,
        JSON.parse(JSON.stringify(this.QuestionList[index]))
      );
    },
    addSurveyPage: function() {
      console.log("addSurveyPage");
      this.QuestionList.push({
        Title: "",
        Description: "",
        PageName: "Page " + (this.QuestionList.length + 1),
        QuestionList: []
      });
    },
    delSurveyPage: function(index) {
      this.QuestionList.splice(index, 1);
    },
    formAction: async function() {
      var object = await this.getFormData(this.SurveyInfoInput);
      console.log("mainFormObject", object);
      console.log("QuestionList", this.QuestionList);
      object["Question"] = this.QuestionList;
      console.log("object", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createSurvetTemplate", {
          router: this.$router,
          surveyTemplate: object
        });
        console.log(ret);
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Survey Template List",
            params: { message: "New Survey Template added" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("createSurvetTemplate error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getFormData: async function(form) {
      var FormData = {};
      for (const [key, value] of Object.entries(form)) {
        console.log("value", value);
        if (value.required) {
          if (value.value) {
            if (value.type == "number") {
              FormData[value.key] = parseInt(value.value);
            } else {
              FormData[value.key] = value.value;
            }
          } else {
            this.formMessage = value.name + " is required";
            this.showFormMessage = true;
            return;
          }
        } else {
          if (value.type == "number") {
            FormData[value.key] = parseInt(value.value);
          } else {
            FormData[value.key] = value.value;
          }
        }
      }
      return FormData;
    },

    checkEdited: async function(check) {
      this.confirmBack = check;
    }
  },

  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Survey Template",
      FormTitle: "Survey Template Info",
      formActionButton: "Save",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      QuestionList: [],
      SurveyInfoInput: {
        TemplateNameInput: {
          type: "text",
          key: "Name",
          name: "範本名稱",
          required: true,
          style: "width:100%",
          value: null
        },
        DescriptionInput: {
          type: "text",
          key: "Description",
          name: "簡述",
          required: false,
          style: "width:100%",
          value: null
        },
        TemplateTypeInput: {
          type: "select",
          key: "TemplateType",
          name: "範本類型",
          required: false,
          style: "width:100%",
          value: null,
          option: [
            {
              key: "Event",
              name: "活動"
            },
            {
              key: "Product",
              name: "產品"
            },
            {
              key: "EventProduct",
              name: "活動產品"
            }
          ]
        },
        HideProductNameInput: {
          type: "checkbox",
          key: "HideProductName",
          name: "隱藏產品名稱",
          required: false,
          style: "width:100%",
          value: false
        },
        CanReSubmitInput: {
          type: "checkbox",
          key: "CanReSubmit",
          name: "可重複填寫",
          required: false,
          style: "width:100%",
          value: false
        }
      }
    };
  }
};
</script>
