<template>
  <div style="max-height:700px">
    <div class="row" style="width:100%">
      <div style="width:50%">
        <h4 class="tableTitle">{{ tableHeader }}</h4>
      </div>
      <div class="action">
        <u v-if="tableAction">{{ tableAction }}</u>

        <u
          v-if="!tableAction && !ShowMore"
          :class="{ actionAllow: actionAllow() }"
          @click="showMore"
          >更多</u
        >
        <u
          v-if="!tableAction && ShowMore"
          :class="{ actionAllow: actionAllow() }"
          @click="hideItem"
          >隱藏</u
        >
      </div>
    </div>

    <div v-if="ItemList.length > 0">
      <table style="width:100%;text-align: center;">
        <tr v-for="Item in ItemList" :key="Item.ID">
          <td
            v-for="(value, key) in Item"
            :key="key"
            @click="onclickItem(Item.ID)"
            :class="{ tdleft: !ItemCenter }"
          >
            {{ value }}
          </td>
        </tr>
      </table>
    </div>

    <div v-else style="text-align:center;font-weight: 400;">
      沒有 {{ tableHeader }}
    </div>
    <div class="row" v-if="ShowMore">
      <div class="column" style="width:45%;text-align:right">
        <md-button
          v-if="pageNumber > 1"
          class="backbutton"
          style="background-color:white !important;"
          @click="goPage('prev')"
        >
          <md-icon
            class="backIcon"
            style="color:black !important;margin-left: -10px;"
            >arrow_back_ios</md-icon
          >
        </md-button>
      </div>
      <div class="column" style="width:10%;text-align:center">
        <div style="padding-top:25px;font-size:18px">{{ pageNumber }}</div>
      </div>
      <div class="column" style="width:45%;text-align:left">
        <md-button
          v-if="showNextButton"
          class="backbutton"
          style="background-color:white !important;"
          @click="goPage('next')"
        >
          <md-icon
            class="backIcon"
            style="color:black !important;margin-left: -10px;"
            >arrow_forward_ios</md-icon
          >
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goPage: function(action) {
      console.log("goPage", action);
      var object = {
        table: this.tableHeader,
        action: action
      };
      this.$emit("goPage", object);
    },
    showMore: function() {
      this.$emit("showMore", this.tableHeader);
    },
    hideItem: function() {
      this.$emit("hideItem", this.tableHeader);
    },
    onclickItem: function(ID) {
      console.log(ID);
      //this.$emit("onclickItem", ID);
    },
    actionAllow: function() {
      if (this.ItemList.length == 0) {
        return true;
      }
      return false;
    }
  },
  name: "information-table-component",
  props: {
    tableHeader: {
      type: String,
      default() {
        return "";
      }
    },
    tableAction: {
      type: String,
      default() {
        return "";
      }
    },
    ItemCenter: {
      default() {
        return true;
      }
    },
    ItemList: {
      type: Array,
      default() {
        return [];
      }
    },
    ShowMore: {
      default() {
        return false;
      }
    },
    showNextButton: {
      default() {
        return true;
      }
    },
    pageNumber: {
      type: Number,
      default() {
        return 1;
      }
    }
  },
  data() {
    return {
      showSortPopup: false
    };
  },
  created() {},
  computed: {}
};
</script>

<style>
.actionAllow {
  color: gray;
}
.action {
  text-align: right;
  width: 50%;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
}
.tableTitle {
  margin-top: 10px;
  font-weight: 400;
  font-size: 20px;
}
.backIcon {
  padding: unset;
  margin: unset;
  color: black !important;
}

/* table{
  border-collapse: collapse;
}
.tableFixHead          { overflow: auto; height: 550px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; background: white;} */
/* 
 th {
    border:none;
    height: 50px;
    max-width: 50px;
} */
td {
  border-top: 0px;
  border-bottom: 1px solid gray;
  height: 50px;
  max-width: 50px;
}
.tdleft {
  text-align: left;
}

table {
  page-break-inside: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
</style>
