<template>
  <md-card style="margin:10px 0;">
    <md-card-content>
      <BlogItem :item="previewItem"> </BlogItem>
    </md-card-content>
  </md-card>
</template>

<script>
import BlogItem from "../Blog/BlogItem.vue";
export default {
  name: "inspiration-preview",
  components: { BlogItem },
  props: {
    previewItem: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
