<template>
  <codemirror
    :options="{
      mode: 'htmlmixed',
      lineNumbers: true,
      lineWrapping: true,
      extraKeys: { 'Ctrl-Space': 'autocomplete' }
    }"
    :value="code"
    @change="updateCode"
  ></codemirror>
</template>

<script>
import { codemirror } from "vue-codemirror-lite";
import "vue-codemirror-lite/node_modules/codemirror/lib/codemirror.css";
require("vue-codemirror-lite/node_modules/codemirror/mode/htmlmixed/htmlmixed");
require("vue-codemirror-lite/node_modules/codemirror/addon/hint/show-hint.js");
require("vue-codemirror-lite/node_modules/codemirror/addon/hint/show-hint.css");
require("vue-codemirror-lite/node_modules/codemirror/addon/hint/html-hint.js");
export default {
  components: {
    codemirror
  },
  methods: {
    updateCode(value) {
      this.$emit("updateCode", value);
    }
  },
  name: "code-editor-component",
  props: {
    code: {
      type: String,
      default() {
        return "";
      }
    }
  },
  data() {
    return {};
  },
  mounted() {
    const state = EditorState.create({
      extensions: [
        basicSetup,
        javascript(),
        html(),
        keymap.of([defaultTabBinding]),
        EditorView.updateListener.of(update => {
          // handle editor content changes here
        })
      ]
    });
    const view = new EditorView({
      state,
      parent: this.$refs.editor
    });
  },
  computed: {}
};
</script>
<style>
.CodeMirror {
  height: 570px;
}
</style>
