<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createNewItem"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="AddNewButton"
        />
        <md-card>
          <md-card-content>
            <TableComponent
              v-on:onclickItem="onclickItem"
              :TableList="TableItemList"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { TableComponent, FormHeader, Spinner } from "@/components";

export default {
  components: {
    TableComponent,
    FormHeader,
    Spinner
  },
  data() {
    return {
      language: "zh",
      message: "",
      showDeletePopup: false,
      showUpdateStatusPopup: false,
      newProductStatus: {
        Name: "",
        Key: ""
      },
      showSortPopup: false,
      isLoading: false,
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: false,
      displaySize: 10,
      HeaderTitle: "產品類型列表",
      ProductTypeList: [],
      TableItemList: [],
      ItemList: [],
      AddNewButton: "新增產品類型",
      columns: ["產品類型"],
      selectAll: false,
      selectable: false,
      haveSearchFunction: false,
      confirmBack: false
    };
  },
  async created() {
    this.isLoading = true;
    await this.listProductType();
    this.isLoading = false;
  },
  methods: {
    onclickItem: function(ID) {
      this.$router.push({
        name: "Update Product Type",
        params: { ProductTypeID: ID }
      });
    },
    createNewItem: function() {
      this.$router.push({ name: "Create Product Type" });
    },
    listProductType: async function() {
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("listProductType", {
          router: this.$router,
          data: null
        });
        this.isLoading = false;
        this.ProductType = ret.data.ProductType;
        this.setDisplayList(this.ProductType);
        console.log("ProductType", this.ProductType);
      } catch (err) {
        this.isLoading = false;
        console.log("listProductType error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setDisplayList(list) {
      console.log("setDisplayList", list);
      this.TableItemList = [];
      for (var i in list) {
        var object = {
          ID: list[i].ProductTypeID,
          產品類型: list[i].Name[this.language]
        };
        this.TableItemList.push(object);
      }
      console.log("setDisplayList after", this.TableItemList);
    }
  }
};
</script>
