<template>
  <div>
    <div class="collapsible">
      <input type="checkbox" style="display:none" :id="columnItem.Key" />
      <div class="row unsetMargin" @click="expandItem(columnItem.Key)">
        <div class="explorIcon">
          <md-icon :id="columnItem.Key + icon" style="color: white;"
            >expand_less</md-icon
          >
        </div>
        <div class="col explorTitle">
          <label>{{ columnItem.Key }}</label>
        </div>
        <div class="row" style="margin: auto;">
          <div class="col" @click="diplicateColumn()">
            <md-icon class="iconButton">file_copy</md-icon>
          </div>
          <div class="col" @click="delColumn()">
            <md-icon class="iconButton" style="color:#cc2727">
              delete
            </md-icon>
          </div>
        </div>
      </div>
      <div class="collapsible-item" :id="columnItem.Key + content">
        <md-card>
          <md-card-content>
            <SelectInput
              v-on:selected="DataTypeSelected"
              :Item="DataTypeInput"
            />
            <LanguageObjectInput
              v-on:checkEdited="DisplayNameUpdated"
              :Item="DisplayNameInput"
            />
            <TextInput v-on:checkEdited="KeyUpdated" :Item="KeyInput" />
            <TextInput v-on:checkEdited="NameUpdated" :Item="NameInput" />
            <CheckBoxInput
              v-on:checkBoxChange="RequiredChecked"
              :Item="RequiredInput"
            />
            <CheckBoxInput
              v-on:checkBoxChange="shortDescriptionKeyChecked"
              :Item="shortDescriptionKeyInput"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SelectInput,
  LanguageObjectInput,
  TextInput,
  CheckBoxInput
} from "@/components";
export default {
  components: {
    SelectInput,
    LanguageObjectInput,
    TextInput,
    CheckBoxInput
  },
  props: {
    columnItem: {
      default() {
        return {};
      }
    },
    languageList: {
      default() {
        return [];
      }
    }
  },
  created() {
    this.DataTypeInput.value = this.columnItem.DataType;
    this.KeyInput.value = this.columnItem.Key;
    this.NameInput.value = this.columnItem.Name;
    this.RequiredInput.value = this.columnItem.required;
    this.shortDescriptionKeyInput.value = this.columnItem.shortDescriptionKey;
  },
  mounted() {
    for (var i in this.languageList) {
      var language = this.languageList[i].Language;
      this.DisplayNameInput.value[language] = this.columnItem.displayName[language];
      this.DisplayNameInput.model +=
        this.languageList[i].Language +
        ":" +
        this.columnItem.displayName[language] +
        " / ";
    }
    this.DisplayNameInput.model = this.DisplayNameInput.model.slice(0, -2);
    this.expandItem(this.columnItem.Key);
  },
  methods: {
    delColumn() {
      this.$emit("delColumn", this.columnItem.Key);
    },
    diplicateColumn() {
      this.$emit("diplicateColumn", this.columnItem.Key);
    },
    DisplayNameUpdated: function() {
      this.columnItem.displayName = this.DisplayNameInput.value;
      console.log("columnItem", this.columnItem);
    },
    NameUpdated: function() {
      this.columnItem.Name = this.NameInput.value;
      console.log("columnItem", this.columnItem);
    },
    KeyUpdated: function() {
      this.columnItem.Key = this.KeyInput.value;
      console.log("columnItem", this.columnItem);
    },
    DataTypeSelected: function(value) {
      this.columnItem.DataType = this.DataTypeInput.value;
      console.log("columnItem", this.columnItem);
    },
    RequiredChecked: function() {
      this.columnItem.required = this.RequiredInput.value;
      console.log("columnItem", this.columnItem);
    },
    shortDescriptionKeyChecked: function() {
      this.columnItem.shortDescriptionKey = this.shortDescriptionKeyInput.value;
      console.log("columnItem", this.columnItem);
    },
    expandItem: function(element) {
      let iconClass = document.getElementById(element + this.icon);
      let contentClass = document.getElementById(element + this.content);
      if (document.getElementById(element).checked) {
        iconClass.classList.remove("iconup");
        iconClass.classList.add("icondown");
      } else {
        iconClass.classList.remove("icondown");
        iconClass.classList.add("iconup");
      }
      document.getElementById(element).checked = !document.getElementById(
        element
      ).checked;
      if (document.getElementById(element).checked) {
        contentClass.style.minHeight = contentClass.scrollHeight + "px";
        contentClass.style.maxHeight = contentClass.scrollHeight + "px";
      } else {
        contentClass.style.maxHeight = "0px";
        contentClass.style.minHeight = "0px";
      }
    }
  },
  data() {
    return {
      icon: "icon",
      content: "content",
      DataTypeInput: {
        name: "輸入類型",
        key: "DataType",
        required: true,
        value: "",
        option: [
          { key: "object", name: "多語言" },
          { key: "number", name: "數字" }
        ]
      },
      DisplayNameInput: {
        name: "顯示名稱",
        key: "displayName",
        required: true,
        model: "",
        value: {}
      },
      KeyInput: {
        name: "Key (不能重複, 不能有空格)",
        key: "Key"
      },
      NameInput: {
        name: "名稱 (用戶管理介面顯示)",
        key: "Name"
      },
      RequiredInput: {
        name: "必填",
        key: "required",
        value: false
      },
      shortDescriptionKeyInput: {
        name: "設定為簡述",
        key: "shortDescriptionKey",
        value: false
      }
    };
  }
};
</script>