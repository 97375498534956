<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <p class="error">{{ error }}</p>
        <p class="decode-result">
          Last code: <b>{{ code }}</b>
        </p>
        <p class="decode-result">
          Last loading: <b>{{ loading }}</b>
        </p>
        <p class="decode-result" v-if="code">
          <button @click="showPopup = true">Open Last Record</button>
        </p>
        <qrcode-stream @decode="onDecode"></qrcode-stream>
      </div>
    </div>
    <div>
      <md-dialog-confirm
        class="partiDialog"
        :md-active.sync="showConfirmDialog"
        md-title="Participant Information"
        :md-content="displayInfo"
        md-confirm-text="Yes"
        md-cancel-text="No"
        @md-cancel="onCancel"
        @md-confirm="onConfirm"
      />

      <md-dialog-alert
        class="partiDialog"
        :md-active.sync="showAlertDialog"
        :md-title="`Participant Information: ${alert}`"
        :md-content="displayInfo"
      />
    </div>
    <div class="testing"></div>
    <UserEventRecordPopup :showPopup.sync="showPopup" :code="code" />
    <md-snackbar
      :md-duration="4000"
      :md-active.sync="showSnackbar"
      md-persistent
    >
      <span :style="`color:${snackBarTextColor};`">{{ snackBarContent }}</span>
      <md-button class="md-primary" @click="showSnackbar = false"
        >Close</md-button
      >
    </md-snackbar>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { UserEventRecordPopup } from "@/components";

export default {
  data() {
    return {
      result: "",
      code: "",
      error: "",
      showPopup: false,
      showConfirmDialog: false,
      showAlertDialog: false,
      showSnackbar: false,
      snackBarContent: "",
      event: {},
      guest_list: [],
      number_of_seat: 0,
      partiNum: 0,
      displayInfo: "",
      alert: "",
      order: {},
      status: "",
      canAttend: true,
      loading: false,
      snackBarTextColor: "white"
    };
  },
  components: {
    QrcodeStream,
    UserEventRecordPopup
  },
  methods: {
    async onDecode(code) {
      console.log(code);
      this.code = code;
      this.showPopup = true;
      //this.loading=true
      /*this.$store.dispatch('scanQRCode',{router:this.$router, data:{code:code}})
      .then((ret)=> {
          console.log(ret)
          this.loading = false;
          this.guest_list=ret.data.data.Guest;
          this.partiNum=ret.data.data.ParticipatedNumber||0;
          this.number_of_seat=ret.data.data.NumberOfSeat;
          this.event=ret.data.data.EventDetail;
          this.status=ret.data.data.Status;
          this.canAttend=ret.data.canAttend;

          this.displayInfo="<table><tr><th>Guest List</th></tr></th><tr><th>Name</th><th>Phone</th></tr>";

          this.guest_list?.forEach((guest)=>{
            this.displayInfo=this.displayInfo+"<tr><td>"+guest.Name+"</td><td>"+guest.Contact+"</td></tr>"
          })

          this.displayInfo=this.displayInfo+"<tr><td>Number of Seats: </td><td>"+this.partiNum+"/"+this.number_of_seat+"</td></tr>"

          this.displayInfo=this.displayInfo+"<tr><th>Event Details</th></tr>"

          this.displayInfo=this.displayInfo+"<tr><td>Title: </td><td>"+this.event.Title+"</td></tr>";

          this.displayInfo=this.displayInfo+"<tr><td>Datetime: </td><td>"+this.event.EventDate+" "+this.event.EventStartTime+" - "+this.event.EventEndTime+"</td></tr>";

          this.displayInfo=this.displayInfo+"<tr><td>Location: </td><td>"+this.event.Location.Name+"<br/>"+this.event.Location.Address+", "+this.event.Location.District+"</td></tr>"

          if(this.status=="Ended"){
            this.alert="Event already end"
            this.showAlertDialog=true
          }
          else if(this.number_of_seat <= this.partiNum){
            this.alert="Already take attendance"
            this.showAlertDialog=true
          }
          else if(!this.canAttend){
            this.alert="Blocked by backend"
            this.showAlertDialog=true
          }
          else{
            this.showConfirmDialog=true;
          }
          
          //console.log(ret);
        })
      .catch(err=>{
        console.log(err.message)
        if(err?.response?.status==403){
          alert(err.response.data.message);
        }
        this.loading = false;
      })*/
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    onConfirm() {
      this.loading = true;
      this.$store
        .dispatch("takeAttendance", {
          router: this.$router,
          data: { code: this.code, addNumber: this.number_of_seat }
        })
        .then(ret => {
          this.loading = false;
          this.snackBarTextColor = "white";
          this.snackBarContent = "take the attendance successfully.";
          this.showSnackbar = true;
          console.log(ret);
        })
        .catch(err => {
          console.log(err.message);
          if (err.response.status == 403) {
            alert(err.response.data.message);
          }
          this.loading = false;
        });
    },
    onCancel() {
      //this.value = 'Disagreed'
    }
  },
  watch: {
    showPopup: function(newVal, oldVal) {
      console.log(newVal);
    }
  }
  //created(){this.onDecode ("c7bc7f92-b6c3-47c0-b6b9-8ea88d571a2c$:$G1630383098066::fa9f7029-d28b-46e7-a01b-8d56e0839ae5")},
};
</script>
<style lang="scss">
.partiDialog .md-dialog-container {
  width: 80%;
}
</style>
