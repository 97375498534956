<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          :HeaderTitle="orderID"
          :formActionButton="formActionButton"
          v-on:formAction="formAction"
        />

        <div class="row" style="width: 100%;">
          <div class="column" style="width: 60%;">
            <md-card style="margin:10px 0">
              <md-card-content>
                <div class="row">
                  <div class="column" style="width: 50%;">
                    <h4 style="font-weight: 600;">訂單內容</h4>
                    <!-- {{ orderDetail }} -->
                    <label
                      >總計: {{ orderDetail.Currency }}
                      {{ orderDetail.SubTotal }}</label
                    >
                    <br />
                    <label
                      >運費: {{ orderDetail.Currency }}
                      {{ orderDetail.ShippingFee }}</label
                    >
                    <br />
                    <label
                      >稅項: {{ orderDetail.Currency }} {{ orderDetail.Taxes }}
                    </label>
                    <br />
                    <br />
                    <div v-if="orderDetail.Discount">
                      <div v-if="orderDetail.Discount.extraDiscount">
                        <label>
                          折扣:
                          {{ orderDetail.Discount.extraDiscount.Description }} :
                          {{ orderDetail.Currency }}
                          {{ orderDetail.Discount.extraDiscount.Discount }}
                        </label>
                      </div>
                      <div v-if="orderDetail.Discount.CampaignList">
                        <label> 折扣: </label>
                        <div
                          v-for="(compaign, index) in orderDetail.Discount
                            .CampaignList"
                          :key="index"
                        >
                          <label>
                            {{ compaign.Description.zh }} :
                            {{ orderDetail.Currency }} {{ compaign.Discount }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <br />
                    <label
                      >總價: {{ orderDetail.Currency
                      }}{{ orderDetail.Total }}</label
                    >
                    <br />
                    <label>匯率: {{ orderDetail.ExchangeRate }}</label>
                  </div>
                  <div class="column" style="width: 50%;text-align: right;">
                    <h4 style="font-weight: 600;">{{ orderDetail.Status }}</h4>
                    <div v-if="DeliveryOrder.length > 0">
                      <label>Tracking No. :</label>
                      <table>
                        <tr v-for="id in DeliveryOrder" :key="id">
                          <td
                            @click="openTrackOrderPopup(id)"
                            style="cursor: pointer;"
                          >
                            {{ id }}
                          </td>
                          <td style="width: 20px;">
                            <div class="download" @click="downloadPDF(id)">
                              <md-icon>save_alt</md-icon>
                            </div>
                          </td>
                        </tr>
                      </table>

                      <button @click="cancelOrder()" class="newProductButton">
                        取消運單
                      </button>
                    </div>

                    <!-- <div v-for="id in orderDetail.DeliveryOrder" :key="id">
                                  <label>{{ id }}</label> <div class="download" @click="downloadPDF(id)"><md-icon>save_alt</md-icon></div><br>
                              </div> -->
                  </div>
                </div>
                <CargoList
                  :cargos="Cargo"
                  :canEdit="canEdit"
                  v-on:updateCargo="updateCargo"
                />
              </md-card-content>
            </md-card>
          </div>
          <div class="column" style="width: 35%;padding-left: 20px;">
            <md-card style="margin:10px 0">
              <md-card-content>
                <div class="row">
                  <h4 style="font-weight: 600;">寄件人資料&取貨地址</h4>
                  <u v-if="canEdit" class="editButton" @click="setEditSender()"
                    >Edit</u
                  >
                </div>
                <label>聯絡人: {{ sender.contact }}</label> <br />
                <label>公司: {{ sender.company }}</label> <br />
                <label>電話: {{ sender.mobile }}</label> <br />
                <label>國家: {{ sender.country }}</label> <br />
                <label>城市: {{ sender.city }}</label> <br />
                <label>縣/區: {{ sender.county }}</label> <br />
                <label>省: {{ sender.province }}</label> <br />
                <label>地址: {{ sender.address }}</label> <br />
              </md-card-content>
            </md-card>
            <md-card style="margin:10px 0">
              <md-card-content>
                <div class="row">
                  <h4 style="font-weight: 600;">收件資料</h4>
                  <u
                    v-if="canEdit"
                    class="editButton"
                    @click="setEditReceiver()"
                    >Edit</u
                  >
                </div>
                <label>聯絡人: {{ recipient.contact }}</label> <br />
                <label>電話: {{ recipient.mobile }}</label> <br />
                <label>國家: {{ recipient.country }}</label> <br />
                <label>城市: {{ recipient.city }}</label> <br />
                <label>縣/區: {{ recipient.county }}</label> <br />
                <label>省: {{ recipient.province }}</label> <br />
                <label>地址: {{ recipient.address }}</label> <br />
              </md-card-content>
            </md-card>
            <md-card style="margin:10px 0">
              <md-card-content>
                <h4 style="font-weight: 600;">用戶資料</h4>

                <label>姓氐: {{ user.FamilyName }}</label> <br />
                <label>名子: {{ user.GivenName }}</label> <br />
                <label
                  >電話: +{{ user.PhoneCountryCode }} {{ user.Phone }}</label
                >
                <br />
                <label>電郵: {{ user.Email }}</label> <br />
                <label>性別: {{ user.Gender }}</label> <br />
                <label>出生日期: {{ user.Birthday }}</label> <br />
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showEditReceiver">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div class="modal-header">
                <slot name="header">修改收件資料</slot>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col" style="width: 50%;">
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.contact"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.country"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.city"
                    />
                  </div>
                  <div class="col" style="width: 50%;">
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.mobile"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.province"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.county"
                    />
                  </div>
                </div>
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="senderReceiverInput.address"
                />
                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <button
                      class="cancelButton"
                      @click="showEditReceiver = false"
                    >
                      取消
                    </button>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="actionButton" @click="EditReceiver()">
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="modal" v-if="showEditSender">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div class="modal-header">
                <slot name="header">修改寄件人資料</slot>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col" style="width: 50%;">
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.contact"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.country"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.city"
                    />
                  </div>
                  <div class="col" style="width: 50%;">
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.mobile"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.province"
                    />
                    <TextInput
                      v-on:checkEdited="checkEdited"
                      :Item="senderReceiverInput.county"
                    />
                  </div>
                </div>
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="senderReceiverInput.address"
                />
                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <button
                      class="cancelButton"
                      @click="showEditSender = false"
                    >
                      取消
                    </button>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="actionButton" @click="EditSender()">
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="modal" v-if="showRequestPopup">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popupclose" @click="showRequestPopup = false">
              <md-icon>close</md-icon>
            </div>
            <div class="popup">
              <div class="modal-header">
                <slot name="header">新增運單</slot>
              </div>
              <div class="modal-body">
                <div class="row">
                  <SelectInput
                    v-on:checkEdited="checkEdited"
                    :Item="Request.expressType"
                    style="padding-top: 25px;"
                    @selected="selectExpressType"
                  />
                  <TextInput
                    v-on:checkEdited="checkEdited"
                    :Item="Request.pickupTime"
                    v-if="Request.expressType.value!=-1"
                  />
                </div>
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="Request.remark"
                  v-if="Request.expressType.value!=-1"
                />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="Request.waybillno"
                  v-if="Request.expressType.value==-1"
                />
                <div v-if="showfee">預計費用: {{ fee }}</div>
                <div v-if="showfee && feeList.length>0">細項:
                  <div
                      style="padding-left:8px;"
                      v-for="(row, index) in feeList"
                      :key="index"
                  >
                    {{ row.serviceName }}: {{ row.estimateFee }}
                  </div>
                </div>
                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <button
                      class="cancelButton"
                      @click="showRequestPopup = false"
                    >
                      取消
                    </button>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <!-- <button class="actionButton"  @click="createSFOrder()">確認</button> -->
                    <!-- <button class="actionButton" @click="calculateSF()">計算</button> -->
                    <button
                      class="actionButton"
                      v-if="!showfee"
                      @click="calculateFee()"
                    >
                      計算
                    </button>
                    <button
                      class="actionButton"
                      v-else
                      @click="createOrder()"
                    >
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="modal" v-if="showOrderTracking">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div class="modal-header">
                <slot name="header"
                  >運單追蹤 {{ displayTrackOrder.mailNo }}</slot
                >
              </div>
              <div class="modal-body">
                <div style="height: 400px;overflow-y: auto;">
                  <table style="text-align: center;">
                    <tr>
                      <th>地址</th>
                      <th>備註</th>
                      <th>時間</th>
                    </tr>
                    <tr
                      v-for="(record, index) in displayTrackOrder.routes"
                      :key="index"
                    >
                      <td>{{ record.acceptAddress }}</td>
                      <td>{{ record.remark }}</td>
                      <td>{{ record.acceptTime }}</td>
                    </tr>
                  </table>
                </div>

                <button class="actionButton" @click="showOrderTracking = false">
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import {
  FormHeader,
  TextInput,
  SelectInput,
  Spinner,
  CargoList
} from "@/components";
export default {
  components: {
    FormHeader,
    TextInput,
    SelectInput,
    Spinner,
    CargoList
  },
  created() {
    console.log(this.$route.params.orderID);
    this.getOrderDetail();
    this.setPickupTime();
  },
  methods: {
    setPickupTime: function() {
      //pick up time is current time + 1 hour
      //if current time is 2021-08-01 12:01, then pick up time is 2021-08-01 13:30
      //if current time is 2021-08-01 12:31, then pick up time is 2021-08-01 14:00
      var now = new Date();
      var hour = now.getHours();
      hour += 1;
      var minute = now.getMinutes();
      if (minute > 30) {
        hour += 1;
        minute = "00";
      } else {
        minute = 30;
      }
      var newTime = now.toISOString().split("T")[0] + "T" + hour + ":" + minute;
      console.log(newTime);
      this.Request.pickupTime.value = newTime;
    },
    updateCargo: function(newCargo) {
      this.Cargo = newCargo;
    },
    checkEdited: function() {
      console.log("checkEdited");
    },
    EditReceiver: function() {
      console.log("EditReceiver");
      for (const [key, value] of Object.entries(this.senderReceiverInput)) {
        this.recipient[key] = value.value;
        value.value = "";
      }
      this.showEditReceiver = false;
    },
    setEditReceiver: function() {
      for (const [key, value] of Object.entries(this.senderReceiverInput)) {
        value.value = this.recipient[key];
      }
      this.showEditReceiver = true;
    },
    EditSender: function() {
      for (const [key, value] of Object.entries(this.senderReceiverInput)) {
        this.sender[key] = value.value;
        value.value = "";
      }
      this.showEditSender = false;
    },
    setEditSender: function() {
      for (const [key, value] of Object.entries(this.senderReceiverInput)) {
        value.value = this.sender[key];
      }
      this.showEditSender = true;
    },
    cancelOrder: async function(){
      if(this.orderDetail.Global){
        console.log("global");
        this.cancelSFGlobalOrder();
      }
      else{
        console.log("hk/china");
        this.cancelSFOrder();
      }
    },
    cancelSFOrder: async function() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("cancelSFOrder", {
          router: this.$router,
          data: { DeliveryOrderID: this.DeliveryOrderID }
        });
        console.log(response.data);
        this.isLoading = false;
        this.DeliveryOrder = [];
        this.getOrderDetail();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    cancelSFGlobalOrder: async function() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("cancelSFGlobalOrder", {
          router: this.$router,
          data: { DeliveryOrderID: this.DeliveryOrderID }
        });
        console.log(response.data);
        this.isLoading = false;
        this.DeliveryOrder = [];
        this.getOrderDetail();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    getDeliveryOrder: async function() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("getDeliveryOrder", {
          router: this.$router,
          data: { DeliveryOrderID: this.DeliveryOrderID }
        });
        console.log(response.data);
        this.Cargo = response.data.DeliveryOrder.cargoList;
        this.DeliveryOrder = response.data.DeliveryOrder.waybillNoList;
        this.recipient.contact = response.data.DeliveryOrder.recipient.contact;
        this.recipient.mobile = response.data.DeliveryOrder.recipient.mobile;
        this.recipient.country = response.data.DeliveryOrder.recipient.country;
        this.recipient.city = response.data.DeliveryOrder.recipient.city;
        this.recipient.county = response.data.DeliveryOrder.recipient.county;
        this.recipient.address = response.data.DeliveryOrder.recipient.address;
        this.sender.contact = response.data.DeliveryOrder.sender.contact;
        this.sender.mobile = response.data.DeliveryOrder.sender.mobile;
        this.sender.country = response.data.DeliveryOrder.sender.country;
        this.sender.city = response.data.DeliveryOrder.sender.city;
        this.sender.county = response.data.DeliveryOrder.sender.county;
        this.sender.address = response.data.DeliveryOrder.sender.address;
        this.DeliveryPDF=response.data.DeliveryOrder.DeliveryPDF;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    getOrderDetail: async function() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("getOrderDetail", {
          router: this.$router,
          data: { OrderID: this.orderID }
        });
        console.log(response.data);
        this.orderDetail = response.data.order;
        if (!this.orderDetail.Currency) {
          this.orderDetail.Currency = "HKD";
        }
        this.user = response.data.user;
        this.setCargo(this.orderDetail.Product);
        console.log(this.Cargo)
        if (this.orderDetail.Status === "IN PROCESS") {
          this.canEdit = true;
          this.setRecipient(this.orderDetail.DeliveryAddress);

          this.formActionButton = "Create Delivery";
        } else if (this.orderDetail.Status === "Shipped") {
          this.DeliveryOrderID = this.orderDetail.DeliveryOrderID;
          this.getDeliveryOrder();
          this.trackOrder();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    setRecipient: function(DeliveryAddress) {
      this.recipient.contact =
      DeliveryAddress.name||DeliveryAddress.family_name + " " + DeliveryAddress.given_name;
      this.recipient.mobile = DeliveryAddress.phone;
      this.recipient.country = DeliveryAddress.country;
      this.recipient.city = DeliveryAddress.city;
      this.recipient.county = DeliveryAddress.district;
      this.recipient.address = DeliveryAddress.addressOne;
      if (DeliveryAddress.addressTwo) {
        this.recipient.address += ", " + DeliveryAddress.addressTwo;
      }
    },
    setCargo: function(Product) {
      this.Cargo = [];
      var Item = [];
      var now = new Date().getTime();
      for (var i in Product) {
        const product = Product[i];
        for (var v in product.VarianceList) {
          const variance = product.VarianceList[v];
          var name = product.ProductType.Name[this.language];
          if (product.ProductType.Name.en == "Tea") {
            if (this.language == "cn") {
              name = "茶叶";
            } else if (this.language == "zh") {
              name = "茶葉";
            }
          }
          var imgID=variance?.Image[0]?.path||product?.Image[0]?.path
          Item.push({
            itemID: variance.VarianceID,
            image: this.s3path + imgID,
            name: name,
            productName:
              product.Name[this.language] +
              "(" +
              variance.Name[this.language] +
              ")",
            count: variance.SelectedQuantity,
            amount: variance.Price,
            weight: variance.Weight,
            currency: this.orderDetail.Currency,
            unit: "盒",
            sourceArea: product.Country.Alpha2
          });
        }
      }
      this.Cargo.push({
        cargoID: "c" + now,
        name: "Cargo 1",
        Item: Item,
        totalWeight: Item.reduce((a, b) => a + b.weight, 0),
        minHeight: 240 * Item.length
      });
    },
    formAction: function() {
      this.showRequestPopup = true;
    },
    calculateFee: async function(){
      if(this.Request.expressType.value=="999"){
        return this.calculateSFGlobal()
      }
      else{
        return this.calculateSF();
      }
    },
    calculateSF: async function() {
      var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
      var object = {
        totalWeight: totalWeight,
        sender: this.sender,
        recipient: this.recipient,
        expressType: parseInt(this.Request.expressType.value)
      };
      console.log(object);
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("calculateSF", {
          router: this.$router,
          data: object
        });
        console.log(response.data);
        if (response.data.result) {
          this.fee = response.data.apiResultData.msgData.deliverTmDto[0].fee;
          this.sfFee[this.Request.expressType.value]=this.fee;
          this.showfee = true;
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    calculateSFGlobal: async function() {
      this.isLoading=true;
      var object = {
        Keyword: this.sender.country
      };
      const senderSearch = await this.$store.dispatch("searchCountry", {
        router: this.$router,
        data: object
      });
      var senderA2;
      console.log(senderSearch.data.data.hits);
      if(senderSearch.data.data.hits.hits.length<=0){
        alert("Sender country not found")
      }
      else{
        senderA2=senderSearch.data.data.hits.hits[0]._source.Alpha2
      }
      console.log(this.recipient)
      object = {
        Keyword: this.recipient.country
      };
      const receiverSearch = await this.$store.dispatch("searchCountry", {
        router: this.$router,
        data: object
      });
      var receiverA2;
      console.log(receiverSearch.data.data.hits.hits)
      if(receiverSearch.data.data.hits.hits.length<=0){
        alert("Sender country not found")
      }
      else{
        receiverA2=receiverSearch.data.data.hits.hits[0]._source.Alpha2
      }

      var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
      object={
        "receiverCountry":receiverA2,
        "receiverPostCode":"000000",
        "senderCountry":senderA2,
        "senderPostCode":"000000",
        "totalWeight":totalWeight,
        "currency":"HKD"
      }
      const ret = await this.$store.dispatch("estimateFeeSFGlobal", {
        router: this.$router,
        data: object
      });
      this.isLoading = false;
      if (ret.data.result) {
        const result=ret.data.apiResultData
        this.fee = result.data.totalFee;
        this.feeList=result.data.feeInfoList;
        this.feeList.push()
        console.log(this.feeList);
        this.sfFee[this.Request.expressType.value]=this.fee;
        this.showfee = true;
      }
      console.log(ret);
      return;
      var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
      var object = {
        totalWeight: totalWeight,
        sender: this.sender,
        recipient: this.recipient,
        expressType: parseInt(this.Request.expressType.value)
      };
      console.log(object);
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("calculateSFGlobal", {
          router: this.$router,
          data: object
        });
        console.log(response.data);
        if (response.data.result) {
          this.fee = response.data.apiResultData.msgData.deliverTmDto[0].fee;
          this.sfFee[this.Request.expressType.value]=this.fee;
          this.showfee = true;
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    createOrder: async function(){
      switch (this.Request.expressType.value) {
        case "1":
          this.createSFOrder()
          break;
        case "999":
          this.createSFGlobalOrder()
          break;
        case "-1":
          this.createManualSFOrder()
          break;
      }
    },
    createSFOrder: async function() {
      var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
      var object = {
        orderID: this.orderID,
        expressType: this.Request.expressType.value,
        date: this.Request.pickupTime.value.split("T")[0],
        time: this.Request.pickupTime.value.split("T")[1],
        totalWeight: totalWeight,
        remark: this.Request.remark.value,
        sender: this.sender,
        recipient: this.recipient,
        cargoList: this.Cargo
      };
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("createSFOrder", {
          router: this.$router,
          data: object
        });
        console.log(response.data);
        this.isLoading = false;
        await this.createOrderPDF();
        this.showRequestPopup = false;
        this.getOrderDetail();
        //this.$router.push({name: "Payment"});
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
      console.log(object);
    },
    createSFGlobalOrder: async function() {
      var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
      var object = {
        orderID: this.orderID,
        parcelQuantity: this.Cargo.length,
        parcelTotalWeight: totalWeight,
        remark: this.Request.remark.value,
        senderInfo: {
          ...this.sender,
          phoneAreaCode:this.sender.mobile.substring(1,3),
          phoneNo:this.sender.mobile.substring(3),
          postCode:"518055",
          country:"CN"
        },
        receiverInfo: {
          ...this.recipient,
          phoneNo:this.recipient.mobile,
          country:"HK"
        },
        parcelInfoList: this.Cargo,
        declaredCurrency: this.orderDetail.Currency,
        declaredValue:this.orderDetail.SubTotal,
        paymentInfo: {
            payMethod: "1", 
            payMonthCard: "0270000250", 
            taxPayMethod: "2", 
        },

      };
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("createSFGlobalOrder", {
          router: this.$router,
          data: object
        });
        console.log(response.data);
        this.isLoading = false;
        //await this.createOrderPDF();
        this.showRequestPopup = false;
        this.getOrderDetail();
        //this.$router.push({name: "Payment"});
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
      console.log(object);
    },
    createManualSFOrder: async function() {
      var totalWeight = this.Cargo.reduce((a, b) => a + b.totalWeight, 0);
      var object = {
        orderID: this.orderID,
        expressType: this.Request.expressType.value,
        date: this.Request.pickupTime.value.split("T")[0],
        time: this.Request.pickupTime.value.split("T")[1],
        totalWeight: totalWeight,
        remark: this.Request.remark.value,
        sender: this.sender,
        recipient: this.recipient,
        cargoList: this.Cargo,
        waybillNoInfoList: this.Request.waybillno.value.replace(" ","").split(",")
      };
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("createManualSFOrder", {
          router: this.$router,
          data: object
        });
        console.log(response.data);
        this.isLoading = false;
        //await this.createOrderPDF();
        this.showRequestPopup = false;
        this.getOrderDetail();
        //this.$router.push({name: "Payment"});
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
      console.log(object);
    },

    downloadPDF: async function(id) {
      console.log(id);
      console.log(this.DeliveryPDF.length);
      if (this.DeliveryPDF.length == 0) {
        await this.createOrderPDF();
      }
      console.log(this.DeliveryPDF);
      const pdf = this.DeliveryPDF.find(pdf => pdf.waybillNo == id);
      console.log(pdf.downloadLink);
      //open url in new tab
      window.open(pdf.downloadLink, "_blank");
    },
    openTrackOrderPopup: function(id) {
      this.displayTrackOrder = this.trackOrderList.find(
        order => order.mailNo == id
      );
      this.showOrderTracking = true;
    },
    trackOrder: async function() {
      console.log("trackOrder");
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("trackOrder", {
          router: this.$router,
          data: { DeliveryOrderID: this.DeliveryOrderID }
        });
        console.log(response.data);
        this.trackOrderList = response.data.trackingResult.routeResps;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    createOrderPDF: async function() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch("createOrderPDF", {
          router: this.$router,
          data: { DeliveryOrderID: this.DeliveryOrderID }
        });
        console.log(response.data);
        await this.savePDF(response.data.files);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    savePDF: async function(files) {
      console.log("savePDF");
      for (var i in files) {
        const file = files[i];
        var object = {
          DeliveryOrderID: this.DeliveryOrderID,
          orderID: this.orderID,
          waybillNo: file.waybillNo,
          url: file.url,
          token: file.token
        };
        try {
          const response = await this.$store.dispatch("downloadSFpdf", {
            router: this.$router,
            data: object
          });
          console.log(response.data);
          this.DeliveryPDF.push(response.data.pdf);
        } catch (error) {
          console.log(error);
        }
      }
    },
    selectExpressType:function(value){
      console.log(value);
      console.log(this.Request.expressType.value)
      if(this.Request.expressType.value==-1 || this.sfFee.hasOwnProperty(this.Request.expressType.value)){
        this.showfee=true;
        this.fee=this.sfFee[this.Request.expressType.value]
      }
      else{
        this.showfee=false;
      }
    }
  },
  data() {
    return {
      formActionButton: "",
      senderReceiverInput: {
        contact: {
          key: "contact",
          name: "聯絡人",
          required: true,
          value: "",
          type: "text"
        },
        company: {
          key: "company",
          name: "公司",
          required: false,
          value: "",
          type: "text"
        },
        mobile: {
          key: "mobile",
          name: "電話",
          required: true,
          value: "",
          type: "text"
        },
        country: {
          key: "country",
          name: "國家",
          required: true,
          value: "",
          type: "text"
        },
        city: {
          key: "city",
          name: "城市",
          required: true,
          value: "",
          type: "text"
        },
        county: {
          key: "county",
          name: "縣/區",
          required: true,
          value: "",
          type: "text"
        },
        province: {
          key: "province",
          name: "省",
          required: false,
          value: "",
          type: "text"
        },
        address: {
          key: "address",
          name: "地址",
          required: true,
          value: "",
          type: "text"
        }
      },
      showEditReceiver: false,
      showEditSender: false,
      showOrderTracking: false,
      icon: "icon",
      content: "content",
      s3path: process.env.VUE_APP_S3_URL,
      isLoading: false,
      orderDetail: {},
      language: "zh",
      user: {},
      DeliveryPDF: [],
      DeliveryOrderID: "",
      DeliveryOrder: [],
      trackOrderList: [],
      displayTrackOrder: {},
      canEdit: false,
      showfee: false,
      fee: 0,
      feeList:[],
      sender: {
        contactType: "1",
        contact: "宋先生",
        company: "一杯子（深圳）国际控股有限公司",
        mobile: "+8618666899288",
        country: "中國",
        city: "深圳市",
        county: "南山区",
        province: "广东省",
        address: "广东省深圳市南山区桃源街道留仙大道3333号塘朗城B座2605"
      },
      recipient: {
        contactType: "2",
        contact: "",
        mobile: "",
        country: "",
        city: "",
        county: "",
        province: "",
        address: ""
      },
      orderID: this.$route.params.orderID,
      showRequestPopup: false,
      sfFee:{},
      Request: {
        expressType: {
          key: "expressType",
          name: "快遞服務",
          required: true,
          value: "6",
          option: [
            {
              key: "1",
              name: "順豐特快(中國/香港)"
            },
            // {
            // "key": "2",
            // "name": "順豐特惠",
            // },
            // {
            // "key": "5",
            // "name": "順豐次晨",
            // },
            // {
            // "key": "6",
            // "name": "順豐即日",
            // },
            // {
            // "key": "29",
            // "name": "國際電商專遞-標準",
            // },
            // {
            // "key": "132",
            // "name": "順豐國際特惠(FBA)",
            // },
            // {
            // "key": "276",
            // "name": "酒類溫控上門",
            // },
            // {
            // "key": "24",
            // "name": "順豐国际特惠(包裹)",
            // },
            {
              "key": "999",
              "name":"順豐國際空運"
            },
            {
              "key":"-1",
              "name":"手動輸入追蹤號碼"
            }
          ]
        },
        pickupTime: {
          key: "pickupTime",
          name: "取件時間",
          required: true,
          value: null,
          type: "datetime-local"
        },
        remark: {
          key: "remark",
          name: "備註",
          required: false,
          value: "",
          type: "text"
        },
        waybillno: {
          key: "waybillno",
          name: "運單追縱號碼",
          required: true,
          value: "",
          type: "text"
        }
      },
      Cargo: []
    };
  }
};
</script>
<style scoped>
.editButton {
  margin: auto;
  margin-right: 0;
  cursor: pointer;
}
.download {
  cursor: pointer;
}
.popupclose i{
  margin: 10px 10px;
}
</style>
