<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="updateTasteCard"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <div class="row">
          <div style="width:45%;">
            <md-card>
              <md-card-content>
                <h3 class="formTitle">基本資料</h3>
                <AutoComplete
                  :item="AuthorInput"
                  v-on:checkEdited="checkEdited"
                  v-on:checkAutocomplete="searchUser"
                  v-on:setAutocompleteValue="setAuthor"
                  style="width: 50%"
                />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteCardInfo.TitleInput"
                />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteCardInfo.Description"
                />
                <TasteNotesInput
                  v-on:checkEdited="checkEdited"
                  :languageList="languageList"
                  :Item="TasteCardInfo.TasteNotesInput"
                />
                <AromaInput
                  v-on:checkEdited="checkEdited"
                  :languageList="languageList"
                  :Item="TasteCardInfo.AromaInput"
                />
                <FiveSensesInput
                  v-on:checkEdited="checkEdited"
                  :Item="TasteCardInfo.FiveSensesInput"
                />
                <div class="row">
                  <div
                    class="column inputcolumn"
                    v-for="(field, key) in ProductParameterInputList"
                    :key="key"
                  >
                    <TextInput
                      v-if="field.type == 'Text'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    />
                    <SelectInput
                      v-if="field.type == 'Option'"
                      v-on:checkEdited="checkEdited"
                      :Item="field"
                    />
                    <TimeInput
                      v-if="field.type == 'Time'"
                      v-on:checkEdited="checkEdited"
                      :item="field"
                      :name="field.name"
                      :value="field.value"
                    />
                  </div>
                </div>
                <div class="row">
                  <SelectInput
                    style="width: 50%;"
                    v-on:checkEdited="checkEdited"
                    :Item="TasteCardInfo.StatusInput"
                  />
                  <div style="width: 50%;padding-left: 50px;">
                    <label>顯示語言</label>
                    <div class="row">
                      <CheckBoxInput
                        v-for="(field, key) in TasteCardInfo.DisplayLanguageInput.option"
                        :key="key"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      />
                    </div>
                  </div>
                </div>
                <div>
                    <label>Taste Card 產品圖片</label>
                    <label style="color:red">*</label>
                    <FileInput
                      v-on:RemoveItem="RemoveTasteCardImage"
                      v-on:setMedia="setMedia"
                      :Item="TasteCardImage"
                      :acceptType="acceptType"
                    />
                  </div>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:55%;padding-left: 30px;">
            <md-card>
              <md-card-content>
                <div class="row">
                  <div style="width: 50%; ">
                    <h3 class="formTitle">沖煮步驟</h3> 
                  </div>
                  <div style="width: 50%; text-align: right;">
                    <label class="clearAll" @click="clearAllStep()"><u>clear all</u></label>
                  </div>
                  
                </div>
                
                <stepPage
                  :stepList="BrewingStepList"
                />
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDeleteMedia">
      <Popup
        v-on:popupcancel="showConfirmDeleteMedia = false"
        v-on:popupaction="deleteTasteCardImage"
        PopupTitle="刪除圖片"
        :PopupContent="PopupContent"
        actionButton="刪除"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  Spinner,
  TextInput,
  AutoComplete,
  TasteNotesInput,
  AromaInput,
  TimeInput,
  SelectInput,
  CheckBoxInput,
  FiveSensesInput,
  FileInput,
  Popup
} from "@/components";
import stepPage from "./components/stepPage.vue";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput,
    AutoComplete,
    TasteNotesInput,
    AromaInput,
    TimeInput,
    SelectInput,
    CheckBoxInput,
    FiveSensesInput,
    stepPage,
    FileInput,
    Popup
  },
  async created() {
    this.TasteCardID = this.$route.params.TasteCardID;
    await this.getLanguage();
    await this.getTasteCard();
  },
  methods: {
    /*
       form function
    */
    setMedia: async function(Item) {
      console.log("setMedia", Item);
      this.TasteCardImage.type = "tempImage"
    },
    deleteTasteCardImage: async function() {
      this.isLoading = true;
      try{
        const ret = await this.$store.dispatch("deleteTasteCardProductImage", {
          router: this.$router,
          data: { CardID: this.TasteCardID, path: this.TasteCardImage.path }
        });
        console.log("deleteMedia", ret.data);
        this.isLoading = false;
        this.showConfirmDeleteMedia = false;
        await this.getTasteCard();
      }catch (error){
        this.isLoading = false;
        console.log("deleteMedia error", error);
      }
    },
    RemoveTasteCardImage: async function(ID, deleteAction) {
      console.log("RemoveItem", ID);
      console.log("deleteAction", deleteAction);
      if (deleteAction) {
        console.log("deleteAction", this.TasteCardImage);
        if(this.TasteCardImage.type == "image"){
          this.showConfirmDeleteMedia = true;
          this.PopupContent = "確定要刪除圖片? 圖片刪除後將設定為預設圖片";
        }else{
          this.TasteCardImage.id = "";
          this.TasteCardImage.url = "";
          this.TasteCardImage.path = "";
          this.TasteCardImage.type = "default";
        }
      }
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    setAuthor: async function(author) {
      console.log("setAuthor", author);
      this.UserID = author.Key;
    },
    setUserAutocompleteList: function(list) {
      this.AuthorInput.autocompleteList = [];
      for (var i in list) {
        var name = list[i]["_source"].Name;
        if (list[i]["_source"].SupplierName) {
          name = list[i]["_source"].SupplierName;
        }
        this.AuthorInput.autocompleteList.push({
          Key: list[i]["_source"].UserID,
          Name: name
        });
      }
    },
    setFormValue: async function() {
      this.TasteCardInfo.TitleInput.value = this.TasteCard.Title;
      this.TasteCardInfo.Description.value = this.TasteCard.Description;
      /*
        set language
      */
      var language = ["zh", "en"];
      if (this.TasteCard.Language) {
        if (Array.isArray(this.TasteCard.Language)) {
          language = this.TasteCard.Language;
        } else {
          language = [this.TasteCard.Language];
        }
      }
      var LanguageInputOption = this.TasteCardInfo.DisplayLanguageInput.option;
      for (var i in LanguageInputOption) {
        var languageOption = LanguageInputOption[i];
        console.log("languageOption", languageOption);
        if (language.includes(languageOption.key)) {
          languageOption.value = true;
        } else {
          languageOption.value = false;
        }
      }
      this.TasteCardInfo.StatusInput.value = this.TasteCard.Status;

      if (this.TasteCard.TasteNote.length > 0) {
        this.TasteCardInfo.TasteNotesInput.value = [];
        this.TasteCardInfo.TasteNotesInput.model = [];
        for (var i in this.TasteCard.TasteNote) {
          var tastenote = this.TasteCard.TasteNote[i];
          this.TasteCardInfo.TasteNotesInput.value.push(tastenote.TasteNoteID);
          var TasteNotesString = "";
          for (const [key, value] of Object.entries(tastenote.Name)) {
            TasteNotesString += key + " : " + value + " / ";
          }
          TasteNotesString = TasteNotesString.slice(0, -2);
          this.TasteCardInfo.TasteNotesInput.model.push(TasteNotesString);
        }
      }
      //AromaInput
      if (this.TasteCard.Aroma && this.TasteCard.Aroma.length > 0) {
        this.TasteCardInfo.AromaInput.value = [];
        this.TasteCardInfo.AromaInput.model = [];
        for (var i in this.TasteCard.Aroma) {
          var aroma = this.TasteCard.Aroma[i];
          this.TasteCardInfo.AromaInput.value.push(aroma.AromaID);
          var AromaString = "";
          for (const [key, value] of Object.entries(aroma.Name)) {
            AromaString += key + " : " + value + " / ";
          }
          AromaString = AromaString.slice(0, -2);
          this.TasteCardInfo.AromaInput.model.push(AromaString);
        }
      }
      /*
        set five senses
      */
      var FiveSensesInputValue = this.TasteCardInfo.FiveSensesInput.value;
      for (var i in FiveSensesInputValue) {
        FiveSensesInputValue[i].value = Number(
          this.TasteCard.FiveSenses[FiveSensesInputValue[i].key]
        );
      }

     
      this.HeaderTitle += " " + this.Product.Name[this.language];
      this.ProductParameterInputList = [];
      if (this.Product.ProductType["Parameter"]) {
        var Parameter = this.Product.ProductType["Parameter"];
        for (var p in Parameter) {
          var parameter = Parameter[p];
          console.log("Parameter", parameter);
          var object = {
            type: parameter.Type,
            required: true,
            key: parameter.Key,
            name: parameter.Name.zh,
            value: ""
          };
          if (parameter.Type == "Option") {
            var option = [];
            var optionValue = "";
            if (this.TasteCard.Parameter[object.key].Key) {
              optionValue = this.TasteCard.Parameter[object.key].Key;
            } else if (this.TasteCard.Parameter[object.key].Value) {
              optionValue = this.TasteCard.Parameter[object.key].Value.en;
            } else if (this.TasteCard.Parameter[object.key].en) {
              optionValue = this.TasteCard.Parameter[object.key].en;
            } else {
              optionValue = this.TasteCard.Parameter[object.key];
            }
            object.value = optionValue;

            for (var o in parameter.Option) {
              var optionSelect = parameter.Option[o];
              option.push({
                key: optionSelect.Key,
                name: optionSelect.Name.zh
              });
              object.option = option;
            }
          } else if (parameter.Type == "Time") {
            var hours = 0;
            var minutes = 0;
            var seconds = 0;
            var timeString = this.TasteCard.Parameter[object.key];
            var timeStringArray = timeString.split("h");
            if (timeStringArray.length > 1) {
              hours = parseInt(timeStringArray[0]);
              timeString = timeStringArray[1];
            }
            timeStringArray = timeString.split("m");
            if (timeStringArray.length > 1) {
              minutes = parseInt(timeStringArray[0]);
              timeString = timeStringArray[1];
            }
            timeStringArray = timeString.split("s");
            if (timeStringArray.length > 1) {
              seconds = parseInt(timeStringArray[0]);
            }
            object.hours = hours;
            object.minutes = minutes;
            object.seconds = seconds;
            object.value = this.TasteCard.Parameter[object.key];
          } else {
            object.value = this.TasteCard.Parameter[object.key];
          }
          if (parameter.Unit) {
            object.Unit = parameter.Unit;
          }
          this.ProductParameterInputList.push(object);
        }
      }
      console.log("ProductParameterInputList", this.ProductParameterInputList);
    },
    /*
        api
    */
    async updateTasteCard() {
      var TasteCardObject = {
        CardID: this.TasteCardID,
        ProductID: this.Product.ProductID,
        UserID: this.UserID,
        Title: this.TasteCardInfo.TitleInput.value,
        Description: this.TasteCardInfo.Description.value,
        TasteNote: this.TasteCardInfo.TasteNotesInput.value,
        Aroma: this.TasteCardInfo.AromaInput.value,
        Status: this.TasteCardInfo.StatusInput.value,
        ProductImage: this.TasteCardImage,
        FiveSenses: {},
        Parameter: {},
        Step: this.BrewingStepList
      };
      var Language = [];
      for (var i in this.TasteCardInfo.DisplayLanguageInput.option) {
        if (this.TasteCardInfo.DisplayLanguageInput.option[i].value) {
          Language.push(this.TasteCardInfo.DisplayLanguageInput.option[i].key);
        }
      }
      TasteCardObject.Language = Language;
      for (var i in this.TasteCardInfo.FiveSensesInput.value) {
        TasteCardObject.FiveSenses[
          this.TasteCardInfo.FiveSensesInput.value[i].key
        ] = parseInt(this.TasteCardInfo.FiveSensesInput.value[i].value);
      }
      for (var i in this.ProductParameterInputList) {
        if (this.ProductParameterInputList[i].Unit) {
          //check value contain unit or not
          if (
            this.ProductParameterInputList[i].value.includes(
              this.ProductParameterInputList[i].Unit
            )
          ) {
            TasteCardObject.Parameter[
              this.ProductParameterInputList[i].key
            ] = this.ProductParameterInputList[i].value;
          } else {
            TasteCardObject.Parameter[this.ProductParameterInputList[i].key] =
              this.ProductParameterInputList[i].value +
              this.ProductParameterInputList[i].Unit;
          }
        } else {
          console.log(this.ProductParameterInputList[i], "no unit");
          TasteCardObject.Parameter[
            this.ProductParameterInputList[i].key
          ] = this.ProductParameterInputList[i].value;
        }
      }
      for (const [key, value] of Object.entries(TasteCardObject.FiveSenses)) {
        if (value > 4 || value < 0) {
          this.formMessage = "Five Senses value must between 0-4";
          this.showFormMessage = true;
          return;
        }
      }
      for (const [key, value] of Object.entries(TasteCardObject.Parameter)) {
        if (!value) {
          console.log(key + " no value");
          return;
        }
      }
      console.log("updateTasteCard", TasteCardObject);
      //return
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateTasteCard", {
          router: this.$router,
          tasteCard: TasteCardObject
        });
        this.formMessage = "TasteCard 已更新";
        this.confirmBack = false;
        this.showFormMessage = true;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    async getTasteCard() {
      this.isLoading = true;
      var object = {
        CardID: this.TasteCardID
      };
      try {
        const ret = await this.$store.dispatch("getTasteCard", {
          router: this.$router,
          data: object
        });
        console.log("getTasteCard", ret.data);
        
        for(var i in ret.data.TasteCardDetail.Step){
          var step = ret.data.TasteCardDetail.Step[i];
          if(step.image){
            step.image.url = this.s3path + step.image.path;
          }else{
            step.image = {
              id: "",
              url: "",
              path: "",
              type: ""
            }
          }
          
        }
        this.BrewingStepList = ret.data.TasteCardDetail.Step;
        console.log("BrewingStepList",this.BrewingStepList);
        this.TasteCard = ret.data.TasteCardDetail;
        this.TasteCardImage.path = this.TasteCard.ProductImage.path;
        this.TasteCardImage.url = this.s3path + this.TasteCard.ProductImage.path;
        this.TasteCardImage.type = this.TasteCard.ProductImage.type

        if (this.TasteCard.TasteNote && this.TasteCard.TasteNote.length > 0) {
          await this.getTasteNote(this.TasteCard.TasteNote);
        }
        if (this.TasteCard.Aroma && this.TasteCard.Aroma.length > 0) {
          await this.getAroma(this.TasteCard.Aroma);
        }
        await this.getUser(this.TasteCard.UserID);
        await this.getProduct(this.TasteCard.ProductID);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    async getProduct(ProductID) {
      this.isLoading = true;
      var object = {
        ProductID: ProductID
      };
      console.log("getProduct", object);
      try {
        const ret = await this.$store.dispatch("getProduct", {
          router: this.$router,
          product: object
        });
        console.log("getProduct", ret.data);
        this.Product = ret.data.ProductDetail;
        await this.setFormValue();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    getTasteNote: async function(TasteNoteList) {
      try {
        const ret = await this.$store.dispatch("getTasteNote", {
          router: this.$router,
          data: { TasteNote: TasteNoteList }
        });
        console.log("getTasteNote", ret.data.tasteNoteList);
        this.TasteCard.TasteNote = ret.data.tasteNoteList;
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getAroma: async function(AromaList) {
      try {
        const ret = await this.$store.dispatch("getAroma", {
          router: this.$router,
          data: { Aroma: AromaList }
        });
        console.log("getAroma", ret.data.aromaList);
        this.TasteCard.Aroma = ret.data.aromaList;
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        this.TasteCardInfo.DisplayLanguageInput.option = [];
        for (var i in this.languageList) {
          var language = this.languageList[i];
          this.TasteCardInfo.DisplayLanguageInput.option.push({
            key: language.Language,
            name: language.Language,
            value: false
          });
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getUser: async function(UserID) {
      this.isLoading = true;
      var object = {
        userID: UserID
      };
      try {
        const ret = await this.$store.dispatch("getCustomerInfo", {
          router: this.$router,
          customer: object
        });
        console.log("getUser", ret.data);
        this.isLoading = false;
        var authorName = "";
        if (ret.data.userInfo) {
          this.UserID = ret.data.userInfo.UserID;
          if (ret.data.userInfo.SupplierName) {
            authorName = ret.data.userInfo.SupplierName;
          } else {
            authorName = ret.data.userInfo.Name;
          }
        }
        this.AuthorInput.value = authorName;
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    searchUser: async function(keyword) {
      if (!keyword) return;
      var object = {
        Keyword: keyword,
        Filter: {
          UserRole: ["Offical", "Supplier"]
        },
        Source: ["UserID", "Name", "SupplierName"]
      };
      try {
        const ret = await this.$store.dispatch("SearchUser", {
          router: this.$router,
          keyword: object
        });
        console.log("searchUser", ret.data.data.hits.hits);
        this.setUserAutocompleteList(ret.data.data.hits.hits);
      } catch (err) {
        this.isLoading = false;
        console.log("searchUser error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      s3path: process.env.VUE_APP_S3_URL,
      acceptType: "image/*",
      language: "zh",
      isLoading: false,
      UserID: null,
      TasteCardID: null,
      TasteCard: null,
      Product: null,
      userInfo: null,
      showFormMessage: false,
      formMessage: "",

      AuthorInput: {
        name: "作者",
        value: "",
        required: true,
        autocompleteList: []
      },
      languageList: [],
      HeaderTitle: "編輯",
      confirmBack: false,
      formActionButton: "儲存",
      TasteCardInfo: {
        TitleInput: {
          type: "text",
          key: "Title",
          name: "標題",
          required: true,
          value: ""
        },
        Description: {
          type: "text",
          key: "Description",
          name: "描述",
          required: false,
          value: ""
        },
        TasteNotesInput: {
          key: "TasteNote",
          name: "風味",
          required: true,
          value: [],
          model: []
        },
        AromaInput: {
          key: "Aroma",
          name: "香氣",
          required: true,
          value: [],
          model: []
        },
        StatusInput: {
          key: "Status",
          name: "Status",
          required: true,
          value: "Hide",
          option: [
            {
              key: "Hide",
              name: "隱藏"
            },
            {
              key: "Private",
              name: "私人"
            },
            {
              key: "UnListed",
              name: "不公開"
            },
            {
              key: "Published",
              name: "公開"
            }
          ]
        },
        DisplayLanguageInput: {
          key: "DisplayLanguage",
          name: "顯示語言",
          required: true,
          value: null,
          option: []
        },
        FiveSensesInput: {
          key: "FiveSenses",
          name: "五感 (between 0-4)",
          required: true,
          value: [
            {
              key: "Sweet",
              name: "甜",
              value: 0
            },
            {
              key: "Sour",
              name: "酸",
              value: 0
            },
            {
              key: "Bitter",
              name: "苦",
              value: 0
            },
            {
              key: "Salty",
              name: "鹹",
              value: 0
            },
            {
              key: "Umami",
              name: "鮮",
              value: 0
            }
          ]
        }
      },
      TasteCardImage: {
        id: "",
        url: "",
        path: "",
        type: "default"
      },
      BrewingStepList: [],
      ProductParameterInputList: [],
      showConfirmDeleteMedia: false,
    };
  }
};
</script>