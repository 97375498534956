import oneSipApiCall from "../apiCall.js";

const payment = {
  testPayment({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-test-payment", data);
  },
  searchProductOrder({ commit, state }, { router, keyword }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-product-order",
      keyword
    );
  },
  calculateSF({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-calculate-sf", data);
  },
  createSFOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-sf-order", data);
  },
  cancelSFOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-cancel-sf-order", data);
  },
  trackOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-sf-tracking", data);
  },
  createOrderPDF({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-sf-pdf", data);
  },
  downloadSFpdf({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-download-sf-pdf", data);
  },
  createManualSFOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-manual-sf-order", data);
  },
  calculateSFGlobal({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-calculate-sf-global", data);
  },
  estimateFeeSFGlobal({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-estimate-fee-sf-global", data);
  },
  createSFGlobalOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-sf-global-order", data);
  },
  cancelSFGlobalOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-cancel-sf-global-order", data);
  },
  searchEventOrder({ commit, state }, { router, keyword }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-event-order",
      keyword
    );
  },
  listGatheringParticipationPayment(
    { commit, state },
    { router, gatheringID }
  ) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-list-gathering-participation-payment",
      gatheringID
    );
  },
  listGatheringProductPayment({ commit, state }, { router, gatheringID }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-list-gathering-product-payment",
      gatheringID
    );
  },
  listParticipationPayment({ commit, state }, { router }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-list-participation-payment"
    );
  },
  listProductPayment({ commit, state }, { router }) {
    return oneSipApiCall(commit, state, router, "/admin-list-product-payment");
  },
  calculateOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-calculate-order", data);
  },
  createOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/admin-create-order", data);
  },
  getOrderDetail({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-order-detail",
      data
    );
  },
  getDeliveryOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-delivery-order",
      data
    );
  },
  createDeliveryOrder({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-create-delivery-order",
      data
    );
  },
  searchRefundRequest({ commit, state }, { router, keyword }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-refund-request",
      keyword
    );
  },
  getRefundRequestDetail({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-get-refund-request",
      data
    );
  },
  updateRefundRequest({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-update-refund-request",
      data
    );
    return new Promise((resolve, reject) => {
      console.log(data);
      commit("action_request");
      //resolve(state.idToken);
      axios({ url: url + "/updateRefundRequest", data: data, method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  refundPayment({ commit, state }, { router, method, payment }) {
    if (method == "Paypal")
      return oneSipApiCall(
        commit,
        state,
        router,
        "/admin-paypal-refund",
        payment
      );
    return "Invalid Method";
  }
};
export default payment;
