<template>
  <div>
    <md-card>
      <md-card-content>
        <div style="width:100%">
          <h3 class="formTitle">Taste Card</h3>
        </div>
        <TableComponent
          v-on:selectItem="selectItem"
          v-on:selectAllItem="selectAllItem"
          v-on:selectPageItem="selectPageItem"
          v-on:searchItem="searchItem"
          v-on:onclickItem="onclickItem"
          v-on:selectSortOption="selectSortOption"
          v-on:selectedItemActionFunction="selectedItemActionFunction"
          v-on:goPage="goPage"
          v-on:SortPopupControl="SortPopupControl"
          :TableList="TableItemList"
          :selectedItemCounter="selectedItemCounter"
          :selectedItem="selectedItem"
          :columns="columns"
          :selectAll="selectAll"
          :selectable="selectable"
          :selectedItemAction="selectedItemAction"
          :haveSearchFunction="haveSearchFunction"
          :sortOption="sortOption"
          :pageNumber="pageNumber"
        />
        <div
          style="text-align: center;padding-top: 10px;"
          @click="showAddTasteCard = true"
        >
          <md-icon>add_circle_outline</md-icon>
          <u class="addLang" @click="createTasteCard()">新增</u>
        </div>
      </md-card-content>
    </md-card>
    <Spinner v-if="isLoading" />
    <Transition name="modal" v-if="showDeleteTasteCardPopup">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="deleteTasteCard"
        :PopupTitle="deleteTasteCardTitle"
        :PopupContent="deleteTasteCardContent"
        :actionButton="deleteTasteCardActionButton"
      />
    </Transition>
  </div>
</template>

<script>
import { TableComponent, Spinner } from "@/components";
export default {
  components: {
    TableComponent,
    Spinner
  },
  async created() {
    this.ProductID = this.$route.params.ProductID;
    console.log("ProductID", this.ProductID);
    await this.searchItem({ keyword: "" });
  },
  methods: {
    /*
    TableComponent function
    */
    selectItem: function(ID, action) {
      console.log(ID, action);
      if (action) {
        this.selectedItem.push(ID);
      } else {
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID);
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectPageItem: async function(action) {
      console.log("selectPageItem", action);
      console.log(this.TableItemList);
      if (action) {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index == -1) {
            this.selectedItem.push(this.TableItemList[i].ID);
          }
        }
      } else {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index > -1) {
            this.selectedItem.splice(index, 1);
          }
        }
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectAllItem: function(action) {
      this.selectAll = false;
      if (action) {
        this.selectAll = true;
        for (var i = 0; i < this.TableItemList.length; i++) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index < 0) {
            this.selectedItem.push(this.TableItemList[i].ID);
          }
        }
      } else {
        for (var i = 0; i < this.TableItemList.length; i++) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          this.selectedItem.splice(index, 1);
        }
      }
      console.log("after", this.selectedItem);
      this.selectedItemCounter = this.selectedItem.length;
    },
    onclickItem: async function(ID) {
      this.$router.push({
        name: "Update Taste Card",
        params: { TasteCardID: ID }
      });
    },
    SortPopupControl: function() {
      this.showSortPopup = !this.showSortPopup;
    },
    selectSortOption: function(option) {
      console.log("charge sorting with " + option);
      var sortOrder = "";
      var index = this.sortOption.findIndex(sort => sort.Key == option);
      console.log("option", index);
      if (index > -1) {
        this.sortOption[index].Value = true;
        this.sortOption[index].Order =
          this.sortOption[index].Order == "asc" ? "desc" : "asc";

        sortOrder = this.sortOption[index].Order;
        this.Sort[option] = sortOrder;

        this.showSortPopup = false;
        this.searchItem({ keyword: "" });
      }
    },
    selectedItemActionFunction: function(action) {
      console.log("selectedItemActionFunction", action);
      if (action == "delete") {
        console.log("delete", this.selectedItem);
        this.deleteTasteCardContent =
          "是否確認刪除" + this.selectedItem.length + "個Taste Card?";
        this.showDeleteTasteCardPopup = true;
      }
    },
    goPage: function(action) {
      if (action == "next") {
        this.index = this.index + this.displaySize;
        this.pageNumber = this.pageNumber + 1;
      } else if (action == "prev" && this.index > 0) {
        this.index = this.index - this.displaySize;
        this.pageNumber = this.pageNumber - 1;
      } else {
        this.index = 0;
        this.pageNumber = 1;
      }
      this.$router.replace({
        name: "Product List PageNumber",
        params: { page: this.pageNumber }
      });
      this.searchItem({ keyword: "" });
    },
    TableSelectUIControl(ItemList) {
      this.showNextButton = true;
      if (ItemList.length < this.displaySize) {
        this.showNextButton = false;
      }
      var counter = 0;
      for (var i = 0; i < ItemList.length; i++) {
        var index = this.selectedItem.indexOf(ItemList[i].ID);
        if (index > -1) {
          counter++;
        }
      }
      if (counter == ItemList.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    setDisplayList(list) {
      this.TableItemList = [];
      for (var i in list) {
        var language = "zh";
        if (list[i]["_source"].Language) {
          language = list[i]["_source"].Language;
        }
        var object = {
          ID: list[i]["_source"].CardID,
          標題: list[i]["_source"].Title,
          日期: list[i]["_source"].CreatedDatetime,
          狀態: list[i]["_source"].Status,
          語言: language,
          用戶類型: list[i]["_source"].UserType
        };
        this.TableItemList.push(object);
      }
      console.log("setDisplayList after", this.TableItemList);
      this.TableSelectUIControl(this.TableItemList);
    },
    /*
        end of TableComponent function
    */
    createTasteCard: function() {
      this.$router.push({
        name: "Create Taste Card",
        params: { ProductID: this.ProductID }
      });
    },
    /*
        Popup action 
    */
    popupcancel: function() {
      this.showAddTasteCard = false;
      this.showDeleteTasteCardPopup = false;
    },
    deleteTasteCard: async function() {
      this.showDeleteTasteCardPopup = false;
      this.isLoading = true;
      try {
        var error = false;
        for (var i = 0; i < this.selectedItem.length; i++) {
          error = await this.DeleteTasteCard(this.selectedItem[i]);
        }
        this.isLoading = false;
        if (!error) {
          this.formMessage = "刪除成功";
          this.showFormMessage = true;
        }
        this.getProductInfo();
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err;
        this.showFormMessage = true;
        console.log(err);
      }
    },
    /*
        end of Popup action
    */
    /*
        TasteCard API
    */
    searchItem: async function({ keyword }) {
      this.isLoading = true;
      var object = {
        Size: this.displaySize,
        From: this.index,
        Sort: this.Sort,
        Source: this.Source
      };
      if (this.ProductID) {
        var ProductIDList = [];
        ProductIDList.push(this.ProductID);
        object.Filter = {
          ProductID: ProductIDList
        };
      }
      if (keyword) {
        object["Keyword"] = keyword;
        this.selectAll = false;
        this.selectedItem = [];
        this.selectedItemCounter = 0;
        this.index = 0;
        this.pageNumber = 1;
      }
      try {
        const ret = await this.$store.dispatch("searchTasteCard", {
          router: this.$router,
          data: object
        });
        console.log("searchTasteCard", ret.data);
        this.setDisplayList(ret.data.filterResult.hits);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err.message);
      }
    }
  },
  data() {
    return {
      ProductID: "",
      showAddTasteCard: false,
      showDeleteTasteCardPopup: false,
      showSortPopup: false,
      isLoading: false,
      selectedItemCounter: 0,
      selectedItem: [],
      TableItemList: [],
      columns: ["標題", "日期", "狀態", "語言", "用戶類型"],
      haveSearchFunction: true,
      selectAll: false,
      selectable: true,
      selectedItemAction: [
        { Key: "delete", Name: "刪除", Style: "color:#CC2727" }
      ],
      displaySize: 10,
      index: 0,
      pageNumber: 1,
      Sort: { CreatedDatetime: "desc" },
      sortOption: [
        {
          Key: "CreatedDatetime",
          Name: "最近新增",
          Value: true,
          Order: "desc"
        }
      ],
      Source: [
        "CardID",
        "CreatedDatetime",
        "Status",
        "UserType",
        "Title",
        "Language"
      ],
      filter: {}
    };
  }
};
</script>