<template>
  <div class="survey-menu-item md-elevation-4" @click="onSelect">
    <h2>
      {{ title }}
    </h2>
    <md-icon class="arrow-icon">
      chevron_right
    </md-icon>
  </div>
</template>

<script>
export default {
  name: "SurveyMenuItem",
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      return this.survey.Name;
    }
  },
  data() {
    return {};
  },
  methods: {
    onSelect() {
      this.$emit("select", this.survey);
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-menu-item {
  background-color: #ffffff;
  padding: 10px 45px 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;

  position: relative;
  transition: background-color 0.2s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  cursor: pointer;

  h2 {
    margin: 0.3em 0;
    font-size: 1.2em;
    font-weight: 800;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .arrow-icon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: #f4f4f4;
  }
}
</style>
