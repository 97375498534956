<template>

    <div class="filter-mask">
        <div class="modal-wrapper">
            <div class="filter-container">
                <div class="row filterRow">
                    <div class="column filterHeader" style="width:50%">
                        Select Icon
                    </div>
                    <div class="column filterClose" style="width:50%">
                        <u style="cursor: pointer;" @click="closePopup()">close</u>
                    </div>
                </div>
                <hr />
                <!-- loop iconList, 3 item for 1 row -->
                <div class="row filter">
                    <div class="column filterIcon" v-for="(icon, index) in iconList" :key="index" style="width:33.33%">
                        <img :src="icon.url" class="iconImage" @click="ImageToStep('icon', icon)" />
                    </div>
                </div>
                <div class="row filterRow endOfFilter" style="text-align: center;">
                    <!--<label for="file-upload" class="uploadIcon">
                        上傳圖片
                    </label>
                    <md-file :accept="acceptType" @md-change="uploadFile($event, Item.ID)" style="display: none" />-->
                    <div style="width: 100%;">
                        <label for="file-upload" class="uploadIcon">
                            上傳圖片
                        </label>
                        <input id="file-upload" type="file" @change="handleFileUpload" style="display: none" />
                    </div>
                </div>
            </div>
        </div>
        <Spinner v-if="isLoading" />
    </div>
</template>
<script>
import {
  Spinner,
} from "@/components";
export default {
    components: {
        Spinner
    },
    name: "icon-popup",
    prop: {

    },
    async created() {
        await this.listTasteCardIcon();
    },
    methods: {
        async handleFileUpload(event) {
            const file = event.target.files[0];
            // Handle the selected file here, e.g., read its contents
            console.log(file);
            var base64 = await this.getBase64(file);
            var contentType = base64.split(":", 2)[1].split(";", 1)[0];
            this.isLoading = true;
            try {
                const ret = await this.$store.dispatch("uploadMedia", {
                    router: this.$router,
                    data: { Media: contentType }
                });
                await this.$store.dispatch("updateMediaToS3", {
                    file: base64,
                    urlObject: ret.data.UploadUrl
                });

                var url = this.s3path + ret.data.UploadUrl.key;
                this.$emit("ImageToStep", {id: ret.data.MediaID, type:'tempImage', url: url});
                this.isLoading = false;
            } catch (err) {
                this.isLoading = false;
                console.log("uploadMedia error", err);
            }

        },
        getBase64(file) {
            const reader = new FileReader();
            return new Promise(resolve => {
                reader.onload = ev => {
                    resolve(ev.target.result);
                };
                reader.readAsDataURL(file);
            });
        },
        ImageToStep: function (type, image) {
            console.log("selectIcon", image);
            this.$emit("ImageToStep", {type: type, url: image.url, path:image.path});
        },
        closePopup: function () {
            console.log("closePopup");
            this.$emit("iconPopupControl", 0, false);
        },
        listTasteCardIcon: async function () {
            try {
                const ret = await this.$store.dispatch("listTasteCardIcon", {
                    router: this.$router
                });
                console.log("listTasteCardIcon", ret);
                this.iconList = []
                for(var i in ret.data.iconList){
                    this.iconList.push({
                        path: ret.data.iconList[i].path,
                        url: this.s3path + ret.data.iconList[i].path
                    });
                }

            } catch (err) {
                this.formMessage = err.response.data.message;
                this.showFormMessage = true;
            }
        }
    },
    data() {
        return {
            s3path: process.env.VUE_APP_S3_URL,
            formMessage: "",
            showFormMessage: false,
            isLoading: false,
            acceptType: "image/*",
            iconList: []
        };
    }
}
</script>

<style>
.uploadIcon {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: #575757;
    color: white;
    padding: 10px 35%;
    margin: 0 20px;
    font-size: 15px;
    border-radius: 5px;
}

.iconImage {
    width: 100%;
    height: 100%;
    padding: 10px 30px;
    cursor: pointer;
}

.custom-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
</style>
