import axios from "axios";

import url from "../config.js";

const blacklist = {
  listBlacklistRecords({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("action_request");
      //resolve(state.idToken);
      axios({ url: url + "/admin-list-blacklist", method: "POST" })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  createBlacklistRecord({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      console.log(user);
      commit("action_request");
      //resolve(state.idToken);
      axios({
        url: url + "/admin-create-blacklist",
        data: user,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  updateBlacklistRecord({ commit, state }, user) {
    return new Promise((resolve, reject) => {
      console.log(user);
      commit("action_request");
      //resolve(state.idToken);
      axios({
        url: url + "/admin-update-blacklist",
        data: user,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  },
  deleteBlacklistRecord({ commit, state }, userID) {
    return new Promise((resolve, reject) => {
      console.log(userID);
      commit("action_request");
      //resolve(state.idToken);
      axios({
        url: url + "/admin-delete-blacklist",
        data: userID,
        method: "POST"
      })
        .then(resp => {
          commit("action_success");
          console.log(resp);
          resolve(resp);
        })
        .catch(err => {
          if (err.response.stats == 401) {
          }
          commit("action_error", { err });
          reject(err);
        });
    });
  }
};

export default blacklist;
