import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";

//import Icons from "@/pages/Icons.vue";
//import Maps from "@/pages/Maps.vue";
//import Notifications from "@/pages/Notifications.vue";

import Login from "@/pages/Login.vue";
import MainNavbar from "@/pages/Layout/MainNavbar.vue";
import MainFooter from "@/pages/Layout/MainFooter.vue";

//new page
import CustomerList from "@/pages/Customer/CustomerList.vue";
import CreateCustomer from "@/pages/Customer/CreateCustomer.vue";
import CustomerProfile from "@/pages/Customer/CustomerProfile.vue";
import UpdateCustomer from "@/pages/Customer/UpdateCustomer.vue";

import CustomerGroupList from "@/pages/CustomerGroup/CustomerGroupList.vue";
import CustomerGroupDetail from "@/pages/CustomerGroup/CustomerGroupDetail.vue";
import CreateCustomerGroup from "@/pages/CustomerGroup/CreateCustomerGroup.vue";
import AddCustomerToCustomerGroup from "@/pages/CustomerGroup/AddCustomerToCustomerGroup.vue";

import ProductList from "@/pages/Product/ProductList.vue";
import CreateProduct from "@/pages/Product/CreateProduct.vue";
import UpdateProduct from "@/pages/Product/UpdateProduct.vue";
import CreateProductVariance from "@/pages/Product/CreateProductVariance.vue";
import UpdateVariance from "@/pages/Product/UpdateVariance.vue";

import CreateTasteCard from "@/pages/TasteCard/CreateTasteCard.vue";
import UpdateTasteCard from "@/pages/TasteCard/UpdateTasteCard.vue";

import CouponList from "@/pages/Coupon/CouponList.vue";
import CreateCoupon from "@/pages/Coupon/CreateCoupon.vue";
import CouponDetail from "@/pages/Coupon/CouponDetail.vue";

import LocationList from "@/pages/LocationList.vue";
import CreateLocation from "@/pages/CreateLocation";
import UpdateLocation from "@/pages/UpdateLocation";

import EventList from "@/pages/Event/EventList.vue";
import CreateEvent from "@/pages/Event/CreateEvent.vue";
// import EventDetails from "@/pages/Event/EventDetails.vue";
import UpdateEvent from "@/pages/Event/UpdateEvent.vue";
// import UpdateEventVariance from "@/pages/Event/UpdateEventVariance.vue";
import EventSurveySummary from "@/pages/Event/EventSurveySummary.vue";
import UpdateEventSurvey from "@/pages/Event/UpdateEventSurvey.vue";
import VarianceDetail from "@/pages/Event/VarianceDetail.vue";

import SurveyTemplateList from "@/pages/Survey/SurveyTemplateList.vue";
import CreateSurveyTemplate from "@/pages/Survey/CreateSurveyTemplate.vue";
import UpdateSurveyTemplate from "@/pages/Survey/UpdateSurveyTemplate.vue";
import SurveyResult from "@/pages/Survey/SurveyResult.vue";
import SurveyResultDetail from "@/pages/Survey/SurveyResultDetail.vue";

import PaymentManagement from "@/pages/Payment/PaymentManagement.vue";
import CreateProductOrder from "@/pages/Payment/CreateProductOrder.vue";
import OrderDetail from "@/pages/Payment/OrderDetail.vue";

import RefundRequestList from "@/pages/RefundRequestList.vue";
import RefundRequest from "@/pages/RefundRequest.vue";

import EmailTemplateList from "@/pages/EmailTemplate/EmailTemplateList.vue";
import CreateEmailTemplate from "@/pages/EmailTemplate/CreateEmailTemplate.vue";
import UpdateEmailTemplate from "@/pages/EmailTemplate/UpdateEmailTemplate.vue";

import InspirationList from "@/pages/Inspiration/InspirationList.vue";
import CreateInspiration from "@/pages/Inspiration/CreateInspiration.vue";
import UpdateInspiration from "@/pages/Inspiration/UpdateInspiration.vue";

import EmailEventList from "@/pages/EmailEvent/EmailEventList.vue";
import CreateEmailEvent from "@/pages/EmailEvent/CreateEmailEvent.vue";

import InventoryList from "@/pages/InventoryList.vue";
import CreateInventory from "@/pages/CreateInventory.vue";
import UpdateInventory from "@/pages/UpdateInventory.vue";

import ContentManagement from "@/pages/ContentManagement/ContentManagement.vue";
import UpdateAboutWeSip from "@/pages/ContentManagement/UpdateAboutWeSip.vue";
import UpdateBigPromotionBanner from "@/pages/ContentManagement/UpdateBigPromotionBanner.vue";
import UpdateContactUsInformation from "@/pages/ContentManagement/UpdateContactUsInformation.vue";
import UpdateFAQ from "@/pages/ContentManagement/UpdateFAQ.vue";
import UpdateFooter from "@/pages/ContentManagement/UpdateFooter.vue";
import UpdateHomeBanner from "@/pages/ContentManagement/UpdateHomeBanner.vue";
import UpdateInspirationTitle from "@/pages/ContentManagement/UpdateInspirationTitle.vue";
import UpdateNavMenu from "@/pages/ContentManagement/UpdateNavMenu.vue";
import UpdateNewInSection from "@/pages/ContentManagement/UpdateNewInSection.vue";
import UpdateNewsletterSubscription from "@/pages/ContentManagement/UpdateNewsletterSubscription.vue";
import UpdatePrivacyPolicyInformation from "@/pages/ContentManagement/UpdatePrivacyPolicyInformation.vue";
import UpdateSEO from "@/pages/ContentManagement/UpdateSEO.vue";
import UpdateSmallPromotionBanner from "@/pages/ContentManagement/UpdateSmallPromotionBanner.vue";
import UpdateSurveyLandingSection from "@/pages/ContentManagement/UpdateSurveyLandingSection.vue";
import UpdateTermsAndConditions from "@/pages/ContentManagement/UpdateTermsAndConditions.vue";

import QRCodeScanner from "@/pages/QRCodeScanner.vue";

import Logout from "@/pages/Logout.vue";
import TestPayment from "@/pages/Payment/TestPayment.vue";
import Stripe from "@/pages/Payment/Stripe.vue";
import StripeWallets from "@/pages/Payment/StripeWallets.vue";
import PaymentFail from "@/pages/Payment/PaymentFail.vue";
import PaymentSuccess from "@/pages/Payment/PaymentSuccess.vue";

import ParameterList from "@/pages/ParameterManagement/ParameterList.vue";

import TasteNoteList from "@/pages/ParameterManagement/TasteNote/TasteNoteList.vue";
import CreateTasteNote from "@/pages/ParameterManagement/TasteNote/CreateTasteNote.vue";
import UpdateTasteNote from "@/pages/ParameterManagement/TasteNote/UpdateTasteNote.vue";
import ImportTasteNote from "@/pages/ParameterManagement/TasteNote/ImportTasteNote.vue";
import ExportTasteNote from "@/pages/ParameterManagement/TasteNote/ExportTasteNote.vue";

import AromaList from "@/pages/ParameterManagement/Aroma/AromaList.vue";
import CreateAroma from "@/pages/ParameterManagement/Aroma/CreateAroma.vue";
import UpdateAroma from "@/pages/ParameterManagement/Aroma/UpdateAroma.vue";

import ParingTagList from "@/pages/ParameterManagement/ParingTag/ParingTagList.vue";
import CreateParingTag from "@/pages/ParameterManagement/ParingTag/CreateParingTag.vue";
import UpdateParingTag from "@/pages/ParameterManagement/ParingTag/UpdateParingTag.vue";

import CountryList from "@/pages/ParameterManagement/Country/CountryList.vue";
import CreateCountry from "@/pages/ParameterManagement/Country/CreateCountry.vue";
import UpdateCountry from "@/pages/ParameterManagement/Country/UpdateCountry.vue";

import ProductTypeList from "@/pages/ParameterManagement/ProductType/ProductTypeList.vue";
import CreateProductType from "@/pages/ParameterManagement/ProductType/CreateProductType.vue";
import UpdateProductType from "@/pages/ParameterManagement/ProductType/UpdateProductType.vue";

import LanguageManagement from "@/pages/ParameterManagement/Language/LanguageManagement.vue";
import EventTypeList from "@/pages/ParameterManagement/Event/EventTypeList.vue";
import UpdateEventType from "@/pages/ParameterManagement/Event/UpdateEventType.vue";
import CreateEventType from "@/pages/ParameterManagement/Event/CreateEventType.vue";

import EventLevelList from "@/pages/ParameterManagement/Event/EventLevelList.vue";
import UpdateEventLevel from "@/pages/ParameterManagement/Event/UpdateEventLevel.vue";
import CreateEventLevel from "@/pages/ParameterManagement/Event/CreateEventLevel.vue";

import CustomTasteNoteList from "@/pages/CustomTasteNote/CustomTasteNoteList.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      //new page routes
      {
        path: "customer/",
        name: "Customer List",
        component: CustomerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TestPayment",
        name: "TestPayment",
        component: TestPayment,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TestPayment/Stripe/:clientSecret",
        name: "Stripe",
        component: Stripe,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TestPayment/StripeWallets/:paymentID/:clientSecret/:total",
        name: "Wallets",
        component: StripeWallets,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TestPayment/Success/:paymentID",
        name: "Payment Success",
        component: PaymentSuccess,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TestPayment/Fail",
        name: "Payment Fail",
        component: PaymentFail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customer/:page",
        name: "Customer List PageNumber",
        component: CustomerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/customer",
        name: "Create Customer",
        component: CreateCustomer,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customer/profile/:customer",
        name: "Customer Profile",
        component: CustomerProfile,
        meta: {
          requiresAuth: true
        },
        props: true
      },
      {
        path: "customer/profile/update/:customer",
        name: "Update Customer",
        component: UpdateCustomer,
        meta: {
          requiresAuth: true
        },
        props: true
      },
      {
        path: "customergroup/",
        name: "CustomerGroup List",
        component: CustomerGroupList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/:page",
        name: "CustomerGroup List PageNumber",
        component: CustomerGroupList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/customergroup",
        name: "Create Customer Group",
        component: CreateCustomerGroup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/detail/:customerGroupID",
        name: "CustomerGroup Detail",
        component: CustomerGroupDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/detail/:customerGroupID/:page",
        name: "CustomerGroup Detail PageNumber",
        component: CustomerGroupDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/addCustomer/:customerGroupID",
        name: "Add Customer To Customer Group",
        component: AddCustomerToCustomerGroup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customergroup/addCustomer/:customerGroupID/:page",
        name: "Add Customer To Customer Group PageNumber",
        component: AddCustomerToCustomerGroup,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "product",
        name: "Product List",
        component: ProductList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "product/:page",
        name: "Product List PageNumber",
        component: ProductList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/product",
        name: "Create Product",
        component: CreateProduct,
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "create/product-variance/:ProductID",
        name: "Create Product Variance",
        component: CreateProductVariance,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/product/:ProductID",
        name: "Update Product",
        component: UpdateProduct,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/variance/:VarianceID",
        name: "Update Variance",
        component: UpdateVariance,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/tastecard/:ProductID",
        name: "Create Taste Card",
        component: CreateTasteCard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/tastecard/:TasteCardID",
        name: "Update Taste Card",
        component: UpdateTasteCard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "coupon",
        name: "Coupon List",
        component: CouponList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "coupon/:page",
        name: "Coupon List PageNumber",
        component: CouponList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/coupon",
        name: "Create Coupon",
        component: CreateCoupon,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CouponDetail/:CouponID",
        name: "Coupon Detail",
        component: CouponDetail,
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "location",
        name: "Location List",
        component: LocationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "location/:page",
        name: "Location List PageNumber",
        component: LocationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/location",
        name: "Create Location",
        component: CreateLocation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/location/:locationID",
        name: "Update Location",
        component: UpdateLocation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "eventlist",
        name: "Event List",
        component: EventList,
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "eventlist/:page",
        name: "Event List PageNumber",
        component: EventList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/event",
        name: "Create Event",
        component: CreateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/event/:page",
        name: "Create Event PageNumber",
        component: CreateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID",
        name: "Update Event",
        component: UpdateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID/:page",
        name: "Update Event PageNumber",
        component: UpdateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/survey/:eventID",
        name: "Event Survey Summary",
        component: EventSurveySummary,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/survey/:eventID/:surveyID",
        name: "Update Event Survey",
        component: UpdateEventSurvey,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/:eventID",
        name: "Update Event",
        component: UpdateEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/variance/:eventID/:varianceID",
        name: "Variance Detail",
        component: VarianceDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/variance/:eventID/:varianceID/survey",
        name: "Survey Result",
        component: SurveyResult,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "event/variance/:eventID/:varianceID/survey/:surveyID",
        name: "Survey Result Detail",
        component: SurveyResultDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "surveytemplate",
        name: "Survey Template List",
        component: SurveyTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "surveytemplate/:page",
        name: "Survey Template List PageNumber",
        component: SurveyTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/surveytemplate",
        name: "Create Survey Template",
        component: CreateSurveyTemplate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/surveytemplate/:TemplateID",
        name: "Update Survey Template",
        component: UpdateSurveyTemplate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "payments",
        name: "Payments",
        component: PaymentManagement,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "payments/create/productorder",
        name: "Create Product Order",
        component: CreateProductOrder,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "payments/orderdetail/:orderID",
        name: "Order Detail",
        component: OrderDetail,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "refundrequest",
        name: "Refund Request",
        component: RefundRequestList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "refundrequest/:RefundRequestID",
        name: "Refund Request Detail",
        component: RefundRequest,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailTemplate",
        name: "Email Template",
        component: EmailTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailTemplate/:page",
        name: "Email Template PageNumber",
        component: EmailTemplateList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/EmailTemplate",
        name: "Create EmailTemplate",
        component: CreateEmailTemplate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/EmailTemplate/:templateName",
        name: "Update EmailTemplate",
        component: UpdateEmailTemplate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "InspirationList",
        name: "Inspiration List",
        component: InspirationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "InspirationList/:page",
        name: "Inspiration List PageNumber",
        component: InspirationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inspiration/create",
        name: "Create Inspiration",
        component: CreateInspiration,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inspiration/update/:inspirationID",
        name: "Update Inspiration",
        component: UpdateInspiration,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailEvent",
        name: "Email Event List",
        component: EmailEventList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EmailEvent/:page",
        name: "Email Event List PageNumber",
        component: EmailEventList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/EmailEvent",
        name: "Create Email Event",
        component: CreateEmailEvent,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inventory",
        name: "Inventory List",
        component: InventoryList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Inventory/:page",
        name: "Inventory List PageNumber",
        component: InventoryList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "create/Inventory/",
        name: "Create Inventory",
        component: CreateInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "update/Inventory/:InventoryID",
        name: "Update Inventory",
        component: UpdateInventory,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement",
        name: "Content Management",
        component: ContentManagement,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Contact Us",
        name: "Contact Us",
        component: UpdateContactUsInformation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Privacy Policy",
        name: "Privacy Policy",
        component: UpdatePrivacyPolicyInformation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/NewInSection",
        name: "New In Section",
        component: UpdateNewInSection,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/About WeSip",
        name: "About WeSip",
        component: UpdateAboutWeSip,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/BigPromotionBanner",
        name: "Big Promotion Banner",
        component: UpdateBigPromotionBanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/SurveyLandingSection",
        name: "Survey Landing Section",
        component: UpdateSurveyLandingSection,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/SEO",
        name: "SEO",
        component: UpdateSEO,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/smallPromotionBanner",
        name: "Small Promotion Banner",
        component: UpdateSmallPromotionBanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Home Banner",
        name: "Home Banner",
        component: UpdateHomeBanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Terms & Conditions",
        name: "Terms & Conditions",
        component: UpdateTermsAndConditions,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/NavMenu",
        name: "Nav Menu",
        component: UpdateNavMenu,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/Footer",
        name: "Footer",
        component: UpdateFooter,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/InspirationTitle",
        name: "Inspiration Title",
        component: UpdateInspirationTitle,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/NewsletterSubscription",
        name: "Newsletter Subscription",
        component: UpdateNewsletterSubscription,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ContentManagement/FAQ",
        name: "FAQ",
        component: UpdateFAQ,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ParameterList",
        name: "Parameter List",
        component: ParameterList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "AromaList",
        name: "AromaList",
        component: AromaList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ProductTypeList",
        name: "ProductType List",
        component: ProductTypeList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TasteNoteList",
        name: "TasteNoteList",
        component: TasteNoteList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "TasteNoteList/:page",
        name: "TasteNoteList PageNumber",
        component: TasteNoteList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateTasteNote",
        name: "Create Taste Note",
        component: CreateTasteNote,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ImportTasteNote",
        name: "ImportTasteNote",
        component: ImportTasteNote,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ExportTasteNote",
        name: "ExportTasteNote",
        component: ExportTasteNote,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateTasteNote/:TasteNoteID",
        name: "Update Taste Note",
        component: UpdateTasteNote,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "AromaList",
        name: "AromaList",
        component: AromaList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "AromaList/:page",
        name: "AromaList PageNumber",
        component: AromaList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateAroma",
        name: "Create Aroma",
        component: CreateAroma,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateAroma/:AromaID",
        name: "Update Aroma",
        component: UpdateAroma,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ParingTagList",
        name: "ParingTag List",
        component: ParingTagList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ParingTagList/:page",
        name: "ParingTag List PageNumber",
        component: ParingTagList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateParingTag",
        name: "Create Paring Tag",
        component: CreateParingTag,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateParingTag/:ParingTagID",
        name: "Update Paring Tag",
        component: UpdateParingTag,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CountryList",
        name: "Country List",
        component: CountryList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CountryList/:page",
        name: "Country List PageNumber",
        component: CountryList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateCountry",
        name: "Create Country",
        component: CreateCountry,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateCountry/:CountryID",
        name: "Update Country",
        component: UpdateCountry,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "ProductTypeList",
        name: "Product Type List",
        component: ProductTypeList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateProductType",
        name: "Create Product Type",
        component: CreateProductType,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateProductType/:ProductTypeID",
        name: "Update Product Type",
        component: UpdateProductType,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "Language",
        name: "Language Management",
        component: LanguageManagement,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EventTypeList/",
        name: "EventTypeList",
        component: EventTypeList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateEventType",
        name: "CreateEventType",
        component: CreateEventType,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateEventType/:EventTypeID",
        name: "UpdateEventType",
        component: UpdateEventType,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "EventLevelList/",
        name: "EventLevelList",
        component: EventLevelList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CreateEventLevel",
        name: "CreateEventLevel",
        component: CreateEventLevel,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "UpdateEventLevel/:EventLevelID",
        name: "UpdateEventLevel",
        component: UpdateEventLevel,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "qr-scanner",
        name: "QR Scanner",
        component: QRCodeScanner,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "CustomTasteNoteList",
        name: "CustomTasteNoteList",
        component: CustomTasteNoteList,
        meta: {
          requiresAuth: true
        }
      },
      //end of new page routes
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: "/login",
    name: "login",
    components: { default: Login, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  }
];

export default routes;
