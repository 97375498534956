<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <TableHeader
          :HeaderTitle="HeaderTitle"
          :AddNewButton="AddNewButton"
          v-on:createNewItem="createNewItem"
        ></TableHeader>
        <md-card>
          <md-card-content>
            <TableComponent
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:selectPageItem="selectPageItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectSortOption="selectSortOption"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              v-on:SortPopupControl="SortPopupControl"
              v-on:setFilter="setFilter"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :sortOption="sortOption"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
              :showSortPopup="showSortPopup"
              :filterOption="filterOption"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
      <Transition name="modal" v-if="showDeletePopup">
        <Popup
          v-on:popupcancel="popupcancel"
          v-on:popupaction="deleteSelectedProduct"
          PopupTitle="刪除產品"
          PopupContent="確定要刪除這個產品嗎? 刪除的資料將無法復原"
          actionButton="刪除"
        ></Popup>
      </Transition>
      <Transition name="modal" v-if="showUpdateStatusPopup">
        <Popup
          v-on:popupcancel="popupcancel"
          v-on:popupaction="updateProductStatus"
          PopupTitle="更新產品"
          :PopupContent="
            '是否確定將選擇的產品狀態更新為' + newProductStatus.Name + '?'
          "
          actionButton="確定"
        ></Popup>
      </Transition>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { TableComponent, TableHeader, Popup, Spinner } from "@/components";

export default {
  components: {
    TableComponent,
    TableHeader,
    Popup,
    Spinner
  },
  data() {
    return {
      language: "zh",
      message: "",
      showDeletePopup: false,
      showUpdateStatusPopup: false,
      newProductStatus: {
        Name: "",
        Key: ""
      },
      showSortPopup: false,
      isLoading: false,
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "產品列表",
      ProductTypeList: [],
      TableItemList: [],
      AddNewButton: "新增產品",
      columns: ["img", "產品名稱", "類型", "價格", "狀態"],
      Source: [
        "Name",
        "ProductType.Name",
        "MinimumPrice",
        "SupplierID",
        "Image",
        "ProductID",
        "Status"
      ],
      Sort: { CreatedDatetime: "desc" },
      //sortOption:[],
      sortOption: [
        {
          Key: "CreatedDatetime",
          Name: "最近新增",
          Value: true,
          Order: "desc"
        },
        { Key: "MinimumPrice", Name: "價格", Value: false, Order: "desc" }
      ],
      selectedItemAction: [
        { Key: "Hide", Name: "隱藏" },
        { Key: "UnListed", Name: "非公開" },
        { Key: "Published", Name: "公開" },
        { Key: "delete", Name: "刪除", Style: "color:#CC2727" }
      ],
      filterOption: [
        {
          Key: "ProductType",
          Name: "產品類型",
          type: "checkbox",
          inputType: "checkbox",
          option: []
        },
        {
          Key: "MinimumPrice",
          Name: "價格",
          type: "range",
          inputType: "number",
          from: "",
          to: ""
        }
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      filter: {},
      range: {}
    };
  },
  async created() {
    this.isLoading = true;
    await this.listProductType();
    //await this.getSuppliers();
    console.log("params", this.$route.params);
    this.message = this.$route.params.message;
    if (this.$route.params.page) {
      if (Number(this.$route.params.page) > 0) {
        this.pageNumber = Number(this.$route.params.page);
      }
      this.index =
        this.index + (Number(this.$route.params.page) - 1) * this.displaySize;
    }
    this.checkShowMessage();
    await this.searchItem({ keyword: "" });
    this.isLoading = false;
  },
  methods: {
    listProductType: async function() {
      try {
        const ret = await this.$store.dispatch("listProductType", {
          router: this.$router,
          data: null
        });
        this.ProductTypeList = ret.data.ProductType;
        console.log("listProductType", this.ProductTypeList);
        var filter = this.filterOption.find(x => x.Key == "ProductType");
        filter.option = [];
        for (var p in this.ProductTypeList) {
          filter.option.push({
            Key: this.ProductTypeList[p].ProductTypeID,
            Name: this.ProductTypeList[p].Name.zh,
            Value: false
          });
        }
      } catch (err) {
        console.log("listProductType error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setFilter: function(filterOption) {
      console.log("setFilter", filterOption);
      this.filter = {};
      for (var f in filterOption) {
        var option = filterOption[f];
        if (option.type == "range") {
          var filterObject = {};
          if (option.from) {
            filterObject["Start"] = option.from;
          }
          if (option.to) {
            filterObject["End"] = option.to;
          }
          if (filterObject.Start || filterObject.End) {
            this.range[option.Key] = filterObject;
          }
        } else {
          var filterList = [];
          for (var o in option.option) {
            if (option.option[o].Value) {
              filterList.push(option.option[o].Key);
            }
          }
          if (filterList.length > 0) {
            this.filter[option.Key] = filterList;
          }
        }
      }
      this.selectAll = false;
      (this.selectedItem = []), (this.selectedItemCounter = 0);

      this.index = 0;
      this.pageNumber = 1;
      console.log("filter", this.filter);
      this.searchItem({ keyword: "" });
    },
    SortPopupControl: function() {
      this.showSortPopup = !this.showSortPopup;
    },
    goPage: function(action) {
      if (action == "next") {
        this.index = this.index + this.displaySize;
        this.pageNumber = this.pageNumber + 1;
      } else if (action == "prev" && this.index > 0) {
        this.index = this.index - this.displaySize;
        this.pageNumber = this.pageNumber - 1;
      } else {
        this.index = 0;
        this.pageNumber = 1;
      }
      this.$router.replace({
        name: "Product List PageNumber",
        params: { page: this.pageNumber }
      });
      this.searchItem({ keyword: "" });
    },
    checkShowMessage: async function() {
      if (this.message) {
        try {
          await this.delayFunction();
          this.showMessage = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    delayFunction: async function() {
      setTimeout(function() {
        this.showMessage = false;
        this.message = "";
        return true;
      }, 3000);
    },
    selectedItemActionFunction: function(action) {
      console.log("selectedItemActionFunction", action);
      if (action == "delete") {
        this.showDeletePopup = true;
      } else {
        this.newProductStatus = this.selectedItemAction.find(
          x => x.Key == action
        );
        this.showUpdateStatusPopup = true;
      }
    },
    onclickItem: function(ID) {
      this.$router.push({ name: "Update Product", params: { ProductID: ID } });
    },
    createNewItem: function() {
      this.$router.push({ name: "Create Product" });
    },
    popupcancel: function() {
      this.showDeletePopup = false;
      this.showUpdateStatusPopup = false;
    },
    updateProductStatus: async function() {
      console.log("updateProductStatus");
      this.isLoading = true;
      var object = {
        ProductIDList: this.selectedItem,
        Status: this.newProductStatus.Key
      };
      try {
        const ret = await this.$store.dispatch("updateProductStatus", {
          router: this.$router,
          product: object
        });
        console.log("updateProductStatus", ret);
        await this.delayFunction();
        this.isLoading = false;
        this.showUpdateStatusPopup = false;
        this.message = this.selectedItem.length + " 個產品狀態已更新";
        this.showMessage = true;
        this.searchItem({ keyword: "" });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    deleteSelectedProduct: async function() {
      console.log("delete Product");
      this.isLoading = true;
      for (var i = 0; i < this.selectedItem.length; i++) {
        await this.deleteProduct(this.selectedItem[i]);
      }
      this.message = this.selectedItem.length + " 個產品已刪除";
      try {
        this.showDeletePopup = false;
        await this.delayFunction();
        (this.selectedItem = []), (this.selectedItemCounter = 0);
        this.showMessage = true;
        this.searchItem({ keyword: "" });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    deleteProduct: async function(ProductID) {
      try {
        const ret = await this.$store.dispatch("deleteProduct", {
          router: this.$router,
          product: { ProductID: ProductID }
        });
      } catch (err) {
        console.log("deleteProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    selectSortOption: function(option) {
      console.log("charge sorting with " + option);
      var sortOrder = "";
      var index = this.sortOption.findIndex(sort => sort.Key == option);
      console.log("option", index);
      if (index > -1) {
        this.sortOption[index].Value = true;
        this.sortOption[index].Order =
          this.sortOption[index].Order == "asc" ? "desc" : "asc";

        sortOrder = this.sortOption[index].Order;
        this.Sort[option] = sortOrder;

        this.showSortPopup = false;
        this.searchItem({ keyword: "" });
      }
    },
    selectPageItem: async function(action) {
      console.log("selectPageItem", action);
      console.log(this.TableItemList);

      if (action) {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index == -1) {
            this.selectedItem.push(this.TableItemList[i].ID);
          }
        }
      } else {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index > -1) {
            this.selectedItem.splice(index, 1);
          }
        }
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectAllItem: async function(action, { keyword }) {
      console.log("action", action);
      console.log("keyword", keyword);
      if (!action) {
        this.selectAll = true;
        this.isLoading = true;
        var object = {
          Source: this.Source,
          Filter: this.filter,
          Range: this.range
        };
        if (keyword) {
          object["Keyword"] = keyword;
        }
        try {
          const ret = await this.$store.dispatch("searchProduct", {
            router: this.$router,
            keyword: object
          });
          this.selectedItem = ret.data.data.hits.hits.map(item =>
            String(item._id)
          );
          this.isLoading = false;
        } catch (err) {
          this.formMessage = err.response.data.message;
          this.showFormMessage = true;
        }
      } else {
        this.selectAll = false;
        this.selectedItem = [];
      }
      console.log("after", this.selectedItem);
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectItem: function(ID, action) {
      console.log(ID, action);
      if (action) {
        this.selectedItem.push(ID);
      } else {
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID);
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length;
    },
    searchItem: async function({ keyword }) {
      this.isLoading = true;
      var object = {
        Size: this.displaySize,
        From: this.index,
        Sort: this.Sort,
        Source: this.Source,
        Filter: this.filter,
        Range: this.range
      };
      if (keyword) {
        object["Keyword"] = keyword;
        this.selectAll = false;
        (this.selectedItem = []), (this.selectedItemCounter = 0);
        this.index = 0;
        this.pageNumber = 1;
      }
      try {
        const ret = await this.$store.dispatch("searchProduct", {
          router: this.$router,
          keyword: object
        });
        console.log("searchProduct", ret);
        this.setDisplayList(ret.data.data.hits.hits);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("searchProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },

    setDisplayList(list) {
      console.log("setDisplayList", list);
      this.TableItemList = [];
      for (var i = 0; i < list.length; i++) {
        var object = {
          ID: list[i]["_source"].ProductID,
          產品名稱: list[i]["_source"].Name[this.language],
          類型: list[i]["_source"].ProductType.Name.en,
          價格: list[i]["_source"].MinimumPrice,
          狀態: list[i]["_source"].Status
        };
        if (list[i]["_source"].Image.length > 0) {
          object["img"] =
            process.env.VUE_APP_S3_URL + list[i]["_source"].Image[0].path;
        }
        this.TableItemList.push(object);
      }
      console.log("setDisplayList after", this.TableItemList);
      this.TableSelectUIControl(this.TableItemList);
    },
    TableSelectUIControl(ItemList) {
      this.showNextButton = true;
      if (ItemList.length < this.displaySize) {
        this.showNextButton = false;
      }
      var counter = 0;
      for (var i = 0; i < ItemList.length; i++) {
        var index = this.selectedItem.indexOf(ItemList[i].ID);
        if (index > -1) {
          counter++;
        }
      }
      if (counter == ItemList.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  }
};
</script>
