<template>
  <div>
    <div class="mobile-only">
      <md-toolbar class="mobile-menu md-transparent">
        <md-button
          class="md-icon-button"
          @click="$sidebar.showNavigation = true"
        >
          <md-icon>menu</md-icon>
        </md-button>
      </md-toolbar>

      <md-drawer :md-active.sync="$sidebar.showNavigation" md-swipeable>
        <md-toolbar class="md-transparent" md-elevation="0">
          <div class="logo" style=" padding:20px 0">
            <a class="simple-text logo-normal" style="color: black;">
              {{ title }}
            </a>
          </div>
        </md-toolbar>
        <md-list class="nav">
          <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
          <slot>
            <sidebar-link
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :to="link.path"
              :link="link"
            >
            </sidebar-link>
          </slot>
        </md-list>
        <div class="qrcode logo" @click="gotoQrcodeScanner()">
          <p
            style="font-size: 12px !important; float: unset !important; padding:10px 0;color:black;"
          >
            <md-icon>qr_code_scanner</md-icon> QR Code Scanner
          </p>
        </div>
      </md-drawer>
    </div>
    <div
      class="sidebar sidebarCus desktop-only"
      :data-color="sidebarItemColor"
      :data-image="sidebarBackgroundImage"
      :style="sidebarStyle"
    >
      <div class="logo sidebarCus" style=" padding:20px 0">
        <a class="simple-text logo-mini" href="/">
          <div class="logo-img">
            <img :src="imgLogo" alt="" />
          </div>
        </a>

        <a class="simple-text logo-normal" style="color: black;" href="/">
          {{ title }}
        </a>
      </div>

      <div
        class="sidebar-wrapper sidebarCus"
        style="max-height: 85% ;padding-bottom: 70px;"
      >
        <slot name="content"></slot>
        <md-list class="nav">
          <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
          <slot>
            <sidebar-link
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :to="link.path"
              :link="link"
            >
            </sidebar-link>
          </slot>
        </md-list>
      </div>
      <div class="qrcode logo" @click="gotoQrcodeScanner()">
        <p
          style="font-size: 12px !important; float: unset !important; padding:10px 0"
        >
          <md-icon>qr_code_scanner</md-icon> QR Code Scanner
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink
  },
  props: {
    title: {
      type: String,
      default: "WeSip"
    },
    sidebarBackgroundImage: {
      type: String,
      default: null
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/we_sip_logo.png")
    },
    sidebarItemColor: {
      type: String,
      default: "green",
      validator: value => {
        let acceptedValues = [
          "",
          "purple",
          "blue",
          "green",
          "orange",
          "red",
          "black"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showNavigation: false
  }),
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    gotoQrcodeScanner() {
      this.$router.push({ path: "/qr-scanner" });
      this.$sidebar.showNavigation = false;
    }
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`
      };
    }
  }
};
</script>
<style lang="scss">
.mobile-only {
  display: none !important;
}

.desktop-only {
  display: block !important;
}
.sidebarCus {
  background-color: #fafafa;
  color: black;
}

.qrcode {
  padding: 0 !important;
  margin: 0 !important;
  text-align-last: center;
  color: #fafafa !important;
  /* height: 60px; */
}
.qrcodebutton {
  background: rgba(0, 0, 0, 0.5) !important;
}

.mobile-menu {
  justify-content: flex-start !important;
  box-shadow: none;
}

@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}

@media (max-width: 991px) {
  .mobile-only {
    display: block !important;
  }

  .desktop-only {
    display: none !important;
  }
}
</style>
