<template>
    <div class="content">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
          <FormHeader
            v-on:formAction="showHideTasteNote"
            :HeaderTitle="HeaderTitle"
            :confirmBack="confirmBack"
            :formActionButton="AddNewButton"
          />
          <md-card>
            <md-card-content>
              <TableComponent
                v-on:selectItem="selectItem"
                v-on:selectPageItem="selectPageItem"
                v-on:onclickItem="onclickItem"
                v-on:selectedItemActionFunction="selectedItemActionFunction" 
                :TableList="TableItemList"
                :selectedItemCounter="selectedItemCounter"
                :selectedItem="selectedItem"
                :columns="columns"
                :selectAll="selectAll"
                :selectable="selectable"
                :pageNumber="pageNumber"
                :haveSearchFunction="haveSearchFunction"
                :selectedItemAction="selectedItemAction"
                :showSelectALLString="showSelectALLString"
              />
            </md-card-content>
          </md-card>
          <!-- :AddNewItem="AddNewItem" -->
        </div>
      </div>
      <Transition name="modal" v-if="showConfirmHide">
        <Popup
            v-on:popupcancel="showConfirmHide = false"
            v-on:popupaction="confirmHideTasteNote"
            PopupTitle="確認隱藏"
            :PopupContent="`確認隱藏 ${selectedItemCounter} 個自訂風味？`"
            actionButton="隱藏"
        />
      </Transition>
      <Transition name="modal" v-if="showCreateTasteNote">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="popupclose" @click="showCreateTasteNote=false">
                <md-icon>close</md-icon>
              </div>
              <div class="popup">
                <div class="modal-header">
                  <slot name="header">新增風味</slot>
                </div>
                <div class="modal-body">
                  <TextInput
                    v-for="item in NameInputList"
                    :key="item.key"
                    :Item="item"
                  />
                  <div class="row" style="text-align: center;padding-top: 20px;">
                    <div class="column" style="width:47.5%">
                      <button class="cancelButton" @click="showCreateTasteNote=false">取消</button>
                    </div>
                    <div class="column" style="width:5%"></div>
                    <div class="column" style="width:47.5%">
                      <button class="actionButton" @click="createTasteNote()">
                        確定
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="modal" v-if="showSetKeyword">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="popupclose" @click="showSetKeyword=false">
                <md-icon>close</md-icon>
              </div>
              <div class="popup">
                <div class="modal-header">
                  <slot name="header">設定為關鍵字</slot>
                </div>
                <div class="modal-body">
                  <AutoComplete
                      :item="TasteNoteInput"
                      v-on:checkAutocomplete="searchTasteNotes"
                      v-on:setAutocompleteValue="setTasteNote"
                    />
                  <div class="row" style="text-align: center;padding-top: 20px;">
                    <div class="column" style="width:47.5%">
                      <button class="cancelButton" @click="showSetKeyword=false">取消</button>
                    </div>
                    <div class="column" style="width:5%"></div>
                    <div class="column" style="width:47.5%">
                      <button class="actionButton" @click="updateTasteNoteKeyword()">
                        確定
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Spinner v-if="isLoading" />
      <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
    </div>
  </template>
  
  <script>
  import { 
    TableComponent, 
    FormHeader, 
    Spinner, 
    Popup,
    AutoComplete,
    TextInput
  } from "@/components";
  
  export default {
    components: {
      TableComponent,
      FormHeader,
      Spinner,
      Popup,
      AutoComplete,
      TextInput
    },
    data() {
      return {
        language: "zh",
        showMessage: false,
        message: "",
        showhide: false,
        isLoading: false,
        pageNumber: 1,
        HeaderTitle: "自訂風味列表",
        TableItemList: [],
        NameInputList: [],
        languageList: [],
        AddNewButton: "顯示隱藏",
        columns: ["風味", "數量"],
        selectedItemAction: [
          { Key: "setKeyword", Name: "設定為關鍵字"},
          { Key: "create", Name: "新增為風味"},
          { Key: "hide", Name: "隱藏", Style: "color:#CC2727" }
        ],
        TasteNoteInput: {
          name: "風味",
          value: "",
          ID: "",
          required: true,
          autocompleteList: []
        },
        selectedItem: [],
        selectedItemCounter: 0,
        selectAll: false,
        selectable: true,
        haveSearchFunction: false,
        confirmBack: false,
        showSelectALLString: false,
        showConfirmHide: false,
        showSetKeyword: false,
        showCreateTasteNote: false
      };
    },
    async created() {
        await this.listTemporaryTasteNote();
        await this.getLanguage();
    },
    methods: {
      showHideTasteNote: function(){
        this.showhide = !this.showhide;
        console.log("showhide", this.showhide);
        this.selectedItem = [];
        this.selectedItemCounter = 0;
        this.AddNewButton = this.showhide ? "隱藏" : "顯示隱藏";
        this.selectedItemAction = [];
        if (this.showhide) {
          this.selectedItemAction = [
            { Key: "setKeyword", Name: "設定為關鍵字"},
            { Key: "create", Name: "新增為風味"}
          ];
        } else {
          this.selectedItemAction = [
            { Key: "setKeyword", Name: "設定為關鍵字"},
            { Key: "create", Name: "新增為風味"},
            { Key: "hide", Name: "隱藏", Style: "color:#CC2727" }
          ];
        }
        this.listTemporaryTasteNote(this.showhide);
      },
      confirmHideTasteNote: async function(){
        this.isLoading = true;
        await this.deleteOrHideTasteNote(this.selectedItem, "hide");
        this.isLoading = false;
        this.selectedItem = [];
        this.selectedItemCounter = 0;
        this.showConfirmHide = false;
        this.message = "隱藏成功";
        this.showMessage = true;
        await this.listTemporaryTasteNote();
      },
      updateTasteNoteKeyword: async function (){
        console.log("updateTasteNoteKeyword");
        var object = {
          "TasteNoteID": this.TasteNoteInput.ID,
        }
        var keyword = []
        for(var i in this.selectedItem){
          console.log(this.selectedItem[i]);
          keyword.push(this.selectedItem[i]);
        }
        object["Keyword"] = keyword;
        console.log("object", object);
        this.isLoading = true;
        try{
          const ret = await this.$store.dispatch("updateTasteNote", {
            router: this.$router,
            data: object
          });
          await this.deleteOrHideTasteNote(keyword, "delete");
          /*
            clear selected item
          */
          this.selectedItem = [];
          this.selectedItemCounter = 0;
          this.showSetKeyword = false;
          this.isLoading = false;
          this.message = "設定成功";
          this.showMessage = true;
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.message;
          this.showFormMessage = true;
        }

      },
      searchTasteNotes: async function(keyword) {
        console.log("keyword", keyword);
        var object = {
          "Keyword": keyword
        }
        console.log("object", object);
        try{
          const ret = await this.$store.dispatch("searchTasteNotes", {
            router: this.$router,
            data: object
          });
          console.log("searchTasteNotes", ret.data);
          this.setProductAutocompleteList(ret.data.data.hits.hits);
        } catch (err) {
          this.message = err.response.data.message;
          this.showFormMessage = true;
        }
        return
      },
      setProductAutocompleteList: function(list) {
        this.TasteNoteInput.autocompleteList = [];
        for (var i in list) {
          this.TasteNoteInput.autocompleteList.push({
            Key: list[i]["_source"].TasteNoteID,
            Name: list[i]["_source"].Name[this.language]
          });
        }
      },
      setTasteNote: async function(tastenote) {
        console.log("setTasteNote", tastenote);
        this.TasteNoteInput.ID = tastenote.Key;
      },
      selectedItemActionFunction: async function(action) {
        console.log("selectedItemActionFunction", action);
        switch (action) {
            case 'hide':
                this.showConfirmHide = true;
                break;
            case 'setKeyword':
                this.showSetKeyword = true; 
                break;
            case 'showhide':
                await this.listTemporaryTasteNote();
                break;
            case 'create':
                if (this.selectedItem.length == 1) {
                  for(var i in this.NameInputList){
                    this.NameInputList[i].value = this.selectedItem[0];
                  }
                  this.showCreateTasteNote = true;
                } else {
                  this.message = "只能新增一個風味";
                  this.showMessage = true;
                }
                
                break;
            default:
                break;
        }
      },
      onclickItem: function(ID) {
        /*
        if ID is not in selectedItem, add it to selectedItem
        else remove it from selectedItem
        */
        var index = this.selectedItem.indexOf(ID);
        if (index == -1) {
          this.selectedItem.push(ID);
        } else {
          this.selectedItem.splice(index, 1);
        }
        this.selectedItemCounter = this.selectedItem.length;
      },
      setDisplayList(list) {
        this.TableItemList = [];
        for (var i in list) {
          var object = {
            ID: list[i].TasteNote,
            風味: list[i].TasteNote,
            數量: list[i].count
          };
          this.TableItemList.push(object);
        }
      },
      selectPageItem: async function(action) {
        console.log("selectPageItem", action);
        console.log(this.TableItemList);
  
        if (action) {
          for (var i in this.TableItemList) {
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if (index == -1) {
              this.selectedItem.push(this.TableItemList[i].ID);
            }
          }
        } else {
          for (var i in this.TableItemList) {
            var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
            if (index > -1) {
              this.selectedItem.splice(index, 1);
            }
          }
        }
        this.selectedItemCounter = this.selectedItem.length;
      },
      selectItem: function(ID, action) {
        console.log(ID, action);
        if (action) {
          this.selectedItem.push(ID);
        } else {
          this.selectAll = false;
          var index = this.selectedItem.indexOf(ID);
          this.selectedItem.splice(index, 1);
        }
        console.log(this.selectedItem.length);
        this.selectedItemCounter = this.selectedItem.length;
      },
      createTasteNote: async function(){
        var object = {
          Name: {},
          Keyword: []
        };
        for (var i in this.NameInputList) {
          object.Name[this.NameInputList[i].key] = this.NameInputList[i].value;
        }
        this.isLoading = true;
        try {
          const ret = await this.$store.dispatch("createTasteNote", {
            router: this.$router,
            data: object
          });
          console.log("createTasteNote", ret);
          this.isLoading = false;
          this.showCreateTasteNote = false;
          await this.deleteOrHideTasteNote(this.selectedItem, "delete");
          this.selectedItem = [];
          this.selectedItemCounter = 0;
          await this.listTemporaryTasteNote();
          if(ret.data.result){
            this.message = "新增成功";
            this.showFormMessage = true;
          }else{
            this.message = "風味已存在";
            this.showFormMessage = true;
          }
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.message;
          this.showFormMessage = true;
        }
      },
      deleteOrHideTasteNote: async function(TasteNoteList, action) {
        var object = {
          TasteNote: TasteNoteList,
          action: action
        }
        try {
          const ret = await this.$store.dispatch("deleteOrHideTemporaryTastenote", {
            router: this.$router,
            data: object
          });
          this.listTemporaryTasteNote();
        } catch (err) {
          this.message = err.response.data.message;
          this.showFormMessage = true;
        }
      },
      listTemporaryTasteNote: async function(showHide = false) {
        this.isLoading = true;
        var object = {}
        if(showHide){
          object["ShowHide"] = showHide;
        }
        try {
          const ret = await this.$store.dispatch("listTemporaryTasteNote", {
            router: this.$router,
            data: object
          });
          this.isLoading = false;
          var tasteNoteList = ret.data.tasteNoteList;
          /*
            update tasteNoteList sort by count from high to low
          */
          tasteNoteList.sort(function(a, b) {
            return b.count - a.count;
          });
          this.setDisplayList(tasteNoteList);
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.message;
          this.showFormMessage = true;
        }
      },
      getLanguage: async function() {
        try {
          const ret = await this.$store.dispatch("getLanguage", {
            router: this.$router,
            data: null
          });
          this.languageList = ret.data.Language;
          console.log("getLanguage", this.languageList);
          for(var i in this.languageList){
            for (const [key, value] of Object.entries(this.languageList[i])) {
              this.NameInputList.push({
                key: value,
                name: value,
                value: "",
                required: true
              });
            }
          }
        } catch (err) {
          this.message = err.response.data.message;
          this.showFormMessage = true;
        }
      }
    }
  };
  </script>
  