import auth from "./auth/auth.js";
import product from "./product/product.js";
import location from "./location/location.js";
import event from "./event/event.js";
import blacklist from "./blacklist/blacklist.js";
import attendance from "./attendance/attendance.js";
import payment from "./payment/payment.js";
import survey from "./survey/survey.js";
import customerManagement from "./customerManagement/customerManagement.js";
import couponManagement from "./couponManagement/couponManagement.js";
import userGroupManagement from "./UserGroupManagement/userGroupManagement.js";
import supplier from "./supplier/supplier.js";
import eventHost from "./eventHost/eventHost.js";
import smallPromotionBanner from "./contentManagement/smallPromotionBanner.js";
import bigPromotionBanner from "./contentManagement/bigPromotionBanner.js";
import newsletterSubscription from "./contentManagement/newsletterSubscription.js";
import inspirationCM from "./contentManagement/inspiration.js";
import surveyLanding from "./contentManagement/surveyLanding.js";
import newInSection from "./contentManagement/newInSection.js";
import footerContent from "./contentManagement/footerContent.js";
import navigationMenu from "./contentManagement/navigationMenu.js";
import faq from "./contentManagement/FAQ.js";
import seo from "./contentManagement/SEO.js";
import inspiration from "./inspiration/inspiration.js";
import Inventory from "./Inventory/Inventory.js";
import crm from "./CRM/crm.js";
import edm from "./edm/edm.js";
import contentManagement from "./contentManagement/contentManagement.js";
import upload from "./upload/upload.js";
import table from "./table/table.js";
import analytics from "./analytics/analytics.js";
import tasteCard from "./inspiration/tasteCard.js";
import router from "./router/router.js";

const api = {
  ...auth,
  ...product,
  ...location,
  ...event,
  ...blacklist,
  ...attendance,
  ...payment,
  ...survey,
  ...customerManagement,
  ...userGroupManagement,
  ...couponManagement,
  ...supplier,
  ...eventHost,
  ...smallPromotionBanner,
  ...bigPromotionBanner,
  ...newsletterSubscription,
  ...inspirationCM,
  ...surveyLanding,
  ...newInSection,
  ...footerContent,
  ...navigationMenu,
  ...edm,
  ...inspiration,
  ...Inventory,
  ...faq,
  ...crm,
  ...seo,
  ...contentManagement,
  ...upload,
  ...table,
  ...analytics,
  ...router,
  ...tasteCard
};

export default api;
