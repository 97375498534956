<template>
  <div>
    <div class="collapsible">
      <input type="checkbox" style="display:none" :id="parameterItem.Key" />
      <div class="row unsetMargin" @click="expandItem(parameterItem.Key)">
        <div class="explorIcon">
          <md-icon :id="parameterItem.Key + icon" style="color: white;"
            >expand_less</md-icon
          >
        </div>
        <div class="col explorTitle">
          <label>{{ parameterItem.Key }}</label>
        </div>
        <div class="row" style="margin: auto;">
          <div class="col" @click="diplicateParameter()">
            <md-icon class="iconButton">file_copy</md-icon>
          </div>
          <div class="col" @click="delParameter()">
            <md-icon class="iconButton" style="color:#cc2727">
              delete
            </md-icon>
          </div>
        </div>
      </div>
      <div class="collapsible-item" :id="parameterItem.Key + content">
        <md-card>
          <md-card-content>
            <SelectInput v-on:selected="TypeSelected" :Item="TypeInput" />
            <TextInput v-on:checkEdited="KeyUpdated" :Item="KeyInput" />
            <LanguageObjectInput
              v-on:checkEdited="NameUpdated"
              :Item="NameInput"
            />
            <label>圖示</label>
            <FileInput
              v-on:RemoveItem="RemoveItem"
              :Item="IconInput"
              :acceptType="acceptType"
            />
            <TextInput v-on:checkEdited="UnitUpdated" :Item="UnitInput" />
            <div v-if="TypeInput.value == 'Option'">
              <label>選項</label>
              <table>
                <tr>
                  <th>
                    <label>選項Key</label>
                  </th>
                  <th>
                    <label>選項名稱</label>
                  </th>
                  <th>
                    <label>刪除</label>
                  </th>
                </tr>
                <tr v-for="option in OptionInputList" :key="option.key.key">
                  <td>
                    <TextInput
                      v-on:checkEdited="OptionUpdated"
                      :Item="option.key"
                    />
                  </td>
                  <td style="width: 50%;">
                    <LanguageObjectInput
                      v-on:checkEdited="OptionUpdated"
                      :Item="option.option"
                    />
                  </td>
                  <td>
                    <md-button
                      class="md-icon-button  md-accent"
                      @click="removeOption(option.key.key)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </td>
                </tr>
              </table>
              <md-card-content>
                <div style="text-align: center;" @click="addOption()">
                  <md-icon>add_circle_outline</md-icon>
                  <u class="addQuestion">選項</u>
                </div>
              </md-card-content>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SelectInput,
  TextInput,
  LanguageObjectInput,
  FileInput
} from "@/components";

export default {
  components: {
    SelectInput,
    TextInput,
    LanguageObjectInput,
    FileInput
  },
  props: {
    parameterItem: {
      default() {
        return {};
      }
    },
    languageList: {
      default() {
        return [];
      }
    }
  },
  created() {
    this.TypeInput.value = this.parameterItem.Type;
    this.KeyInput.value = this.parameterItem.Key;
  },
  mounted() {
    for (var i in this.languageList) {
      var language = this.languageList[i].Language;
      this.NameInput.value[language] = this.parameterItem.Name[language];
      this.NameInput.model +=
        language + ":" + this.parameterItem.Name[language] + " / ";
    }
    this.NameInput.model = this.NameInput.model.slice(0, -2);

    if (this.parameterItem.Unit) {
      this.UnitInput.value = this.parameterItem.Unit;
    }
    if (this.parameterItem.Type == "Option") {
      for (var i in this.parameterItem.Option) {
        var option = this.parameterItem.Option[i];
        console.log("option", option);
        var OptionObject = {
          key: option.Key,
          model: "",
          value: option.Name
        };
        for (var i in this.languageList) {
          OptionObject.model +=
            this.languageList[i].Language +
            ":" +
            option.Name[this.languageList[i].Language] +
            " / ";
        }
        OptionObject.model = OptionObject.model.slice(0, -2);
        var KeyOption = {
          key: option.Key,
          name: "",
          required: true,
          value: option.Key
        };
        this.OptionInputList.push({
          key: KeyOption,
          option: OptionObject
        });
      }
    }
    this.expandItem(this.parameterItem.Key);
  },
  methods: {
    RemoveItem: async function(ID, deleteAction) {
      console.log("RemoveItem", ID);
      console.log("deleteAction", deleteAction);
    },
    delParameter() {
      this.$emit("delParameter", this.parameterItem.Key);
    },
    diplicateParameter() {
      this.$emit("diplicateParameter", this.parameterItem.Key);
    },
    addOption() {
      var key = this.OptionInputList.length;
      var OptionObject = {
        key: {
          key: key,
          name: "",
          required: true,
          value: ""
        },
        option: {
          key: key,
          model: "",
          value: {}
        }
      };
      for (var i in this.languageList) {
        OptionObject.option.value[this.languageList[i].Language] = "";
      }
      this.OptionInputList.push(OptionObject);
      this.OptionUpdated();
    },
    removeOption: function(key) {
      for (var i in this.OptionInputList) {
        if (this.OptionInputList[i].key.key == key) {
          this.OptionInputList.splice(i, 1);
          break;
        }
      }
      this.OptionUpdated();
    },
    OptionUpdated: function() {
      console.log("parameterItem", this.parameterItem.Option);
      this.parameterItem.Option = [];
      for (var i in this.OptionInputList) {
        var option = this.OptionInputList[i];
        console.log("option", option);
        var object = {
          Key: option.key.value,
          Name: option.option.value
        };
        this.parameterItem.Option.push(object);
      }
      console.log("parameterItem", this.parameterItem);
    },
    UnitUpdated: function() {
      this.parameterItem.Unit = this.UnitInput.value;
      console.log("parameterItem", this.parameterItem);
    },
    NameUpdated: function() {
      this.parameterItem.Name = this.NameInput.value;
      console.log("parameterItem", this.parameterItem);
    },
    KeyUpdated: function() {
      this.parameterItem.Key = this.KeyInput.value;
      console.log("parameterItem", this.parameterItem);
    },
    TypeSelected: function() {
      this.parameterItem.Type = this.TypeInput.value;
      console.log("TypeSelected");
    },
    expandItem: function(element) {
      let iconClass = document.getElementById(element + this.icon);
      let contentClass = document.getElementById(element + this.content);
      if (document.getElementById(element).checked) {
        iconClass.classList.remove("iconup");
        iconClass.classList.add("icondown");
      } else {
        iconClass.classList.remove("icondown");
        iconClass.classList.add("iconup");
      }
      document.getElementById(element).checked = !document.getElementById(
        element
      ).checked;
      if (document.getElementById(element).checked) {
        contentClass.style.minHeight = contentClass.scrollHeight + "px";
        contentClass.style.maxHeight = contentClass.scrollHeight + "px";
      } else {
        contentClass.style.maxHeight = "0px";
        contentClass.style.minHeight = "0px";
      }
    }
  },
  data() {
    return {
      icon: "icon",
      content: "content",
      acceptType: "image/*",
      TypeInput: {
        name: "參數類型",
        key: "Type",
        required: true,
        value: "",
        option: [
          { key: "Text", name: "文字" },
          { key: "Option", name: "選項" },
          { key: "Time", name: "時間" }
        ]
      },
      KeyInput: {
        name: "Key (不能重複, 不能有空格)",
        key: "Key",
        value: "",
        required: true
      },
      NameInput: {
        name: "參數名稱",
        key: "Name",
        required: true,
        model: "",
        value: {}
      },
      UnitInput: {
        name: "單位",
        key: "Unit",
        required: false,
        value: ""
      },
      OptionInputList: [],
      IconInput: {
        ID: "",
        url: ""
      }
    };
  }
};
</script>