<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createEventType"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <center>
          <md-card style="width: 50%;text-align: left;">
            <md-card-content>
              <LanguageObjectInput :Item="eventTypeInput" />
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, LanguageObjectInput } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    LanguageObjectInput
  },
  async created() {
    await this.getLanguage();
  },
  methods: {
    createEventType: async function() {
      var object = {
        Name: this.eventTypeInput.value
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createEventType", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        this.formMessage = "更新成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.replace({ name: "EventTypeList" });
        }, 2000);
      } catch (err) {
        console.log("createEventType error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },

    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        for (var i in this.languageList) {
          this.eventTypeInput.value[this.languageList[i].Language] = "";
        }
        this.eventTypeInput.model = model.slice(0, -2);
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      EventTypeID: "",
      language: "zh",
      formActionButton: "儲存",
      HeaderTitle: "新增活動類型",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      deleteContent: "",
      deleteLanguageID: "",
      formMessage: "",
      languageList: [],
      Name: {},
      eventTypeInput: {
        name: "類型名稱",
        key: "Name",
        required: true,
        model: "",
        value: {}
      }
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
