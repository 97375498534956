import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions/api";
import routerModule from "./routerHandler";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    router: routerModule
  },
  state: {
    status: "",
    accessToken: localStorage.getItem("accessToken") || "",
    idToken: localStorage.getItem("idToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    errorMsg: ""
  },
  mutations: {
    auth_request: state => {
      state.status = "loading";
    },
    auth_success: (state, { idToken, accessToken, refreshToken }) => {
      state.status = "success";
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
    },
    auth_error: state => {
      state.status = "error";
    },
    logout: state => {
      state.status = "";
      state.accessToken = "";
      state.idToken = "";
      state.refreshToken = "";
    },
    action_request: state => {
      state.status = "loading";
    },
    action_success: state => {
      state.status = "success";
    },
    action_error: (state, { errorMsg }) => {
      state.status = "error";
      state.errorMsg = errorMsg;
    }
  },
  actions: actions,
  getters: {
    isLoggedIn: state =>
      !!state.refreshToken && state.refreshToken !== undefined,
    refreshToken: state => state.refreshToken,
    authStatus: state => state.status
  }
});
