import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const analytics = {
  searchUserActivity({ commit, state }, { router, keyword }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/AdminSearchUserActivity",
      keyword
    );
  },
  analysisProductOrder({commit,state},{router,data}){
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-analysis-product-order",
      data
    );
  }
};

export default analytics;
