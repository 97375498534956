<template>
  <md-card class="md-card-nav-tabs">
    <md-card-media>
      <img
        :src="image"
        alt="product.Name"
        :onerror="
          `this.onerror=null; this.src=&quot;${require('@/assets/img/we_sip_logo.png')}&quot;`
        "
      />
    </md-card-media>
    <md-card-header>
      <div>{{ product.Name }}</div>
      <div class="md-subhead"></div>
    </md-card-header>
    <md-card-content>
      <slot name="content">Producer: {{ product.NameOfProducer }}</slot>
      <br />
      <slot name="content">Year: {{ product.YearOfProduction }}</slot>
      <br />
      <slot name="content">Price: $ {{ product.UnitPrice }} HKD</slot>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "product-card",
  props: ["productID"],
  data: () => ({
    product: {
      Image: null,
      Name: null
    },
    loading: false,
    image: null
  }),
  methods: {
    getProduct: async function(productID) {
      this.loading = true;
      this.$store
        .dispatch("getProduct", {
          router: this.$router,
          product: { ProductID: productID }
        })
        .then(ret => {
          console.log(this.product);
          this.loading = false;
          this.product = ret.data.data.Item;
          this.image =
            process.env.VUE_APP_S3_URL + "product/" + this.product.ProductID;
        })
        .catch(err => {
          this.loading = false;
          console.log(err.message);
        });
    }
  },
  created() {
    if (this.productID) this.getProduct(this.productID);
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-title {
  font-size: 18px;
  line-height: 26px;
}
.md-card .md-card-header {
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
  box-shadow: none;
  text-align: center;
}
</style>
