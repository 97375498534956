<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createCountry"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <center>
          <md-card style="width: 50%;text-align: left;">
            <md-card-content>
              <TextInput
                v-for="item in NameInputList"
                :key="item.key"
                :Item="item"
              />
              <div class="row">
                <TextInput :Item="Alpha2Input" style="width: 50%" />
                <TextInput :Item="Alpha3Input" style="width: 50%" />
              </div>
              <div class="row">
                <TextInput :Item="CountryCodeInput" style="width: 50%" />
                <TextInput :Item="NumericInput" style="width: 50%" />
              </div>
              <SelectInput :Item="AcceptCurrencyInput" style="width: 50%" />
              <div v-if="AcceptCurrencyInput.value == 1">
                <TextInput :Item="CurrencyInput" style="width: 50%;" />
              </div>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, TextInput, SelectInput } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput,
    SelectInput
  },
  async created() {
    await this.getLanguage();
  },
  methods: {
    async createCountry() {
      var object = {
        Name: {},
        Alpha2: this.Alpha2Input.value,
        Alpha3: this.Alpha3Input.value,
        CountryCode: this.CountryCodeInput.value,
        Numeric: this.NumericInput.value,
        AcceptCurrency: this.AcceptCurrencyInput.value
      };
      for (var i in this.NameInputList) {
        object.Name[this.NameInputList[i].key] = this.NameInputList[i].value;
      }
      if (this.AcceptCurrencyInput.value == 1) {
        if (this.CurrencyInput.value == "") {
          this.formMessage = "請輸入貨幣";
          this.showFormMessage = true;
          return;
        }
        object.Currency = this.CurrencyInput.value;
      }
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createCountry", {
          router: this.$router,
          data: object
        });
        console.log("createCountry", ret);
        this.isLoading = false;
        this.formMessage = "新增成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    /*
        api
    */
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        for (var i in this.languageList) {
          for (const [key, value] of Object.entries(this.languageList[i])) {
            this.NameInputList.push({
              key: value,
              name: value,
              value: this.Name[value],
              required: true
            });
          }
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "新增國家/地區",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      formMessage: "",
      Name: {},
      languageList: [],
      NameInputList: [],
      Alpha2Input: {
        key: "Alpha2",
        name: "Alpha2",
        value: "",
        required: true
      },
      Alpha3Input: {
        key: "Alpha3",
        name: "Alpha3",
        value: "",
        required: true
      },
      CountryCodeInput: {
        key: "CountryCode",
        name: "Country Code",
        value: "",
        required: true
      },
      NumericInput: {
        key: "Numeric",
        name: "Numeric",
        value: "",
        required: true
      },
      AcceptCurrencyInput: {
        key: "AcceptCurrency",
        name: "收款貨幣",
        value: 0,
        required: true,
        option: [
          {
            key: 0,
            name: "否"
          },
          {
            key: 1,
            name: "是"
          }
        ]
      },
      CurrencyInput: {
        key: "Currency",
        name: "貨幣",
        value: "",
        required: true
      }
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
