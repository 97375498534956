<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <div>
          <md-card>
            <md-card-content>
              <md-field>
                <md-file :accept="acceptType" @md-change="selectFile($event)" />
              </md-field>
            </md-card-content>
          </md-card>
          <md-card v-if="showExcel">
            <md-card-content>
              <table>
                <tr v-for="(item, key) in TasteNoteExcel" :key="key">
                  <td v-for="(subItem, subKey) in item" :key="subKey">
                    {{ subItem }}
                  </td>
                </tr>
              </table>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">
      {{ formMessage }}
    </md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner } from "@/components";
import * as XLSX from "xlsx";
export default {
  components: {
    FormHeader,
    Spinner
  },
  methods: {
    selectFile(fileList) {
      console.log("fileList", fileList);
      if (fileList.constructor === FileList && fileList[0] !== undefined) {
        var file = fileList[0];
        const reader = new FileReader();
        reader.onload = async event => {
          const data = event.target.result;
          console.log("data", data);
          const workbook = XLSX.read(data, { type: "binary" });
          console.log("workbook", workbook);
          var languageSheet = workbook.Sheets["Language"];
          console.log("languageSheet", languageSheet);
          this.languageList = [];
          for (var [key, value] of Object.entries(languageSheet)) {
            if (value.v) {
              this.languageList.push(value.v);
            }
          }
          console.log("languageList", this.languageList);
          //var languageJson = XLSX.utils.sheet_to_json(languageSheet);
          var TasteNoteSheet = workbook.Sheets["TasteNote"];
          console.log("TasteNoteSheet", TasteNoteSheet);
          this.TasteNoteExcel = XLSX.utils.sheet_to_json(TasteNoteSheet, {
            header: 1,
            blankrows: false
          });
          for (var i in this.TasteNoteExcel) {
            for (var d in this.TasteNoteExcel[i]) {
              this.TasteNoteExcel[i][d] = this.TasteNoteExcel[i][d]
                .trim()
                .replace(/\s*,\s*/g, ",");
            }
          }
          console.log("TasteNoteExcel", this.TasteNoteExcel);

          var KeywordSheet = workbook.Sheets["Keyword"];
          console.log("KeywordSheet", KeywordSheet);
          var KeywordExcel = XLSX.utils.sheet_to_json(KeywordSheet, {
            header: 1,
            blankrows: false
          });
          console.log("KeywordExcel", KeywordExcel);
          for (var i in KeywordExcel) {
            this.KeywordList.push({
              TasteNoteName: KeywordExcel[i][0],
              Keyword: KeywordExcel[i][1].split(",")
            });
          }
          console.log("KeywordList", this.KeywordList);
          this.showExcel = true;
        };
        reader.readAsBinaryString(file);
      }
    },
    async findOrCreateTasteNote(TasteNoteName, Language) {
      var nameList = TasteNoteName.split(",");
      /*
        remove the start and end space
      */
      for (var i in nameList) {
        nameList[i] = nameList[i];
      }
      var nameObject = {};
      var keyword = [];
      for (var i in Language) {
        nameObject[Language[i]] = nameList[i];
      }
      for (var i in nameList) {
        keyword.push(nameList[i]);
      }
      for (var i in this.KeywordList) {
        if (this.KeywordList[i].TasteNoteName == TasteNoteName) {
          keyword = keyword.concat(this.KeywordList[i].Keyword);
        }
      }
      var object = {
        Name: nameObject,
        Keyword: keyword
      };
      console.log("object", object);
      try {
        const ret = await this.$store.dispatch("createTasteNote", {
          router: this.$router,
          data: object
        });
        console.log("createTasteNote", ret);
        return ret.data.TasteNoteID;
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    async createRelation(TasteNoteID, Parent) {
      var object = {
        TasteNoteID: TasteNoteID,
        Parent: Parent
      };
      try {
        const ret = await this.$store.dispatch("setTasteNoteRelation", {
          router: this.$router,
          data: object
        });
        console.log("setTasteNoteRelation", ret);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    removeDuplicates(array) {
      return array.filter((item, index) => {
        return (
          index ===
          array.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item))
        );
      });
    },
    async formAction() {
      var TasteNoteNameList = [];
      var TasteNoteRelationList = [];
      /*
        add all the name to the list
      */
      for (var i in this.TasteNoteExcel) {
        /*
          this.TasteNoteExcel[i] add all to TasteNoteNameList
        */
        for (var j in this.TasteNoteExcel[i]) {
          TasteNoteNameList.push(this.TasteNoteExcel[i][j]);
          if (j == 0) {
            TasteNoteRelationList.push({
              TasteNote: this.TasteNoteExcel[i][j],
              Parent: "Root"
            });
          } else {
            TasteNoteRelationList.push({
              TasteNote: this.TasteNoteExcel[i][j],
              Parent: this.TasteNoteExcel[i][j - 1]
            });
          }
        }
        TasteNoteNameList = [...new Set(TasteNoteNameList)];
      }
      /*
        remove duplicate from TasteNoteRelationList
      */
      var uniqueRelationList = this.removeDuplicates(TasteNoteRelationList);
      var TasteNoteListWithID = [];
      this.isLoading = true;
      this.formMessage = "正在新成風味";
      this.showFormMessage = true;
      for (var i in TasteNoteNameList) {
        var TasteNoteID = await this.findOrCreateTasteNote(
          TasteNoteNameList[i],
          this.languageList
        );
        TasteNoteListWithID.push({
          ExcelName: TasteNoteNameList[i],
          TasteNoteID: TasteNoteID
        });
      }
      console.log("TasteNoteListWithID", TasteNoteListWithID);
      var relationIDList = [];
      for (var i in uniqueRelationList) {
        var Parent = "";
        if (uniqueRelationList[i].Parent == "Root") {
          Parent = "Root";
        } else {
          Parent = TasteNoteListWithID.find(
            item => item.ExcelName == uniqueRelationList[i].Parent
          ).TasteNoteID;
        }
        var TasteNoteID = TasteNoteListWithID.find(
          item => item.ExcelName == uniqueRelationList[i].TasteNote
        ).TasteNoteID;
        relationIDList.push({
          TasteNoteID: TasteNoteID,
          Parent: Parent
        });
      }
      this.formMessage = "正在設定關係";
      this.showFormMessage = true;
      console.log("relationIDList", relationIDList);
      for (var i in relationIDList) {
        await this.createRelation(
          relationIDList[i].TasteNoteID,
          relationIDList[i].Parent
        );
      }
      this.formMessage = "匯入成功";
      this.showFormMessage = true;
      this.isLoading = false;
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "匯入風味",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      showExcel: false,
      formMessage: "",
      Name: {},
      languageList: [],
      TasteNoteExcel: [],
      KeywordList: [],
      acceptType:
        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    };
  }
};
</script>