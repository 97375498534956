<template lang="html">
  <transition name="anim-slide" mode="out-in">
    <div
      class="sheet-background"
      v-show="isActive"
      :style="cssVars"
      @click.self="collapse()"
    >
      <div class="sheet-container">
        <div class="title-bar" @click="collapse()">
          <h2 v-if="title">{{ title }}</h2>
          <md-button class="md-icon-button no-margin">
            <md-icon>expand_less</md-icon>
          </md-button>
        </div>

        <div class="sheet-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BottomSheet",
  props: {
    title: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  computed: {
    cssVars() {
      return {
        "--height-value": this.height + "px"
      };
    }
  },
  methods: {
    expand() {
      this.isActive = true;
    },
    collapse() {
      this.isActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
// == anim ==
.anim-slide-enter-active,
.anim-slide-leave-active {
  transition: opacity 0.25s;
}

.anim-slide-enter,
.anim-slide-leave-to {
  opacity: 0;
}

.anim-slide-enter-to,
.anim-slide-leave {
  opacity: 1;
}

.anim-slide-enter-active .sheet-container,
.anim-slide-leave-active .sheet-container {
  transition: transform 0.25s;
}

.anim-slide-enter .sheet-container,
.anim-slide-leave-to .sheet-container {
  transform: translateY(100%);
}

.anim-slide-enter-to .sheet-container,
.anim-slide-leave .sheet-container {
  transform: translateY(0);
}

.sheet-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10;
}

.sheet-container {
  width: 100%;
  border-radius: 10px 10px 0 0;

  height: 100%;
  max-height: var(--height-value);

  background: #0c0206;
  color: #ffffff;

  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.md-button {
  background-color: transparent !important;
  box-shadow: none;
}
.md-layout {
  margin: none;
}
.title-bar {
  position: relative;
  flex-grow: 0;

  cursor: pointer;

  > h2 {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
    padding: 0.8em;

    text-align: center;
  }

  > .md-icon-button {
    position: absolute;
    z-index: 5;
    top: 5px;
    right: 5px;

    .md-icon {
      transform: rotate(180deg);
      color: #ffffff !important;
    }
  }
  .md-icon-button:hover {
    background: rgba(#f4f4f4, 0.1) !important;
  }
}

.sheet-content {
  flex-grow: 1;

  padding: 10px 5px;
}
</style>
