<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <FormHeader
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="null"
        />
        <center>
          <div v-for="(survey, key) in SurveyList" :key="key">
            <md-card class="surveyArea">
              <md-card-content>
                <div @click="goSurveyResultDetail(survey.SurveyID)">
                  <label v-if="survey.SurveyType == 'EventProduct'">
                    Product Survey
                  </label>
                  <label v-else> Event Survey </label>
                  <br />
                  <h4 class="surveyTitle">{{ survey.Name }}</h4>
                  <div v-if="survey.SurveyType == 'EventProduct'">
                    <label
                      v-for="(ParameterValue, ParameterKey) in survey.Parameter"
                      :key="ParameterKey"
                    >
                      {{ ParameterValue.value }}
                    </label>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import { FormHeader, Spinner } from "@/components";

export default {
  components: {
    FormHeader,
    Spinner
  },
  data() {
    return {
      HeaderTitle: "Survey Result",
      confirmBack: false,
      isLoading: false,
      showFormMessage: false,
      formMessage: "",
      showAddSurvey: false,
      SurveyList: []
    };
  },
  async created() {
    this.isLoading = true;

    await this.getEventSurveyList(this.$route.params.eventID);
    this.isLoading = false;
  },
  methods: {
    goSurveyResultDetail(surveyID) {
      //this.$router.push({name: 'Survey Result', params: {eventID: this.$route.params.eventID, varianceID: this.$route.params.varianceID}})
      this.$router.push({
        name: "Survey Result Detail",
        params: {
          eventID: this.$route.params.eventID,
          varianceID: this.$route.params.varianceID,
          surveyID: surveyID
        }
      });
    },
    checkEdited: function(check) {
      this.confirmBack = check;
    },
    async getEventSurveyList(eventID) {
      try {
        const ret = await this.$store.dispatch("listEventSurvey", {
          router: this.$router,
          data: { EventID: eventID }
        });
        this.SurveyList = ret.data.SurveyList;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style>
.AddSurveyArea {
  margin: 10px 0 !important ;
  width: 60% !important;
  cursor: pointer;
}
.AddSurveyArea label {
  font-size: 18px !important;
  font-weight: 400;
}
.surveyArea {
  margin: 10px 0 !important ;
  width: 60% !important;
  text-align: left !important;
  min-height: 120px !important;
  cursor: pointer;
}
.surveyTitle {
  font-size: 25px !important;
  margin-top: 5px;
  font-weight: 500;
}
.arrowIcon {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
</style>
