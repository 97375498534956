<template>
  <div>
    <label>{{ Item.name }}</label
    ><label style="color:red" v-if="Item.required">*</label>
    <md-field style="margin-top:0px;padding-top:5px">
      <md-input
        type="number"
        :value="Item.value"
        class="formInput"
        v-model="Item.value"
        @change="checkEdited"
      />
    </md-field>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    checkEdited: function() {
      this.$emit("checkEdited", true);
    }
  },
  name: "number-input-component",
  props: {
    Item: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
<style></style>
