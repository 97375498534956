<template>
  <div class="container">
    <ToolsBar class="item left-bar" />
    <SurveyArea class="item main-area" />
    <PropertiesBar class="item right-bar" />
  </div>
</template>

<script>
import ToolsBar from "./components/wysiwyg/ToolsBar.vue";
import PropertiesBar from "./components/wysiwyg/PropertiesBar.vue";
import SurveyArea from "./components/wysiwyg/SurveyArea.vue";
export default {
  name: "wysiwyg-survey-template-builder",
  components: {
    ToolsBar,
    PropertiesBar,
    SurveyArea
  }
};
</script>

<style lang="scss" scoped>
.container {
  background-color: red;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
.item {
  margin: auto;
}
.left-bar {
  flex: 1;
}
.right-bar {
  flex: 1;
}
.main-area {
  flex: 3;
}
</style>
