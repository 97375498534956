<template>
  <div class="white-bg full-height"></div>
</template>
<script>
export default {
  name: "survey-properties-bar"
};
</script>

<style lang="scss" scoped>
.white-bg {
  background-color: white;
}
.full-height {
  height: 100%;
}
</style>
