<template>
  <div>
    <label>{{ Item.name }}</label>
    <label style="color:red" v-if="Item.required">*</label>
    <label v-if="Item.hits">{{ Item.hits }}</label>
    <md-field style="margin-top:0px;padding-top:6px">
      <md-select
        v-model="Item.value"
        class="formInput"
        :placeholder="Item.name"
        :readonly="readonly"
        @md-selected="selected"
      >
        <md-option
          v-for="option in Item.option"
          :key="option.key"
          :value="option.key"
          >{{ option.name }}</md-option
        >
      </md-select>
    </md-field>
  </div>
</template>
<script>
export default {
  name: "select-input-component",
  props: {
    Item: {
      default() {
        return {};
      }
    },
    readonly: {
      default: false
    }
  },
  methods: {
    selected: function(value) {
      this.$emit("selected", value);
    }
  }
};
</script>
<style>
.md-menu-content {
  width: auto !important;
  max-width: 500px !important;
}
</style>
