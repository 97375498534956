<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:45%;">
            <md-card style="margin:10px 0;">
              <md-card-content>
                <h3 class="formTitle">{{ Form1Title }}</h3>
                <LanguageObjectInput
                  v-on:checkEdited="checkEdited"
                  :Item="ProductNameInput"
                />

                <div class="row" style="padding: 10px 0">
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="SupplierInput"
                    />
                  </div>
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="StatusInput"
                    />
                  </div>
                </div>
                <CountryInput
                  v-on:checkEdited="checkEdited"
                  :languageList="languageList"
                  :Item="CountryInput"
                />
                <div>
                  <label>產品圖片</label>
                  <draggable :list="ProductImageInput" :group="{ name: 'g1' }">
                    <div v-for="Item in ProductImageInput" :key="Item.ID">
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        :Item="Item"
                        :acceptType="acceptType"
                      />
                    </div>
                  </draggable>
                  <div
                    style="text-align: center;"
                    @click="addProductImageInput()"
                  >
                    <md-icon>add_circle_outline</md-icon>
                    <u class="addLang">增加圖片</u>
                  </div>
                  <div v-if="!noTasteCard" >
                    <div>
                      <label>Taste Card 背景圖片</label>
                      <label style="color:red">*</label>
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        :Item="TasteCardImage"
                        :acceptType="acceptType"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
            <md-card style="margin:10px 0;">
              <md-card-content>
                <div style="text-align:left">
                  <div class="row">
                    <div style="width:100%">
                      <h3 class="formTitle">產品詳情</h3>
                    </div>
                    <div style="padding:20px 20px 0px 20px;width:100%">
                      <label for="product-type">產品類型</label><br />
                      {{ ProductType }}
                    </div>
                  </div>
                  <div v-if="!noTasteCard" style="padding-top:25px">
                    <SelectInput
                      v-for="(item, key) in SeriesInputList"
                      :key="key"
                      style="width: 50%;"
                      v-on:checkEdited="checkEdited"
                      :Item="item"
                    />
                    <TasteNotesInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="TasteNotesInput"
                    />
                    <AromaInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="AromaInput"
                    />
                    <ParingTagInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="ParingTagsInput"
                    />
                  </div>
                  <div class="row" style="padding-top:25px">
                    <div
                      class="column inputcolumn"
                      v-for="(field, key) in ProductInfoInputList"
                      :key="key"
                    >
                      <LanguageObjectInput
                        v-if="field.type == 'object'"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      />
                      <NumberInput
                        v-if="field.type == 'number'"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      >
                      </NumberInput>
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:55%;padding-left: 30px;">
            <variance
              v-on:renewInfo="getProductInfo"
              :VarianceItemList="TableItemList"
            />
            <tastecard v-if="ProductType != 'Tools'" />
          </div>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDeleteMedia">
      <Popup
        v-on:popupcancel="showConfirmDeleteMedia = false"
        v-on:popupaction="deleteMedia"
        PopupTitle="刪除圖片"
        :PopupContent="PopupContent"
        actionButton="刪除"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import {
  FormHeader,
  LanguageObjectInput,
  SelectInput,
  CountryInput,
  FileInput,
  TasteNotesInput,
  AromaInput,
  NumberInput,
  Popup,
  ParingTagInput,
  Spinner
} from "@/components";
import draggable from "vuedraggable";
import variance from "./components/variance.vue";
import tastecard from "./components/tastecard.vue";
export default {
  components: {
    FormHeader,
    LanguageObjectInput,
    SelectInput,
    CountryInput,
    FileInput,
    draggable,
    TasteNotesInput,
    AromaInput,
    NumberInput,
    Popup,
    ParingTagInput,
    variance,
    tastecard,
    Spinner
  },
  async created() {
    this.isLoading = true;
    this.listSeries();
    await this.getLanguage();
    await this.getSuppliers();
    
    await this.getProductInfo();
    this.isLoading = false;
  },
  methods: {
    /*
      Image function
    */
    RemoveItem: async function(ID, deleteAction) {
      console.log("RemoveItem", ID);
      console.log("deleteAction", deleteAction);
      var index = this.ProductImageInput.findIndex(x => x.ID == ID);
      if (deleteAction) {
        this.deleteMediaID = ID;
        this.deleteMediaType = "Product";
        this.PopupContent = "是否確定刪除此產品圖片?";
        if (index < 0) {
          this.deleteMediaType = "TasteCard";
          this.PopupContent = "是否確定刪除TasteCard圖片?";
        }
        this.showConfirmDeleteMedia = true;
      } else {
        if (index >= 0) {
          this.ProductImageInput.splice(index, 1);
        }
      }
    },
    addProductImageInput: function() {
      var ID = new Date().getTime();
      this.ProductImageInput.push({
        ID: ID,
        url: ""
      });
    },
    deleteMedia: async function() {
      var MediaID = this.deleteMediaID;
      if (this.deleteMediaType == "Product") {
        var result = this.deleteProductImage(MediaID);
        if (result) {
          var index = this.ProductImageInput.findIndex(x => x.ID == MediaID);
          this.ProductImageInput.splice(index, 1);
        }
      } else {
        var result = await this.deleteTasteCardImage();
        if (result) {
          this.TasteCardImage.url = "";
        }
      }
      this.showConfirmDeleteMedia = false;
    },
    deleteProductImage: async function(ID) {
      console.log("deleteProductImage", ID);
      var object = {
        ImageID: ID,
        ProductID: this.$route.params.ProductID
      };
      this.isLoading = true;
      try {
        await this.$store.dispatch("deleteProductImage", {
          router: this.$router,
          data: object
        });
        this.isLoading = false;
        return true;
      } catch (err) {
        this.isLoading = false;
        console.log("deleteMedia error", err);
        return false;
      }
    },
    deleteTasteCardImage: async function(ID) {
      console.log("deleteTasteCardImage");
      this.isLoading = true;
      try {
        await this.$store.dispatch("deleteTasteCardImage", {
          router: this.$router,
          data: { ProductID: this.$route.params.ProductID }
        });
        this.isLoading = false;
        return true;
      } catch (err) {
        this.isLoading = false;
        console.log("deleteTasteCardImage error", err);
        return false;
      }
    },
    /*
      api call function
    */
    formAction: async function() {
      await this.updateProduct();
      await this.getProductInfo();
      this.confirmBack = false;
    },
    updateProduct: async function() {
      console.log("updateProduct");
      var object = {
        ProductID: this.$route.params.ProductID,
        Name: this.ProductNameInput.value,
        SupplierID: this.SupplierInput.value,
        Country: this.CountryInput.value,
        ParingTags: this.ParingTagsInput.value,
        Aroma: this.AromaInput.value,
        TasteNote: this.TasteNotesInput.value,
        ProductType: this.ProudctTypeValue,
        Status: this.StatusInput.value
      };

      var Series = []
      for(var i in this.SeriesInputList){
        if(this.SeriesInputList[i].value != ""){
          Series.push({
            SeriesID: this.SeriesInputList[i].key,
            OptionID: this.SeriesInputList[i].value
          });
        }
      }
      object["Series"] = Series;
      
      console.log("TasteCardImage", this.TasteCardImage);
      if (this.TasteCardImage.isTempory) {
        object["TasteCardImage"] = this.TasteCardImage.ID;
      }
      console.log("ProductInfoInputList", this.ProductInfoInputList);
      for (var i in this.ProductInfoInputList) {
        if (this.ProductInfoInputList[i].type == "number") {
          object[this.ProductInfoInputList[i].key] = parseFloat(
            this.ProductInfoInputList[i].value
          );
        } else {
          object[this.ProductInfoInputList[i].key] = this.ProductInfoInputList[
            i
          ].value;
        }
      }
      var Image = [];
      console.log("ProductImageInput", this.ProductImageInput);
      for (var i in this.ProductImageInput) {
        var imageObject = {
          ImageID: this.ProductImageInput[i].ID
        };
        if (this.ProductImageInput[i].isTempory) {
          imageObject.newImage = true;
        } else {
          imageObject.newImage = false;
        }
        Image.push(imageObject);
      }
      object["Image"] = Image;
      console.log("object", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateProduct", {
          router: this.$router,
          product: object
        });
        console.log("updateProduct", ret.data);
        this.isLoading = false;
        this.formMessage = this.$route.params.ProductID + " 更新成功";
        this.showFormMessage = true;
      } catch (err) {
        this.isLoading = false;
        console.log("updateProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        for (var i = 0; i < this.languageList.length; i++) {
          this.ProductNameInput.value[this.languageList[i].Language] = "";
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    listSeries: async function() {
      try {
        const ret = await this.$store.dispatch("listSeries", {
          router: this.$router,
          data: null
        });
        console.log("listSeries", ret.data.series);
        //this.ParingTagsInput.option = ret.data.Series;
        this.SeriesList = ret.data.series;
        for(var i in this.SeriesList){
          var option = [{
            key: "",
            name: "無"
          }];
          for(var j in this.SeriesList[i].Option){
            option.push({
              key: this.SeriesList[i].Option[j].OptionID,
              name: this.SeriesList[i].Option[j].Name.zh
            });
          }
          this.SeriesInputList.push({
            key: this.SeriesList[i].SeriesID,
            name: this.SeriesList[i].Name.zh,
            value: "",
            option: option
          });
        }
        this.SeriesInput.name = ret.data.series[0].Name.zh;
        this.SeriesInput.key = ret.data.series[0].SeriesID;
        for(var i in ret.data.series[0].Option){
          var seriesOption = ret.data.series[0].Option[i];
          this.SeriesInput.option.push({
            key: seriesOption.OptionID,
            name: seriesOption.Name.zh
          });
        }
      } catch (err) {
        console.log("listSeries error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getSuppliers: async function() {
      try {
        const ret = await this.$store.dispatch("listSuppliers", {
          router: this.$router
        });
        for (var i in ret.data.supplierList) {
          var name = ret.data.supplierList[i].Name;
          if (ret.data.supplierList[i].SupplierName) {
            name = ret.data.supplierList[i].SupplierName;
          }
          this.SupplierInput.option.push({
            key: ret.data.supplierList[i].UserID,
            name: name
          });
        }
      } catch (err) {
        console.log("listSuppliers error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getProductInfo: async function() {
      this.isLoading = true;
      var Object = {
        ProductID: this.$route.params.ProductID
      };
      try {
        const ret = await this.$store.dispatch("getProduct", {
          router: this.$router,
          product: Object
        });
        console.log("getProduct", ret.data);
        this.setFormValue(ret.data.ProductDetail);
        this.TasteCardImage.url =
          this.s3path +
          "taste-card-resource/product/" +
          this.$route.params.ProductID;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("getProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setFormValue: async function(data) {
      /*
      set product series
      */
      if(data.Series && data.Series.length > 0){
        for(var i in data.Series){
          for(var j in this.SeriesInputList){
            if(data.Series[i].SeriesID == this.SeriesInputList[j].key){
              this.SeriesInputList[j].value = data.Series[i].Option.OptionID;
            }
          }
        }
      }
      //set product variacne
      this.ProductVarianceList = data.VarianceList;
      this.TableItemList = [];
      for (var i in this.ProductVarianceList) {
        var imagePath = "";
        if (this.ProductVarianceList[i].Image[0]) {
          imagePath = this.ProductVarianceList[i].Image[0].path;
        }
        this.TableItemList.push({
          ID: this.ProductVarianceList[i].VarianceID,
          img: this.s3path + imagePath,
          選項名稱: this.ProductVarianceList[i].Name.zh,
          價格: this.ProductVarianceList[i].Price,
          重量: this.ProductVarianceList[i].Weight
        });
      }
      //ProductNameInput
      for (const [key, value] of Object.entries(data.Name)) {
        this.ProductNameInput.model += key + " : " + value + " / ";
        this.ProductNameInput.value[key] = value;
      }
      this.ProductNameInput.model = this.ProductNameInput.model.slice(0, -2);
      //SupplierInput
      this.SupplierInput.value = data.SupplierID;
      //CountryInput
      this.CountryInput.value = data.Country.CountryID;
      this.CountryInput.model =
        data.Country.Name.en +
        ", " +
        data.Country.Name.zh +
        ", " +
        data.Country.Alpha2 +
        ", " +
        data.Country.Alpha3;

      //StatusInput
      this.StatusInput.value = data.Status;

      //ParingTagssInput
      if (data.ParingTags && data.ParingTags.length > 0) {
        this.ParingTagsInput.value = [];
        this.ParingTagsInput.model = [];
        for (var i = 0; i < data.ParingTags.length; i++) {
          this.ParingTagsInput.value.push(data.ParingTags[i].ParingTagID);
          var ParingTagsString = "";
          for (const [key, value] of Object.entries(data.ParingTags[i].Name)) {
            ParingTagsString += key + " : " + value + " / ";
          }
          ParingTagsString = ParingTagsString.slice(0, -2);
          this.ParingTagsInput.model.push(ParingTagsString);
        }
      }
      //TasteNotesInput
      if (data.TasteNote.length > 0) {
        this.TasteNotesInput.value = [];
        this.TasteNotesInput.model = [];
        for (var i = 0; i < data.TasteNote.length; i++) {
          this.TasteNotesInput.value.push(data.TasteNote[i].TasteNoteID);
          var TasteNotesString = "";
          for (const [key, value] of Object.entries(data.TasteNote[i].Name)) {
            TasteNotesString += key + " : " + value + " / ";
          }
          TasteNotesString = TasteNotesString.slice(0, -2);
          this.TasteNotesInput.model.push(TasteNotesString);
        }
      }
      //AromaInput
      if (data.Aroma && data.Aroma.length > 0) {
        this.AromaInput.value = [];
        this.AromaInput.model = [];
        for (var i = 0; i < data.Aroma.length; i++) {
          this.AromaInput.value.push(data.Aroma[i].AromaID);
          var AromaString = "";
          for (const [key, value] of Object.entries(data.Aroma[i].Name)) {
            AromaString += key + " : " + value + " / ";
          }
          AromaString = AromaString.slice(0, -2);
          this.AromaInput.model.push(AromaString);
        }
      }
      //ProductImageInput
      this.ProductImageInput = [];
      if (data.Image.length > 0) {
        this.currentImageList = data.Image;
        for (var i in data.Image) {
          var object = {
            ID: data.Image[i].ImageID,
            isTempory: false,
            url: this.s3path + data.Image[i].path
          };
          this.ProductImageInput.push(object);
        }
      }
      //ProductType
      this.ProductType = data.ProductType.Name.en;
      this.ProudctTypeValue = data.ProductType.ProductTypeID;
      this.noTasteCard = data.ProductType.noTasteCard;
      var Column = data.ProductType["Column"];
      this.ProductInfoInputList = [];
      for (var i in Column) {
        if (Column[i]["DataType"] == "object") {
          var object = {
            type: "object",
            key: Column[i].Key,
            name: Column[i].Name,
            required: Column[i].required,
            model: "",
            value: {}
          };
          var model = "";
          for (var l in this.languageList) {
            if (data[Column[i].Key]) {
              model +=
                this.languageList[l].Language +
                " : " +
                data[Column[i].Key][this.languageList[l].Language] +
                " / ";
              object.value[this.languageList[l].Language] =
                data[Column[i].Key][this.languageList[l].Language];
            }
          }
          model = model.slice(0, -2);
          object.model = model;
          this.ProductInfoInputList.push(object);
        } else {
          var object = {
            type: Column[i].DataType,
            key: Column[i].Key,
            name: Column[i].Name,
            required: Column[i].required,
            model: data[Column[i].Key],
            value: data[Column[i].Key]
          };
          this.ProductInfoInputList.push(object);
        }
      }
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    }
  },
  data() {
    return {
      product: {},
      CardID: "",
      isLoading: false,
      HeaderTitle: "更新產品",
      Form1Title: "通用資訊",
      Form2Title: "產品詳情",
      formActionButton: "更新",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      languageList: [],
      s3path: process.env.VUE_APP_S3_URL,
      showConfirmDeleteMedia: false,
      deleteMediaID: "",
      deleteMediaType: "",
      PopupContent: "",
      noTasteCard: false,
      TasteCardImage: {
        ID: "",
        url: ""
      },
      StatusInput: {
        key: "Status",
        name: "Status",
        required: true,
        value: "",
        option: [
          {
            key: "Hide",
            name: "隱藏"
          },
          {
            key: "Published",
            name: "公開"
          },
          {
            key: "UnListed",
            name: "不公開"
          },
          {
            key: "Private",
            name: "私人"
          }
        ]
      },
      ProductNameInput: {
        type: "object",
        key: "Name",
        name: "產品名稱",
        required: true,
        style: "width: 100%;",
        model: "",
        value: {}
      },
      SupplierInput: {
        key: "Supplier",
        name: "供應商",
        required: true,
        value: "",
        option: []
      },
      CountryInput: {
        key: "Country",
        name: "國家(出口用)",
        required: true,
        model: "",
        value: ""
      },
      ParingTagsInput: {
        key: "ParingTags",
        name: "配對標籤",
        required: false,
        value: [],
        model: []
      },
      TasteNotesInput: {
        key: "TasteNote",
        name: "風味",
        required: true,
        value: [],
        model: []
      },
      AromaInput: {
        key: "Aroma",
        name: "香氣",
        required: true,
        value: [],
        model: []
      },
      SeriesInputList: [],
      SeriesList: [],
      ProductInfoInputList: [],
      ProductType: "",
      ProudctTypeValue: "",
      acceptType: "image/*",
      currentImageList: [],
      ProductImageInput: [],
      ProductVarianceList: [],
      //Table Component Data
      TableItemList: []
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
