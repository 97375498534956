<template>
  <div>
    <div>
      <div class="row" v-if="Item.url != ''">
        <video
          class="media"
          v-if="Item.url != '' && Item.type == 'Video'"
          :src="Item.url"
          style="width: 200px; height: 200px; padding-top: 30px;object-fit: contain;"
          controls
        />
        <img
          class="media"
          v-else-if="Item.url != ''"
          :src="Item.url"
          style="width: 200px; height: 200px; padding-top: 30px;object-fit: contain;"
        />
        <div @click="RemoveItem(Item.ID)" class="remove" v-if="!readonly">
          <md-icon class="remove">delete</md-icon>
        </div>
      </div>

      <md-field v-else>
        <md-file
          :accept="acceptType"
          @md-change="uploadFile($event, Item.ID)"
        />
      </md-field>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>
<script>
import Spinner from "../Spinner.vue";
export default {
  components: {
    Spinner
  },
  methods: {
    RemoveItem: async function(ID) {
      var extraDelete = false;
      if (this.Item.isTempory) {
        this.isLoading = true;
        try {
          await this.$store.dispatch("deleteMedia", {
            router: this.$router,
            data: { MediaID: ID }
          });
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          console.log("deleteMedia error", err);
        }
        this.Item.url = "";
      } else {
        extraDelete = true;
      }
      console.log("RemoveItem", ID, extraDelete);
      this.$emit("RemoveItem", ID, extraDelete);
    },
    uploadFile: async function(fileList, ID) {
      if (fileList.constructor === FileList && fileList[0] !== undefined) {
        var file = fileList[0];
        var base64 = await this.getBase64(file);
        var contentType = base64.split(":", 2)[1].split(";", 1)[0];
        this.isLoading = true;
        try {
          const ret = await this.$store.dispatch("uploadMedia", {
            router: this.$router,
            data: { Media: contentType }
          });
          await this.$store.dispatch("updateMediaToS3", {
            file: base64,
            urlObject: ret.data.UploadUrl
          });

          this.Item.url = this.s3path + ret.data.UploadUrl.key;
          console.log("uploadFile", this.Item.url);
          this.Item.ID = ret.data.MediaID;
          this.Item.isTempory = true;
          this.isLoading = false;
          this.$emit("setMedia", this.Item);
        } catch (err) {
          this.isLoading = false;
          console.log("uploadMedia error", err);
        }
      }
    },
    getBase64(file) {
      const reader = new FileReader();
      return new Promise(resolve => {
        reader.onload = ev => {
          resolve(ev.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  },
  name: "object-input-component",
  props: {
    acceptType: {
      default() {
        return [];
      }
    },
    Item: {
      default() {
        return {};
      }
    },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      s3path: process.env.VUE_APP_S3_URL,
      isLoading: false
    };
  }
};
</script>
<style>
.media {
  width: 200px;
  height: 200px;
  position: relative;
}
.remove {
  /* position: absolute; */
  top: 0;
  right: 0;
  color: red;
  cursor: pointer;
}
</style>
