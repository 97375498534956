<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-content>
            <div id="payment-element">
              <!--Stripe.js injects the Payment Element-->
            </div>
            <button @click="pay">Pay Now</button>
            <div id="payment-request-button">
              <!-- A Stripe Element will be inserted here. -->
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { Spinner } from "@/components";
let elements;
export default {
  head: {
    script: [{ src: "https://js.stripe.com/v3" }]
  },
  components: {
    Spinner
    //StripeElementPayment
  },
  data() {
    return {
      stripe: null,
      isLoading: false,
      showMessage: false,
      showTab: "",
      message: "",
      clientSecret: ""
    };
  },
  created() {
    this.clientSecret = this.$route.params.clientSecret;
  },
  mounted() {
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    elements = this.stripe.elements();
    const appearance = {
      theme: "stripe"
    };
    const elementsObject = { appearance, clientSecret: this.clientSecret };
    elements = this.stripe.elements(elementsObject);
    const paymentElementOptions = {
      layout: "tabs"
    };
    const paymentElement = elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  },
  methods: {
    async pay() {
      const { error } = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: process.env.VUE_APP_API_URL + "test/stripe-callback"
        }
      });
      console.log(error);
      //"https://75mcauivra.execute-api.ap-southeast-1.amazonaws.com/test/stripe-callback",
    }
  }
};
</script>
<style></style>
