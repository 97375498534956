<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader :HeaderTitle="HeaderTitle"> </FormHeader>

        <div class="row">
          <div style="width:50%">
            <md-card style="margin:10px 0">
              <md-card-content>
                <!-- 訂單記錄 -->
                <InformationTable
                  v-on:showMore="showMore"
                  v-on:hideItem="hideItem"
                  v-on:goPage="goPage"
                  :tableHeader="firstTableInfo.Header"
                  :ItemList="firstTableInfo.ItemList"
                  :pageNumber="firstTableInfo.pageNumber"
                  :showNextButton="firstTableInfo.showNextButton"
                  :ShowMore="firstTableInfo.ShowMore"
                >
                </InformationTable>
              </md-card-content>
            </md-card>

            <md-card style="margin:10px 0">
              <md-card-content>
                <!-- 活動訂單 -->
                <InformationTable
                  v-on:showMore="showMore"
                  v-on:hideItem="hideItem"
                  v-on:goPage="goPage"
                  :tableHeader="secondTableInfo.Header"
                  :ItemList="secondTableInfo.ItemList"
                  :pageNumber="secondTableInfo.pageNumber"
                  :showNextButton="secondTableInfo.showNextButton"
                  :ShowMore="secondTableInfo.ShowMore"
                />
              </md-card-content>
            </md-card>

            <md-card style="margin:10px 0">
              <md-card-content>
                <!-- 用戶群 -->
                <InformationTable
                  v-on:showMore="showMore"
                  v-on:hideItem="hideItem"
                  v-on:goPage="goPage"
                  :tableHeader="thirdTableInfo.Header"
                  :tableAction="thirdTableInfo.action"
                  :ItemList="thirdTableInfo.ItemList"
                  :ItemCenter="thirdTableInfo.ItemCenter"
                  :pageNumber="thirdTableInfo.pageNumber"
                  :showNextButton="thirdTableInfo.showNextButton"
                  :ShowMore="thirdTableInfo.ShowMore"
                />
              </md-card-content>
            </md-card>

            <md-card style="margin:10px 0">
              <md-card-content>
                <!-- 用戶群 -->
                <InformationTable
                  v-on:showMore="showMore"
                  v-on:hideItem="hideItem"
                  v-on:goPage="goPage"
                  :tableHeader="userActivityTableInfo.Header"
                  :tableAction="userActivityTableInfo.action"
                  :ItemList="userActivityTableInfo.ItemList"
                  :ItemCenter="userActivityTableInfo.ItemCenter"
                  :pageNumber="userActivityTableInfo.pageNumber"
                  :showNextButton="userActivityTableInfo.showNextButton"
                  :ShowMore="userActivityTableInfo.ShowMore"
                />
              </md-card-content>
            </md-card>
            <!-- if userInfo.Enabled or userInfo.Enabled is null, show disable button  -->
            <button
              class="enableButton"
              v-if="userInfo.Enabled"
              @click="showPopup = true"
            >
              Disable account
            </button>
            <button
              class="enableButton"
              v-else
              @click="actionForAccount('enable')"
            >
              Enable account
            </button>
          </div>
          <div style="width:50%;padding-left: 30px;">
            <md-card style="margin:10px 0">
              <md-card-content>
                <div class="row">
                  <div style="width:50%">
                    <h3 class="formTitle">用戶資訊</h3>
                  </div>
                  <div class="action" @click="editForm()"><u>修改</u></div>
                </div>
                <div class="row">
                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.GivenNameInput"
                      :readonly="readonly"
                    />
                  </div>
                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.FamilyNameInput"
                      :readonly="readonly"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="fullRow">
                    <TextInput
                      :Item="UserInput.NameInput"
                      :readonly="readonly"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="fullRow">
                    <TextInput
                      :Item="UserInput.EamilInput"
                      :readonly="readonly"
                    />
                  </div>
                </div>

                <div class="row" v-if="userInfo.Supplier">
                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.SupplierNameInput"
                      :readonly="readonly"
                    />
                  </div>

                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.SupplierDescriptionInput"
                      :readonly="readonly"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.PhoneInput"
                      :readonly="readonly"
                    />
                  </div>
                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.BirthdayInput"
                      :readonly="readonly"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="inputcolumn">
                    <SelectInput
                      :Item="UserInput.GenderInput"
                      :readonly="readonly"
                    />
                  </div>
                  <div class="inputcolumn">
                    <label>頭像</label>
                    <FileInput
                      :Item="UserInput.AvatarInput"
                      :readonly="readonly"
                    />
                  </div>
                </div>
              </md-card-content>
            </md-card>
            <div class="row">
              <button
                :class="checkOffical()"
                @click="confirmEditPopup('Offical')"
              >
                Offical
              </button>
              <button
                :class="checkSupplier()"
                @click="confirmEditPopup('Supplier')"
                style="margin-left: 10px;"
              >
                Supplier
              </button>
              <button
                :class="checkKOL()"
                @click="confirmEditPopup('KOL')"
                style="margin-left: 10px;"
              >
                KOL
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <Transition name="modal" v-if="showPopup">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="popupaction"
        :PopupTitle="PopupTitle"
        :PopupContent="PopupContent"
        :actionButton="actionButton"
      ></Popup>
    </Transition>
    <Transition name="modal" v-if="showConfirmEditPopup">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="setUserType"
        :PopupTitle="ConfirmEditPopupTitle"
        :PopupContent="ConfirmEditPopupContent"
        :actionButton="ConfirmEditPopupButton"
      ></Popup>
    </Transition>
    <Transition name="modal" v-if="showSetSupplierPopup">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container" style="max-width: 650px;">
            <div class="popup">
              <div class="modal-header">
                <slot name="header">設定用戶類型</slot>
              </div>
              <div class="modal-body">
                <div style="width: 100%;text-align: center;">
                  是否要設定{{ userInfo.Name }}為供應商?
                </div>
                <div class="row" style="padding-top: 30px;">
                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.SupplierNameInput"
                      :readonly="false"
                    />
                  </div>

                  <div class="inputcolumn">
                    <TextInput
                      :Item="UserInput.SupplierDescriptionInput"
                      :readonly="false"
                    />
                  </div>
                </div>
                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <button class="cancelButton" @click="popupcancel">
                      取消
                    </button>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="actionButton" @click="setUserType">
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>
<script>
import {
  FormHeader,
  InformationTable,
  SelectInput,
  FileInput,
  TextInput,
  Popup,
  Spinner
} from "@/components";

export default {
  components: {
    FormHeader,
    InformationTable,
    SelectInput,
    FileInput,
    TextInput,
    Popup,
    Spinner
  },
  async created() {
    this.isLoading = true;
    this.message = this.$route.params.message;
    this.checkShowMessage();
    this.getCustomerOrder(this.$route.params.customer);
    this.getCustomerEventOrder(this.$route.params.customer);
    this.getCustomerUserGroup(this.$route.params.customer);
    this.getUserActivity(this.$route.params.customer);
    await this.getUser(this.$route.params.customer);
    this.isLoading = false;
  },
  methods: {
    confirmEditPopup: function(UserType) {
      if (this.userInfo[UserType]) {
        this.ConfirmEditPopupContent =
          "是否將" + this.userInfo.Name + "的" + UserType + "取消?";
        this.showConfirmEditPopup = true;
      } else {
        this.ConfirmEditPopupContent =
          "是否要設定" + this.userInfo.Name + "為" + UserType + "?";
        if (UserType == "Supplier") {
          this.showSetSupplierPopup = true;
        } else {
          this.showConfirmEditPopup = true;
        }
      }
      this.editUserType = UserType;
    },

    setUserType: async function() {
      var UserType = this.editUserType;
      this.userInfo[UserType] = !this.userInfo[UserType];
      var object = {
        UserID: this.userInfo.UserID
      };
      object[UserType] = this.userInfo[UserType];
      if (UserType == "Supplier" && this.userInfo[UserType]) {
        if (this.UserInput.SupplierNameInput.value == "") {
          this.formMessage = "必須設定供應商名稱";
          this.showFormMessage = true;
          return;
        }
        object.SupplierName = this.UserInput.SupplierNameInput.value;
        object.SupplierDescription = this.UserInput.SupplierDescriptionInput.value;
      }
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateUserType", {
          router: this.$router,
          customer: object
        });
        this.isLoading = false;
        this.showSetSupplierPopup = false;
        this.showConfirmEditPopup = false;
        this.message =
          this.userInfo.Name + "'s " + UserType + " has been updated";
        this.showMessage = true;
      } catch (err) {
        console.log("updateUserType error", err.response.data);
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    checkSupplier: function() {
      if (this.userInfo.Supplier) {
        return "disableButton";
      } else {
        return "enableButton";
      }
    },
    checkOffical: function() {
      if (this.userInfo.Offical) {
        return "disableButton";
      } else {
        return "enableButton";
      }
    },
    checkKOL: function() {
      if (this.userInfo.KOL) {
        return "disableButton";
      } else {
        return "enableButton";
      }
    },
    goPage: function(object) {
      console.log("goPage ", object);
      if (object.table == this.firstTableInfo.Header) {
        if (object.action == "next") {
          this.firstTableInfo.index =
            this.firstTableInfo.index + this.firstTableInfo.displaySize;
          this.firstTableInfo.pageNumber = this.firstTableInfo.pageNumber + 1;
        } else if (object.action == "prev" && this.firstTableInfo.index > 0) {
          this.firstTableInfo.index =
            this.firstTableInfo.index - this.firstTableInfo.displaySize;
          this.firstTableInfo.pageNumber = this.firstTableInfo.pageNumber - 1;
        } else {
          this.firstTableInfo.index = 0;
          this.firstTableInfo.pageNumber = 1;
        }
        this.getCustomerOrder(this.userInfo.UserID);
      } else if (object.table == this.secondTableInfo.Header) {
        if (object.action == "next") {
          this.secondTableInfo.index =
            this.secondTableInfo.index + this.secondTableInfo.displaySize;
          this.secondTableInfo.pageNumber = this.secondTableInfo.pageNumber + 1;
        } else if (object.action == "prev" && this.secondTableInfo.index > 0) {
          this.secondTableInfo.index =
            this.secondTableInfo.index - this.secondTableInfo.displaySize;
          this.secondTableInfo.pageNumber = this.secondTableInfo.pageNumber - 1;
        } else {
          this.secondTableInfo.index = 0;
          this.secondTableInfo.pageNumber = 1;
        }
        this.getCustomerEventOrder(this.userInfo.UserID);
      }
    },
    showMore: function(table) {
      console.log("here showMore");
      if (table == this.firstTableInfo.Header) {
        this.firstTableInfo.displaySize = 5;
        this.firstTableInfo.ShowMore = true;
        this.getCustomerOrder(this.userInfo.UserID);
      } else if (table == this.secondTableInfo.Header) {
        this.secondTableInfo.displaySize = 5;
        this.secondTableInfo.ShowMore = true;
        this.getCustomerEventOrder(this.userInfo.UserID);
      }
    },
    hideItem: function(table) {
      if (table == this.firstTableInfo.Header) {
        this.firstTableInfo.displaySize = 2;
        this.firstTableInfo.index = 0;
        this.firstTableInfo.pageNumber = 1;
        this.firstTableInfo.ShowMore = false;
        this.getCustomerOrder(this.userInfo.UserID);
      } else if (table == this.secondTableInfo.Header) {
        this.secondTableInfo.displaySize = 2;
        this.secondTableInfo.index = 0;
        this.secondTableInfo.pageNumber = 1;
        this.secondTableInfo.ShowMore = false;
        this.getCustomerEventOrder(this.userInfo.UserID);
      }
    },
    popupcancel: function() {
      this.showConfirmEditPopup = false;
      this.showSetSupplierPopup = false;
      this.showPopup = false;
    },
    popupaction: function() {
      this.showPopup = false;
      this.actionForAccount("disable");
    },
    checkShowMessage: async function() {
      if (this.message) {
        try {
          await this.delayFunction();
          this.showMessage = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    delayFunction: async function() {
      setTimeout(function() {
        this.showMessage = false;
        this.message = "";
        return true;
      }, 1000);
    },
    editForm: function() {
      this.$router.push({
        name: "Update Customer",
        params: { customer: this.userInfo.UserID }
      });
    },
    getUser: async function(UserID) {
      try {
        const ret = await this.$store.dispatch("getCustomerInfo", {
          router: this.$router,
          customer: { userID: UserID }
        });
        console.log("getCustomerInfo", ret.data.userInfo);
        this.userInfo = ret.data.userInfo;
        if (ret.data.userInfo.Enabled == null) {
          this.userInfo.Enabled = true;
        }
        this.HeaderTitle = this.userInfo.Name;
        this.setDatatoForm(this.userInfo);
      } catch (err) {
        console.log("getCustomerInfo error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setDatatoForm: function(data) {
      console.log("setDatatoForm", data);
      for (const [userKey, userValue] of Object.entries(this.UserInput)) {
        if (userValue.type == "phone") {
          if (data["PhoneCountryCode"] != null && data[userValue.key] != null) {
            userValue["value"] =
              "+" + data["PhoneCountryCode"] + data[userValue.key];
          }
        } else if (userValue.type == "image") {
          console.log("image", data["Avatar"]);
          if (data["Avatar"] != null) {
            userValue["url"] = this.s3path + data["Avatar"];
          }
        } else {
          userValue["value"] = data[userValue.key];
        }
      }
    },
    getCustomerOrder: async function(UserID) {
      var object = {
        UserID: UserID,
        Size: this.firstTableInfo.displaySize,
        From: this.firstTableInfo.index,
        Sort: this.Sort,
        Source: ["CreatedDatetime", "OrderID", "Total", "Currency"]
      };

      try {
        const ret = await this.$store.dispatch("searchProductOrder", {
          router: this.$router,
          keyword: object
        });
        console.log("searchProductOrder", ret);
        this.setUserOrderList(ret.data.data.hits.hits);
      } catch (err) {
        console.log("searchProductOrder error", err);
        this.formMessage = err;
        this.showFormMessage = true;
      }
    },
    setUserOrderList: function(list) {
      this.firstTableInfo.ItemList = [];
      for (var i in list) {
        //timestamp to date
        var date = new Date(list[i]["_source"].CreatedDatetime).toDateString();
        var Currency = "HKD";
        if (list[i]["_source"].Currency) {
          Currency = list[i]["_source"].Currency;
        }
        var object = {
          ID: list[i]["_source"].OrderID,
          CreatedDatetime: "Create at: " + date,
          Total: Currency + " " + list[i]["_source"].Total
        };
        this.firstTableInfo.ItemList.push(object);
      }
    },
    getCustomerEventOrder: async function(UserID) {
      var object = {
        UserID: UserID,
        Size: this.secondTableInfo.displaySize,
        From: this.secondTableInfo.index,
        Sort: this.Sort,
        Source: ["CreatedDatetime", "OrderID", "Total", "Currency"]
      };

      try {
        const ret = await this.$store.dispatch("searchEventOrder", {
          router: this.$router,
          keyword: object
        });
        console.log("searchEventOrder", ret);
        this.setUserEventOrderList(ret.data.data.hits.hits);
      } catch (err) {
        console.log("searchEventOrder error", err);
        this.formMessage = err;
        this.showFormMessage = true;
      }
    },
    setUserEventOrderList: function(list) {
      this.secondTableInfo.ItemList = [];
      for (var i in list) {
        //timestamp to date
        var date = new Date(list[i]["_source"].CreatedDatetime).toDateString();
        var Currency = "HKD";
        if (list[i]["_source"].Currency) {
          Currency = list[i]["_source"].Currency;
        }
        var object = {
          ID: list[i]["_source"].OrderID,
          CreatedDatetime: "Create at: " + date,
          Total: Currency + " " + list[i]["_source"].Total
        };
        this.secondTableInfo.ItemList.push(object);
      }
    },
    getCustomerUserGroup: async function(UserID) {
      try {
        const ret = await this.$store.dispatch("getCustomerUserGroup", {
          router: this.$router,
          customer: { UserID: UserID }
        });
        this.setUserUserGroupList(ret.data.UserGroup);
      } catch (err) {
        console.log("getCustomerUserGroup error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setUserUserGroupList: function(data) {
      var list = data.Items;
      var newlist = [];
      for (var i = 0; i < list.length; i++) {
        var object = {
          ID: list[i].UserGroupID
        };
        newlist.push(object);
      }
      this.thirdTableInfo.ItemList = newlist;
    },
    getUserActivity: async function(UserID) {
      var object = {
        Size: 2,
        From: 0,
        FilterOut: [{ match: { UserID: UserID } }],
        Sort: [{ CreatedDatetime: { order: "desc" } }]
      };
      try {
        const ret = await this.$store.dispatch("searchUserActivity", {
          router: this.$router,
          keyword: object
        });
        this.setUserActivityList(ret.data.data.hits.hits);
      } catch (err) {
        console.log("getUserActivity error", err);
        this.formMessage = err.response.message;
        this.showFormMessage = true;
      }
    },
    setUserActivityList: function(list) {
      var newlist = [];
      for (var i in list) {
        var object = {
          Action: list[i]._source.Action,
          Datetime: new Date(list[i]._source.CreatedDatetime).toISOString()
        };
        newlist.push(object);
      }
      this.userActivityTableInfo.ItemList = newlist;
    },
    actionForAccount: async function(action) {
      try {
        const ret = await this.$store.dispatch("enabledisableCustomer", {
          router: this.$router,
          data: { userID: this.userInfo.UserID, action: action }
        });
        this.message = this.userInfo.Name + " has been " + action;
        this.$router.push({
          path: "/customer/1",
          params: { message: this.message }
        });
      } catch (err) {
        console.log("enabledisableCustomer error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },

  data() {
    return {
      isLoading: false,
      showMessage: false,
      message: "",
      HeaderTitle: "",
      s3path: process.env.VUE_APP_S3_URL,
      readonly: true,
      Sort: { CreatedDatetime: "desc" },
      firstTableInfo: {
        Header: "訂單記錄",
        ItemList: [],
        index: 0,
        pageNumber: 1,
        displaySize: 2,
        showNextButton: true,
        ShowMore: false
      },
      secondTableInfo: {
        Header: "活動記錄",
        ItemList: [],
        index: 0,
        pageNumber: 1,
        displaySize: 2,
        showNextButton: true,
        ShowMore: false
      },
      thirdTableInfo: {
        Header: "用戶群",
        ItemList: [],
        index: 0,
        pageNumber: 1,
        displaySize: 2,
        showNextButton: true,
        ShowMore: false,
        action: "Add to a Group",
        ItemCenter: false
      },
      userActivityTableInfo: {
        Header: "Activity Log",
        ItemList: [],
        index: 0,
        pageNumber: 1,
        displaySize: 1,
        showNextButton: true,
        ShowMore: false,
        ItemCenter: false
      },
      editAble: false,
      UserInput: {
        GivenNameInput: {
          type: "text",
          key: "GivenName",
          name: "名字",
          required: true,
          value: ""
        },
        FamilyNameInput: {
          type: "text",
          key: "FamilyName",
          name: "姓氏",
          required: true,
          value: ""
        },
        NameInput: {
          type: "text",
          key: "Name",
          name: "Username",
          required: true,
          value: ""
        },
        SupplierNameInput: {
          type: "text",
          key: "SupplierName",
          name: "供應商名稱",
          required: true,
          value: ""
        },
        SupplierDescriptionInput: {
          type: "text",
          key: "SupplierDescription",
          name: "供應商描述",
          required: false,
          value: ""
        },
        PhoneInput: {
          type: "phone",
          key: "Phone",
          name: "電話",
          required: false,
          value: "",
          countyCode: ""
        },
        EamilInput: {
          type: "text",
          key: "Email",
          name: "電郵",
          required: true,
          value: ""
        },
        BirthdayInput: {
          type: "date",
          key: "Birthday",
          name: "出生日期",
          required: false,
          value: ""
        },
        GenderInput: {
          type: "select",
          type: "select",
          key: "Gender",
          name: "性別",
          required: false,
          value: "",
          option: [
            {
              key: "M",
              name: "M"
            },
            {
              key: "F",
              name: "F"
            },
            {
              key: "Others",
              name: "Others"
            }
          ]
        },
        AvatarInput: {
          type: "image",
          key: "Avatar",
          name: "頭像",
          url: ""
        }
      },
      userInfo: {},
      userGatheringList: [],
      userUserGroupList: [],
      showPopup: false,
      PopupTitle: "Disable account",
      PopupContent: "This action will forbid customers to access their account",
      actionButton: "Disable anyway",
      showSetSupplierPopup: false,
      showConfirmEditPopup: false,
      ConfirmEditPopupTitle: "設定用戶類型",
      ConfirmEditPopupContent: "",
      ConfirmEditPopupButton: "確認",
      editUserType: ""
    };
  }
};
</script>
<style>
.enableButton {
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.disableButton {
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
  background-color: #cc2727;
  color: white;
}
.fullRow {
  width: 100%;
  padding: 0 20px;
}
</style>
