<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createNewItem"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="AddNewButton"
        />
        <md-card>
          <md-card-content>
            <TableComponent
              v-on:selectItem="selectItem"
              v-on:selectAllItem="selectAllItem"
              v-on:selectPageItem="selectPageItem"
              v-on:searchItem="searchItem"
              v-on:onclickItem="onclickItem"
              v-on:selectedItemActionFunction="selectedItemActionFunction"
              v-on:goPage="goPage"
              :TableList="TableItemList"
              :selectedItemCounter="selectedItemCounter"
              :selectedItem="selectedItem"
              :columns="columns"
              :selectAll="selectAll"
              :selectable="selectable"
              :haveSearchFunction="haveSearchFunction"
              :selectedItemAction="selectedItemAction"
              :pageNumber="pageNumber"
              :showNextButton="showNextButton"
            ></TableComponent>
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { TableComponent, FormHeader, Spinner } from "@/components";

export default {
  components: {
    TableComponent,
    FormHeader,
    Spinner
  },
  data() {
    return {
      language: "zh",
      message: "",
      showDeletePopup: false,
      showUpdateStatusPopup: false,
      showSortPopup: false,
      isLoading: false,
      index: 0,
      pageNumber: 1,
      showMessage: false,
      showNextButton: true,
      displaySize: 10,
      HeaderTitle: "國家/地區列表",
      ProductTypeList: [],
      TableItemList: [],
      ItemList: [],
      AddNewButton: "新增國家/地區",
      columns: ["國家/地區"],
      selectedItemAction: [
        { Key: "delete", Name: "刪除", Style: "color:#CC2727" }
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      confirmBack: false
    };
  },
  async created() {
    this.isLoading = true;
    console.log("params", this.$route.params);
    this.message = this.$route.params.message;
    if (this.$route.params.page) {
      if (Number(this.$route.params.page) > 0) {
        this.pageNumber = Number(this.$route.params.page);
      }
      this.index =
        this.index + (Number(this.$route.params.page) - 1) * this.displaySize;
    }
    this.checkShowMessage();
    await this.searchItem({ keyword: "" });
    this.isLoading = false;
  },
  methods: {
    goPage: function(action) {
      if (action == "next") {
        this.index = this.index + this.displaySize;
        this.pageNumber = this.pageNumber + 1;
      } else if (action == "prev" && this.index > 0) {
        this.index = this.index - this.displaySize;
        this.pageNumber = this.pageNumber - 1;
      } else {
        this.index = 0;
        this.pageNumber = 1;
      }
      this.$router.replace({
        name: "Country List PageNumber",
        params: { page: this.pageNumber }
      });
      this.searchItem({ keyword: "" });
    },
    checkShowMessage: async function() {
      if (this.message) {
        try {
          await this.delayFunction();
          this.showMessage = true;
        } catch (err) {
          console.log(err);
        }
      }
    },
    delayFunction: async function() {
      setTimeout(function() {
        this.showMessage = false;
        this.message = "";
        return true;
      }, 3000);
    },
    selectedItemActionFunction: function(action) {
      console.log("selectedItemActionFunction", action);
      if (action == "delete") {
        this.showDeletePopup = true;
      }
    },
    onclickItem: function(ID) {
      var Country = this.ItemList.find(x => x._source.CountryID == ID)._source;
      this.$router.push({ name: "Update Country", params: Country });
    },
    createNewItem: function() {
      this.$router.push({ name: "Create Country" });
    },
    popupcancel: function() {
      this.showDeletePopup = false;
      //this.showUpdateStatusPopup = false;
    },
    selectPageItem: async function(action) {
      console.log("selectPageItem", action);
      console.log(this.TableItemList);

      if (action) {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index == -1) {
            this.selectedItem.push(this.TableItemList[i].ID);
          }
        }
      } else {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index > -1) {
            this.selectedItem.splice(index, 1);
          }
        }
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectAllItem: async function(action, { keyword }) {
      console.log("action", action);
      console.log("keyword", keyword);
      if (!action) {
        this.selectAll = true;
        this.isLoading = true;
        var object = {
          Source: this.Source,
          Filter: this.filter,
          Range: this.range
        };
        if (keyword) {
          object["Keyword"] = keyword;
        }
        try {
          const ret = await this.$store.dispatch("searchCountry", {
            router: this.$router,
            keyword: object
          });
          this.selectedItem = ret.data.data.hits.hits.map(item =>
            String(item._id)
          );
          this.isLoading = false;
        } catch (err) {
          this.formMessage = err.response.data.message;
          this.showFormMessage = true;
        }
      } else {
        this.selectAll = false;
        this.selectedItem = [];
      }
      console.log("after", this.selectedItem);
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectItem: function(ID, action) {
      console.log(ID, action);
      if (action) {
        this.selectedItem.push(ID);
      } else {
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID);
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length;
    },
    searchItem: async function({ keyword }) {
      this.isLoading = true;
      var object = {
        Size: this.displaySize,
        From: this.index,
        Sort: this.Sort
      };
      if (keyword) {
        object["Keyword"] = keyword;
        this.selectAll = false;
        (this.selectedItem = []), (this.selectedItemCounter = 0);
        this.index = 0;
        this.pageNumber = 1;
      }
      try {
        const ret = await this.$store.dispatch("searchCountry", {
          router: this.$router,
          data: object
        });
        console.log("searchCountry", ret);
        this.ItemList = ret.data.data.hits.hits;
        this.setDisplayList(ret.data.data.hits.hits);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("searchCountry error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setDisplayList(list) {
      console.log("setDisplayList", list);
      this.TableItemList = [];
      for (var i in list) {
        this.TableItemList.push({
          ID: list[i]["_source"].CountryID,
          "國家/地區": list[i]["_source"].Name[this.language]
        });
      }
      console.log("setDisplayList after", this.TableItemList);
      this.TableSelectUIControl(this.TableItemList);
    },
    TableSelectUIControl(ItemList) {
      this.showNextButton = true;
      if (ItemList.length < this.displaySize) {
        this.showNextButton = false;
      }
      var counter = 0;
      for (var i = 0; i < ItemList.length; i++) {
        var index = this.selectedItem.indexOf(ItemList[i].ID);
        if (index > -1) {
          counter++;
        }
      }
      if (counter == ItemList.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  }
};
</script>
