<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="updateLanguage"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <center>
          <md-card style="width: 50%;text-align: left;">
            <md-card-content>
              <table>
                <tr v-for="item in languageInputList" :key="item.key">
                  <td style="width: 95%;">
                    <TextInput :Item="item" />
                  </td>
                  <td>
                    <md-button
                      class="md-icon-button"
                      @click="confirmDelete(item.key)"
                    >
                      <md-icon>delete</md-icon>
                    </md-button>
                  </td>
                </tr>
              </table>
            </md-card-content>
            <md-card-content>
              <div style="text-align: center;" @click="addLanguage()">
                <md-icon>add_circle_outline</md-icon>
                <u class="addQuestion">新增語言</u>
              </div>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Transition name="modal" v-if="showConfirmDelete">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="deleteLanguage"
        PopupTitle="確認刪除"
        :PopupContent="deleteContent"
        actionButton="刪除"
      />
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, TextInput, Popup } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput,
    Popup
  },
  async created() {
    await this.getLanguage();
  },
  methods: {
    popupcancel: function() {
      this.showConfirmDelete = false;
    },
    confirmDelete: function(key) {
      this.deleteLanguageID = key;
      this.deleteContent = "確定刪除語言" + key + "?";
      this.showConfirmDelete = true;
    },
    addLanguage: function() {
      var key = this.languageInputList.length + 1;
      this.languageInputList.push({
        key: key,
        name: "",
        value: "",
        required: true
      });
    },
    /*
        api
    */
    deleteLanguage: async function() {
      this.showConfirmDelete = false;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deleteLanguage", {
          router: this.$router,
          data: { Language: this.deleteLanguageID }
        });
        this.formMessage = "刪除成功";
        this.showFormMessage = true;
        this.isLoading = false;
        this.getLanguage();
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
        this.isLoading = false;
      }
    },
    updateLanguage: async function() {
      var Language = [];
      for (var i in this.languageInputList) {
        if (this.languageInputList[i].value == "") {
          this.formMessage = "請輸入語言";
          this.showFormMessage = true;
          return;
        }
        Language.push(this.languageInputList[i].value);
      }
      console.log("updateLanguage", Language);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateLanguage", {
          router: this.$router,
          data: { Language: Language }
        });
        this.formMessage = "更新成功";
        this.showFormMessage = true;
        this.isLoading = false;
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
        this.isLoading = false;
      }
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        this.languageInputList = [];
        for (var i in this.languageList) {
          for (const [key, value] of Object.entries(this.languageList[i])) {
            this.languageInputList.push({
              key: value,
              name: "",
              value: value,
              required: true
            });
          }
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "語言管理",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      deleteContent: "",
      deleteLanguageID: "",
      formMessage: "",
      languageList: [],
      languageInputList: []
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
