<template>
  <div style="text-align:left;">
    <div class="row">
      <div style="width:50%"><h3 class="formTitle">Product List</h3></div>
      <div v-if="editAble" class="action" @click="addproduct()">
        <u>選擇產品</u>
      </div>
    </div>
    <div v-if="ProductList.length > 0">
      <div
        class="row"
        v-for="product in ProductList"
        :key="product.ID"
        style="padding: 20px 0"
      >
        <div
          class="column"
          style="width: 20%;max-width: 200px; text-align: center;"
        >
          <img class="productImg" :src="product.img" />
          <div>{{ product["Product Name"] }}</div>
          <button @click="addVariance(product.ID)">選擇分類</button>
        </div>
        <div class="column" style="width: 75%;max-width: 850px;">
          <div
            v-if="product.Variance.length > 0"
            class="row"
            style="width: 100%; padding: 20px 5% 20px 5%"
          >
            <table>
              <tr>
                <th>選項</th>
                <th>數量</th>
                <th>單價</th>
                <th>總價</th>
                <th></th>
              </tr>
              <tr v-for="variance in product.Variance" :key="variance.ID">
                <td>{{ variance["Name"] }}</td>
                <td>{{ variance["Quantity"] }}</td>
                <td>{{ variance["Price"] }}</td>
                <td>
                  {{
                    parseInt(variance["Quantity"]) * parseInt(variance["Price"])
                  }}
                </td>
                <td>
                  <div
                    v-if="editAble"
                    class="varianceRemove"
                    @click="removeVariance(product.ID, variance.ID)"
                  >
                    <md-icon style="color:red">delete</md-icon>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="column" style="text-align: center;">
          <div
            v-if="editAble"
            class="productRemove"
            @click="removeproduct(product.ID)"
          >
            <md-icon style="color:red">delete</md-icon>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="text-align:center">
      No Product <br />
      <!-- <md-button v-if="editAble" style="background-color: #575757 !important;width: 35% !important; margin-top: 20px;" @click="addproduct()">Add Product</md-button> -->
    </div>
    <Transition name="modal" v-if="showSelectProduct">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="product-select-container">
            <div class="popup">
              <div class="row" style="padding: 0 10px">
                <md-button
                  class="backbutton"
                  style="background-color:white !important;"
                  @click="showSelectProduct = false"
                >
                  <md-icon
                    class="backIcon"
                    style="color:black !important;margin-left: -10px;"
                    >arrow_back_ios</md-icon
                  >
                </md-button>
                <h3 class="title" style="flex: 1; padding:15px 0px 0px 10px">
                  {{ HeaderTitle }}
                </h3>
              </div>

              <md-card>
                <md-card-content>
                  <TableComponent
                    v-on:selectItem="selectItem"
                    v-on:selectAllItem="selectAllItem"
                    v-on:selectPageItem="selectPageItem"
                    v-on:searchItem="searchItem"
                    v-on:selectSortOption="selectSortOption"
                    v-on:onclickItem="onclickItem"
                    v-on:selectedItemActionFunction="selectedItemActionFunction"
                    v-on:goPage="goPage"
                    v-on:SortPopupControl="SortPopupControl"
                    v-on:setFilter="setFilter"
                    :TableList="TableItemList"
                    :selectedItemCounter="selectedItemCounter"
                    :selectedItem="selectedItem"
                    :columns="columns"
                    :selectAll="selectAll"
                    :selectable="selectable"
                    :haveSearchFunction="haveSearchFunction"
                    :sortOption="sortOption"
                    :selectedItemAction="selectedItemAction"
                    :pageNumber="pageNumber"
                    :showNextButton="showNextButton"
                    :showSortPopup="showSortPopup"
                    :filterOption="filterOption"
                  />
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="modal" v-if="showAddVariancePopup">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div class="modal-header">
                <slot name="header">選擇分類</slot>
              </div>
              <div class="modal-body">
                <SelectInput
                  v-on:checkEdited="checkEdited"
                  :Item="VarianceInput"
                />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="VarianceQuantityInput"
                />

                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <button class="cancelButton" @click="popupcancel">
                      取消
                    </button>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="actionButton" @click="popupaction">
                      {{ actionButton }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import TableComponent from "../Table.vue";
import SelectInput from "../FormComponents/SelectInput.vue";
import TextInput from "../FormComponents/TextInput.vue";
import Spinner from "../Spinner.vue";
export default {
  components: {
    TableComponent,
    SelectInput,
    TextInput,
    Spinner
  },
  async created() {
    this.setFilterOut();
  },
  methods: {
    popupcancel: function() {
      this.showAddVariancePopup = false;
    },
    popupaction: function() {
      console.log("popupaction");
      var index = this.ProductList.findIndex(
        product => product["ID"] == this.selectingProductID
      );
      var varianceIndex = this.ProductList[index]["Variance"].findIndex(
        variance => variance["ID"] == this.VarianceInput.value
      );
      if (varianceIndex < 0) {
        this.ProductList[index]["Variance"].push({
          ID: this.VarianceInput.value,
          Name: this.VarianceInput.option.find(
            option => option.key == this.VarianceInput.value
          ).name,
          Quantity: this.VarianceQuantityInput.value,
          Price: this.VarianceInput.option.find(
            option => option.key == this.VarianceInput.value
          ).price
        });
      } else {
        this.ProductList[index]["Variance"][varianceIndex][
          "Quantity"
        ] = this.VarianceQuantityInput.value;
      }
      this.popupcancel();
    },
    checkEdited: function() {
      this.$emit("checkEdited", true);
    },
    getProduct: async function(ProductList) {
      try {
        const ret = await this.$store.dispatch("getProduct", {
          router: this.$router,
          product: { ProductList: ProductList }
        });
        //console.log("getProduct", ret.data);
        return ret.data.ProductDetailList;
      } catch (err) {
        console.log(err.message);
      }
    },
    addVariance: async function(ID) {
      console.log("addVariance", ID);
      this.selectingProductID = ID;
      this.VarianceInput.value = "";
      this.VarianceInput.option = [];
      this.VarianceQuantityInput.value = "";
      var varianceList = await this.getProductVariance(ID);
      if (varianceList.length > 0) {
        for (var i = 0; i < varianceList.length; i++) {
          this.VarianceInput.option.push({
            key: varianceList[i].VarianceID,
            name: varianceList[i].Name.zh,
            price: varianceList[i].Price
          });
        }
        console.log(this.VarianceInput);
        this.showAddVariancePopup = true;
      }

      //this.$router.push({name: "ProductVariance", params: {ID: ID}});
    },
    getProductVariance: async function(ProductID) {
      try {
        const ret = await this.$store.dispatch("getProductVariance", {
          router: this.$router,
          data: { ProductID: ProductID }
        });
        console.log("getProductVariance", ret.data);
        return ret.data.ProductVariance;
        //return ret.data.ProductVarianceList;
      } catch (err) {
        console.log(err.message);
      }
    },

    addproduct: async function() {
      console.log("go to add product");
      this.isLoading = true;
      await this.searchItem({ keyword: "" });
      this.isLoading = false;
      this.showSelectProduct = true;
    },
    removeproduct: function(ID) {
      var index = this.ProductList.findIndex(product => product["ID"] == ID);
      this.ProductList.splice(index, 1);
      var filterOutIndex = this.filterOut.findIndex(
        item => item.match.ProductID == ID.split("::")[0]
      );
      this.filterOut.splice(filterOutIndex, 1);
    },
    removeVariance: function(ProductID, VarianceID) {
      var productindex = this.ProductList.findIndex(
        product => product["ID"] == ProductID
      );
      var varianceIndex = this.ProductList[productindex]["Variance"].findIndex(
        variance => variance["ID"] == VarianceID
      );
      this.ProductList[productindex]["Variance"].splice(varianceIndex, 1);
    },
    setDefaultSort: function() {
      var index = this.sortOption.findIndex(option => option.Value === true);
      this.Sort[this.sortOption[index].Key] = this.sortOption[index].Order;
    },
    setFilter: function(filterOption) {
      console.log("setFilter", filterOption);
      this.filter = {};
      for (var f in filterOption) {
        var option = filterOption[f];
        if (option.type == "range") {
          var filterObject = {};
          if (option.from) {
            filterObject["Start"] = option.from;
          }
          if (option.to) {
            filterObject["End"] = option.to;
          }
          if (filterObject.Start || filterObject.End) {
            this.range[option.Key] = filterObject;
          }
        } else {
          var filterList = [];
          for (var o in option.option) {
            if (option.option[o].Value) {
              filterList.push(option.option[o].Key);
            }
          }
          if (filterList.length > 0) {
            this.filter[option.Key] = filterList;
          }
        }
      }
      this.selectAll = false;
      (this.selectedItem = []), (this.selectedItemCounter = 0);

      this.index = 0;
      this.pageNumber = 1;
      console.log("filter", this.filter);
      this.searchItem({ keyword: "" });
    },
    SortPopupControl: function() {
      this.showSortPopup = !this.showSortPopup;
    },
    goPage: function(action) {
      if (action == "next") {
        this.index = this.index + this.displaySize;
        this.pageNumber = this.pageNumber + 1;
      } else if (action == "prev" && this.index > 0) {
        this.index = this.index - this.displaySize;
        this.pageNumber = this.pageNumber - 1;
      } else {
        this.index = 0;
        this.pageNumber = 1;
      }
      this.searchItem({ keyword: "" });
    },
    selectedItemActionFunction: async function(action) {
      console.log("selectedItemActionFunction", this.selectedItem);
      if (action == "addselect") {
        for (var i = 0; i < this.selectedItem.length; i++) {
          var index = this.ProductList.findIndex(
            product => product.ID == this.selectedItem[i]
          );
          if (index < 0) {
            this.filterOut.push({
              match: { ProductID: this.selectedItem[i].split("::")[0] }
            });
          }
        }
        var productreuslt = await this.getProduct(this.selectedItem);
        this.selectedProductList = [];
        for (var i = 0; i < productreuslt.length; i++) {
          var objetc = {
            ID: productreuslt[i].ProductID,
            img: this.s3path + productreuslt[i].Image[0].path,
            "Product Name": productreuslt[i].Name.zh,
            "Minimum Price": productreuslt[i].MinimumPrice,
            Variance: []
          };
          if (this.haveQuantity) {
            objetc["Quantity"] = 1;
          }
          if (this.haveOption) {
            objetc["Option"] = null;
          }
          this.selectedProductList.push(objetc);
        }

        this.selectedItem = [];
        this.selectedItemCounter = this.selectedItem.length;
        this.showSelectProduct = false;
        this.$emit("setProductList", this.selectedProductList);
      }
    },
    setFilterOut: function() {
      this.filterOut = [];
      if (this.ProductList.length == 0) {
        return;
      }
      for (var i = 0; i < this.ProductList.length; i++) {
        this.filterOut.push({ match: { ProductID: this.ProductList[i].ID } });
      }
    },
    selectSortOption: function(option) {
      console.log("charge sorting with " + option);
      for (var i = 0; i < this.sortOption.length; i++) {
        if (this.sortOption[i].Key == option) {
          this.sortOption[i].Value = true;
        } else {
          this.sortOption[i].Value = false;
        }
      }
      var object = {};
      object[option] = { order: "desc" };
      this.Sort = [object];
      this.showSortPopup = false;
      this.searchItem({ keyword: "" });
    },
    selectAllItem: async function(action, { keyword }) {
      console.log("action", action);
      console.log("keyword", keyword);
      if (!action) {
        this.selectAll = true;
        this.isLoading = true;
        var object = {
          Source: this.Source,
          Filter: this.filter,
          Range: this.range
        };
        if (keyword) {
          object["Keyword"] = keyword;
        }
        if (this.filterOut.length > 0) {
          object["FilterOut"] = this.filterOut;
        }
        try {
          const ret = await this.$store.dispatch("searchProduct", {
            router: this.$router,
            keyword: object
          });
          this.selectedItem = ret.data.data.hits.hits.map(item =>
            String(item._id)
          );
          this.isLoading = false;
        } catch (err) {
          this.formMessage = err.response.data.message;
          this.showFormMessage = true;
        }
      } else {
        this.selectAll = false;
        this.selectedItem = [];
      }
      console.log("after", this.selectedItem);
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectPageItem: async function(action) {
      console.log("selectPageItem", action);
      console.log(this.TableItemList);
      if (action) {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index == -1) {
            this.selectedItem.push(this.TableItemList[i].ID);
          }
        }
      } else {
        for (var i in this.TableItemList) {
          var index = this.selectedItem.indexOf(this.TableItemList[i].ID);
          if (index > -1) {
            this.selectedItem.splice(index, 1);
          }
        }
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    onclickItem: function(ID) {
      var index = this.selectedItem.indexOf(ID);
      if (index > -1) {
        this.selectedItem.splice(index, 1);
      } else {
        this.selectedItem.push(ID);
      }
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectItem: function(ID, action) {
      console.log(ID, action);
      if (action) {
        this.selectedItem.push(ID);
      } else {
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID);
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length;
    },
    searchItem: async function({ keyword }) {
      var object = {
        Size: this.displaySize,
        From: this.index,
        Sort: this.Sort,
        Source: this.Source,
        Filter: this.filter,
        Range: this.range
      };
      if (keyword) {
        object["Keyword"] = keyword;
        this.selectAll = false;
        (this.selectedItem = []), (this.selectedItemCounter = 0);
        this.index = 0;
        this.pageNumber = 1;
      }
      if (this.filterOut.length > 0) {
        object["FilterOut"] = this.filterOut;
      }
      try {
        const ret = await this.$store.dispatch("searchProduct", {
          router: this.$router,
          keyword: object
        });
        this.setDisplayList(ret.data.data.hits.hits);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("searchProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setDisplayList(list) {
      console.log(list);
      var newlist = [];
      for (var i = 0; i < list.length; i++) {
        var object = {
          ID: list[i]["_source"].ProductID,
          產品名稱: list[i]["_source"].Name.zh,
          類型: list[i]["_source"].ProductType.Name.zh,
          最低價格: list[i]["_source"].MinimumPrice
        };
        if (list[i]["_source"].Image.length > 0) {
          object["img"] =
            process.env.VUE_APP_S3_URL +
            "product/" +
            list[i]["_source"].ProductID +
            "/" +
            list[i]["_source"].Image[0].ImageID;
        }

        newlist.push(object);
      }
      this.TableItemList = newlist;
      this.$forceUpdate();
      this.TableSelectUIControl(this.TableItemList);
      /*
        force update ui
      */
    },

    TableSelectUIControl(ItemList) {
      this.showNextButton = true;
      if (ItemList.length < this.displaySize) {
        this.showNextButton = false;
      }
      var counter = 0;
      for (var i = 0; i < ItemList.length; i++) {
        var index = this.selectedItem.indexOf(ItemList[i].ID);
        if (index > -1) {
          counter++;
        }
      }
      if (counter == ItemList.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  },
  watch: {
    showSelectProduct: function(val) {
      var rootClass = document.getElementsByClassName("md-theme-default")[0];
      if (val) {
        /*
            set scroll-y to hide the scrollbar
          */
        rootClass.style.overflowY = "hidden";
      } else {
        rootClass.style.overflowY = "auto";
      }
    }
  },
  name: "product-selecter-component",
  props: {
    ProductList: {
      type: Array,
      default() {
        return [];
      }
    },
    HeaderTitle: {
      type: String,
      default() {
        return "Add Product to";
      }
    },
    editAble: {
      default() {
        return true;
      }
    },
    haveQuantity: {
      default() {
        return false;
      }
    },
    haveOption: {
      default() {
        return false;
      }
    },
    Option: {
      default() {
        return [];
      }
    },
    displaySize: {
      default() {
        return 10;
      }
    }
  },
  data() {
    return {
      s3path: process.env.VUE_APP_S3_URL,
      showSelectProduct: false,
      index: 0,
      pageNumber: 1,
      showNextButton: true,
      showSortPopup: false,
      isLoading: false,
      actionButton: "確定",
      columns: ["img", "產品名稱", "類型", "最低價格"],
      Source: [
        "Name",
        "ProductType.Name",
        "MinimumPrice",
        "Image",
        "ProductID"
      ],
      Sort: { CreatedDatetime: "desc" },
      sortOption: [
        { Key: "CreatedDatetime", Name: "最新", Value: true, Order: "desc" },
        { Key: "MinimumPrice", Name: "價格", Value: false, Order: "desc" }
      ],
      selectedItemAction: [{ Key: "addselect", Name: "加入" }],
      filterOption: [
        {
          Key: "ProductType",
          Name: "類型",
          type: "checkbox",
          inputType: "checkbox",
          option: []
        },
        {
          Key: "MinimumPrice",
          Name: "價格",
          type: "range",
          inputType: "number",
          from: "",
          to: ""
        }
      ],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      haveSearchFunction: true,
      filter: {},
      range: {},
      filterOut: [],
      showAddVariancePopup: false,
      selectingProductID: "",
      VarianceInput: {
        key: "Variance",
        name: "分類",
        required: true,
        value: "",
        option: []
      },
      VarianceQuantityInput: {
        key: "Quantity",
        name: "數量",
        required: true,
        value: "",
        type: "number"
      }
    };
  }
};
</script>

<style>
.productSelecterTable {
  width: 100%;
}
.productSelecterTable tr td {
  border: none !important;
}
.productRemove {
  font-size: 18px;
  padding-top: 25px;
  color: #cc2727;
  cursor: pointer;
}
.varianceRemove {
  font-size: 18px;
  color: #cc2727;
  cursor: pointer;
}
.productText {
  font-size: 16px;
  padding-top: 25px;
  padding-left: 20px;
}
.productImg {
  width: 100px !important;
  height: 100px !important;
}
.backButtonTitlePadding {
  padding-left: 20px;
}
.backIcon {
  padding: unset;
  margin: unset;
  color: black !important;
}
.backbutton {
  padding: unset;
  margin: 15px 0 0 0 !important;
  min-width: 40px !important;
  width: 40px !important;
  height: 45px !important;
}
.formTitle {
  padding-left: 15px;
  margin-top: 10px;
  font-weight: 500;
}
.action {
  text-align: right;
  width: 50%;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.product-select-container {
  overflow-y: auto;
  max-width: 100%;
  height: 100%;
  margin: 0px auto;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
</style>
