import Router from "./main.js";

const routerModule = {
  namespaced: true,
  state: {
    prevPages: [],
    inspirationCacheUrl: null
  },
  // getters: {
  //   getPageHistory( state ){
  //       return state.prevPages;
  //   },
  // },
  mutations: {
    setPageHistory(state, page) {
      if (page.name == "login") {
        return;
      }
      state.prevPages.push({
        name: page.name,
        params: page.params
      });
      // only keep 3 items
      while (state.prevPages.length > 3) {
        state.prevPages.shift();
      }
    }
  },
  actions: {
    redirectToPageBeforeVerify(context) {
      try {
        const targetPageItem =
          context.state.prevPages[context.state.prevPages.length - 2]; // skip the verify page
        console.log("targetPageItem", targetPageItem);
        if (!targetPageItem) return;
        return targetPageItem;
      } catch (err) {
        console.log("Error: no prev pages");
        Router.push("/dashboard");
      }
    }
  }
};

export default routerModule;
