<template>
  <div>
    <label>{{ Item.name }}</label
    ><label style="color:red" v-if="Item.required">*</label>
    <!-- <md-input :type=Item.type :value=Item.value class="formInput" v-model=Item.value  @change="checkEdited"/> -->
    <md-chips
      v-model="Item.value"
      md-check-duplicated
      style="margin-top:0px;padding-top:0px"
    ></md-chips>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    checkEdited: function(event) {
      this.$emit("checkEdited", true);
    }
  },
  name: "chips-input-component",
  props: {
    Item: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
<style></style>
