<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          HeaderTitle="Add Product Order"
          :confirmBack="confirmBack"
          formActionButton="計算"
          v-on:formAction="formAction"
        >
        </FormHeader>
        <center>
          <md-card style="width:70%;min-width: 600px;text-align: left;">
            <md-card-content>
              <h3>用戶</h3>
              <div class="row">
                <TextInput :item="UserInfo" style="width: 50%;" />
                <div v-if="searchUserDone">
                  <md-icon v-if="findUser" style="padding-top: 40px;color:green"
                    >check_circle_outline</md-icon
                  >
                  <md-icon
                    v-else
                    style="padding-top: 35px;padding-left: 35px;color:red;transform: rotate(45deg);"
                    >add_circle_outline</md-icon
                  >
                </div>

                <button
                  @click="SearchUserByEmail()"
                  class="tablenewItemButton"
                  style="margin: 25px"
                >
                  搜尋
                </button>
              </div>

              <!-- <AutoComplete
                    :item="UserInfo"
                    v-on:checkEdited="checkEdited"
                    v-on:checkAutocomplete="searchUser"
                    v-on:setAutocompleteValue="setUser"
                    style="width: 50%;"
                /> -->
              <h3>收件方式</h3>
              <SelectInput
                v-on:checkEdited="checkEdited"
                v-on:selected="DeliveryMethodSelected"
                :Item="DeliveryMethodInput"
                style="width: 50%;"
              />
              <div v-if="DeliveryMethodInput.value == 'delivery'">
                <h3>收件資料</h3>
                <div class="deliverAddress">
                  <div class="row">
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.familyNameInput"
                      />
                    </div>
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.givenNameInput"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.phoneInput"
                      />
                    </div>
                  </div>
                  <TextInput
                    v-on:checkEdited="checkEdited"
                    :Item="DeliveryInfo.addressOneInput"
                  />

                  <TextInput
                    v-on:checkEdited="checkEdited"
                    :Item="DeliveryInfo.addressTwoInput"
                  />
                  <div class="row">
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.districtInput"
                      />
                    </div>
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.postCodeInput"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.cityInput"
                      />
                    </div>
                    <div class="column" style="width: 50%;">
                      <TextInput
                        v-on:checkEdited="checkEdited"
                        :Item="DeliveryInfo.countryInput"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <ProductVarianceSelecter
                v-on:setProductList="setProductList"
                v-on:checkEdited="checkEdited"
                :ProductList="ProductList"
                :displaySize="displaySize"
              />
              <div class="row" style="padding-top:50px">
                <div class="column" style="width: 50%;">
                  <h3>付款方式</h3>
                  <SelectInput
                    v-on:checkEdited="checkEdited"
                    :Item="PaymentMethodInput"
                    style="width: 50%;"
                  />
                </div>
                <div class="column" style="width: 50%;">
                  <h3>訂單狀態</h3>
                  <SelectInput
                    v-on:checkEdited="checkEdited"
                    :Item="OrderStatusInput"
                    style="width: 50%;"
                  />
                </div>
              </div>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Transition name="modal" v-if="showCalculatePopup">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="order-container">
            <div class="popupclose" @click="showCalculatePopup = false">
              <md-icon>close</md-icon>
            </div>
            <div class="popup">
              <div class="modal-header">
                <slot name="header">訂單預覽</slot>
              </div>
              <div class="modal-body">
                <div class="orderinfo">
                  <label>用戶: {{ User.Name }}</label> <br />
                  <label
                    >收件方式:
                    {{
                      DeliveryMethodInput.value == "pickup" ? "自取" : "送貨"
                    }}</label
                  >
                  <br />
                  <div v-if="DeliveryMethodInput.value == 'delivery'">
                    <label>收件人資料:</label> <br />
                    <label
                      >姓名: {{ DeliveryInfo.familyNameInput.value }}
                      {{ DeliveryInfo.givenNameInput.value }}</label
                    >
                    <br />
                    <label>電話: {{ DeliveryInfo.phoneInput.value }}</label>
                    <br />
                    <label
                      >地址1: {{ DeliveryInfo.addressOneInput.value }}</label
                    >
                    <br />
                    <label
                      >地址2: {{ DeliveryInfo.addressTwoInput.value }}</label
                    >
                    <br />
                    <label>地區: {{ DeliveryInfo.districtInput.value }}</label>
                    <br />
                    <label
                      >郵遞區號: {{ DeliveryInfo.postCodeInput.value }}</label
                    >
                    <br />
                    <label>城市: {{ DeliveryInfo.cityInput.value }}</label>
                    <br />
                    <label
                      >國家/地區: {{ DeliveryInfo.countryInput.value }}</label
                    >
                    <br />
                  </div>
                  <label
                    >訂單狀態:
                    {{ getOrderStatusName(OrderStatusInput.value) }}</label
                  >
                  <br />
                </div>

                <div style="max-height: 350px; overflow: auto;">
                  <div
                    class="row"
                    v-for="product in ProductList"
                    :key="product.ID"
                    style="margin: 10px 20px;"
                  >
                    <div
                      class="column"
                      style="width: 20%;max-width: 200px; text-align: center;"
                    >
                      <img class="productImg" :src="product.img" />
                      <div>{{ product["Product Name"] }}</div>
                    </div>

                    <div class="column" style="width: 75%;max-width: 850px;">
                      <div
                        v-if="product.Variance.length > 0"
                        class="row"
                        style="width: 100%; padding: 20px 5% 20px 5%"
                      >
                        <table>
                          <tr>
                            <th>選項</th>
                            <th>數量</th>
                            <th>單價</th>
                            <th>總價</th>
                            <th></th>
                          </tr>
                          <tr
                            v-for="variance in product.Variance"
                            :key="variance.ID"
                            style="text-align: center;"
                          >
                            <td>{{ variance["Name"] }}</td>
                            <td>{{ variance["Quantity"] }}</td>
                            <td>${{ variance["Price"] }}</td>
                            <td>
                              ${{
                                parseInt(variance["Quantity"]) *
                                  parseInt(variance["Price"])
                              }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- {{ CalculateResult }} -->

                <div class="column">
                  <div class="orderinfo">
                    <label>總計: ${{ CalculateResult.SubTotal }}</label> <br />
                    <label>運費: ${{ CalculateResult.ShippingFee }}</label>
                    <br />
                    <label>稅項: ${{ CalculateResult.Tax }}</label> <br />
                    <div v-if="CalculateResult.Discount">
                      <div
                        v-if="
                          CalculateResult.Discount.CampaignList &&
                            CalculateResult.Discount.CampaignList.length > 0
                        "
                      >
                        <div
                          v-for="(campaign, index) in CalculateResult.Discount
                            .CampaignList"
                          :key="index"
                        >
                          <label>
                            <!-- {{ campaign }} -->
                            折扣: {{ campaign.Description.zh }} : ${{
                              campaign.Discount
                            }}
                          </label>
                          <br />
                        </div>
                      </div>
                      <div class="row" style="padding-left: 8px;">
                        <label>總價: ${{ CalculateResult.Total }}</label>
                        <div @click="showEditOrderPopup = true">
                          <md-icon class="editOrder">edit</md-icon>
                        </div>
                      </div>
                      <div v-if="NewTotal > 0">
                        <label>新總價: ${{ NewTotal }}</label> <br />
                        <label>新總價原因: {{ NewTotalReason }}</label> <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="PaymentMethodInput.value == 'PayMe'"
                  style="text-align: center;"
                >
                  <img src="@/assets/img/payme.jpg" style="width: 50%;" />
                </div>
                <div
                  v-if="PaymentMethodInput.value == 'FPS'"
                  style="text-align: center;"
                >
                  FPS: 160704664
                </div>
                <div class="buttonArea">
                  <button
                    class="actionButton orderButton"
                    @click="createOrder()"
                  >
                    生成訂單
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="modal" v-if="showEditOrderPopup">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popupclose" @click="showEditOrderPopup = false">
              <md-icon>close</md-icon>
            </div>
            <div class="popup">
              <div class="modal-header">
                <slot name="header">Add Variance</slot>
              </div>
              <div class="modal-body">
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="NewTotalInput"
                />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="NewTotalReasonInput"
                />

                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <button
                      class="cancelButton"
                      @click="showEditOrderPopup = false"
                    >
                      取消
                    </button>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="actionButton" @click="updateTotal()">
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  //AutoComplete,
  ProductVarianceSelecter,
  TextInput,
  SelectInput,
  Spinner
} from "@/components";

export default {
  components: {
    FormHeader,
    //AutoComplete,
    ProductVarianceSelecter,
    TextInput,
    SelectInput,
    Spinner
  },
  async created() {
    // var height = window.innerHeight;
    // console.log("height", height);
    // if(height < 830){
    //    this.displaySize = Math.ceil(height/52/2);
    // }
  },
  methods: {
    DeliveryMethodSelected: function(value) {
      console.log("DeliveryMethodSelected", value);
      if (value == "pickup") {
        this.OrderStatusInput.value = "COMPLETED";
      } else {
        this.OrderStatusInput.value = "IN PROCESS";
      }
    },
    updateTotal: function() {
      console.log("updateTotal");
      if (this.NewTotalInput.value == 0) {
        this.formMessage = "請輸入新總價";
        this.showFormMessage = true;
        return;
      }
      if (this.NewTotalReasonInput.value == "") {
        this.formMessage = "請輸入新總價原因";
        this.showFormMessage = true;
        return;
      }
      //new toal price should not higher than the original total price
      if (this.NewTotalInput.value > this.CalculateResult.Total) {
        this.formMessage = "新總價不可高於原總價";
        this.showFormMessage = true;
        return;
      }
      //new total price should not lower than the original 85% total price
      // if(this.NewTotalInput.value < this.CalculateResult.Total * 0.85){
      //   this.formMessage = "新總價不可低於原總價的85%";
      //   this.showFormMessage = true;
      //   return;
      // }
      this.NewTotal = this.NewTotalInput.value;
      this.NewTotalReason = this.NewTotalReasonInput.value;
      this.showEditOrderPopup = false;
    },
    formAction: async function() {
      if (this.DeliveryMethodInput.value == "delivery") {
        this.formCheck();
        if (this.showFormMessage) {
          return;
        }
      }

      this.VarianceList = [];
      for (var p = 0; p < this.ProductList.length; p++) {
        for (var v = 0; v < this.ProductList[p].Variance.length; v++) {
          this.VarianceList.push({
            VarianceID: this.ProductList[p].Variance[v].ID,
            Quantity: this.ProductList[p].Variance[v].Quantity
          });
        }
      }
      if (this.VarianceList.length == 0) {
        this.formMessage = "請選擇商品";
        this.showFormMessage = true;
        return;
      }
      var object = {
        UserID: this.User.Key,
        VarianceList: this.VarianceList,
        DeliveryMethod: this.DeliveryMethodInput.value
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("calculateOrder", {
          router: this.$router,
          data: object
        });
        console.log(ret.data.data);
        this.CalculateResult = ret.data.data;
        this.isLoading = false;
        this.showCalculatePopup = true;
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
        console.log("formAction error", err.response.data);
      }
    },
    formCheck: async function() {
      console.log("formCheck");
      if (this.ProductList.length == 0) {
        this.formMessage = "請選擇商品";
        this.showFormMessage = true;
        return;
      }
      if (this.User.Key == null) {
        this.formMessage = "請選擇用戶";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.familyNameInput.value == "") {
        this.formMessage = "請輸入姓氏";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.givenNameInput.value == "") {
        this.formMessage = "請輸入名字";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.phoneInput.value == "") {
        this.formMessage = "請輸入電話";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.addressOneInput.value == "") {
        this.formMessage = "請輸入地址 1";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.districtInput.value == "") {
        this.formMessage = "請輸入地區";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.cityInput.value == "") {
        this.formMessage = "請輸入城市";
        this.showFormMessage = true;
        return;
      }
      if (this.DeliveryInfo.countryInput.value == "") {
        this.formMessage = "請輸入國家/地區";
        this.showFormMessage = true;
        return;
      }
    },
    createOrder: async function() {
      var object = {
        UserID: this.User.Key,
        DeliveryMethod: this.DeliveryMethodInput.value,
        VarianceList: this.VarianceList,
        Status: this.OrderStatusInput.value,
        PaymentMethod: this.PaymentMethodInput.value
      };
      if (this.DeliveryMethodInput.value == "delivery") {
        object.DeliveryAddress = {
          family_name: this.DeliveryInfo.familyNameInput.value,
          given_name: this.DeliveryInfo.givenNameInput.value,
          phone: this.DeliveryInfo.phoneInput.value,
          addressOne: this.DeliveryInfo.addressOneInput.value,
          addressTwo: this.DeliveryInfo.addressTwoInput.value,
          district: this.DeliveryInfo.districtInput.value,
          postCode: this.DeliveryInfo.postCodeInput.value,
          city: this.DeliveryInfo.cityInput.value,
          country: this.DeliveryInfo.countryInput.value
        };
      }
      if (this.NewTotal > 0) {
        object.NewTotal = parseInt(this.NewTotal);
        object.NewTotalReason = this.NewTotalReason;
      }
      console.log("formAction", object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createOrder", {
          router: this.$router,
          data: object
        });
        console.log(ret.data);
        this.showCalculatePopup = false;
        this.isLoading = false;
        this.$router.push({ name: "Payments" });
      } catch (err) {
        this.isLoading = false;
        console.log("formAction error", err.response.data);
      }
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    SearchUserByEmail: async function() {
      console.log("SearchUserByEmail", this.UserInfo.value);
      var object = {
        Email: this.UserInfo.value
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("SearchUserByEmail", {
          router: this.$router,
          keyword: object
        });
        console.log(ret.data);
        this.searchUserDone = true;
        if (ret.data.userInfo.length > 0) {
          console.log("find user");
          this.findUser = true;
          this.setUser({
            Key: ret.data.userInfo[0].UserID,
            Name: ret.data.userInfo[0].Email
          });
        } else {
          console.log("not find user");
          this.findUser = false;
        }
        this.isLoading = false;
        this.$forceUpdate();
      } catch (err) {
        this.isLoading = false;
        console.log("SearchUser error", err.response.data);
      }
    },
    setUser: function(object) {
      this.User = object;
    },
    setProductList: function(list) {
      for (var i = 0; i < list.length; i++) {
        var index = this.ProductList.findIndex(x => x.ID == list[i].ID);
        if (index < 0) {
          this.ProductList.push(list[i]);
        }
      }
    },
    getOrderStatusName: function(key) {
      var index = this.OrderStatusInput.option.findIndex(x => x.key == key);
      if (index >= 0) {
        return this.OrderStatusInput.option[index].name;
      }
      return "";
    }
  },
  watch: {
    showCalculatePopup: function(val) {
      var rootClass = document.getElementsByClassName("md-theme-default")[0];
      if (val) {
        /*
            set scroll-y to hide the scrollbar
          */
        rootClass.style.overflowY = "hidden";
      } else {
        rootClass.style.overflowY = "auto";
      }
    }
  },
  data() {
    return {
      searchUserDone: false,
      findUser: false,
      isLoading: false,
      formActionButton: "",
      formMessage: null,
      showFormMessage: false,
      showCalculatePopup: false,
      showEditOrderPopup: false,
      confirmBack: false,
      displaySize: 10,
      UserSource: ["UserID", "Email"],
      ProductSource: ["ProductID", "Name"],
      UserInfo: {
        name: "Email",
        autocompleteList: []
      },
      ProductInfo: {
        name: "Product",
        autocompleteList: []
      },
      User: {},
      ProductList: [],
      VarianceList: [],
      CalculateResult: {},
      DeliveryInfo: {
        addressOneInput: {
          key: "addressOne",
          name: "地址 1",
          required: true,
          value: "",
          type: "text"
        },
        addressTwoInput: {
          key: "addressTwo",
          name: "地址 2",
          required: false,
          value: "",
          type: "text"
        },
        districtInput: {
          key: "district",
          name: "地區",
          required: true,
          value: "",
          type: "text"
        },
        phoneInput: {
          key: "phone",
          name: "電話",
          required: true,
          value: "",
          type: "text"
        },
        cityInput: {
          key: "city",
          name: "城市",
          required: true,
          value: "",
          type: "text"
        },
        countryInput: {
          key: "country",
          name: "國家/地區",
          required: true,
          value: "",
          type: "text"
        },
        familyNameInput: {
          key: "family_name",
          name: "姓氏",
          required: true,
          value: "",
          type: "text"
        },
        givenNameInput: {
          key: "given_name",
          name: "名字",
          required: true,
          value: "",
          type: "text"
        },
        postCodeInput: {
          key: "postCode",
          name: "郵遞區號",
          required: false,
          value: "",
          type: "text"
        }
      },
      DeliveryMethodInput: {
        key: "DeliveryMethod",
        name: "收件方式",
        required: true,
        value: "pickup",
        option: [
          {
            key: "pickup",
            name: "自取"
          },
          {
            key: "delivery",
            name: "送貨"
          }
        ]
      },
      OrderStatusInput: {
        key: "Status",
        name: "訂單狀態",
        required: true,
        value: "COMPLETED",
        option: [
          {
            key: "IN PROCESS",
            name: "處理中"
          },
          {
            key: "COMPLETED",
            name: "已完成"
          }
        ]
      },
      PaymentMethodInput: {
        key: "PaymentMethod",
        name: "付款方式",
        required: true,
        value: "Cash",
        option: [
          {
            key: "Cash",
            name: "現金"
          },
          {
            key: "FPS",
            name: "轉數快"
          },
          {
            key: "PayMe",
            name: "PayMe"
          }
        ]
      },
      NewTotalInput: {
        key: "newTotal",
        name: "新總價",
        required: true,
        value: "",
        type: "number"
      },
      NewTotalReasonInput: {
        key: "newTotalReason",
        name: "新總價原因",
        required: true,
        value: "",
        type: "text"
      },
      NewTotal: 0,
      NewTotalReason: ""
    };
  }
};
</script>

<style>
.deliverAddress {
  width: 100%;

  .row {
    width: 100%;
  }

  .column {
    padding: 0px 10px;
  }
}
.popupclose {
  position: sticky;
  top: 3px;
  left: 3px;
  cursor: pointer;
}
.order-container {
  overflow-y: auto;
  width: 50%;
  height: 80%;
  margin: 0px auto;
  position: relative;
  background-color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
.orderinfo label {
  padding: 0 10px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}
.editOrder {
  cursor: pointer;
  font-size: 18px !important;
}
.orderButton {
  width: 20%;
  min-width: 200px;
  max-width: 250px;
}
.buttonArea {
  width: 90%;
  text-align: center;
  position: absolute;
  bottom: 30px;
}
</style>
