<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createTasteNote"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <div class="row">
          <div class="col" style="width: 45%;text-align: left;">
            <md-card>
              <md-card-content>
                <h3>官方名稱</h3>
                <TextInput
                  v-for="item in NameInputList"
                  :key="item.key"
                  :Item="item"
                />
              </md-card-content>
            </md-card>
          </div>
          <md-card style="width: 50%;text-align: left;margin-left: 25px;">
            <md-card-content>
              <h3>額外關鍵字</h3>
              <table>
                <tr v-for="item in KeywordInputList" :key="item.key">
                  <td>
                    <TextInput
                      :Item="item"
                    />
                  </td>
                  <td>
                    <md-button
                      class="md-icon-button  md-accent"
                      @click="removeKeyword(item.key)"
                    >
                      <md-icon >delete</md-icon>
                    </md-button>
                  </td>
                </tr>
              </table>
              <md-card-content>
                <div style="text-align: center;" @click="addKeyword()">
                  <md-icon>add_circle_outline</md-icon>
                  <u class="addQuestion">關鍵字</u>
                </div>
              </md-card-content>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, TextInput } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput
  },
  async created() {
    await this.getLanguage();
  },
  methods: {
    addKeyword() {
      this.KeywordInputList.push({
        key: this.KeywordInputList.length,
        value: "",
        required: true
      });
    },
    removeKeyword(ID) {
      var keywordInput = this.KeywordInputList.find(item => item.key == ID);
      this.KeywordInputList.splice(this.KeywordInputList.indexOf(keywordInput), 1);
    },
    async createTasteNote() {
      this.isLoading = true;
      var object = {
        Name: {},
        Keyword: []
      };
      for (var i in this.KeywordInputList) {
        object.Keyword.push(this.KeywordInputList[i].value);
      }
      for (var i in this.NameInputList) {
        object.Name[this.NameInputList[i].key] = this.NameInputList[i].value;
      }
      try {
        const ret = await this.$store.dispatch("createAroma", {
          router: this.$router,
          data: object
        });
        console.log("createAroma", ret);
        this.isLoading = false;
        this.formMessage = "新增成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.replace({ name: "AromaList" })
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    /*
        api
    */
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        for (var i in this.languageList) {
          for (const [key, value] of Object.entries(this.languageList[i])) {
            this.NameInputList.push({
              key: value,
              name: value,
              value: this.Name[value],
              required: true
            });
          }
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "新增香氣",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      formMessage: "",
      Name: {},
      languageList: [],
      NameInputList: [],
      KeywordInputList: []
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
