<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:45%;min-width: 500px">
            <md-card style="margin:10px 0">
              <md-card-content>
                <div style="text-align: right;">
                  <md-button
                    style="background-color: #575757 !important;"
                    @click="PreviewInspiration()"
                    >Preview</md-button
                  >
                </div>

                <TextInput
                  :Item="FormItem.TitleInput"
                  v-on:checkEdited="checkEdited"
                />
                <TextInput
                  :Item="FormItem.DescriptionInput"
                  v-on:checkEdited="checkEdited"
                />
                <div v-if="AuthorList.length > 0">
                  <div v-for="(author, index) in AuthorList" :key="index">
                    {{ author.Name }}
                    <label
                      class="remove authorLabel"
                      @click="removeAuthor(author.Key)"
                      >remove</label
                    >
                  </div>
                </div>
                <md-button
                  style="background-color: #575757 !important;width:30%"
                  @click="showAddAuthor = true"
                  >+ Author</md-button
                >

                <ChipsInput
                  :Item="FormItem.TagInput"
                  v-on:checkEdited="checkEdited"
                />
                <SelectInput
                  style="padding: 20px 0;"
                  :Item="FormItem.StatusInput"
                  v-on:checkEdited="checkEdited"
                />

                <div class="row">
                  <div style="padding:0px 20px 0px 20px;width:100%">
                    <label>Media</label>
                    <div>
                      <md-radio v-model="MediaType" value="Image"
                        >Image</md-radio
                      >
                      <md-radio v-model="MediaType" value="Video"
                        >Video</md-radio
                      >
                      <md-radio v-model="MediaType" value="TasteCard"
                        >TasteCard</md-radio
                      >
                    </div>
                  </div>
                </div>
                <div v-if="MediaType == 'Image'">
                  <label>Media</label>

                  <draggable
                    :list="InspirationMediaInput"
                    :group="{ name: 'g1' }"
                  >
                    <div v-for="Item in InspirationMediaInput" :key="Item.ID">
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        v-on:SetItemObject="SetItemObject"
                        :Item="Item"
                        :acceptType="acceptType"
                      />
                    </div>
                  </draggable>
                  <div
                    style="text-align: center;"
                    @click="addInspirationMediaInput()"
                  >
                    <md-icon>add_circle_outline</md-icon>
                    <u class="addLang">Add Image</u>
                  </div>
                </div>
                <div v-if="MediaType == 'Video'">
                  <FileInput
                    v-on:RemoveItem="RemoveVideo"
                    :Item="VideoInput"
                    acceptType="video/*"
                  />
                  <FileInput
                    v-on:RemoveItem="RemoveThumbnail"
                    :Item="ThumbnailInput"
                    acceptType="image/*"
                  />
                </div>
                <div v-if="MediaType == 'TasteCard'">
                  <TasteCardInput
                    :Item="TasteCardInput"
                    v-on:checkEdited="checkEdited"
                  />
                </div>
              </md-card-content>
            </md-card>
            <md-card>
              <md-card-content>
                <ProductSelecter
                  v-on:setProductList="setProductList"
                  :ProductList="InspirationProductList"
                  :HeaderTitle="PopHeaderTitle"
                >
                </ProductSelecter>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:45%;padding-left: 30px;z-index: 0;">
            <InspirationPreview :previewItem="previewItem"></InspirationPreview>
          </div>
        </div>
      </div>
    </div>
    <Transition name="modal" v-if="showAddAuthor">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div class="modal-header">
                <slot name="header">Add Author</slot>
              </div>
              <div class="modal-body">
                <UserInput :Item="AuthorInput" v-on:checkEdited="checkEdited" />

                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <label @click="showAddAuthor = false">Cancel</label>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="cancelButton" @click="addAuthor()">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  //FormComponent,
  FormHeader,
  ProductSelecter,
  InspirationPreview,
  FileInput,
  Spinner,
  TextInput,
  UserInput,
  ChipsInput,
  SelectInput
} from "@/components";
import draggable from "vuedraggable";
import TasteCardInput from "./TasteCardInput.vue";
export default {
  components: {
    //FormComponent,
    FormHeader,
    ProductSelecter,
    InspirationPreview,
    FileInput,
    draggable,
    Spinner,
    TextInput,
    UserInput,
    ChipsInput,
    SelectInput,
    TasteCardInput
  },
  created() {
    //this.listAuthor();
  },
  methods: {
    AddMedia: function(type) {
      console.log("AddMedia", type);
      var now = new Date();
      var ID = now.getTime();
      var Object = {
        ID: ID,
        type: type,
        base64: null,
        url: null,
        value: null
      };
      if (type == "Image") {
        Object.accept = "image/*";
      } else if (type == "Video") {
        Object.accept = "video/*";
      }
      this.MediaList.push(Object);
    },
    addAuthor: function() {
      this.showAddAuthor = false;
      console.log("addAuthor", this.AuthorInput);
      this.AuthorList.push({
        Key: this.AuthorInput.value,
        Name: this.AuthorInput.model
      });
      this.AuthorInput.value = null;
      this.AuthorInput.model = null;
    },
    removeAuthor: function(ID) {
      console.log("removeAuthor", ID);
      var index = this.AuthorList.findIndex(x => x.Key == ID);
      if (index > -1) {
        this.AuthorList.splice(index, 1);
      }
    },
    setProductList: function(ProductList) {
      //push product list to inspiration product list
      for (var i = 0; i < ProductList.length; i++) {
        var index = this.InspirationProductList.findIndex(
          x => x.ID == ProductList[i].ID
        );
        if (index < 0) {
          this.InspirationProductList.push(ProductList[i]);
        }
      }
    },
    addInspirationMediaInput: function() {
      var now = new Date();
      var ID = now.getTime();
      var Object = {
        ID: ID,
        type: "Image",
        base64: "",
        url: "",
        value: ""
      };
      this.InspirationMediaInput.push(Object);
    },
    RemoveItem: function(ID) {
      console.log("RemoveItem", ID);
      var index = this.InspirationMediaInput.findIndex(x => x.ID == ID);
      if (index > -1) {
        this.InspirationMediaInput.splice(index, 1);
      }
    },
    RemoveVideo: function(ID) {
      console.log("RemoveVideo", ID);
      this.VideoInput.base64 = "";
      this.VideoInput.url = "";
      this.VideoInput.value = "";
    },
    RemoveThumbnail: function(ID) {
      console.log("RemoveThumbnail", ID);
      this.ThumbnailInput.base64 = "";
      this.ThumbnailInput.url = "";
      this.ThumbnailInput.value = "";
    },
    PreviewInspiration: async function() {
      var object = await this.getFormData(this.FormItem);

      this.previewItem["Title"] = object.Title;
      this.previewItem["Tag"] = object.Tag;
      this.previewItem["Description"] = object.Description;
      this.previewItem["mediaType"] = this.MediaType;

      var ProductList = [];
      for (var i = 0; i < this.InspirationProductList.length; i++) {
        var Productobject = {
          ProductID: this.InspirationProductList[i]["ID"],
          Name: this.InspirationProductList[i]["Product Name"],
          UnitPrice: this.InspirationProductList[i]["Unit Price"]
        };
        console.log("Productobject", Productobject);
        ProductList.push(Productobject);
      }
      this.previewItem["ProductList"] = ProductList;

      var authorIdIndex = this.AuthorList.findIndex(
        author => author.AuthorName == object.Author
      );
      if (authorIdIndex < 0) {
        this.formMessage = "Author not found";
        this.showFormMessage = true;
        return;
      }
      this.previewItem["Author"] = {
        AuthorName: object.Author,
        iconSrc:
          process.env.VUE_APP_S3_URL +
          "Author/" +
          this.AuthorList[authorIdIndex].AuthorID
      };

      var MediaList = [];
      if (this.MediaType == "Image") {
        for (var i = 0; i < this.InspirationMediaInput.length; i++) {
          MediaList.push(this.InspirationMediaInput[i].url);
        }
      } else {
        var MediaObject = await this.getFormData(this.VideoItem);
        for (var i = 0; i < MediaObject.Media.length; i++) {
          MediaList.push(MediaObject.Media[i].url);
        }
      }

      this.previewItem["MediaList"] = MediaList;

      console.log("previewItem", this.previewItem);
    },
    formAction: async function() {
      console.log("formAction", this.FormItem);
      var Data = {};
      for (const [key, value] of Object.entries(this.FormItem)) {
        console.log("key", key);
        console.log("value", value);
        if (value.required) {
          if (value.value) {
            if (value.type == "number") {
              Data[value.key] = parseInt(value.value);
            } else {
              Data[value.key] = value.value;
            }
          } else {
            this.formMessage = value.name + " is required";
            this.showFormMessage = true;
            return;
          }
        } else {
          if (value.value) {
            if (value.type == "number") {
              Data[value.key] = parseInt(value.value);
            } else {
              Data[value.key] = value.value;
            }
          } else {
            Data[value.key] = null;
          }
        }
      }
      if (this.AuthorList.length == 0) {
        this.formMessage = "Author is required";
        this.showFormMessage = true;
        return;
      }
      var Author = [];
      for (var a in this.AuthorList) {
        Author.push(this.AuthorList[a].Key);
      }
      Data["Author"] = Author;

      var MediaList = [];
      if (this.MediaType == "Image") {
        console.log("this.InspirationMediaInput", this.InspirationMediaInput);
        for (var i in this.InspirationMediaInput) {
          var imageObjetc = {
            type: "Image",
            MediaType: this.InspirationMediaInput[i].base64
              .split(":", 2)[1]
              .split(";", 1)[0]
          };
          MediaList.push(imageObjetc);
        }
      } else if (this.MediaType == "Video") {
        var videoObjetc = {
          type: "Video",
          MediaType: this.VideoInput.base64.split(":", 2)[1].split(";", 1)[0]
        };
        if (this.ThumbnailInput.base64) {
          videoObjetc["ThumbnailMediaType"] = this.ThumbnailInput.base64
            .split(":", 2)[1]
            .split(";", 1)[0];
        }
        MediaList.push(videoObjetc);
      } else {
        MediaList.push({
          type: "TasteCard",
          CardID: this.TasteCardInput.value
        });
      }
      Data["MediaList"] = MediaList;

      /*if(this.InspirationProductList.length == 0){
        this.formMessage = "Product is required"
        this.showFormMessage = true;
        return
      }*/
      var ProductList = [];
      for (var p in this.InspirationProductList) {
        ProductList.push(this.InspirationProductList[p].ID);
      }
      Data["ProductList"] = ProductList;
      console.log("Object", Data);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createInspiration", {
          router: this.$router,
          data: Data
        });
        console.log("createInspiration", ret);
        //await this.$store.dispatch('updateMediaToS3',{file:this.TasteCardImage.base64, urlObject:ret.data.tasteCardUrl})
        if (this.MediaType == "Image") {
          for (var i in this.InspirationMediaInput) {
            const retMedia = await this.$store.dispatch("updateMediaToS3", {
              file: this.InspirationMediaInput[i].base64,
              urlObject: ret.data.UploadMediaUrlList[i]
            });
          }
        } else if (this.MediaType == "Video") {
          const retMedia = await this.$store.dispatch("updateMediaToS3", {
            file: this.VideoInput.base64,
            urlObject: ret.data.UploadMediaUrlList[0]
          });
          if (this.ThumbnailInput.base64) {
            const retMedia = await this.$store.dispatch("updateMediaToS3", {
              file: this.ThumbnailInput.base64,
              urlObject: ret.data.UploadThumbnailUrl
            });
          }
        }
        this.isLoading = false;
        this.formMessage = "Create Inspiration Success";
        this.showFormMessage = true;

        setTimeout(() => {
          this.$router.push({ path: "/InspirationList" });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log(err.message);
      }
    },
    getFormData: async function(form) {
      var FormData = {};
      for (const [key, value] of Object.entries(form)) {
        for (var i = 0; i < value.length; i++) {
          if (value[i].required) {
            if (value[i].value) {
              if (value[i].type == "number") {
                FormData[value[i].key] = parseInt(value[i].value);
              } else {
                FormData[value[i].key] = value[i].value;
              }
            } else {
              this.formMessage = value[i].name + " is required";
              this.showFormMessage = true;
              return;
            }
          } else {
            if (value[i].type == "number") {
              FormData[value[i].key] = parseInt(value[i].value);
            } else {
              FormData[value[i].key] = value[i].value;
            }
          }
        }
      }
      return FormData;
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    }
  },

  data() {
    return {
      isLoading: false,
      showAddMedia: false,
      showAddImage: false,
      showAddAuthor: false,
      DoneButton: "Preview",
      HeaderTitle: "Add Inspiration",
      FormTitle: "New Inspiration",
      formActionButton: "Create Inspiration",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      MediaType: "Image",
      InspirationProductList: [],
      AuthorList: [],
      PopHeaderTitle: "Preview",
      previewItem: {
        Title: null,
        Tag: [],
        Description: null,
        mediaType: null,
        Author: {
          AuthorName: null,
          iconSrc: null
        },
        MediaList: [],
        ProductList: []
      },
      MediaList: [],
      FormItem: {
        TitleInput: {
          type: "text",
          key: "Title",
          name: "Title",
          required: true,
          style: "width:100%",
          value: null
        },
        DescriptionInput: {
          type: "text",
          key: "Description",
          name: "Description",
          required: false,
          style: "width:100%",
          value: ""
        },
        TagInput: {
          type: "array",
          key: "Tag",
          name: "Tags",
          style: "width:100%",
          required: false,
          value: []
        },
        StatusInput: {
          key: "Status",
          name: "Status",
          required: true,
          value: null,
          option: [
            {
              key: "Draft",
              name: "草稿"
            },
            {
              key: "Private",
              name: "私人"
            },
            {
              key: "Published",
              name: "公開"
            },
            {
              key: "UnListed",
              name: "不公開"
            }
          ]
        }
      },
      ImageItem: { ID: 1, base64: "", url: "" },
      AuthorInput: {
        type: "text",
        key: "Author",
        name: "Author",
        required: true,
        value: null,
        model: null,
        showAutocomplete: true,
        autocompleteList: []
      },
      TasteCardInput: {
        type: "text",
        key: "TasteCard",
        name: "TasteCard",
        required: true,
        value: null,
        model: null,
        showAutocomplete: true,
        autocompleteList: []
      },
      VideoInput: {
        ID: "Video",
        type: "Video",
        base64: "",
        url: "",
        value: ""
      },
      ThumbnailInput: {
        ID: "Thumbnail",
        type: "Image",
        base64: "",
        url: "",
        value: ""
      },
      InspirationMediaInput: [],
      acceptType: "image/*"
    };
  }
};
</script>
<style>
.authorLabel {
  right: 30px;
  position: absolute;
}
</style>
