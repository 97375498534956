<template lang="html">
  <div class="blog-item no-select">
    <BottomSheet title="Related Products" :height="550" ref="product-sheet">
      <div class="related-product-con">
        <div class="inner-con">
          <ProductGrid
            :productList="item.ProductList"
            :fixedColCount="2"
            darkTheme
            wishlist
          />
        </div>

        <md-button class="no-text-transform md-raised wishlishButton">
          Go to Wishlist
        </md-button>
      </div>
    </BottomSheet>

    <BottomSheet
      :title="`#${item.Tag[0]}`"
      :height="400"
      ref="related-blog-sheet"
    >
      <div class="related-blog-con">
        <md-list>
          <md-list-item v-for="(blog, i) in relatedBlogList" :key="i">
            <md-avatar>
              <img
                :src="require('@/assets/images/v2/one_sip_logo_square.png')"
              />
            </md-avatar>

            <div class="md-list-item-text">
              <span v-if="blog.Title">{{ blog.Title }}</span>
              <span v-if="blog.Description">{{ blog.Description }}</span>
              <div v-if="item.Tag">
                <span v-for="(tag, i) in item.Tag" :key="i"> #{{ tag }} </span>
              </div>
            </div>
          </md-list-item>
        </md-list>
      </div>
    </BottomSheet>

    <BottomSheet title="Share" :height="250" ref="social-share-sheet">
      <ShareButtonsCon />
    </BottomSheet>

    <div class="background-content full-size" v-if="item.mediaType">
      <Blog-Slider
        v-if="item.mediaType == 'Image'"
        ref="content-slider"
        :urls="item.MediaList"
      />
      <BlogVideo
        v-if="item.mediaType == 'Video'"
        ref="content-video"
        :url="item.MediaList"
      />
    </div>

    <!-- <span
            v-touch:swipe.stop="onSwipe"
            @click.stop="onClick"
        > -->
    <span @click.stop="onClick">
      <div class="cover-content full-size">
        <md-button
          class="search-button md-icon-button inspirationIcon"
          @click.stop="search"
        >
          <!-- <md-icon>search</md-icon> -->
          <img :src="require('@/assets/images/v2/Icons/icon_search.svg')" />
        </md-button>

        <div class="main-content" :class="{ 'indicator-space': haveIndicator }">
          <div class="text-info">
            <h2 class="title">
              {{ item.Title }}
            </h2>
            <div class="description">
              {{ item.Description }}
            </div>
            <div class="tags-list" v-if="item.Tag">
              <span
                v-for="(tag, i) in item.Tag"
                :key="i"
                @click.stop="searchTag(tag)"
              >
                #{{ tag }}
              </span>
            </div>

            <div class="author-con">
              <md-avatar>
                <img :src="item.Author.iconSrc" :alt="item.Author.AuthorName" />
              </md-avatar>

              <div class="name">{{ item.Author.AuthorName }}</div>
            </div>
          </div>

          <div class="btns-col">
            <div class="like-btn icon-text-btn" :class="{ active: isLiked }">
              <md-button
                class="md-icon-button inspirationIcon"
                @click.stop="like"
              >
                <!-- <md-icon v-if="isLiked">thumb_up</md-icon>
                              <md-icon v-else>thumb_up_off_alt</md-icon> -->
                <img
                  v-if="isLiked"
                  :src="
                    require('@/assets/images/v2/Icons/icon_like_active.svg')
                  "
                />
                <img
                  v-else
                  :src="require('@/assets/images/v2/Icons/icon_like.svg')"
                />
              </md-button>
              <span>Like</span>
            </div>

            <div class="share-btn icon-text-btn">
              <md-button
                class="md-icon-button inspirationIcon"
                @click.stop="share"
              >
                <!-- <md-icon>share</md-icon> -->
                <img
                  :src="
                    require('@/assets/images/v2/Icons/icon_share_white.svg')
                  "
                />
              </md-button>
              <span>Share</span>
            </div>

            <div class="product-list-btn">
              <md-button
                class="md-icon-button inspirationIcon"
                @click.stop="showProduct"
              >
                <!-- <md-icon>inventory</md-icon> -->
                <img
                  :src="
                    require('@/assets/images/v2/Icons/icon_shopping_bag_white.svg')
                  "
                />
              </md-button>
            </div>
          </div>
        </div>

        <div
          class="bottom-bar"
          v-if="item.Tag && item.Tag.length > 0"
          @click.stop="showRelatedBlog"
        >
          <div>
            <span>HIT</span>
            #{{ item.Tag[0] }}
          </div>

          <md-icon>expand_more</md-icon>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
//import BlogImage from "./BlogImage";
import BlogSlider from "./BlogSlider";
import BlogVideo from "./BlogVideo";

import BottomSheet from "./BottomSheet";
import ProductGrid from "./ProductGrid";
import ShareButtonsCon from "./ShareButtonsCon";

import FormatNum from "./formatNum";

export default {
  name: "BlogItem",
  components: {
    BlogSlider,
    BlogVideo,

    BottomSheet,
    ProductGrid,
    ShareButtonsCon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      clickTimeout: null,
      isLiked: false,
      productDetailList: [],
      relatedBlogList: [],
      authorInfo: {} // name + iconSrc

      // item: {
      //     title: "What's next for your daily coffee?",
      //     description: "Lorem ipsum dolor sit amet, consectetuer",
      //     tags: ["daily coffee", "sed", "diam", "nonummy", "nibh"],
      //     author: {
      //       iconSrc: require( "@/assets/images/v2/one_sip_logo_square.png" ),
      //       name: "Lincon Cafe",
      //     },
      // }
    };
  },
  computed: {
    // cssStyle(){
    //   console.log("AA");
    //
    //   if( !this.$refs.box ) return;
    //
    //   console.log("BB");
    //   console.log( "CH:" ,this.$el.clientHeight );
    //   console.log( "CH:" ,this.$refs.box.$el.clientHeight );
    //
    //   return {
    //     "--item-height": this.$ref.box.$el.clientHeight,
    //   };
    // },

    haveIndicator() {
      return this.item.mediaType == "Image" && this.item.MediaList.length > 1;
    }
  },
  created() {},
  mounted() {
    this.$nextTick(
      function() {
        // console.log( "height", this.calcBoxHeight() );
        // this.$emit("rendered", this.$el);
      }.bind(this)
    );
  },
  methods: {
    // calcBoxHeight(){
    //   if( !this.$refs.box ) return 0;
    //   console.log(this.$refs.box );
    //   console.log(this.$refs.box.$el );
    //   console.log(this.$refs.box.$el.clientHeight );
    //
    //   return this.$refs.box.$el.clientHeight;
    // },

    showProduct() {
      console.log("showProduct");
      this.$refs["product-sheet"].expand();
    },
    showRelatedBlog() {
      console.log("showRelatedBlog");
      this.$refs["related-blog-sheet"].expand();
    },
    searchTag(tag) {
      return function(event) {
        console.log("searchTag:", tag);
      };
    },

    toggleVideo() {
      if (this.$refs["content-video"]) {
        this.$refs["content-video"].toggleActive();
      }
    },

    onSwipe(direction) {
      console.log("onSwipe", direction);

      if (direction == "left") {
        if (this.$refs["content-slider"]) {
          this.$refs["content-slider"].toNextItem();
        }
      } else if (direction == "right") {
        if (this.$refs["content-slider"]) {
          this.$refs["content-slider"].toPrevItem();
        }
      } else if (direction == "top") {
        this.$emit("toPrev");
      } else if (direction == "bottom") {
        this.$emit("toNext");
      }
    },

    onClick() {
      if (this.clickTimeout) {
        // is double click
        this.like();

        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
      } else {
        // is single click
        this.clickTimeout = setTimeout(
          function() {
            if (this.$refs["content-video"]) {
              this.$refs["content-video"].toggleActive();
            }

            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
          }.bind(this),
          200
        ); // double click duration = 200ms
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.blog-item {
  position: relative;
  width: 100%;
  max-width: 350px;
  aspect-ratio: 9/16;
  overflow: hidden;
  font-family: MPLUSRounded1c !important;
  font-weight: 500 !important;
  letter-spacing: -0.01em !important;
  background: #ffffff;
  // margin: 100px 0;
  margin: auto;
}

.full-size {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cover-content {
  background: linear-gradient(
    #00000080 0%,
    transparent 10%,
    transparent 65%,
    #00000080 90%
  ); // opacity = .5

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;

  position: relative;

  .search-button {
    position: absolute;
    top: 5px;
    right: 0;

    .md-icon {
      font-size: 30px !important;
      color: #ffffff !important;
    }
  }
}

.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  margin-bottom: 5px;

  &.indicator-space {
    margin-bottom: 20px;
  }
}

.text-info {
  color: #ffffff !important;
  text-shadow: 2px 2px 2px #00000080;
  padding: 10px;
  margin-right: 10px;
  font-family: MPLUSRounded1c !important;

  > .title {
    color: #ffffff !important;
    text-shadow: 2px 2px 2px #00000080;
    font-size: 1.05em;
    font-weight: 500;
    margin: 0 0 0.25em 0;
    font-family: MPLUSRounded1c !important;
  }

  > .description {
    color: #ffffff !important;
    text-shadow: 2px 2px 2px #00000080;
    font-size: 0.9em;
  }

  > .tags-list {
    text-shadow: 2px 2px 2px #00000080;
    font-size: 0.9em;
    margin-bottom: 10px;

    > span {
      cursor: pointer;
    }
  }

  > .author-con {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > * {
      margin: 0;
      margin-right: 10px;
    }
  }
}
.md-layout {
  margin: unset;
}

.btns-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding-right: 5px;
  padding-bottom: 10px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 10px;

    text-shadow: 2px 2px 2px #00000080;
    color: #ffffff;
    font-size: 0.9em;
  }

  // .md-icon {
  //   width: 30px;
  //   height: 30px;
  //   font-size: 30px !important;
  //   text-shadow: 0 1px 3px #00000080;
  //   color: #FFFFFF !important;
  // }

  img {
    filter: drop-shadow(2px 2px 2px #00000080);
  }

  .like-btn.active img {
    animation: shake 0.5s;
  }

  .product-list-btn {
    background: #fc2864 !important;
    border-radius: 15px;
    // box-shadow: 0 1px 3px #00000080;
    margin-top: 20px;

    .md-button {
      margin: 0;

      .md-icon {
        font-size: 20px !important;
      }
    }
  }
}

@keyframes shake {
  0%,
  100% {
    transform: rotateZ(0) translateY(0);
  }

  50% {
    transform: rotateZ(-15deg) translateY(-5px);
  }
}

.bottom-bar {
  background: #00000040; // opacity = .25
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;

  cursor: pointer;

  > div {
    color: #ffffff;

    > span {
      // color: #FC2864;
      color: rgb(255, 126, 54);
      padding-right: 5px;
    }
  }

  > .md-icon {
    margin: 0;
    transform: rotate(180deg);
    color: #ffffff !important;
  }
}

.md-icon-button {
  background-color: transparent !important;
  box-shadow: none;
  border-radius: 50%;
  z-index: 5;
}
.md-icon-button:hover {
  background: rgba(#f4f4f4, 0.1) !important;
}
.related-product-con {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  height: 100%;

  > .inner-con {
    flex-grow: 1;
    height: 0;
    overflow: auto;
  }

  > .md-button {
    background: rgba(#f4f4f4, 0.1) !important;
  }
}
.inspirationIcon {
  height: 40px;
}
.wishlishButton {
  min-width: 88px;
  height: 36px;
  margin: 6px 8px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: MPLUSRounded1c;
  src: url("~@/assets/fonts/MPLUSRounded1c/MPLUSRounded1c-Black.ttf");
  font-weight: 900;
}
</style>
<style src="@/assets/styles/normalize.css"></style>
