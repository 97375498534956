<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div class="column" style="width:35%" v-if="!editOn">
            <md-card style="margin:10px 0">
              <md-card-content>
                <h3 class="formTitle">Email Info</h3>
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="EmailEventNameInput"
                />
                <TextInput v-on:checkEdited="checkEdited" :Item="SourceInput" />
                <TextInput
                  v-on:checkEdited="checkEdited"
                  :Item="EmailDateTimeInput"
                />
                <CheckBoxInput
                  v-on:checkEdited="checkEdited"
                  :Item="DraftInput"
                />
                <SelectInput
                  v-on:checkEdited="checkEdited"
                  :Item="EmailTemplateInput"
                />
                <ObjectInput
                  v-on:checkEdited="checkEdited"
                  :Item="TemplateDataInput"
                />
              </md-card-content>
            </md-card>
            <md-card style="margin:10px 0">
              <md-card-content>
                <h3 class="formTitle">Email List</h3>

                <SelectInput
                  v-on:checkEdited="checkEdited"
                  :Item="CustomerGroupInput"
                />
              </md-card-content>
            </md-card>
          </div>
          <div style="width:65%;padding-left: 30px;z-index: 0;">
            <HtmlPreview :HtmlPart="HtmlPart"></HtmlPreview>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  HtmlPreview,
  TextInput,
  SelectInput,
  ObjectInput,
  CheckBoxInput,
  Spinner
} from "@/components";

export default {
  components: {
    FormHeader,
    HtmlPreview,
    TextInput,
    SelectInput,
    ObjectInput,
    CheckBoxInput,
    Spinner
  },
  async created() {
    this.isLoading = true;
    await this.getEmailTemplateList();
    await this.getUserGroupList();
    this.isLoading = false;
  },
  methods: {
    formAction: async function() {
      var object = {
        TemplateName: this.EmailTemplateInput.value,
        EmailEventName: this.EmailEventNameInput.value,
        Source: this.SourceInput.value,
        UserGroup: this.CustomerGroupInput.value,
        templateData: this.TemplateDataInput.value,
        EmailDateTime: Date.parse(this.EmailDateTimeInput.value)
      };
      // if(this.DraftInput.value){

      // }
      console.log(object);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createEmailEvent", {
          router: this.$router,
          data: object
        });
        console.log("createEmailEvent", ret);
        this.isLoading = false;
        // setTimeout(()=>{
        //       this.isLoading = false;
        //       this.$router.replace({name: 'Email Event List', params:{message:"New Email Event created"}})
        //      }, 2000);
        // console.log(ret)
      } catch (err) {
        this.isLoading = false;
        console.log("createEmailEvent error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    formSubmit: async function(data) {},

    checkEdited: async function(check) {
      console.log(check);
      this.confirmBack = check;
    },
    //api function
    getUserGroupList: async function() {
      try {
        const ret = await this.$store.dispatch("listAllUserGroup", {
          router: this.$router
        });
        console.log(ret);
        for (var i = 0; i < ret.data.UserGroupList.length; i++) {
          this.CustomerGroupInput.option.push({
            key: ret.data.UserGroupList[i].UserGroupID,
            name: ret.data.UserGroupList[i].UserGroupID
          });
        }
      } catch (err) {
        console.log("listAllUserGroup error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getEmailTemplateList: async function() {
      var object = {};
      try {
        const ret = await this.$store.dispatch("listEmailTemplate", {
          router: this.$router,
          keyword: object
        });
        console.log(ret);
        for (
          var i = 0;
          i < ret.data.TemplateList.TemplatesMetadata.length;
          i++
        ) {
          this.EmailTemplateInput.option.push({
            key: ret.data.TemplateList.TemplatesMetadata[i].Name,
            name: ret.data.TemplateList.TemplatesMetadata[i].Name
          });
        }
      } catch (err) {
        console.log("listEmailTemplate error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getEmailTemplate: async function(EmailTemplate) {
      try {
        const ret = await this.$store.dispatch("getEmailTemplate", {
          router: this.$router,
          data: { TemplateName: EmailTemplate }
        });
        console.log("getEmailTemplate", ret);
        if (ret.data.TemplateData.templateData) {
          this.TemplateDataInput.value = ret.data.TemplateData.templateData;
        }
        this.HtmlPart = ret.data.TemplateResult.Template.HtmlPart;
      } catch (err) {
        console.log("getEmailTemplate error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
    //end of api function
  },
  watch: {
    "EmailTemplateInput.value": function(newValue) {
      this.getEmailTemplate(newValue);
      //console.log(newValue);
    }
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Create New Event",
      FormTitle: "Email Info",
      formActionButton: "Save",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      QuestionList: [],
      HtmlPart: "",
      editOn: false,
      EmailEventNameInput: {
        type: "text",
        key: "EmailEventName",
        name: "Email Event Name",
        required: true,
        value: ""
      },
      SourceInput: {
        type: "email",
        key: "Source",
        name: "Source Email",
        required: true,
        value: "no-reply@1sip.com.hk"
      },
      EmailTemplateInput: {
        key: "TemplateName",
        name: "Eamil Template",
        required: true,
        value: "",
        option: []
      },
      EmailDateTimeInput: {
        type: "datetime-local",
        key: "EmailDateTime",
        name: "Date & Time",
        required: true,
        value: ""
      },
      TemplateDataInput: {
        type: "object",
        key: "TemplateData",
        name: "Template Data",
        required: true,
        value: {}
      },
      DraftInput: {
        type: "checkbox",
        key: "Draft",
        name: "Save as Draft",
        required: false,
        value: false
      },
      CustomerGroupInput: {
        key: "CustomerGroup",
        name: "Customer Group",
        required: true,
        value: "",
        option: []
      }
    };
  }
};
</script>
