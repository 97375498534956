import { render, staticRenderFns } from "./CreateEventType.vue?vue&type=template&id=2c55fe54&"
import script from "./CreateEventType.vue?vue&type=script&lang=js&"
export * from "./CreateEventType.vue?vue&type=script&lang=js&"
import style0 from "./CreateEventType.vue?vue&type=style&index=0&id=2c55fe54&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports