<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:45%;min-width: 500px">
            <md-card style="margin:10px 0">
              <md-card-content>
                <div style="text-align: right;">
                  <md-button
                    style="background-color: #575757 !important;"
                    @click="PreviewInspiration()"
                    >Preview</md-button
                  >
                </div>

                <TextInput
                  :Item="FormItem.TitleInput"
                  v-on:checkEdited="checkEdited"
                />
                <TextInput
                  :Item="FormItem.DescriptionInput"
                  v-on:checkEdited="checkEdited"
                />
                <div v-if="AuthorList.length > 0">
                  <div v-for="(author, index) in AuthorList" :key="index">
                    {{ author.Name }}
                    <label
                      class="remove authorLabel"
                      @click="removeAuthor(author.Key)"
                      >remove</label
                    >
                  </div>
                </div>
                <md-button
                  style="background-color: #575757 !important;width:30%"
                  @click="showAddAuthor = true"
                  >+ Author</md-button
                >

                <ChipsInput
                  :Item="FormItem.TagInput"
                  v-on:checkEdited="checkEdited"
                />
                <SelectInput
                  style="padding: 20px 0;"
                  :Item="FormItem.StatusInput"
                  v-on:checkEdited="checkEdited"
                />
                <div v-if="MediaType == 'Image'">
                  <label>Image</label>
                  <div v-for="Item in InspirationMediaInput" :key="Item.ID">
                    <img
                      :src="Item.url"
                      style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
                    />
                  </div>
                </div>
                <div v-if="MediaType == 'Video'">
                  <label>Video</label><br />
                  <video
                    v-if="VideoInput.url != ''"
                    :src="VideoInput.url"
                    style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
                    controls
                  />
                  <img
                    v-if="ThumbnailInput.url != ''"
                    :src="ThumbnailInput.url"
                    style="width: 400px; height: 200px; padding-top: 30px;object-fit: contain;"
                  />
                </div>
                <div v-if="MediaType == 'TasteCard'">
                  <label>TasteCard</label><br />
                  <label>{{ TasteCardInput.model }}</label>
                </div>
              </md-card-content>
            </md-card>
            <md-card>
              <md-card-content>
                <ProductSelecter
                  v-on:setProductList="setProductList"
                  :ProductList="InspirationProductList"
                  :HeaderTitle="PopHeaderTitle"
                >
                </ProductSelecter>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:45%;padding-left: 30px;z-index: 0;">
            <InspirationPreview :previewItem="previewItem" />
            <button class="enableButton" @click="showPopup = true">
              Delete
            </button>
          </div>
        </div>
      </div>
      <div v-if="ProductDetailList != []" style="width: 100%;">
        <md-card>
          <md-card-content>
            <div style="width:100%">
              <h3 class="formTitle">Comment Bot</h3>
            </div>
            <h4 class="formTitle">Pros Bot</h4>
            <div v-for="(p, idx) in ProductDetailList" :key="idx">
              <div>{{ p.Name.zh }}: <br /></div>
              <div id="prosHints">{{ prosHints[idx] }}</div>
              <md-button
                type="button"
                class="md-primary"
                @click="copyProsHints(idx)"
                >Copy</md-button
              >
            </div>
            <iframe
              src="https://poe.com/KwahKwah"
              title="Pros Bot"
              style="width: 100%;height:600px;"
            >
            </iframe>
            <br />
            <div style="display:flex;padding-top: 20px;">
              <md-field style="flex:1;margin: 13px 0 0 0;padding: 0;">
                <label for="selectedProsPuppet">Select a user</label>
                <md-select v-model="selectedProsPuppet" id="selectedProsPuppet">
                  <md-option
                    v-for="user in PuppetList"
                    :value="user.UserID"
                    :key="user.UserID"
                    >{{ user.Name }}</md-option
                  >
                </md-select>
              </md-field>
              <md-input style="flex:3;" v-model="prosComment" />
              <md-button
                style="flex:1;"
                class="md-raised md-primary"
                @click="adminCreateProsComment()"
                >Create Comment</md-button
              >
            </div>

            <h4 class="formTitle">Cons Bot</h4>
            <div v-for="(p, idx) in ProductDetailList" :key="idx">
              <div>{{ p.Name.zh }}: <br /></div>
              <div id="prosHints">{{ consHints[idx] }}</div>
              <md-button
                type="button"
                class="md-primary"
                @click="copyConsHints(idx)"
                >Copy</md-button
              >
            </div>
            <iframe
              src="https://poe.com/EQTrainer"
              title="Pros Bot"
              style="width: 100%;height:600px;"
            >
            </iframe>
            <br />
            <div style="display:flex;padding-top: 20px;">
              <md-field style="flex:1;margin: 13px 0 0 0;padding: 0;">
                <label for="selectedConsPuppet">Select a user</label>
                <md-select v-model="selectedConsPuppet" id="selectedConsPuppet">
                  <md-option
                    v-for="user in PuppetList"
                    :value="user.UserID"
                    :key="user.UserID"
                    >{{ user.Name }}</md-option
                  >
                </md-select>
              </md-field>
              <md-input style="flex:3;" v-model="consComment" />
              <md-button
                style="flex:1;"
                class="md-raised md-primary"
                @click="adminCreateConsComment()"
                >Create Comment</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <Transition name="modal" v-if="showAddAuthor">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="popup">
              <div class="modal-header">
                <slot name="header">Add Author</slot>
              </div>
              <div class="modal-body">
                <UserInput :Item="AuthorInput" v-on:checkEdited="checkEdited" />

                <div class="row" style="text-align: center;padding-top: 20px;">
                  <div class="column" style="width:47.5%">
                    <label @click="showAddAuthor = false">Cancel</label>
                  </div>
                  <div class="column" style="width:5%"></div>
                  <div class="column" style="width:47.5%">
                    <button class="cancelButton" @click="addAuthor()">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="modal" v-if="showPopup">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="popupaction"
        :PopupTitle="PopupTitle"
        :PopupContent="PopupContent"
        :actionButton="actionButton"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  //FormComponent,
  FormHeader,
  ProductSelecter,
  InspirationPreview,
  //FileInput,
  Spinner,
  TextInput,
  UserInput,
  ChipsInput,
  SelectInput,
  Popup
} from "@/components";
//import draggable from "vuedraggable";
//import TasteCardInput from "./TasteCardInput.vue";
export default {
  components: {
    //FormComponent,
    FormHeader,
    ProductSelecter,
    InspirationPreview,
    //FileInput,
    //draggable,
    Spinner,
    TextInput,
    UserInput,
    ChipsInput,
    SelectInput,
    Popup
    //TasteCardInput
  },
  async created() {
    this.isLoading = true;
    this.InspirationID = this.$route.params.inspirationID;
    //this.listAuthor();
    //this.setMediaItem(this.MediaType)
    this.getPuppetList();
    await this.getInspiration(this.InspirationID);
    this.isLoading = false;
  },
  watch: {},
  methods: {
    copyProsHints: function(i) {
      navigator.clipboard.writeText(this.prosHints[i]);
    },
    copyConsHints: function(i) {
      navigator.clipboard.writeText(this.consHints[i]);
    },
    productInfoToStr: function(product) {
      var ret = "";
      //inspiration title
      ret += "主題: " + this.FormItem.TitleInput.value + " \n";
      //inspiration description
      ret += "內容: " + this.FormItem.DescriptionInput.value + " \n";
      //product type
      ret += "飲品類型: " + product.ProductType.Name.zh + " \n";
      //name
      ret += "名稱: " + product.Name.zh + " \n";
      //bean origin
      if (product.BeanOrigin && product.BeanOrigin.zh != "")
        ret += "豆原產地: " + product.BeanOrigin.zh + " \n";
      //variety
      if (product.Variety && product.Variety.zh != "")
        ret += "原料: " + product.Variety.zh + " \n";
      //processing
      if (product.Processing && product.Processing.zh != "")
        ret += "處理手法: " + product.Processing.zh + " \n";
      //roasting
      if (product.Roasting && product.Roasting.zh != "")
        ret += "烘焙方式: " + product.Roasting.zh + " \n";
      //aroma
      if (product.Aroma && product.Aroma.length > 0) {
        ret += "香味: ";
        product.Aroma.forEach((a, idx) => {
          ret += a.Name.zh;
          if (idx != product.Aroma.length - 1) {
            ret += ", ";
          }
        });
        ret += " \n";
      }
      //taste note
      if (product.TasteNote && product.TasteNote.length > 0) {
        ret += "風味: ";
        product.TasteNote.forEach((tn, idx) => {
          ret += tn.Name.zh;
          if (idx != product.TasteNote.length - 1) {
            ret += ", ";
          }
        });
        ret += " \n";
      }

      //price
      ret += "價錢: HKD" + product.MinimumPrice + " \n";
      //inspiration tags
      if (this.FormItem.TagInput.value.length > 0) {
        ret += "Tag: ";
        this.FormItem.TagInput.value.forEach((t, idx) => {
          ret += t;
          if (idx != this.FormItem.TagInput.value.length - 1) {
            ret += ", ";
          }
        });
        ret += " \n";
      }

      return ret;
    },
    popupaction: async function() {
      var object = {
        InspirationID: this.InspirationID
      };
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deleteInspiration", {
          router: this.$router,
          data: object
        });
        console.log("deleteInspiration", ret);

        this.isLoading = false;
        this.formMessage = "Delete Inspiration Success";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.push({ path: "/InspirationList" });
        }, 2000);
      } catch (err) {
        console.log("deleteInspiration error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    popupcancel: function() {
      this.showPopup = false;
    },
    addAuthor: function() {
      this.showAddAuthor = false;
      console.log("addAuthor", this.AuthorInput);
      this.AuthorList.push({
        Key: this.AuthorInput.value,
        Name: this.AuthorInput.model
      });
      this.AuthorInput.value = null;
      this.AuthorInput.model = null;
    },
    removeAuthor: function(ID) {
      console.log("removeAuthor", ID);
      var index = this.AuthorList.findIndex(x => x.Key == ID);
      if (index > -1) {
        this.AuthorList.splice(index, 1);
      }
    },
    setInspirationProductList: function(ProductList) {
      //push product list to inspiration product list
      for (var i = 0; i < ProductList.length; i++) {
        var index = this.InspirationProductList.findIndex(
          x => x.ID == ProductList[i].ID
        );
        if (index < 0) {
          this.InspirationProductList.push(ProductList[i]);
        }
      }
    },
    //popup function

    //end popup function
    //draggable function

    //end draggable function

    PreviewInspiration: async function() {
      var object = await this.getFormData(this.FormItem);
      this.previewItem["Title"] = object.Title;
      this.previewItem["Tag"] = object.Tag;
      this.previewItem["Description"] = object.Description;
      this.previewItem["mediaType"] = this.MediaType;

      var ProductList = [];
      for (var i = 0; i < this.InspirationProductList.length; i++) {
        var Productobject = {
          ProductID: this.InspirationProductList[i]["ID"],
          Name: this.InspirationProductList[i]["Product Name"],
          UnitPrice: this.InspirationProductList[i]["Unit Price"]
        };
        console.log("Productobject", Productobject);
        ProductList.push(Productobject);
      }
      this.previewItem["ProductList"] = ProductList;

      var authorIdIndex = this.AuthorList.findIndex(
        author => author.AuthorName == object.Author
      );
      if (authorIdIndex < 0) {
        this.formMessage = "Author not found";
        this.showFormMessage = true;
        return;
      }
      this.previewItem["Author"] = {
        AuthorName: object.Author,
        iconSrc:
          this.awsUrl + "/Author/" + this.AuthorList[authorIdIndex].AuthorID
      };

      var MediaList = [];
      if (this.MediaType == "Image") {
        for (var i = 0; i < this.InspirationMediaInput.length; i++) {
          MediaList.push(this.InspirationMediaInput[i].url);
        }
      } else {
        var MediaObject = await this.getFormData(this.VideoItem);
        for (var i = 0; i < MediaObject.Media.length; i++) {
          MediaList.push(MediaObject.Media[i].url);
        }
      }

      this.previewItem["MediaList"] = MediaList;

      console.log("previewItem", this.previewItem);
    },
    formAction: async function() {
      console.log("formAction", this.FormItem);
      var Data = {};
      for (const [key, value] of Object.entries(this.FormItem)) {
        console.log("key", key);
        console.log("value", value);
        if (value.required) {
          if (value.value) {
            if (value.type == "number") {
              Data[value.key] = parseInt(value.value);
            } else {
              Data[value.key] = value.value;
            }
          } else {
            this.formMessage = value.name + " is required";
            this.showFormMessage = true;
            return;
          }
        } else {
          if (value.value) {
            if (value.type == "number") {
              Data[value.key] = parseInt(value.value);
            } else {
              Data[value.key] = value.value;
            }
          } else {
            Data[value.key] = null;
          }
        }
      }
      if (this.AuthorList.length == 0) {
        this.formMessage = "Author is required";
        this.showFormMessage = true;
        return;
      }
      var Author = [];
      for (var a in this.AuthorList) {
        Author.push(this.AuthorList[a].Key);
      }
      Data["Author"] = Author;

      var ProductList = [];
      for (var p in this.InspirationProductList) {
        ProductList.push(this.InspirationProductList[p].ID);
      }
      Data["ProductList"] = ProductList;
      Data["InspirationID"] = this.InspirationID;
      console.log("Data", Data);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateInspiration", {
          router: this.$router,
          data: Data
        });
        console.log("updateInspiration", ret);

        this.isLoading = false;
        this.formMessage = "Update Inspiration Success";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.push({ path: "/InspirationList" });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("formAction error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getFormData: async function(form) {
      var FormData = {};
      for (const [key, value] of Object.entries(form)) {
        for (var i = 0; i < value.length; i++) {
          if (value[i].required) {
            if (value[i].value) {
              if (value[i].type == "number") {
                FormData[value[i].key] = parseInt(value[i].value);
              } else {
                FormData[value[i].key] = value[i].value;
              }
            } else {
              this.formMessage = value[i].name + " is required";
              this.showFormMessage = true;
              return;
            }
          } else {
            if (value[i].type == "number") {
              FormData[value[i].key] = parseInt(value[i].value);
            } else {
              FormData[value[i].key] = value[i].value;
            }
          }
        }
      }
      return FormData;
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    //api call function
    getInspiration: async function(InspirationID) {
      try {
        const ret = await this.$store.dispatch("getInspiration", {
          router: this.$router,
          data: { InspirationID: InspirationID }
        });
        console.log("getInspiration", ret);
        this.setDatatoForm(ret.data.Inspiration);
        if (
          ret.data.Inspiration.ProductList &&
          ret.data.Inspiration.ProductList.length > 0
        ) {
          this.getProduct(ret.data.Inspiration.ProductList);
        }
        if (
          ret.data.Inspiration.Author &&
          ret.data.Inspiration.Author.length > 0
        ) {
          this.getUser(ret.data.Inspiration.Author);
        }
        if (
          ret.data.Inspiration.Media &&
          ret.data.Inspiration.Media.length > 0
        ) {
          this.setMediaItem(ret.data.Inspiration.Media);
        }
      } catch (err) {
        console.log("getInspiration error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getUser: async function(Author) {
      console.log("getUser", Author);
      try {
        const ret = await this.$store.dispatch("getUserList", {
          router: this.$router,
          data: { UserList: Author }
        });
        console.log("getUser", ret);
        this.setAuthorList(ret.data.UserList);
      } catch (err) {
        console.log("getUser error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getPuppetList: async function() {
      try {
        var object = {
          UserGroupID: "Puppet",
          Size: 1000
        };
        const ret = await this.$store.dispatch("listUserInUserGroup", {
          router: this.$router,
          data: object
        });
        const userList = await this.$store.dispatch("getUserList", {
          router: this.$router,
          data: {
            UserList: ret.data.userList.map(u => u.UserID)
          }
        });
        console.log(userList);
        this.PuppetList = userList.data.UserList;
        console.log(this.PuppetList);
      } catch (err) {
        this.isLoading = false;
        console.log("listUserGroup error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    adminCreateProsComment: async function() {
      try {
        var object = {
          Comment: {
            Text: this.prosComment,
            CommentType: "Text"
          },
          UserID: this.selectedProsPuppet,
          SourceID: this.InspirationID,
          Type: "Inspiration"
        };
        const ret = await this.$store.dispatch("createComment", {
          router: this.$router,
          data: object
        });
        console.log(ret);
        if (ret.data.result === true) {
          alert("Comment Created");
          this.selectedProsPuppet = null;
          this.prosComment = "";
        }
      } catch (err) {
        this.isLoading = false;
        console.log("create comment error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    adminCreateConsComment: async function() {
      try {
        var object = {
          Comment: {
            Text: this.consComment,
            CommentType: "Text"
          },
          UserID: this.selectedConsPuppet,
          SourceID: this.InspirationID,
          Type: "Inspiration"
        };
        const ret = await this.$store.dispatch("createComment", {
          router: this.$router,
          data: object
        });
        console.log(ret);
        if (ret.data.result === true) {
          alert("Comment Created");
          this.selectedConsPuppet = null;
          this.consComment = "";
        }
      } catch (err) {
        this.isLoading = false;
        console.log("create comment error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getProduct: async function(Product) {
      console.log("getProduct", Product);
      try {
        const ret = await this.$store.dispatch("getProduct", {
          router: this.$router,
          product: { ProductList: Product }
        });
        console.log("getProduct", ret);
        var ProductDetailList = ret.data.ProductDetailList;
        var ProductList = [];
        for (var i in ProductDetailList) {
          ProductList.push({
            ID: ProductDetailList[i].ProductID,
            img:
              process.env.VUE_APP_S3_URL + ProductDetailList[i].Image[0].path,
            "Product Name":
              ProductDetailList[i].Name.en + "/" + ProductDetailList[i].Name.zh,
            "Minimum Price": ProductDetailList[i].MinimumPrice
          });
        }
        this.ProductDetailList = ProductDetailList;
        this.ProductDetailList.forEach(p => {
          this.prosHints.push(
            "幫我讚下依個沖野飲Post: \n" + this.productInfoToStr(p)
          );
          this.consHints.push(
            "幫我鬧下依沖野飲Post: \n" + this.productInfoToStr(p)
          );
        });
        //console.log(this.ProductDetailList)
        this.setProductList(ProductList);
      } catch (err) {
        console.log("getProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    //end api call function

    //set data to form
    setMediaItem: function(MediaList) {
      console.log("setMediaItem", MediaList);
      this.MediaType = MediaList[0].type;

      if (this.MediaType == "Video") {
        console.log("MediaList", MediaList[0]);
        this.VideoInput.url = process.env.VUE_APP_S3_URL + MediaList[0].path;
        if (MediaList[0].Thumbnail) {
          this.ThumbnailInput.url =
            process.env.VUE_APP_S3_URL + MediaList[0].Thumbnail;
        }
        console.log("this.VideoInput", this.VideoInput);
      } else if (this.MediaType == "Image") {
        for (var i = 0; i < MediaList.length; i++) {
          var object = {
            ID: MediaList[i].MediaID,
            MediaID: MediaList[i].MediaID,
            current: true,
            url: process.env.VUE_APP_S3_URL + MediaList[i].path,
            base64: ""
          };
          this.InspirationMediaInput.push(object);
        }
      } else if (this.MediaType == "TasteCard") {
        this.TasteCardInput.model = MediaList[0].path;
        this.TasteCardInput.value = MediaList[0].path;
      }
    },
    setAuthorList: function(authorList) {
      console.log("setAuthorList", authorList);
      for (var i = 0; i < authorList.length; i++) {
        //resultList[i]._source.GivenName + " " + resultList[i]._source.FamilyName + " (" + resultList[i]._source.Email + ")"
        this.AuthorList.push({
          Name:
            authorList[i].GivenName +
            " " +
            authorList[i].FamilyName +
            " (" +
            authorList[i].Email +
            ")",
          Key: authorList[i].UserID
        });
      }
    },
    setProductList: function(ProductList) {
      console.log("setProductList", ProductList);
      for (var i = 0; i < ProductList.length; i++) {
        var index = this.InspirationProductList.findIndex(
          x => x.ID == ProductList[i].ID
        );
        if (index < 0) {
          this.InspirationProductList.push(ProductList[i]);
        }
      }
    },
    setDatatoForm: function(data) {
      console.log("setDatatoForm", data);
      for (const [key, value] of Object.entries(this.FormItem)) {
        var datakey = this.FormItem[key].key;
        this.FormItem[key].value = data[datakey];
      }
    }
    //end set data to form
  },

  data() {
    return {
      PopupTitle: "Delete Inspiration",
      PopupContent: "Are you sure you want to delete this inspiration?",
      actionButton: "Delete",
      isLoading: false,
      showAddAuthor: false,
      showPopup: false,
      InspirationID: "",
      HeaderTitle: "Edit Inspiration",
      FormTitle: "Edit Inspiration",
      formActionButton: "Save Inspiration",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      MediaType: "Image",
      InspirationProductList: [],
      ProductDetailList: [],
      prosHints: [],
      consHints: [],
      AuthorList: [],
      PopHeaderTitle: "Preview",
      PuppetList: [],
      selectedProsPuppet: null,
      selectedConsPuppet: null,
      prosComment: "",
      consComment: "",
      previewItem: {
        Title: null,
        Tag: [],
        Description: null,
        mediaType: null,
        Author: {
          AuthorName: null,
          iconSrc: null
        },
        MediaList: [],
        ProductList: []
      },
      FormItem: {
        TitleInput: {
          type: "text",
          key: "Title",
          name: "Title",
          required: true,
          style: "width:100%",
          value: null
        },
        DescriptionInput: {
          type: "text",
          key: "Description",
          name: "Description",
          required: true,
          style: "width:100%",
          value: null
        },
        TagInput: {
          type: "array",
          key: "Tag",
          name: "Tags",
          style: "width:100%",
          required: true,
          value: []
        },
        StatusInput: {
          key: "Status",
          name: "Status",
          required: true,
          value: null,
          option: [
            {
              key: "Draft",
              name: "草稿"
            },
            {
              key: "Private",
              name: "私人"
            },
            {
              key: "Published",
              name: "公開"
            },
            {
              key: "UnListed",
              name: "不公開"
            }
          ]
        }
      },
      ImageItem: { ID: 1, base64: "", url: "" },
      AuthorInput: {
        type: "text",
        key: "Author",
        name: "Author",
        required: true,
        value: null,
        model: null,
        showAutocomplete: true,
        autocompleteList: []
      },
      TasteCardInput: {
        type: "text",
        key: "TasteCard",
        name: "TasteCard",
        required: true,
        value: null,
        model: null,
        showAutocomplete: true,
        autocompleteList: []
      },
      VideoInput: {
        ID: "Video",
        type: "Video",
        base64: "",
        url: "",
        value: ""
      },
      ThumbnailInput: {
        ID: "Thumbnail",
        type: "Image",
        base64: "",
        url: "",
        value: ""
      },
      InspirationMediaInput: [],
      acceptType: "image/*"
    };
  }
};
</script>
<style scoped>
.mediaView {
  width: auto;
  height: 100px;
}
</style>
