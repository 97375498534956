<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" style="z-index:99">
        <div class="popup">
          <div class="modal-header">
            <slot name="header">{{ Item.name }}</slot>
          </div>
          <div class="modal-body" style="text-align: center">
            <table class="DragDropTable" style="width: 100%">
              <div v-for="(ItemValue, ItemKey) in Item.value" :key="ItemKey">
                <tr>
                  <td>
                    <label>{{ ItemKey }}</label>
                  </td>
                  <td style="width: 100%">
                    <center>
                      <md-field style="width:90%;">
                        <md-input type="text" v-model="Item.value[ItemKey]" />
                      </md-field>
                    </center>
                  </td>
                </tr>
              </div>
            </table>
            <div class="row" style="text-align: center;padding-top: 20px;">
              <div class="column" style="width:47.5%">
                <button class="cancelButton" @click="popupcancel">
                  取消
                </button>
              </div>
              <div class="column" style="width:5%"></div>
              <div class="column" style="width:47.5%">
                <button class="cancelButton" @click="addChip">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    popupcancel: function() {
      this.$emit("popupcancel");
    },
    addChip: function() {
      this.$emit("addChip");
    }
    /*autoTranslate: async function(){
              console.log(this.Item.value);
              if(this.Item.value.zh.length===0){
                var temp="";
                if(this.Item.value.cn.length!==0){
                  //temp=await translate(this.Item.value.cn, { to: 'zh-TW' }); //cn=>zh
                }
                else if(this.Item.value.en.length!==0){
                  //temp=await translate(this.Item.value.en, { to: 'zh-TW' }); //en=>zh
                }
                this.$set(this.Item.hint,"zh",temp)
              }
              if(this.Item.value.cn.length===0){
                var temp="";
                if(this.Item.value.zh.length!==0){
                  //temp=await translate(this.Item.value.zh, { to: 'zh-CN' }); //zh=>cn
                }
                else if(this.Item.value.en.length!==0){
                  //temp=await translate(this.Item.value.en, { to: 'zh-CN' }); //en=>cn
                }
                this.$set(this.Item.hint,"cn",temp)
              }
              if(this.Item.value.en.length===0){
                var temp="";
                if(this.Item.value.zh.length!==0){
                  //temp=await translate(this.Item.value.zh, { to: 'en' }); //zh=>en
                }
                else if(this.Item.value.cn.length!==0){
                  //temp=await translate(this.Item.value.cn, { to: 'en' }); //cn=>en
                }
                this.$set(this.Item.hint,"en",temp)
              }
            }*/
  },
  name: "popup-component",
  props: {
    Item: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {}
};
</script>
<style>
.popup {
  padding: 30px 0px;
  margin: 0px 20px;
}
.popupcontent {
  width: 80%;
}
.modal-wrapper {
  z-index: 0;
}
.modal-container {
  z-index: 99;
  min-width: 350px;
  max-width: 400px;
}
.modal-header {
  font-size: 24px;
  font-weight: 500;
}
.modal-body {
  font-size: 16px;
  font-weight: 500;
}
.cancelButton {
  width: 100%;
  background-color: #575757;
  color: white;
  border-color: #575757;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
.actionButton {
  width: 100%;
  background-color: white;
  color: #cc2727;
  border-color: #cc2727;
  border-width: thin;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
}
</style>
