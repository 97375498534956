<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:45%;">
            <md-card style="margin:10px 0;">
              <md-card-content>
                <h3 class="formTitle">{{ Form1Title }}</h3>
                <LanguageObjectInput
                  v-on:checkEdited="checkEdited"
                  :Item="ProductNameInput"
                />
                <div class="row" style="padding: 10px 0">
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="SupplierInput"
                    />
                  </div>
                  <div class="inputcolumn">
                    <SelectInput
                      v-on:checkEdited="checkEdited"
                      :Item="StatusInput"
                    />
                  </div>
                </div>
                <CountryInput
                  v-on:checkEdited="checkEdited"
                  :languageList="languageList"
                  :Item="CountryInput"
                />
                <div>
                  <label>產品圖片</label>
                  <draggable :list="ProductImageInput" :group="{ name: 'g1' }">
                    <div v-for="Item in ProductImageInput" :key="Item.ID">
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        :Item="Item"
                        :acceptType="acceptType"
                      />
                    </div>
                  </draggable>
                  <div
                    style="text-align: center;"
                    @click="addProductImageInput()"
                  >
                    <md-icon>add_circle_outline</md-icon>
                    <u class="addLang">增加圖片</u>
                  </div>
                  <div style="padding-top:25px;" v-if="!noTasteCard">
                    <label>Taste Card 背景圖片</label>
                      <label style="color:red">*</label>
                      <FileInput
                        v-on:RemoveItem="RemoveItem"
                        :Item="TasteCardImage"
                        :acceptType="acceptType"
                      />
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
          <div style="width:55%;padding-left: 30px;">
            <md-card style="margin:10px 0;">
              <md-card-content>
                <div style="text-align:left">
                  <div class="row">
                    <div style="width:100%">
                      <h3 class="formTitle">{{ Form2Title }}</h3>
                    </div>
                    <div style="padding:20px 20px 0px 20px;width:100%">
                      <label for="product-type">{{ Form2SubTitle }}</label>
                      <table style="width:100%">
                        <tr>
                          <td
                            v-for="(value, key) in ProductTypeList"
                            :key="key"
                            style="border:none"
                          >
                            <md-radio
                              v-model="ProductType"
                              :value="value.ProductTypeID"
                              >{{ value.Name.zh }}</md-radio
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div style="padding-top:25px;" v-if="!noTasteCard">

                    <SelectInput
                      v-for="(item, key) in SeriesInputList"
                      :key="key"
                      style="width: 50%;"
                      v-on:checkEdited="checkEdited"
                      :Item="item"
                    />
                    <TasteNotesInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="TasteNotesInput"
                    />
                    <AromaInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="AromaInput"
                    />
                    <ParingTagInput
                      v-on:checkEdited="checkEdited"
                      :languageList="languageList"
                      :Item="ParingTagsInput"
                    />
                  </div>
                  <div class="row" style="padding-top: 25px">
                    <div
                      class="column inputcolumn"
                      v-for="(field, key) in ProductInfoInputList"
                      :key="key"
                    >
                      <LanguageObjectInput
                        v-if="field.type == 'object'"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      />
                      <NumberInput
                        v-if="field.type == 'number'"
                        v-on:checkEdited="checkEdited"
                        :Item="field"
                      />
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  LanguageObjectInput,
  SelectInput,
  CountryInput,
  FileInput,
  ParingTagInput,
  TasteNotesInput,
  AromaInput,
  NumberInput,
  Spinner
} from "@/components";
import draggable from "vuedraggable";

export default {
  components: {
    FormHeader,
    LanguageObjectInput,
    SelectInput,
    CountryInput,
    FileInput,
    draggable,
    ParingTagInput,
    TasteNotesInput,
    AromaInput,
    NumberInput,
    Spinner
  },
  async created() {
    this.isLoading = true;
    this.listSeries();
    await this.getLanguage();
    await this.listProductType();
    this.getSuppliers();
    this.setProductInfoItem(this.ProductType);
    this.isLoading = false;
  },

  watch: {
    ProductType: function(newValue) {
      this.setProductInfoItem(newValue);
    }
  },
  methods: {
    RemoveItem: async function(ID, deleteAction) {
      console.log("RemoveItem", ID);
      console.log("deleteAction", deleteAction);
      var index = this.ProductImageInput.findIndex(x => x.ID == ID);

      if (index >= 0) {
        this.ProductImageInput.splice(index, 1);
      }
    },
    addProductImageInput: function() {
      var ID = new Date().getTime();
      this.ProductImageInput.push({
        ID: ID,
        url: ""
      });
    },
    formAction: async function() {
      console.log("new function");
      var ProductID = await this.createProduct();
      console.log("ProductID", ProductID);
      if (!ProductID) {
        this.isLoading = false;
        this.formMessage = "Create Product Error";
        this.showFormMessage = true;
        return;
      } else {
        this.formMessage = "產品新增成功";
        this.showFormMessage = true;
      }

      this.isLoading = false;
      setTimeout(() => {
        this.$router.push({
          name: "Update Product",
          params: { ProductID: ProductID }
        });
      }, 2000);
    },
    createProduct: async function() {
      var object = {
        Name: this.ProductNameInput.value,
        SupplierID: this.SupplierInput.value,
        Country: this.CountryInput.value,
        ParingTags: this.ParingTagsInput.value,
        TasteNote: this.TasteNotesInput.value,
        Aroma: this.AromaInput.value,
        ProductType: this.ProductType,
        TasteCardImage: this.TasteCardImage.ID,
        Status: this.StatusInput.value
      };
      var Series = []
      for(var i in this.SeriesInputList){
        if(this.SeriesInputList[i].value != ""){
          Series.push({
            SeriesID: this.SeriesInputList[i].key,
            OptionID: this.SeriesInputList[i].value
          });
        }
      }
      object["Series"] = Series;

      for (var i = 0; i < this.ProductInfoInputList.length; i++) {
        if (this.ProductInfoInputList[i].type == "number") {
          object[this.ProductInfoInputList[i].key] = parseFloat(
            this.ProductInfoInputList[i].value
          );
        } else {
          object[this.ProductInfoInputList[i].key] = this.ProductInfoInputList[
            i
          ].value;
        }
      }
      var Image = [];
      for (var i in this.ProductImageInput) {
        Image.push(this.ProductImageInput[i].ID);
      }
      object["Image"] = Image;
      console.log("createProduct", object);
      //return;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createProduct", {
          router: this.$router,
          product: object
        });
        console.log("createProduct", ret.data);
        this.isLoading = false;
        return ret.data.ProductID;
      } catch (err) {
        this.isLoading = false;
        console.log("createProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        console.log("getLanguage", ret.data);
        this.languageList = ret.data.Language;
        for (var i = 0; i < this.languageList.length; i++) {
          this.ProductNameInput.value[this.languageList[i].Language] = "";
        }
      } catch (err) {
        console.log("getLanguage error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    listProductType: async function() {
      try {
        const ret = await this.$store.dispatch("listProductType", {
          router: this.$router,
          data: null
        });
        console.log("listProductType", ret.data);
        this.ProductTypeList = ret.data.ProductType;
        this.ProductType = ret.data.ProductType[0].ProductTypeID;
        if (ret.data.ProductType[0].noTasteCard) {
          this.noTasteCard = ret.data.ProductType[0].noTasteCard;
        }
      } catch (err) {
        console.log("listProductType error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    listSeries: async function() {
      try {
        const ret = await this.$store.dispatch("listSeries", {
          router: this.$router,
          data: null
        });
        console.log("listSeries", ret.data.series);
        //this.ParingTagsInput.option = ret.data.Series;
        this.SeriesList = ret.data.series;
        for(var i in this.SeriesList){
          var option = [{
            key: "",
            name: "無"
          }];
          for(var j in this.SeriesList[i].Option){
            option.push({
              key: this.SeriesList[i].Option[j].OptionID,
              name: this.SeriesList[i].Option[j].Name.zh
            });
          }
          this.SeriesInputList.push({
            key: this.SeriesList[i].SeriesID,
            name: this.SeriesList[i].Name.zh,
            value: "",
            option: option
          });
        }
        this.SeriesInput.name = ret.data.series[0].Name.zh;
        this.SeriesInput.key = ret.data.series[0].SeriesID;
        for(var i in ret.data.series[0].Option){
          var seriesOption = ret.data.series[0].Option[i];
          this.SeriesInput.option.push({
            key: seriesOption.OptionID,
            name: seriesOption.Name.zh
          });
        }
      } catch (err) {
        console.log("listSeries error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getSuppliers: async function() {
      try {
        const ret = await this.$store.dispatch("listSuppliers", {
          router: this.$router
        });
        for (var i in ret.data.supplierList) {
          var name = ret.data.supplierList[i].Name;
          if (ret.data.supplierList[i].SupplierName) {
            name = ret.data.supplierList[i].SupplierName;
          }
          this.SupplierInput.option.push({
            key: ret.data.supplierList[i].UserID,
            name: name
          });
        }

        console.log("SupplierInput", this.SupplierInput);
      } catch (err) {
        console.log("listSuppliers error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setProductInfoItem: function(ID) {
      console.log(this.ProductTypeList);
      console.log("ID", ID);
      var ProductType = this.ProductTypeList.find(
        item => item.ProductTypeID == ID
      );
      console.log("ProductType", ProductType);
      this.noTasteCard = ProductType.noTasteCard;
  
      var Column = ProductType["Column"];

      this.ProductInfoInputList = [];
      for (var i = 0; i < Column.length; i++) {
        if (Column[i]["DataType"] == "object") {
          var object = {
            type: "object",
            key: ProductType.Column[i].Key,
            name: ProductType.Column[i].Name,
            required: ProductType.Column[i].required,
            model: "",
            value: {}
          };

          for (var l = 0; l < this.languageList.length; l++) {
            object.value[this.languageList[l].Language] = "";
          }
          console.log("object", object);
          this.ProductInfoInputList.push(object);
        } else {
          this.ProductInfoInputList.push({
            type: ProductType.Column[i].DataType,
            key: ProductType.Column[i].Key,
            name: ProductType.Column[i].Name,
            required: ProductType.Column[i].required,
            model: "",
            value: ""
          });
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "新增產品",
      Form1Title: "通用資訊",
      Form2Title: "產品詳情",
      Form2SubTitle: "產品類型",
      formActionButton: "新增",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      ProductTypeList: [],
      languageList: [],
      StatusInput: {
        key: "Status",
        name: "Status",
        required: true,
        value: "Hide",
        option: [
          {
            key: "Hide",
            name: "隱藏"
          }
        ]
      },
      ProductNameInput: {
        type: "object",
        key: "Name",
        name: "產品名稱",
        required: true,
        style: "width: 100%;",
        model: "",
        value: {}
      },
      SupplierInput: {
        key: "Supplier",
        name: "供應商",
        required: true,
        value: "",
        option: []
      },
      CountryInput: {
        key: "Country",
        name: "國家(出口用)",
        required: true,
        model: "",
        value: ""
      },
      ParingTagsInput: {
        key: "ParingTags",
        name: "配對標籤",
        required: false,
        model: [],
        value: []
      },
      TasteNotesInput: {
        key: "TasteNote",
        name: "風味",
        required: true,
        value: [],
        model: []
      },
      AromaInput: {
        key: "Aroma",
        name: "香氣",
        required: true,
        value: [],
        model: []
      },
      SeriesInputList: [],
      SeriesList: [],
      ProductInfoInputList: [],
      ProductParameterInputList: [],
      ProductType: "",
      ProductInfoItem: {},
      acceptType: "image/*",
      ProductImageInput: [],
      TasteCardImage: {
        ID: "",
        url: ""
      },
      showAddVariance: false,
      //Table Component Data
      columns: ["img", "選項名稱", "價格", "重量"],
      TableItemList: [],
      selectedItem: [],
      selectedItemCounter: 0,
      selectAll: false,
      selectable: true,
      noTasteCard: false,
      selectedItemAction: [
        { Key: "delete", Name: "刪除", Style: "color:#CC2727" }
      ]
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
.variance-table th {
  text-align: left;
}
</style>
