<template lang="html">
  <div
    class="num-input-box"
    :class="{
      expanded: defaultExpanded || isExpanded,
      disabled: disabled
    }"
  >
    <md-content class="control-button" id="minus-btn" @click="remove">
      <md-icon>remove</md-icon>
    </md-content>
    <md-content class="number-box no-select" @click="expand" v-if="!isExpanded">
      x{{ quantity }}
    </md-content>

    <input
      type="number"
      class="input-box"
      v-else
      v-model="quantity"
      min="1"
      :max="max"
      :readonly="disabled"
    />

    <md-content class="control-button" id="add-btn" @click="add">
      <md-icon>add</md-icon>
    </md-content>
  </div>
</template>

<script>
export default {
  name: "NumberInputBox",
  props: {
    defaultExpanded: {
      type: Boolean,
      default: true
    },
    value: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 99
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: this.defaultExpanded ? this.defaultExpanded : false,
      quantity: this.value,
      inputReadyTimer: null // input delay
    };
  },
  // computed: {
  //   isExpanded(){
  //     return true;
  //   }
  // },
  mounted() {
    this.updateBtnClass(this.quantity);
  },
  methods: {
    expand() {
      this.isExpanded = true;
    },
    add() {
      if (this.quantity < this.max) {
        this.quantity++;

        this.onValueUpdate();
      }
    },
    remove() {
      if (this.quantity > 1) {
        this.quantity--;

        this.onValueUpdate();
      }
    },
    onValueUpdate() {
      this.$emit("update", this.quantity);
    },
    updateBtnClass(val) {
      if (val >= this.max) {
        document.querySelector("#add-btn").classList.add("disabled");
      } else {
        document.querySelector("#add-btn").classList.remove("disabled");
      }
      if (val <= 1) {
        document.querySelector("#minus-btn").classList.add("disabled");
      } else {
        document.querySelector("#minus-btn").classList.remove("disabled");
      }
    }
  },
  watch: {
    quantity: function(newVal, oldVal) {
      this.updateBtnClass(newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.num-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  width: 50px;

  border-radius: 5px;
  //border: 1px solid black;

  overflow: hidden;
}
//
// .num-input-box.expended{
//   width: 100px;
// }

.num-input-box > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.num-input-box .control-button {
  background-color: #f4f4f4;
  max-width: 0px;
  height: 100%;
  aspect-ratio: 0;
  cursor: pointer;
}

.num-input-box .number-box {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  text-align: center;

  border: 0;
  // border-left: 1px solid black;
  // border-right: 1px solid black;
  background: white;
  z-index: 1;

  cursor: pointer;
}

.num-input-box {
  //border-left: 1px solid black;
  //border-right: 1px solid black;
}

.num-input-box .input-box {
  border: 0;

  background-color: #f4f4f4;
  height: 100%;
  flex-grow: 1;
  // width: auto;
  // max-width: 50%;
  width: 50%;
  outline: none;

  text-align: center;
}

.num-input-box .input-box::-webkit-outer-spin-button,
.num-input-box .input-box::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.num-input-box .input-box[type="number"] {
  -moz-appearance: textfield;
}

.num-input-box,
.num-input-box .control-button {
  transition: all 0.25s;
}

.num-input-box.expanded {
  // width: 100px;
  width: 130px;
}

.num-input-box.expanded .control-button {
  max-width: none;
}

.num-input-box.disabled {
  opacity: 0.5;

  input,
  .control-button {
    cursor: auto;
  }
}
</style>
<style>
.disabled .md-icon {
  color: #c8c8c8 !important;
}
</style>
