<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent>
      <md-card class="md-layout-item">
        <md-card-content>
          <h3>User Info</h3>
          <table style="width:100%">
            <tr>
              <td>
                <label for="BackgroundColor">User ID</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ Order.UserID }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">Name</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ User.Name }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">Gender</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ User.Gender }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">Phone</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ User.Phone }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">email</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ User.email }}</label>
              </td>
            </tr>
          </table>

          <h3>Request Status</h3>
          <label for="BackgroundColor">{{ RefundRequestDetail.Status }}</label>
          <h3>Request Reason</h3>
          <label for="BackgroundColor">{{ RefundRequestDetail.Reason }}</label>
          <div v-if="RefundRequestDetail.Status == 'Finish'">
            <h3>Request Action</h3>
            <table style="width:50%">
              <tr>
                <td>Refund Action</td>
                <td>{{ RefundRequestDetail.ReFundAction }}</td>
              </tr>
              <tr>
                <td>Refund Reason</td>
                <td>{{ RefundRequestDetail.AdminReason }}</td>
              </tr>
              <tr v-if="RefundRequestDetail.ReFundAction == 'accept'">
                <td>Refund amount</td>
                <td>${{ RefundRequestDetail.RefundAmount }}</td>
              </tr>
            </table>
          </div>
          <h3>Order Info</h3>
          <table style="width:100%">
            <tr>
              <td>
                <label for="BackgroundColor">Order ID</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ Order.OrderID }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">PaymentMethod</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ Order.PaymentMethod }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">PaymentID</label>
              </td>
              <td>
                <label for="BackgroundColor">{{ Order.PaymentID }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">ShippingFee</label>
              </td>
              <td>
                <label for="BackgroundColor">${{ Order.ShippingFee }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">SubTotal</label>
              </td>
              <td>
                <label for="BackgroundColor">${{ Order.SubTotal }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">Taxes</label>
              </td>
              <td>
                <label for="BackgroundColor">${{ Order.Taxes }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label for="BackgroundColor">Total</label>
              </td>
              <td>
                <label for="BackgroundColor">${{ Order.Total }}</label>
              </td>
            </tr>
            <tr v-if="Order.Discount">
              <td>
                <label for="BackgroundColor">Discount</label>
              </td>
              <td>
                <div v-if="Order.Discount.Campaign">
                  <label for="BackgroundColor"
                    >{{ Order.Discount.Campaign.Description }} (${{
                      Order.Discount.Campaign.Discount
                    }})</label
                  >
                </div>
              </td>
            </tr>
          </table>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-30" style="">
              <div style="height:50px">
                <span>Products</span>
              </div>
              <div class="elevation-demo">
                <md-content
                  v-for="(item, id) in productList"
                  :key="id"
                  class="md-elevation selected-product"
                >
                  <div :id="'spc-' + item.ProductID">
                    <!-- <div :id="'spc-'+item.ProductID" v-on:click="clickSelectedProduct(item)"> -->
                    <selected-product-card
                      class="selected-card"
                      :item="item"
                    ></selected-product-card>
                  </div>
                </md-content>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <md-card-actions
          v-if="RefundRequestDetail.Status == 'wait for approve'"
        >
          <md-button
            type="button"
            class="md-accent"
            :disabled="sending"
            @click="showRejectRequest()"
            >reject</md-button
          >
          <md-button
            type="button"
            class="md-primary"
            :disabled="sending"
            @click="showRefundOrder()"
            >accept</md-button
          >
        </md-card-actions>
      </md-card>

      <md-dialog :md-active.sync="showRejectRequestDialog">
        <md-dialog-title>Reject refund</md-dialog-title>

        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field :class="getValidationClass('Keyword')">
              <label for="update-quantity">Reason</label>
              <md-input
                name="update-quantity"
                id="update-quantity"
                v-model="AdminRefundReason"
                :disabled="sending"
              />
            </md-field>
          </div>
        </div>
        <md-card-actions>
          <md-button
            type="button"
            class="md-primary"
            :disabled="sending"
            @click="refundOrder"
            >Confirm</md-button
          >
        </md-card-actions>
      </md-dialog>

      <md-dialog :md-active.sync="showRefundOrderDialog">
        <md-dialog-title>Refund</md-dialog-title>

        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field :class="getValidationClass('Keyword')">
              <label for="update-quantity">Amount</label>
              <md-input
                name="update-quantity"
                id="update-quantity"
                v-model="refundAmount"
                :disabled="sending"
                type="number"
              />
            </md-field>
          </div>
        </div>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field :class="getValidationClass('Keyword')">
              <label for="update-quantity">Reason</label>
              <md-input
                name="update-quantity"
                id="update-quantity"
                v-model="AdminRefundReason"
                :disabled="sending"
              />
            </md-field>
          </div>
        </div>
        <md-card-actions>
          <md-button
            type="button"
            class="md-primary"
            :disabled="sending"
            @click="refundOrder"
            >Confirm</md-button
          >
        </md-card-actions>
      </md-dialog>

      <md-snackbar :md-active.sync="InventorySave"
        >The inspiration was saved with success!</md-snackbar
      >
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";

import { required, minLength } from "vuelidate/lib/validators";
import SelectedProductCard from "../Cards/SelectedProductCard.vue";
import "muse-ui/dist/muse-ui.css";

export default {
  name: "refund-request-detail",
  mixins: [validationMixin],
  components: { SelectedProductCard },
  data: () => ({
    refundID: null,
    ReFundAction: null,
    Order: {},
    User: {},
    RefundRequestDetail: {},
    refundAmount: null,
    AdminRefundReason: null,
    search: {
      Price: [0, 300000]
    },
    showAddProductDialog: false,
    showUpdateProductDialog: false,
    showSelectProductDialog: false,
    showRefundOrderDialog: false,
    showRejectRequestDialog: false,
    addQuantity: "",
    updateQuantity: "",
    productToAdd: {},
    productToUpdate: {},
    InventorySave: false,
    sending: false,
    lastInventory: null,
    showProductDialog: false,
    productQueryResult: [],
    productList: {}
  }),

  validations: {
    form: {}
  },
  methods: {
    showRejectRequest: function() {
      this.ReFundAction = "reject";
      this.showRejectRequestDialog = true;
    },
    showRefundOrder: function() {
      this.ReFundAction = "accept";
      this.showRefundOrderDialog = true;
    },
    refundOrder: async function() {
      var object = {
        RefundRequestID: this.refundID,
        Reason: this.AdminRefundReason,
        ReFundAction: this.ReFundAction
      };
      if (parseInt(this.refundAmount) > 0) {
        object["RefundAmount"] = parseInt(this.refundAmount);
      }
      console.log(object);
      this.loading = true;
      this.$store
        .dispatch("updateRefundRequest", object)
        .then(ret => {
          this.loading = false;
          console.log(ret);
          this.showRejectRequestDialog = false;
          this.showRefundOrderDialog = false;
          this.getRefundRequestDetail();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.message);
        });
    },
    getRefundRequestDetail: async function() {
      this.loading = true;
      this.$store
        .dispatch("getRefundRequestDetail", { RefundRequestID: this.refundID })
        .then(ret => {
          this.loading = false;
          this.Order = ret.data.OrderDetail;
          this.RefundRequestDetail = ret.data.refundRequestDetail;
          this.User = ret.data.UserInfo;
          for (var i = 0; i < this.Order.Product.length; i++) {
            this.$set(this.productList, this.Order.Product[i].ProductID, {
              product: this.Order.Product[i],
              discount: 0,
              quantity: parseInt(this.Order.Product[i].Quantity)
            });
          }

          console.log(ret);
        })
        .catch(err => {
          this.loading = false;
          console.log(err.message);
        });
    },
    openAddProductDialog: function(product) {
      this.productToAdd = product;
      if (product.ProductID in this.productList) {
        this.showAddProductDialog = true;
      } else {
        this.showSelectProductDialog = true;
      }
    },
    selectProduct: function() {
      console.log("productToAdd", this.productToAdd);
      var product = this.productToAdd;
      var productID = product.ProductID;
      if (productID in this.productList) {
        console.log("true");
        this.$set(this.productList, productID, {
          product: product,
          discount: 0,
          quantity:
            this.productList[productID].quantity + parseInt(this.addQuantity)
        });
      } else {
        console.log("false");
        this.$set(this.productList, productID, {
          product: product,
          discount: 0,
          quantity: parseInt(this.addQuantity)
        });
      }
      this.showAddProductDialog = false;
      this.showSelectProductDialog = false;
      this.addQuantity = "";
      this.productToAdd = {};
      console.log(this.productList);
    },
    updateProduct: function() {
      var product = this.productToUpdate;
      var productID = product.ProductID;
      if (productID in this.productList) {
        if (this.updateQuantity == 0) {
          this.removeProduct();
        } else {
          this.$set(this.productList, productID, {
            product: product,
            discount: this.updateDiscount,
            quantity: parseInt(this.updateQuantity)
          });
        }
      }
      this.showUpdateProductDialog = false;
      this.updateQuantity = "";
      console.log(this.productList);
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },

    clickSelectedProduct: function(item) {
      this.productToUpdate = item.product;
      this.updateQuantity = item.quantity;
      this.showUpdateProductDialog = true;
    },
    removeProduct: function() {
      if (this.productToUpdate.ProductID in this.productList) {
        this.$delete(this.productList, this.productToUpdate.ProductID);
      }
      this.productToUpdate = {};
      this.showUpdateProductDialog = false;
    },
    queryProduct: async function() {
      this.loading = true;
      this.$store
        .dispatch("queryProduct", { router: this.$router })
        .then(ret => {
          this.loading = false;
          var schema = ret.data.data.schema;
          var rows = ret.data.data.datarows;
          var arr = [];
          rows.forEach(row => {
            var temp = {};
            row.forEach((col, c_i) => {
              temp[schema[c_i].name] = col;
            });
            arr.push(temp);
          });
          this.productQueryResult = arr;
          console.log(this.productQueryResult);
          this.showProductDialog = false;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          console.log(err.message);
        });
    },
    clearForm() {
      this.$v.$reset();
      (this.form.InventoryName = {
        zh: null,
        en: null
      }),
        (this.form.InventoryAddress = {
          zh: null,
          en: null
        }),
        (this.productList = {});
    },

    saveInventory: async function() {
      console.log("saveInventory");
      console.log("form", this.form);
      this.form.ProductList = [];
      for (const [key, value] of Object.entries(this.productList)) {
        var object = {
          ProductID: key,
          Quantity: value.quantity
        };
        this.form.ProductList.push(object);
      }
      console.log("productList", this.form.ProductList);
      try {
        const ret = await this.$store.dispatch("createInventory", {
          ...this.form
        });
        this.sending = false;
        console.log(ret);
        this.InventorySave = true;
        this.sending = false;
        this.clearForm();
      } catch (err) {
        this.sending = false;
        console.log(err.message);
      }
    },
    validateInventory() {
      console.log("here");
      this.$v.$touch();
      console.log("invalid", this.$v.$invalid);
      if (!this.$v.$invalid) {
        this.saveInventory();
      }
    }
  },
  created() {
    this.refundID = this.$route.params.refundID;
    this.getRefundRequestDetail();
  }
};
</script>

<style lang="scss" scoped>
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.md-stepper::v-deep .md-stepper-header {
  background-color: #ffffff !important;
  z-index: 10;
}
.elevation-demo {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  max-height: 650px;
  overflow: auto;
}

.productSelected {
  border: 1px solid blue;
}
.authordiv {
  margin-bottom: 20px;
}
.authorul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px !important;
}

.md-content.selected-product {
  width: 100px;
  height: auto;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.md-content.search-result {
  width: 180px;
  height: auto;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card {
  min-height: 320px;
}

.selected-card {
  min-height: 260px;
}

.md-dialog::v-deep .md-dialog-container {
  max-width: 768px;
  padding: 20px;
}

.md-field::v-deep .slider-target {
  width: 100%;
}

.slider-field {
  margin: 1px;
  margin-top: 10px;
}

.slider-field::after {
  height: 0;
}
.item-list {
  height: 100%;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
