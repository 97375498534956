import oneSipApiCall from "../apiCall.js";

const tasteCard = {
    getTasteCard({ commit, state }, { router, data }) {
        return oneSipApiCall(commit, state, router, "/admin-get-tastecard", data);
    },
    createTasteCard({ commit, state }, { router, tasteCard }) {
        return oneSipApiCall(
            commit,
            state,
            router,
            "/admin-create-tastecard",
            tasteCard
        );
    }, 
    updateTasteCard({ commit, state }, { router, tasteCard }) {
        return oneSipApiCall(
            commit,
            state,
            router,
            "/admin-update-tastecard",
            tasteCard
        );
    },
    deleteStepImage({ commit, state }, { router, data }) {
        return oneSipApiCall(
            commit,
            state,
            router,
            "/admin-delete-step-image",
            data
        );
    },
    deleteTasteCardProductImage({ commit, state }, { router, data }) {
        return oneSipApiCall(
            commit,
            state,
            router,
            "/admin-delete-tastecard-product-image",
            data
        );
    }



}

export default tasteCard;