<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <TableHeader
          :HeaderTitle="HeaderTitle"
          :AddNewButton="AddNewButton"
          :backButton="false"
        />
        <md-card>
          <md-card-content>
            <TableComponent
              v-on:onclickItem="onclickItem"
              :TableList="TableItemList"
              :selectAll="selectAll"
              :selectable="selectable"
              :columns="columns"
              :haveSearchFunction="haveSearchFunction"
              :showPageNumber="showPageNumber"
              :showNextButton="showNextButton"
            />
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { Spinner, TableComponent, TableHeader } from "@/components";

export default {
  components: {
    Spinner,
    TableComponent,
    TableHeader
  },
  data() {
    return {
      HeaderTitle: "參數管理",
      message: "",
      showMessage: false,
      isLoading: false,
      columns: ["參數"],
      TableItemList: [
        {
          ID: "ProductTypeList",
          參數: "產品類型"
        },
        {
          ID: "CountryList",
          參數: "國家/地區"
        },
        {
          ID: "TasteNoteList",
          參數: "風味"
        },
        {
          ID: "AromaList",
          參數: "香氣"
        },
        {
          ID: "ParingTagList",
          參數: "搭配標籤"
        },
        {
          ID: "Language",
          參數: "語言"
        },
        {
          ID: "EventTypeList",
          參數: "活動類型"
        },
        {
          ID: "EventLevelList",
          參數: "活動等級"
        }
      ],
      AddNewButton: "",
      selectAll: false,
      selectable: false,
      haveSearchFunction: false,
      showPageNumber: false,
      showNextButton: false
    };
  },
  methods: {
    onclickItem: function(ID) {
      this.$router.push({ path: ID + "/" });
    }
  }
};
</script>
