<template>
  <div>
    <md-card class="TagePage" style="margin: unset;">
      <md-card-content>
        <div v-if="ImageList.length > 0">
          <div style="width: 100%;text-align: right;">
            <button @click="addFileInput()" class="newProductButton">
              增加圖片
            </button>
          </div>
          <draggable
            :list="ImageList"
            :group="{ name: 'g1' }"
            style="height: 65vh;margin-top: 15px;overflow-y: auto;overflow-x: hidden;"
          >
            <div v-for="Item in ImageList" :key="Item.ID">
              <div v-if="Item.Action != 'delete'">
                <FileInput
                  v-on:RemoveItem="RemoveItem"
                  :Item="Item"
                  :acceptType="acceptType"
                />
              </div>
            </div>
          </draggable>
        </div>
        <div v-else>
          <button @click="addFileInput()" class="newProductButton centerButton">
            增加圖片
          </button>
        </div>
      </md-card-content>
    </md-card>
    <Transition name="modal" v-if="showConfirmDeleteMedia">
      <Popup
        v-on:popupcancel="showConfirmDeleteMedia = false"
        v-on:popupaction="deleteMedia"
        PopupTitle="刪除圖片"
        PopupContent="是否確定刪除此圖片?"
        actionButton="刪除"
      ></Popup>
    </Transition>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { FileInput, Spinner, Popup } from "@/components";

import draggable from "vuedraggable";
export default {
  components: {
    FileInput,
    Spinner,
    draggable,
    Popup
  },
  methods: {
    addFileInput: function() {
      var ID = new Date().getTime();
      this.ImageList.push({
        ID: ID,
        url: ""
      });
    },
    RemoveItem: function(ID, deleteAction) {
      console.log("RemoveItem", ID);
      console.log("deleteAction", deleteAction);
      if (deleteAction) {
        this.deleteMediaID = ID;
        this.showConfirmDeleteMedia = true;
      } else {
        var index = this.ImageList.findIndex(x => x.ID == ID);
        if (index > -1) {
          this.ImageList.splice(index, 1);
        }
      }
    },
    deleteMedia: async function() {
      var MediaID = this.deleteMediaID;
      this.showConfirmDeleteMedia = false;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("deleteEventImage", {
          router: this.$router,
          data: { EventID: this.$route.params.eventID, ImageID: MediaID }
        });
        console.log("deleteMedia", ret);
        var index = this.ImageList.findIndex(x => x.ID == MediaID);
        if (index > -1) {
          this.ImageList.splice(index, 1);
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("deleteMedia", err);
      }
    }
  },
  props: {
    ImageList: {
      default() {
        return [];
      }
    },
    mode: {
      default() {
        return "Create";
      }
    },
    Status: {
      default() {
        return "Draft";
      }
    }
  },
  data() {
    return {
      isLoading: false,
      acceptType: "image/*",
      showConfirmDeleteMedia: false,
      deleteMediaID: ""
    };
  }
};
</script>
