import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";
const contentManagement = {
  getInformationList({ commit, state }, { router }) {
    return oneSipApiCall(commit, state, router, "/getInformationList");
  },
  getInformation({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/getInformation", data);
  },
  updateContactUs({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/updateContactUs", data);
  },
  updatePrivacyPolicy({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/updatePrivacyPolicy", data);
  },
  updateAboutOneSip({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/updateAboutOneSip", data);
  },
  updateTermsAndConditions({ commit, state }, { router, data }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/updateTermsAndConditions",
      data
    );
  },
  updateInformation({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/updateInformation", data);
  },
  uploadHomeBannerImage({ commit, state }, { router }) {
    return oneSipApiCall(commit, state, router, "/uploadHomeBannerImage");
  },
  deleteHomeBannerImage({ commit, state }, { router, data }) {
    return oneSipApiCall(commit, state, router, "/deleteHomeBannerImage", data);
  }
};
export default contentManagement;
