<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item">
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <!--
                    if tab.Selected is true, then the tab is selected
                    background color is black and text color is white
                    else, background color is white and text color is black
                -->
        <div class="row" style="width: 100% !important; padding: 20px 8px;">
          <div v-for="tab in Tab" :key="tab.Key" class="tab" :style="tabWidth">
            <div @click="changeTab(tab.Key)">
              <md-card class="tabClick" style="margin: unset;">
                <md-card-content
                  :class="tabSelected(tab.Selected)"
                  style="padding:25px 0 !important"
                >
                  <label class="tabLabel">{{ tab.Name }}</label>
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
        <div v-if="showTab == 'GeneralInfo'">
          <GeneralInfo
            v-on:checkEdited="checkEdited"
            v-on:checkLoading="checkLoading"
            v-on:checkMessage="checkMessage"
            :mode="mode"
            :Status="Status"
            :EventTypeInput="GeneralInfo.EventTypeInput"
            :EventLevelInput="GeneralInfo.EventLevelInput"
            :EventLocationInput="GeneralInfo.EventLocationInput"
            :DisplayLanguageInput="GeneralInfo.DisplayLanguageInput"
            :LanguageInput="GeneralInfo.LanguageInput"
            :TitleInput="GeneralInfo.TitleInput"
            :DescriptionInput="GeneralInfo.DescriptionInput"
            :TagsInput="GeneralInfo.TagsInput"
            :PolicyInput="GeneralInfo.PolicyInput"
          />
        </div>
        <div v-if="showTab == 'ProductList'">
          <ProductList
            v-on:setProductList="setProductList"
            v-on:checkEdited="checkEdited"
            :ProductList="ProductList"
            :Status="Status"
            :mode="mode"
          />
        </div>
        <div v-if="showTab == 'EventImage'">
          <EventImage
            :ImageList="EventImageList"
            :Status="Status"
            :mode="mode"
          />
        </div>
        <div v-if="showTab == 'EventVariance'">
          <EventVariance
            v-on:addEventVariance="addEventVariance"
            v-on:deleteEventVariance="deleteEventVariance"
            :EventVarianceList="EventVarianceList"
          />
        </div>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import { FormHeader, Spinner } from "@/components";
import GeneralInfo from "./tabPage/GeneralInfo.vue";
import ProductList from "./tabPage/ProductList.vue";
import EventImage from "./tabPage/EventImage.vue";
import EventVariance from "./tabPage/EventVariance.vue";
export default {
  components: {
    FormHeader,
    Spinner,
    GeneralInfo,
    ProductList,
    EventImage,
    EventVariance
  },
  async created() {
    var page = this.$route.params.page;
    console.log("page", page);
    if (page != null) {
      this.showTab = page;
      this.changeTab(page);
    }
  },
  computed: {
    /*
        style function
    */
    tabWidth() {
      return {
        width: 100 / this.Tab.length - 1 + "%"
      };
    }
  },
  data() {
    return {
      mode: "Create",
      Status: "Draft",
      isLoading: false,
      HeaderTitle: "新建活動",
      formActionButton: "下一步",
      confirmBack: false,
      showFormMessage: false,
      formMessage: "",
      showTab: "GeneralInfo",
      ProductList: [],
      EventImageList: [],
      EventVarianceList: [],
      Tab: [
        {
          Name: "基本資料",
          Key: "GeneralInfo",
          Selected: true
        },
        {
          Name: "活動產品",
          Key: "ProductList",
          Selected: false
        },
        {
          Name: "圖片",
          Key: "EventImage",
          Selected: false
        },
        {
          Name: "日期選項",
          Key: "EventVariance",
          Selected: false
        }
      ],
      GeneralInfo: {
        TitleInput: {
          type: "text",
          key: "Title",
          name: "活動名稱",
          required: true,
          value: ""
        },
        DescriptionInput: {
          type: "text",
          key: "Description",
          name: "簡介",
          required: false,
          value: ""
        },
        PolicyInput: {
          type: "text",
          key: "Policy",
          name: "注意事項",
          required: true,
          value:
            "\注意事項:\n- 為了課堂順利進行，請注意不要遲到，遲到的時間將不會順延或補課，也無法退款。\n- 請到達後向在場工作人員出示入場二維碼門票，如報名時有邀請嘉賓請集合後出示門票。\n- 只限一次24小時前通知改期及不設退款。\n- 如因天氣或其他不可抗力因素無法如常進行課程，請先查詢安排，課程亦可能將會延期。\n- 場地需要脫鞋及席地而坐，請穿襪子及避免穿著短裙出席。\n- 未滿18歲人士禁止參與任何涉及酒精飲料的活動，工作人員如有需要可要求參加者出示其身分證以供核實。"
        },
        LanguageInput: {
          key: "Language",
          name: "導師語言",
          required: true,
          value: null,
          option: [
            {
              key: "English",
              name: "English"
            },
            {
              key: "Cantoneses",
              name: "廣東話"
            },
            {
              key: "Mandarin",
              name: "普通話"
            }
          ]
        },
        DisplayLanguageInput: {
          key: "DisplayLanguage",
          name: "顯示語言",
          required: true,
          value: null,
          option: []
        },
        EventTypeInput: {
          key: "EventType",
          name: "活動類型",
          required: true,
          value: null,
          option: []
        },
        EventLevelInput: {
          key: "EventLevel",
          name: "活動等級",
          required: true,
          value: null,
          option: []
        },
        EventLocationInput: {
          key: "Country",
          name: "國家/地區",
          required: true,
          model: "",
          value: ""
        },
        TagsInput: {
          type: "array",
          key: "Tags",
          name: "Tags",
          required: false,
          value: []
        }
      }
    };
  },
  methods: {
    /*
        Event Variance function
    */
    addEventVariance: function(newVariance) {
      console.log("addEventVariance", newVariance);
      this.EventVarianceList.push(newVariance);
    },
    deleteEventVariance: function(delList) {
      for (var d in delList) {
        var index = this.EventVarianceList.findIndex(x => x.ID == delList[d]);
        if (index >= 0) {
          this.EventVarianceList.splice(index, 1);
        }
      }
    },
    formAction: async function() {
      //console.log(this.GeneralInfo);
      // console.log(this.ProductList);
      // console.log(this.EventImageList);
      // console.log(this.EventVarianceList);
      //return
      console.log("formAction");
      this.isLoading = true;
      var GeneralInfoResut = this.GeneralInfoCheck();
      var ProductListResult = this.ProductListCheck();
      var EventImageListResult = this.EventImageListCheck();
      if (GeneralInfoResut && ProductListResult && EventImageListResult) {
        console.log("pass");
      } else {
        console.log("fail");
        this.isLoading = false;
        return;
      }

      var GeneralInfoObject = {};
      for (const [key, value] of Object.entries(this.GeneralInfo)) {
        GeneralInfoObject[value.key] = value.value;
      }
      var ProductList = [];
      for (var p in this.ProductList) {
        ProductList.push(this.ProductList[p].ID);
      }
      GeneralInfoObject.ProductList = ProductList;

      var Image = [];

      for (var i in this.EventImageList) {
        Image.push(this.EventImageList[i].ID);
      }
      GeneralInfoObject.Image = Image;
      console.log(GeneralInfoObject);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createEvent", {
          router: this.$router,
          data: GeneralInfoObject
        });
        console.log("createEvent", ret);
        this.CreateSurvey(ret.data.EventID);
        this.CreateVariance(ret.data.EventID);

        this.isLoading = false;
        this.$router.push({
          name: "Event Survey Summary",
          params: { eventID: ret.data.EventID }
        });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    CreateVariance: async function(EventID) {
      if (this.EventVarianceList.length == 0) {
        return;
      }
      var object = {
        EventID: EventID,
        VarianceList: this.EventVarianceList
      };
      try {
        const ret = await this.$store.dispatch("createEventVarianceList", {
          router: this.$router,
          data: object
        });
        console.log(ret);
      } catch (error) {
        console.log(error);
      }
    },
    CreateSurvey: async function(EventID) {
      var Survey = [];
      // if(this.Survey.StartSurveyInput.value != null && this.Survey.StartSurveyInput.value != ""){
      //     Survey.push({
      //         "Survey": this.Survey.StartSurveyInput.value,
      //         "Type": "Event"
      //     })
      // }
      for (var p in this.ProductList) {
        for (var s in this.ProductList[p].Survey) {
          Survey.push({
            Survey: this.ProductList[p].Survey[s].Survey,
            Parameter: this.ProductList[p].Survey[s].Parameter,
            Type: "EventProduct",
            ProductID: this.ProductList[p].ID
          });
        }
      }
      // if(this.Survey.EndSurveyInput.value != null && this.Survey.EndSurveyInput.value != ""){
      //     Survey.push({
      //         "Survey": this.Survey.EndSurveyInput.value,
      //         "Type": "Event"
      //     })
      // }
      console.log(Survey);
      var object = {
        EventID: EventID,
        Survey: Survey
      };
      try {
        const ret = await this.$store.dispatch("createEventSurvey", {
          router: this.$router,
          data: object
        });
        console.log(ret);
      } catch (error) {
        console.log(error);
      }
    },
    /*
        Data Check
    */
    GeneralInfoCheck() {
      for (const [key, value] of Object.entries(this.GeneralInfo)) {
        if (!value.required) {
          continue;
        }
        if (value.value == null || value.value == "" || value.value == []) {
          this.formMessage = value.name + " is required";
          this.showFormMessage = true;
          return false;
        }
      }
      return true;
    },
    ProductListCheck() {
      if (this.ProductList.length == 0) {
        this.formMessage = "Product List is required";
        this.showFormMessage = true;
        return false;
      }
      for (var p in this.ProductList) {
        console.log(this.ProductList[p]);
        if (this.ProductList[p].Survey.length == 0) {
          this.formMessage = "Survey is required";
          this.showFormMessage = true;
          return false;
        }
        for (var s in this.ProductList[p].Survey) {
          var survey = this.ProductList[p].Survey[s];
          if (survey.Survey == null || survey.Survey == "") {
            this.formMessage = "Survey is required";
            this.showFormMessage = true;
            return false;
          }
        }
      }
      return true;
    },
    EventImageListCheck() {
      if (this.EventImageList.length == 0) {
        this.formMessage = "Event Image is required";
        this.showFormMessage = true;
        return false;
      }
      return true;
    },
    /*
        Product List function
    */
    setProductList: function(ProductList) {
      console.log("setProductList", ProductList);
      //push product list to inspiration product list
      for (var i = 0; i < ProductList.length; i++) {
        var index = this.ProductList.findIndex(x => x.ID == ProductList[i].ID);
        if (index < 0) {
          this.ProductList.push(ProductList[i]);
        }
      }
    },
    /*
        check if the form is edited
    */
    checkEdited: function(check) {
      this.confirmBack = check;
    },
    checkLoading: function(check) {
      this.isLoading = check;
    },
    checkMessage: function(message) {
      this.formMessage = message;
      this.showFormMessage = true;
    },

    /*
        change tab function
    */
    tabSelected: function(selected) {
      if (selected) {
        return "tabSelected";
      } else {
        return "tabNotSelected";
      }
    },
    changeTab: function(tab) {
      console.log(tab);
      for (var i in this.Tab) {
        if (this.Tab[i].Key == tab) {
          this.Tab[i].Selected = true;
          this.showTab = this.Tab[i].Key;
          this.$router.replace({
            name: "Create Event PageNumber",
            params: { page: tab }
          });
        } else {
          this.Tab[i].Selected = false;
        }
      }
    }
  }
};
</script>
<style>
.tab {
  text-align: center;
  padding-right: 1%;
}
.tabLabel {
  font-size: 25px;
}
.tabClick {
  cursor: pointer;
}
.tabSelected {
  background-color: black;
  color: white;
}
.tabNotSelected {
  background-color: white;
  color: black;
}
.TagePage {
  text-align: center;
  margin: unset;
  min-height: 650px;
  height: 75vh;
}
</style>
