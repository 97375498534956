<template>
  <div>
    <label>{{ Item.name }}</label
    ><label style="color:red" v-if="Item.required">*</label>
    <md-field style="margin-top:0px;padding-top:5px">
      <md-input
        type="text"
        class="formInput"
        v-model="Item.model"
        @change="checkEdited"
        @input="checkAutocomplete($event, Item)"
      />
    </md-field>
    <div class="autocompletediv" v-if="autocompleteList.length > 0">
      <ul class="autocomplete-list">
        <li
          v-for="autocompleteItem in autocompleteList"
          :key="autocompleteItem.Key"
        >
          <button
            @click="
              setAutocompleteValue(autocompleteItem.Key, autocompleteItem.Name)
            "
          >
            {{ autocompleteItem.Name }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  name: "user-input-component",
  props: {
    Item: {
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      keyword: "",
      selectedValue: "",
      autocompleteList: []
    };
  },
  methods: {
    checkEdited: function() {
      this.$emit("checkEdited", true);
    },
    checkAutocomplete: function(event) {
      if (event.length > 1 && event !== this.selectedValue) {
        this.searchFromServer(event);
      } else {
        this.autocompleteList = [];
      }
    },
    setAutocompleteValue: function(Key, Name) {
      this.selectedValue = Name;
      this.Item.model = Name;
      this.Item.value = Key;
      this.autocompleteList = [];
      this.keyword = "";
    },
    searchFromServer: async function(keyword) {
      var object = {
        Keyword: keyword
      };
      try {
        const ret = await this.$store.dispatch("searchTasteCard", {
          router: this.$router,
          data: object
        });
        console.log("searchFromServer", ret);
        var resultList = ret.data.filterResult.hits;
        console.log("resultList", resultList);
        this.autocompleteList = [];
        for (var i = 0; i < resultList.length; i++) {
          this.autocompleteList.push({
            Key: resultList[i]._source.CardID,
            Name: resultList[i]._source.CardID
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  }
};
</script>
<style>
.formInput {
  font-weight: 500;
  font-size: 18px !important;
}
.autocompletediv {
  background-color: white;
  z-index: 10;
  position: absolute;
}
.autocomplete-list {
  list-style: none;
  padding: unset;
  margin: unset;
  /* margin-top: -20px */
}
.autocomplete-list button {
  border: unset;
  background: unset;
  cursor: pointer;
}
.autocomplete-list li {
  border: 1px solid #e9e9e9;
  border-top: unset;
}
</style>
