<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader :HeaderTitle="HeaderTitle" :confirmBack="confirmBack">
        </FormHeader>
        <center>
          <md-card style="width:50%;min-width: 500px">
            <md-card-content>
              <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :formMessage="formMessage"
                :showFormMessage="showFormMessage"
              >
              </FormComponent>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import { FormComponent, FormHeader, Spinner } from "@/components";
export default {
  created() {
    console.log(this.$route.params);
    this.getLocation(this.$route.params.locationID);
  },
  methods: {
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    getLocation: async function(locationID) {
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("getLocation", {
          router: this.$router,
          location: { EventHostLocationID: locationID }
        });
        console.log(ret);
        this.locationInfo = ret.data.data.Item;
        this.setDatatoForm(this.locationInfo);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("getLocation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setDatatoForm: function(data) {
      console.log("setDatatoForm", data);
      for (const [key, value] of Object.entries(this.FormItem)) {
        console.log(value);
        for (var i = 0; i < value.length; i++) {
          value[i]["value"] = data[value[i].key];
        }
      }
      console.log("FormItem", this.FormItem);
    },
    formSubmit: async function(object) {
      console.log("formSubmit");
      console.log(object);
      object.EventHostLocationID = this.locationInfo.EventHostLocationID;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateLocation", {
          router: this.$router,
          location: object
        });
        //const ret= await this.$store.dispatch('createCustomer', object)
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Location List",
            params: { message: "Location " + object.Name + " updated" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("updateLocation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  components: {
    FormComponent,
    FormHeader,
    Spinner
  },
  data() {
    return {
      isLoading: false,
      HeaderTitle: "Edit Location",
      FormTitle: "Location Information",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      FormItem: {
        row1: [
          {
            type: "text",
            key: "Name",
            name: "Name",
            required: true,
            style: "width:100%",
            value: null
          }
        ],
        row2: [
          {
            type: "text",
            key: "Address",
            name: "Address",
            required: true,
            style: "width:100%",
            value: null
          }
        ],
        row3: [
          {
            type: "select",
            key: "District",
            name: "District",
            required: true,
            value: null,
            option: [
              {
                key: "Hong Kong Island",
                name: "Hong Kong Island"
              },
              {
                key: "Kowloon",
                name: "Kowloon"
              },
              {
                key: "New Territories",
                name: "New Territories"
              }
            ]
          }
        ]
      },
      locationInfo: {}
    };
  }
};
</script>
