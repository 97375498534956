import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";

const seo = {
  getSEOList({ commit, state }, { router, object }) {
    return oneSipApiCall(commit, state, router, "/getSEOList", object);
  },
  // getSEOList({commit,state},data){
  //   return new Promise((resolve, reject) => {

  //     commit('action_request');
  //     //resolve(state.idToken);
  //     axios({url: url+'/getSEOList', data:data, method: 'POST' })
  //     .then(resp => {
  //       commit('action_success');
  //       console.log(resp);
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       commit('action_error',{err})
  //       reject(err)
  //     })

  //   })
  // },
  getSEO({ commit, state }, { router, object }) {
    return oneSipApiCall(commit, state, router, "/getSEO", object);
  },

  setSEO({ commit, state }, { router, object }) {
    return oneSipApiCall(commit, state, router, "/setSEO", object);
  }
};

export default seo;
