<template>
  <div>
    <div style="padding-left:10px">
      <label>{{ Item.name }}</label>
      <label style="color:red" v-if="Item.required">*</label>
    </div>
    <table>
      <tr>
        <td v-for="(item, index) in Item.value" :key="index" style="width:20px">
          <label>{{ item.name }}</label>
          <md-field style="margin-top:0px;padding-top:5px">
            <md-input
              type="number"
              class="formInput"
              v-model="item.value"
              min="0"
              max="4"
              @change="checkEdited"
            />
          </md-field>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    checkEdited: function() {
      this.$emit("checkEdited", true);
    }
  },
  name: "fivesenss-input-component",
  props: {
    Item: {
      default() {
        return {};
      }
    }
  }
};
</script>
