<template>
  <div class="content">
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver'
export default {

  async created() {
    await this.getLanguage();
    await this.getAllTasteNoteRelation();
    this.setTasteNote();
    
  },
  methods: {
    classifyTasteNote: function() {
      /*
        find all TasteNoteID that Parent is Root
      */
      var rootTasteNote = [];
      var notRootTasteNote = [];
      for (var i in this.relationList) {
        if (this.relationList[i].Parent === "Root") {
          rootTasteNote.push(this.relationList[i].TasteNoteID);
        } else {
          notRootTasteNote.push(this.relationList[i].TasteNoteID);
        }
      }
      return { rootTasteNote, notRootTasteNote };
    },
    tasteNoteIDToName: function(TasteNoteID) {
      var tastenote = this.tasteNoteList.find(obj => obj.TasteNoteID === TasteNoteID);
      var tasteNoteString = ""
      for(var i in this.languageList){
        tasteNoteString += tastenote.Name[this.languageList[i]] + ",";
      }
      /*
        remove last comma
      */
      tasteNoteString = tasteNoteString.slice(0, -1);
      return tasteNoteString;
    },
    setTasteNote: function() {
      var classifyResult = this.classifyTasteNote();
      var rootTasteNote = classifyResult.rootTasteNote;
      var notRootTasteNote = classifyResult.notRootTasteNote;
      var endLayerTasteNote = [];
      /*
        in notRootTasteNote, find all TasteNoteID that is not Parent of any TasteNoteID
      */
      for (var i in notRootTasteNote) {
        var child = this.relationList.filter(
          obj => obj.Parent === notRootTasteNote[i]
        );
        if (child.length === 0) {
          endLayerTasteNote.push(notRootTasteNote[i]);
        }
      }

      /*
        remove endLayerTasteNote from notRootTasteNote
      */
      for (var i in endLayerTasteNote) {
        var index = notRootTasteNote.indexOf(endLayerTasteNote[i]);
        if (index > -1) {
        notRootTasteNote.splice(index, 1);
        }
      }
      console.log("notRootTasteNote", notRootTasteNote);
      console.log("endLayerTasteNote", endLayerTasteNote);
      var Excel = []
      for (var i  in endLayerTasteNote){
        var list = [];
        list.push(this.tasteNoteIDToName(endLayerTasteNote[i]));
        var relation = this.relationList.find(obj => obj.TasteNoteID === endLayerTasteNote[i]);
        var parent = relation.Parent;
        while (parent !== "Root") {
          var parentRelation = this.relationList.find(obj => obj.TasteNoteID === parent);
          list.push(this.tasteNoteIDToName(parentRelation.TasteNoteID));
          var parent = parentRelation.Parent;
          if (parent === "Root") {
            var index = rootTasteNote.indexOf(parentRelation.TasteNoteID);
            rootTasteNote.splice(index, 1);
          }
        }
        Excel.push(list.reverse());
      }
        for(var i in rootTasteNote){
          var list = [];
          list.push(this.tasteNoteIDToName(rootTasteNote[i]));
          Excel.push(list);
        }
        console.log("setTasteNote Excel", Excel);
        this.ExcelTastenote = Excel;
        this.setKeyword(); 
    },
    setKeyword: function() {
      var Excel = [];
      for (var i in this.tasteNoteList) {
        var keywordObjList = this.keywordList.filter(
          obj => obj.TasteNoteID === this.tasteNoteList[i].TasteNoteID
        );
        console.log("keyword", keywordObjList);
        if( keywordObjList.length === 0 ) {
          Excel.push([this.tasteNoteIDToName(this.tasteNoteList[i].TasteNoteID), this.tasteNoteIDToName(this.tasteNoteList[i].TasteNoteID)]);
        } else {
          var keywordString = "";
          for(var j in keywordObjList){
            keywordString += keywordObjList[j].Keyword + ",";
          }
          keywordString = keywordString.slice(0, -1);
          Excel.push([this.tasteNoteIDToName(this.tasteNoteList[i].TasteNoteID), keywordString]);
        }
      }
      console.log("setKeyword Excel", Excel);
      this.ExcelKeyword = Excel;
      this.exportExcel();
    },
    /*
        api
    */
    exportExcel: async function() {
        const workbook = XLSX.utils.book_new();
        const languageSheet = XLSX.utils.aoa_to_sheet([
          this.languageList
        ]);
        const tasteNoteSheet = XLSX.utils.aoa_to_sheet(
          this.ExcelTastenote
        );
        const keywordSheet = XLSX.utils.aoa_to_sheet(
          this.ExcelKeyword
        );
        XLSX.utils.book_append_sheet(workbook, languageSheet, "Language");
        XLSX.utils.book_append_sheet(workbook, tasteNoteSheet, "TasteNote");
        XLSX.utils.book_append_sheet(workbook, keywordSheet, "Keyword");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelData, 'TasteNote.xlsx');
    },
    getAllTasteNoteRelation: async function() {
      try {
        const ret = await this.$store.dispatch("getAllTasteNoteRelation", {
          router: this.$router,
          data: null
        });
        console.log("getAllTasteNoteRelation", ret.data);
        this.tasteNoteList = ret.data.tasteNote
        this.relationList = ret.data.relation
        this.keywordList = ret.data.keyword
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language.map(obj => obj.Language)
        console.log("getLanguage", this.languageList);
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {  
      isLoading: false,
      formMessage: "",
      showFormMessage: false,
      languageList: [],
      tasteNoteList: [],
      keywordList: [],
      relationList: [],
      ExcelKeyword: [],
      ExcelTastenote: []
    };
  }
};
</script>

