<template>
  <div>
    <div v-if="QuestionList.length == 0" style="text-align:center">
      <md-card style="margin:10px 0;width: 550px;">
        <md-card-content>
          <h3 class="formTitle">Create your first Page of Survey</h3>
          <md-button
            style=" background-color: #575757 !important;width: 30% !important;"
            @click="addSurveyPage()"
            >Start</md-button
          >
        </md-card-content>
      </md-card>
    </div>
    <div v-else>
      <draggable
        class="dragArea"
        tag="ul"
        :list="QuestionList"
        :group="{ name: 'QuestionList' }"
      >
        <div v-for="(page, key) in QuestionList" :key="key">
          <div class="collapsible">
            <input type="checkbox" style="display:none" :id="key" checked />
            <div class="row unsetMargin" @click="expandItem(key)">
              <div class="explorIcon">
                <md-icon :id="key + icon" style="color: white;"
                  >expand_less</md-icon
                >
              </div>
              <div class="col explorTitle">
                <label>Page: {{ key + 1 }}</label>
              </div>
              <div class="row" style="margin: auto;">
                <div class="col" @click="diplicateSurveyPage(key)">
                  <md-icon class="iconButton">file_copy</md-icon>
                </div>
                <div class="col" @click="delSurveyPage(key)">
                  <md-icon class="iconButton" style="color:#cc2727"
                    >delete</md-icon
                  >
                </div>
              </div>
            </div>
            <div
              class="collapsible-item"
              :id="key + content"
              style="min-height: 500px;"
            >
              <md-card class="questionContent">
                <md-card-content>
                  <label>Title</label>
                  <md-field style="margin-top:0px;padding-top:5px">
                    <md-input
                      type="text"
                      class="formInput"
                      v-model="page.Title"
                    />
                  </md-field>
                  <label>Description</label>
                  <md-field style="margin-top:0px;padding-top:5px">
                    <md-input
                      type="text"
                      class="formInput"
                      v-model="page.Description"
                    />
                  </md-field>
                  <SurveyTemplateQuestion
                    :QuestionList="page['QuestionList']"
                  />
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
      </draggable>

      <div>
        <md-button
          class="md-fab md-mini md-primary md-arrow"
          @click="addSurveyPage()"
        >
          <md-icon>add</md-icon>
        </md-button>
      </div>
      <!-- <div class="row"  style="flex-wrap: inherit;">
                <div class="column" v-for="(value, index) in QuestionList" :key="index" style="padding-right: 20px;">
                    <md-card style="margin:10px 0;width: 550px;">
                        <md-card-content>   
                            <div class="row">
                                <div class="col"> <h3 class="formTitle"> Page: {{ index + 1 }}</h3></div>
                                <div style="text-align: right;" class="row">
                                    <div class="col" @click="diplicateSurveyPage(index)"><md-icon class="iconButton">file_copy</md-icon></div>
                                    <div class="col" @click="delSurveyPage(index)"><md-icon class="iconButton" style="color:#cc2727">delete</md-icon> </div>
                                </div>
                            </div>
                            <div>
                                <label>Title</label>
                                <md-field style="margin-top:0px;padding-top:5px">
                                    <md-input type="text" class="formInput" v-model=value.Title />
                                </md-field>
                                <label>Description</label>
                                <md-field style="margin-top:0px;padding-top:5px">
                                    <md-input type="text" class="formInput" v-model=value.Description />
                                </md-field>
                            </div>
                            <SurveyTemplateQuestion
                                :QuestionList="value['QuestionList']"
                            />
                        </md-card-content>
                    </md-card>
                </div>
                <div class="column">
                    <div>
                        <md-button class="md-fab md-mini md-primary md-arrow" @click="addSurveyPage()">
                            <md-icon>add</md-icon>
                        </md-button>
                    </div>
                </div>
            </div>  -->
    </div>
  </div>
</template>

<script>
import SurveyTemplateQuestion from "./SurveyTemplateQuestion.vue";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
    SurveyTemplateQuestion
  },
  methods: {
    expandItem: function(element) {
      let iconClass = document.getElementById(element + this.icon);
      let contentClass = document.getElementById(element + this.content);
      console.log("iconClass", iconClass);
      if (document.getElementById(element).checked) {
        iconClass.classList.remove("iconup");
        iconClass.classList.add("icondown");
      } else {
        iconClass.classList.remove("icondown");
        iconClass.classList.add("iconup");
      }
      document.getElementById(element).checked = !document.getElementById(
        element
      ).checked;
      if (document.getElementById(element).checked) {
        console.log("scrollHeight", contentClass.scrollHeight);
        contentClass.style.minHeight = "500px";
        contentClass.style.maxHeight = "500px";
      } else {
        contentClass.style.maxHeight = "0px";
        contentClass.style.minHeight = "0px";
      }
    },
    addSurveyPage: function() {
      console.log(this.QuestionList.length);
      this.paddingValue = 170 + this.QuestionList.length * 570;
      this.$emit("addSurveyPage");
    },
    delSurveyPage: function(index) {
      this.paddingValue = 170 + this.QuestionList.length * 570;
      this.$emit("delSurveyPage", index);
    },
    diplicateSurveyPage: function(index) {
      this.paddingValue = 170 + this.QuestionList.length * 570;
      this.$emit("diplicateSurveyPage", index);
    },
    addQuestion: function() {
      console.log("addQuestion");
      //this.$emit("addQuestion")
    }
  },

  name: "survey-template-question-component",
  props: {
    QuestionList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      icon: "icon",
      content: "content",
      paddingValue: 170
    };
  },
  computed: {}
};
</script>

<style>
.addQuestion {
  cursor: pointer;
  font-size: 18px;
}
.formTitle {
  padding-left: 15px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
}
.iconButton {
  cursor: pointer;
}
</style>
