<template>
  <div>
    <md-card>
      <md-card-content>
        <div style="width:100%">
          <h3 class="formTitle">產品選項</h3>
        </div>
        <TableComponent
          v-on:selectItem="selectItem"
          v-on:selectAllItem="selectAllItem"
          v-on:onclickItem="onclickItem"
          v-on:selectedItemActionFunction="selectedItemActionFunction"
          :TableList="VarianceItemList"
          :selectedItemCounter="selectedItemCounter"
          :selectedItem="selectedItem"
          :columns="columns"
          :selectAll="selectAll"
          :selectable="selectable"
          :selectedItemAction="selectedItemAction"
          :showPageNumber="false"
        />
        <div
          style="text-align: center;padding-top: 10px;"
          @click="showAddVariance = true"
        >
          <md-icon>add_circle_outline</md-icon>
          <u class="addLang">新增</u>
        </div>
      </md-card-content>
    </md-card>
    <Spinner v-if="isLoading" />
    <Transition name="modal" v-if="showAddVariance">
      <AddVariancePopup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="AddVariance"
        PopupTitle="新增選項"
        actionButton="新增"
      />
    </Transition>
    <Transition name="modal" v-if="showdeleteVariancePopup">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="deleteVariance"
        PopupTitle="確認刪除"
        :PopupContent="deleteVarianceContent"
        actionButton="刪除"
      />
    </Transition>
  </div>
</template>

<script>
import { TableComponent, Spinner, AddVariancePopup, Popup } from "@/components";
export default {
  components: {
    TableComponent,
    Spinner,
    AddVariancePopup,
    Popup
  },
  props: {
    VarianceItemList: {
      default() {
        return [];
      }
    }
  },
  methods: {
    /*
    TableComponent function
    */
    selectItem: function(ID, action) {
      console.log(ID, action);
      if (action) {
        this.selectedItem.push(ID);
      } else {
        this.selectAll = false;
        var index = this.selectedItem.indexOf(ID);
        this.selectedItem.splice(index, 1);
      }
      console.log(this.selectedItem.length);
      this.selectedItemCounter = this.selectedItem.length;
    },
    selectAllItem: function(action) {
      this.selectAll = false;
      if (action) {
        this.selectAll = true;
        for (var i = 0; i < this.VarianceItemList.length; i++) {
          var index = this.selectedItem.indexOf(this.VarianceItemList[i].ID);
          if (index < 0) {
            this.selectedItem.push(this.VarianceItemList[i].ID);
          }
        }
      } else {
        for (var i = 0; i < this.VarianceItemList.length; i++) {
          var index = this.selectedItem.indexOf(this.VarianceItemList[i].ID);
          this.selectedItem.splice(index, 1);
        }
      }
      console.log("after", this.selectedItem);
      this.selectedItemCounter = this.selectedItem.length;
    },
    onclickItem: async function(ID) {
      this.$router.push({
        name: "Update Variance",
        params: { VarianceID: ID }
      });
    },
    selectedItemActionFunction: function(action) {
      console.log("selectedItemActionFunction", action);
      if (action == "delete") {
        console.log("delete", this.selectedItem);
        this.deleteVarianceContent =
          "是否確認刪除" + this.selectedItem.length + "個選項?";
        this.showdeleteVariancePopup = true;
      }
    },
    /*
    end of TableComponent function
    */
    /*
    Popup action 
    */
    popupcancel: function() {
      this.showAddVariance = false;
      this.showdeleteVariancePopup = false;
    },
    deleteVariance: async function() {
      this.showdeleteVariancePopup = false;
      this.isLoading = true;
      try {
        var error = false;
        for (var i = 0; i < this.selectedItem.length; i++) {
          error = await this.DeleteVariance(this.selectedItem[i]);
        }
        this.isLoading = false;
        if (!error) {
          this.formMessage = "刪除成功";
          this.showFormMessage = true;
        }
        this.$emit("renewInfo");
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err;
        this.showFormMessage = true;
        console.log(err);
      }
    },
    /*
    end of Popup action
    */
    /*
    Variance API
    */
    AddVariance: async function(data) {
      var object = {
        ProductID: this.$route.params.ProductID,
        Name: data.Name,
        Price: parseInt(data.Price),
        Weight: parseInt(data.Weight)
      };
      if (data.Inventory) {
        object["Inventory"] = data.Inventory;
      }
      var Image = [];
      for (var i in data.Image) {
        Image.push(data.Image[i].ID);
      }
      object["Image"] = Image;
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createProductVariance", {
          router: this.$router,
          variance: object
        });
        console.log("createProductVariance", ret.data);
        this.showAddVariance = false;
        this.isLoading = false;
        this.$emit("renewInfo");
      } catch (err) {
        this.isLoading = false;
        console.log("createProductVariance error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    DeleteVariance: async function(data) {
      const Object = {
        ProductID: this.$route.params.ProductID,
        VarianceID: data
      };
      try {
        const ret = await this.$store.dispatch("deleteProductVariance", {
          router: this.$router,
          data: Object
        });
        console.log("deleteProductVariance", ret.data);
        this.isLoading = false;
        return false;
      } catch (err) {
        console.log("deleteProductVariance error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      showAddVariance: false,
      showdeleteVariancePopup: false,
      isLoading: false,
      selectedItemCounter: 0,
      selectedItem: [],
      columns: ["img", "選項名稱", "價格", "重量"],
      selectAll: false,
      selectable: true,
      selectedItemAction: [
        { Key: "delete", Name: "刪除", Style: "color:#CC2727" }
      ]
    };
  }
};
</script>