<template>
  <div>
    <md-card class="TagePage" style="margin: unset;height: 800px;">
      <md-card-content style="text-align: left;">
        <div class="row">
          <div style="width: 30%;padding:0 20px">
            <SelectInput
              :Item="EventTypeInput"
              v-on:checkEdited="checkEdited"
              style="padding-top:25px"
            />
          </div>
          <div style="width: 30%;padding:0 20px">
            <SelectInput
              :Item="EventLevelInput"
              v-on:checkEdited="checkEdited"
              style="padding-top:25px"
            />
          </div>
          <div style="width: 30%;padding:0 20px">
            <CountryInput
              v-on:checkEdited="checkEdited"
              :languageList="languageList"
              :Item="EventLocationInput"
            />
          </div>
        </div>
        <div class="row" style="padding-top:25px">
          <div class="inputcolumn">
            <SelectInput :Item="LanguageInput" v-on:checkEdited="checkEdited" />
          </div>
          <div class="inputcolumn">
            <SelectInput
              :Item="DisplayLanguageInput"
              v-on:checkEdited="checkEdited"
            />
          </div>
        </div>
        <TextInput :Item="TitleInput" v-on:checkEdited="checkEdited" />
        <TextAreaInput
          :Item="DescriptionInput"
          v-on:checkEdited="checkEdited"
        />
        <ChipsInput :Item="TagsInput" v-on:checkEdited="checkEdited" />
        <TextAreaInput :Item="PolicyInput" v-on:checkEdited="checkEdited" />
        <div v-if="mode == 'Edit'" class="row" style="padding-top:25px">
          <div style="width: 20%">
            <SelectInput :Item="StatusInput" v-on:checkEdited="checkEdited" />
          </div>
          <!-- <div class="inputcolumn">
                    <SelectInput
                        :Item="EndSurveyInput"
                        v-on:checkEdited="checkEdited"
                    />
                </div>   -->
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import {
  SelectInput,
  CountryInput,
  ChipsInput,
  TextInput,
  TextAreaInput
} from "@/components";

export default {
  components: {
    SelectInput,
    CountryInput,
    ChipsInput,
    TextInput,
    TextAreaInput
  },
  props: {
    mode: {
      default() {
        return "Create";
      }
    },
    Status: {
      default() {
        return "Draft";
      }
    },
    EventTypeInput: {
      default() {
        return {
          type: "select",
          key: "EventType",
          name: "活動類型",
          required: true,
          value: null,
          option: []
        };
      }
    },
    EventLevelInput: {
      default() {
        return {
          type: "select",
          key: "EventLevel",
          name: "活動等級",
          required: true,
          value: null,
          option: []
        };
      }
    },
    EventLocationInput: {
      default() {
        return {
          type: "country",
          key: "Country",
          name: "國家/地區",
          required: true,
          value: null,
          option: []
        };
      }
    },
    DisplayLanguageInput: {
      default() {
        return {
          type: "select",
          key: "DisplayLanguage",
          name: "顯示語言",
          required: true,
          value: null,
          option: []
        };
      }
    },
    LanguageInput: {
      default() {
        return {
          type: "select",
          key: "Language",
          name: "導師語言",
          required: true,
          value: null,
          option: []
        };
      }
    },
    TitleInput: {
      default() {
        return {
          type: "text",
          key: "Title",
          name: "活動名稱",
          required: true,
          value: null
        };
      }
    },
    DescriptionInput: {
      default() {
        return {
          type: "text",
          key: "Description",
          name: "簡介",
          required: false,
          value: null
        };
      }
    },
    TagsInput: {
      default() {
        return {
          type: "array",
          key: "Tags",
          name: "Tags",
          required: false,
          value: null
        };
      }
    },
    PolicyInput: {
      default() {
        return {
          type: "text",
          key: "Policy",
          name: "注意事項",
          required: true,
          value: null
        };
      }
    },
    StatusInput: {
      default() {
        return {
          type: "select",
          key: "Status",
          name: "Status",
          required: true,
          value: "Draft",
          option: [
            {
              key: "Draft",
              name: "草稿"
            },
            {
              key: "Published",
              name: "公開"
            },
            {
              key: "Private",
              name: "私人"
            },
            {
              key: "UnListed",
              name: "不公開"
            }
          ]
        };
      }
    }
  },
  async created() {
    this.isLoading = true;
    await this.getLanguage();
    this.getEventType();
    //this.getEventSurvey();
    await this.getEventLevel();
    // //await this.getLocation();
    this.isLoading = false;
  },
  watch: {
    isLoading: function(newValue) {
      this.$emit("isLoading", newValue);
    }
  },
  methods: {
    checkEdited: function() {
      this.$emit("checkEdited", true);
    },
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        console.log("getLanguage", ret);
        var Language = ret.data.Language;
        this.languageList = Language;
        this.DisplayLanguageInput.option = [];
        for (var i = 0; i < Language.length; i++) {
          this.DisplayLanguageInput.option.push({
            key: Language[i].Language,
            name: Language[i].Language
          });
        }
      } catch (err) {
        console.log("getLanguage error", err);
        this.formMessage = err;
        this.showFormMessage = true;
      }
    },
    getEventSurvey: async function() {
      var object = {
        TemplateType: "Event"
      };
      try {
        const ret = await this.$store.dispatch("listSurveyTemplates", {
          router: this.$router,
          data: object
        });
        console.log("listSurveyTemplates", ret.data);
        for (var i = 0; i < ret.data.data.length; i++) {
          this.StartSurveyInput.option.push({
            key: ret.data.data[i].TemplateID,
            name: ret.data.data[i].Name
          });
          this.EndSurveyInput.option.push({
            key: ret.data.data[i].TemplateID,
            name: ret.data.data[i].Name
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    getEventType: async function() {
      try {
        const ret = await this.$store.dispatch("getEventType", {
          router: this.$router
        });
        console.log("getEventType", ret);
        var EventType = ret.data.EventType;
        this.EventTypeInput.option = [];
        for (var i = 0; i < EventType.length; i++) {
          this.EventTypeInput.option.push({
            key: EventType[i].EventTypeID,
            name: EventType[i].Name.en + " / " + EventType[i].Name.zh
          });
        }
      } catch (err) {
        console.log("getEventType error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getEventLevel: async function() {
      try {
        const ret = await this.$store.dispatch("getEventLevel", {
          router: this.$router
        });
        console.log("getEventLevel", ret);
        var EventLevel = ret.data.EventLevel;
        this.EventLevelInput.option = [];
        for (var i = 0; i < EventLevel.length; i++) {
          this.EventLevelInput.option.push({
            key: EventLevel[i].EventLevelID,
            name: EventLevel[i].Name.en + " / " + EventLevel[i].Name.zh
          });
        }
      } catch (err) {
        console.log("getEventLevel error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      languageList: []
    };
  }
};
</script>
