<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        >
        </FormHeader>
        <center>
          <md-card style="width:55%;min-width: 500px">
            <md-card-content>
              <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :formMessage="formMessage"
                :showFormMessage="showFormMessage"
                :haveDoneButton="false"
              >
              </FormComponent>
            </md-card-content>
          </md-card>

          <md-card style="width:55%;min-width: 500px">
            <md-card-content>
              <ProductSelecter
                :ProductList="InventoryProductList"
                :HeaderTitle="PopHeaderTitle"
                :haveQuantity="true"
              >
              </ProductSelecter>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import {
  FormComponent,
  FormHeader,
  ProductSelecter,
  Spinner
} from "@/components";

export default {
  components: {
    FormComponent,
    FormHeader,
    ProductSelecter,
    Spinner
  },
  async created() {
    this.isLoading = true;
    this.InventoryID = this.$route.params.InventoryID;
    await this.getInventory();
    this.isLoading = false;
  },
  methods: {
    getInventory: async function() {
      var object = {
        InventoryID: this.InventoryID
      };
      try {
        const ret = await this.$store.dispatch("getInventory", {
          router: this.$router,
          data: object
        });
        console.log(ret);
        this.setDatatoForm(ret.data.Inventory);
        this.getProduct(ret.data.ProductList);
      } catch (err) {
        console.log("getInventory error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getProduct: async function(Product) {
      console.log("getProduct", Product);
      var ProductIDList = [];
      for (var i = 0; i < Product.length; i++) {
        ProductIDList.push(Product[i].ProductID);
      }
      try {
        const ret = await this.$store.dispatch("getProduct", {
          router: this.$router,
          product: { ProductList: ProductIDList }
        });

        var productDetailList = ret.data.ProductDetailList;
        console.log("getProduct", productDetailList);
        for (var i = 0; i < productDetailList.length; i++) {
          var index = productDetailList.findIndex(
            product => product.ProductID === Product[i].ProductID
          );
          console.log("index", index);
          productDetailList[i].Quantity = Product[index].Quantity;
        }
        this.setProductList(productDetailList);
      } catch (err) {
        console.log("getProduct error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    setProductList: function(ProductList, Product) {
      for (var i = 0; i < ProductList.length; i++) {
        this.InventoryProductList.push({
          ID: ProductList[i].ProductID,
          img:
            process.env.VUE_APP_S3_URL + "product/" + ProductList[i].ProductID,
          "Product Name": ProductList[i].Name,
          Quantity: ProductList[i].Quantity,
          "Unit Price": ProductList[i].UnitPrice
        });
      }
    },
    setDatatoForm: function(data) {
      console.log("setDatatoForm", data);
      for (const [key, value] of Object.entries(this.FormItem)) {
        console.log(value);
        for (var i = 0; i < value.length; i++) {
          if (value[i]["key"] == "Location") {
            var index = value[i]["option"].findIndex(
              option => option.key.EventHostLocationID == data["LocationID"]
            );
            value[i]["value"] = value[i]["option"][index]["key"];
          } else {
            value[i]["value"] = data[value[i].key];
          }
        }
      }
      console.log("FormItem", this.FormItem);
    },
    checkEdited: async function(check) {
      console.log("checkEdited", check);
      this.confirmBack = check;
    },
    formAction: async function() {
      this.sending = true;
      var data = await this.getFormData(this.FormItem);
      data.ProductList = this.InventoryProductList;
      data.InventoryID = this.InventoryID;
      console.log("Data", data);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("updateInventory", {
          router: this.$router,
          data: data
        });
        console.log("updateInventory", ret);
        this.sending = false;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Inventory List",
            params: { message: "Inventory has been updated" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("updateInventory error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getFormData: async function(form) {
      var FormData = {};
      for (const [key, value] of Object.entries(form)) {
        for (var i = 0; i < value.length; i++) {
          if (value[i].required) {
            if (value[i].value) {
              if (value[i].type == "number") {
                FormData[value[i].key] = parseInt(value[i].value);
              } else {
                FormData[value[i].key] = value[i].value;
              }
            } else {
              this.formMessage = value[i].name + " is required";
              this.showFormMessage = true;
              return;
            }
          } else {
            if (value[i].type == "number") {
              FormData[value[i].key] = parseInt(value[i].value);
            } else {
              FormData[value[i].key] = value[i].value;
            }
          }
        }
      }
      return FormData;
    },
    formSubmit: async function(object) {}
  },

  data() {
    return {
      isLoading: false,
      InventoryID: null,
      HeaderTitle: "Update Inventory",
      FormTitle: "Inventory Information",
      PopHeaderTitle: "Add Product to Inventory",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      InventoryProductList: [],
      formActionButton: "Save",
      FormItem: {
        row1: [
          {
            type: "object",
            key: "InventoryName",
            name: "Name",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ],
        row2: [
          {
            type: "object",
            key: "InventoryAddress",
            name: "Address",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ]
      }
    };
  }
};
</script>
