<template lang="html">
  <video autoplay loop muted class="blog-video" ref="video">
    <source :src="url[0]" type="video/mp4" />
  </video>
</template>

<script>
export default {
  name: "BlogVideo",
  props: {
    url: {
      type: Array,
      required: true
    }
  },
  methods: {
    toggleActive() {
      console.log("TOGGLE");

      if (this.$refs.video.paused) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.blog-video {
  height: 100%;
  width: 100%;
  //object-fit: fill;
  // object-fit: fill;
}
</style>
