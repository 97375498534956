<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createNewItem"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="AddNewButton"
        />
        <md-card>
          <md-card-content>
            <TableComponent
              v-on:onclickItem="onclickItem"
              :TableList="TableItemList"
              :selectAll="selectAll"
              :selectable="selectable"
              :columns="columns"
              :haveSearchFunction="haveSearchFunction"
              :showPageNumber="showPageNumber"
              :showNextButton="showNextButton"
            />
          </md-card-content>
        </md-card>
        <!-- :AddNewItem="AddNewItem" -->
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showMessage">{{ message }}</md-snackbar>
  </div>
</template>

<script>
import { Spinner, TableComponent, FormHeader } from "@/components";

export default {
  components: {
    Spinner,
    TableComponent,
    FormHeader
  },
  async created() {
    await this.getEventType();
  },
  data() {
    return {
      HeaderTitle: "活動類型",
      language: "zh",
      message: "",
      showMessage: false,
      isLoading: false,
      columns: ["類型"],
      TableItemList: [],
      AddNewButton: "新增",
      selectAll: false,
      selectable: false,
      haveSearchFunction: false,
      showPageNumber: false,
      showNextButton: false,
      confirmBack: false
    };
  },
  methods: {
    onclickItem: function(ID) {
      var eventType = this.eventTypeList.find(x => x.EventTypeID === ID);
      this.$router.push({ name: "UpdateEventType", params: eventType });
    },
    createNewItem: function() {
      this.$router.push({ name: "CreateEventType" });
    },
    getEventType: async function() {
      try {
        const ret = await this.$store.dispatch("getEventType", {
          router: this.$router
        });
        this.eventTypeList = ret.data.EventType;
        for (var i in this.eventTypeList) {
          this.TableItemList.push({
            ID: this.eventTypeList[i].EventTypeID,
            類型: this.eventTypeList[i].Name[this.language]
          });
        }
      } catch (err) {
        console.log("getEventType error", err);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  }
};
</script>
