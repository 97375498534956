<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="createParingTag"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        />
        <center>
          <md-card style="width: 50%;text-align: left;">
            <md-card-content>
              <TextInput
                v-for="item in NameInputList"
                :key="item.key"
                :Item="item"
              />
              <md-field style="margin-top:0px;padding-top:0px">
                <md-select v-model="Type" class="formInput">
                  <md-option key="Food" value="Food">
                    Food
                  </md-option>
                  <md-option key="Odor" value="Odor">
                    Odor
                  </md-option>
                </md-select>
              </md-field>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>
<script>
import { FormHeader, Spinner, TextInput } from "@/components";
export default {
  components: {
    FormHeader,
    Spinner,
    TextInput
  },
  async created() {
    await this.getLanguage();
  },
  methods: {
    async createParingTag() {
      this.isLoading = true;
      var object = {
        Name: {},
        Type: this.Type
      };
      for (var i in this.NameInputList) {
        object.Name[this.NameInputList[i].key] = this.NameInputList[i].value;
      }
      try {
        const ret = await this.$store.dispatch("createParingTag", {
          router: this.$router,
          data: object
        });
        console.log("createParingTag", ret);
        this.isLoading = false;
        this.formMessage = "新增成功";
        this.showFormMessage = true;
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    /*
        api
    */
    getLanguage: async function() {
      try {
        const ret = await this.$store.dispatch("getLanguage", {
          router: this.$router,
          data: null
        });
        this.languageList = ret.data.Language;
        console.log("getLanguage", this.languageList);
        for (var i in this.languageList) {
          for (const [key, value] of Object.entries(this.languageList[i])) {
            this.NameInputList.push({
              key: value,
              name: value,
              value: this.Name[value],
              required: true
            });
          }
        }
      } catch (err) {
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      formActionButton: "儲存",
      HeaderTitle: "新增配對標籤",
      showFormMessage: false,
      confirmBack: false,
      isLoading: false,
      showConfirmDelete: false,
      formMessage: "",
      Name: {},
      Type: "Food",
      languageList: [],
      NameInputList: []
    };
  }
};
</script>
<style>
.dragArea {
  min-height: 0px;
  outline: 1px dashed;
}
</style>
