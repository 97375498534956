<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        ></FormHeader>
        <div class="row">
          <div style="width:60%">
            <md-card>
              <md-card-content>
                <NestedDrapDrop
                  :allowAddBelow="false"
                  :nextLayers="Footer"
                  :sending="sending"
                  v-on:remove="confirmRemoveItem"
                  v-on:setNewLangId="setNewLangId"
                  v-on:showDelLangPopup="showDelLangPopup"
                  v-on:setAddDialogTarget="setAddDialogTarget"
                />
              </md-card-content>
            </md-card>
          </div>
          <div style="width:35%;padding-left: 10px;">
            <md-card>
              <md-card-content>
                <NestedRawDisplayer :value="Footer" title="Footer" />
              </md-card-content>
            </md-card>
          </div>
        </div>
        <md-button
          class="md-fab md-primary md-fab-bottom-right"
          style="position: fixed !important; bottom: 15%"
          @click="setAddDialog()"
        >
          <md-icon>add</md-icon>
        </md-button>
      </div>
    </div>
    <Transition name="modal" v-if="showDeleteLangModal">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="delLangPopupAction"
        :PopupTitle="delLangPopupTitle"
        :PopupContent="delLangPopupContent"
        :actionButton="delLangActionButton"
      ></Popup>
    </Transition>
    <Transition name="modal" v-if="showRemoveItemPopUp">
      <Popup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="removeItemPopupAction"
        :PopupTitle="removeItemPopupTitle"
        :PopupContent="removeItemPopupContent"
        :actionButton="removeItemActionButton"
      ></Popup>
    </Transition>
    <Transition name="modal" v-if="showNewLangModal">
      <AddDataPopup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="newLangPopupAction"
        :PopupTitle="newLangPopupTitle"
        :PopupContent="newLangContent"
        :actionButton="newLangActionButton"
      ></AddDataPopup>
    </Transition>
    <Transition name="modal" v-if="showAddDialog">
      <AddDataPopup
        v-on:popupcancel="popupcancel"
        v-on:popupaction="addDialogAction"
        :PopupTitle="addDialogPopupTitle"
        :PopupContent="formItem"
        :actionButton="addDialogActionButton"
      ></AddDataPopup>
    </Transition>
    <Spinner v-if="isLoading" />
    <md-snackbar :md-active.sync="showFormMessage">{{
      formMessage
    }}</md-snackbar>
  </div>
</template>

<script>
import {
  FormHeader,
  NestedDrapDrop,
  NestedRawDisplayer,
  Popup,
  AddDataPopup,
  Spinner
} from "@/components";

export default {
  components: {
    FormHeader,
    NestedDrapDrop,
    NestedRawDisplayer,
    Popup,
    AddDataPopup,
    Spinner
  },
  created() {
    this.getInformation("Footer");
  },
  mounted() {},
  methods: {
    /***  Page Function  ***/
    getInformation: async function(InformationID) {
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("getInformation", {
          router: this.$router,
          data: { id: InformationID }
        });
        console.log("getInformation", ret);
        this.Footer = ret.data.Information.content;
        console.log("Footer", this.Footer);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("getInformation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    formAction: function() {
      const data = {
        id: "Footer",
        content: this.Footer
      };
      console.log("data", data);
      this.sending = true;
      this.isLoading = true;
      try {
        const ret = this.$store.dispatch("updateInformation", {
          router: this.$router,
          data: data
        });
        console.log("updateInformation", ret);
        this.sending = false;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Content Management",
            params: { message: "Footer has been updated" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.sending = false;
        console.log("updateInformation error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    findNavInNestedMenu: function(arr, id) {
      for (const nav of arr) {
        if (nav["ID"] == id) {
          return nav;
        } else if (nav["NextLayer"] && nav["NextLayer"].length > 0) {
          const ret = this.findNavInNestedMenu(nav["NextLayer"], id);
          if (ret) {
            return ret;
          }
        }
      }
      return null;
    },
    deleteNavInNestedMenu: function(arr, id) {
      for (const [i, nav] of arr.entries()) {
        if (nav["ID"] == id) {
          arr.splice(i, 1);
          return true;
        } else if (nav["NextLayer"] && nav["NextLayer"].length > 0) {
          const ret = this.deleteNavInNestedMenu(nav["NextLayer"], id);
          if (ret) {
            return ret;
          }
        }
      }
      return false;
    },
    resetAddDialogPopupContent: function() {
      for (const [key, value] of Object.entries(this.addDialogPopupContent)) {
        console.log(value);
        for (var i = 0; i < value.length; i++) {
          if (value[i]["key"] == "Title") {
            value[i]["value"] = {
              en: "",
              zh: ""
            };
          } else if (value[i]["key"] == "TitleColour") {
            value[i]["value"] = "#313131";
          } else {
            value[i]["value"] = null;
          }
        }
      }
      this.formItem = {};
      console.log("resetAddDialogPopupContent", this.addDialogPopupContent);
    },
    /*** End of Page Function  ***/

    /***  open Popup  ***/
    setAddDialog: function() {
      this.formItem = this.addDialogPopupContent;
      this.newNavTarget = this.Footer;
      this.showAddDialog = true;
    },
    showDelLangPopup: function(lang, id) {
      console.log("showDelLangPopup", lang, id);
      this.delLang.lang = lang;
      this.delLang.id = id;
      this.delLangPopupTitle = "Delete Language from " + id;
      this.delLangPopupContent =
        "Are you sure you want to delete " + lang + " from " + id;
      this.showDeleteLangModal = true;
    },
    setNewLangId: function(id) {
      console.log("setNewLangId", id);
      this.newLang = id;
      this.showNewLangModal = true;
      this.newLangPopupTitle = "Add Language to " + id;
    },
    setAddDialogTarget: function(target) {
      const nav = this.findNavInNestedMenu(this.Footer, target);
      this.newNavTarget = nav;
      this.formItem = this.addDialogPopupContent;
      this.showAddDialog = true;
    },
    confirmRemoveItem: function(id) {
      this.removeItemID = id;
      this.removeItemPopupTitle = "Delete Item " + id;
      this.removeItemPopupContent = "Are you sure you want to delete " + id;
      this.showRemoveItemPopUp = true;
    },
    /***  end of open Popup  ***/

    /***  Popup Action  ***/
    removeItemPopupAction: function() {
      this.showRemoveItemPopUp = false;
      var ret = this.deleteNavInNestedMenu(this.Footer, this.removeItemID);
      this.removeItemID = "";
    },
    addDialogAction: function(data) {
      this.showAddDialog = false;
      data.NextLayer = [];
      data.ID = new Date().getTime();
      if (Array.isArray(this.newNavTarget)) {
        this.newNavTarget.push(data);
      } else {
        this.newNavTarget.NextLayer.push(data);
      }
      this.resetAddDialogPopupContent();
    },
    newLangPopupAction: function(lang) {
      this.showNewLangModal = false;
      const nav = this.findNavInNestedMenu(this.Footer, this.newLang);
      this.$set(nav.Title, lang.lang, "");
      this.$set(nav.Content, lang.lang, "");
      this.newLang = "";
    },
    delLangPopupAction: function() {
      this.showDeleteLangModal = false;
      const nav = this.findNavInNestedMenu(this.Footer, this.delLang.id);
      this.$delete(nav.Title, this.delLang.lang);
      this.$delete(nav.Content, this.delLang.lang);
      this.delLang = {
        lang: "",
        id: ""
      };
    },
    popupcancel: function() {
      this.showDeleteLangModal = false;
      this.showNewLangModal = false;
      this.showAddDialog = false;
      this.showRemoveItemPopUp = false;
    }
    /***  end of Popup Action  ***/
  },
  data() {
    return {
      isLoading: true,
      HeaderTitle: "Footer",
      formActionButton: "Save",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      HtmlPart: "",
      Footer: [],
      allowAddBelow: true,
      sending: false,
      showDeleteLangModal: false,
      showRemoveItemPopUp: false,
      showNewLangModal: false,
      showAddDialog: false,
      newNavTarget: null,
      delLang: {
        lang: "",
        id: ""
      },
      newLang: "",
      delLangPopupTitle: "",
      delLangPopupContent: "",
      delLangActionButton: "Delete",
      newLangPopupTitle: "",
      addDialogPopupTitle: "Add New Navigation",
      newLangContent: {
        row1: [
          {
            type: "text",
            key: "lang",
            name: "New Language",
            required: true,
            style: "width:100%",
            value: null
          }
        ]
      },
      formItem: {},
      addDialogPopupContent: {
        row1: [
          {
            type: "object",
            key: "Title",
            name: "Title",
            style: "width:100%",
            value: {
              en: "",
              zh: ""
            }
          }
        ],
        row2: [
          {
            type: "object",
            key: "Content",
            name: "Content",
            style: "width:100%",
            value: {
              en: "",
              zh: ""
            }
          }
        ],
        row3: [
          {
            type: "text",
            key: "Url",
            name: "Url",
            required: false,
            style: "width:100%",
            value: null
          }
        ]
      },
      newLangActionButton: "Add",
      addDialogActionButton: "Add",
      removeItemID: null,
      removeItemPopupTitle: "",
      removeItemPopupContent: "",
      removeItemActionButton: "Delete"
    };
  }
};
</script>
