<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <FormHeader
          v-on:formAction="formAction"
          :HeaderTitle="HeaderTitle"
          :confirmBack="confirmBack"
          :formActionButton="formActionButton"
        >
        </FormHeader>
        <center>
          <md-card style="width:55%;min-width: 500px">
            <md-card-content>
              <FormComponent
                v-on:formSubmit="formSubmit"
                v-on:checkEdited="checkEdited"
                :FormTitle="FormTitle"
                :FormItem="FormItem"
                :formMessage="formMessage"
                :showFormMessage="showFormMessage"
                :haveDoneButton="false"
              >
              </FormComponent>
            </md-card-content>
          </md-card>

          <md-card style="width:55%;min-width: 500px">
            <md-card-content>
              <ProductSelecter
                :ProductList="InventoryProductList"
                :HeaderTitle="PopHeaderTitle"
                :haveQuantity="true"
              >
              </ProductSelecter>
            </md-card-content>
          </md-card>
        </center>
      </div>
    </div>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import {
  FormComponent,
  FormHeader,
  ProductSelecter,
  Spinner
} from "@/components";

export default {
  components: {
    FormComponent,
    FormHeader,
    ProductSelecter,
    Spinner
  },
  created() {},
  methods: {
    checkEdited: async function(check) {
      this.confirmBack = check;
    },
    formAction: async function() {
      this.sending = true;
      var data = await this.getFormData(this.FormItem);
      data.ProductList = this.InventoryProductList;
      console.log("Data", data);
      this.isLoading = true;
      try {
        const ret = await this.$store.dispatch("createInventory", {
          router: this.$router,
          data: data
        });
        console.log("createInventory", ret);
        this.sending = false;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.replace({
            name: "Inventory List",
            params: { message: "New Inventory has been created" }
          });
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        console.log("createInventory error", err.response.data);
        this.formMessage = err.response.data.message;
        this.showFormMessage = true;
      }
    },
    getFormData: async function(form) {
      var FormData = {};
      for (const [key, value] of Object.entries(form)) {
        for (var i = 0; i < value.length; i++) {
          if (value[i].required) {
            if (value[i].value) {
              if (value[i].type == "number") {
                FormData[value[i].key] = parseInt(value[i].value);
              } else {
                FormData[value[i].key] = value[i].value;
              }
            } else {
              this.formMessage = value[i].name + " is required";
              this.showFormMessage = true;
              return;
            }
          } else {
            if (value[i].type == "number") {
              FormData[value[i].key] = parseInt(value[i].value);
            } else {
              FormData[value[i].key] = value[i].value;
            }
          }
        }
      }
      return FormData;
    },
    formSubmit: async function(object) {}
  },

  data() {
    return {
      isLoading: false,
      HeaderTitle: "Add Inventory",
      FormTitle: "New Inventory",
      PopHeaderTitle: "Add Product to Inventory",
      formMessage: null,
      showFormMessage: false,
      confirmBack: false,
      InventoryProductList: [],
      formActionButton: "Save",
      FormItem: {
        row1: [
          {
            type: "object",
            key: "InventoryName",
            name: "Name",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ],
        row2: [
          {
            type: "object",
            key: "InventoryAddress",
            name: "Address",
            required: true,
            style: "width:100%",
            value: {
              en: null,
              zh: null
            }
          }
        ]
      }
    };
  }
};
</script>
