import oneSipApiCall from "../apiCall.js";
import axios from "axios";
import url from "../config.js";
import { get } from "jquery";

const userGroup = {
  searchCoupon({ commit, state }, { router, keyword }) {
    return oneSipApiCall(
      commit,
      state,
      router,
      "/admin-search-coupon",
      keyword
    );
  },
  getCoupon({ commit, state }, { router, coupon }) {
    return oneSipApiCall(commit, state, router, "/admin-get-coupon", coupon);
  },
  saveCoupon({ commit, state }, { router, coupon }) {
    return oneSipApiCall(commit, state, router, "/admin-add-coupon", coupon);
  },
  updateCoupon({ commit, state }, { router, coupon }) {
    return oneSipApiCall(commit, state, router, "/admin-update-coupon", coupon);
  }
};

export default userGroup;
